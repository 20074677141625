import { useRef, useState, useEffect, useContext, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Link, useLocation, useHistory } from 'react-router-dom'
import CountUp from 'react-countup'
import { Context as GeneralContext } from '@/context'
import { Context as AudioContext } from '@/context/audio'
import Meta from '@/components/Meta'
import RevealText from '@/components/RevealText'
import RevealFadeIn from '@/components/RevealFadeIn'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import usePagePrecache from '@/hooks/usePagePrecache'
import * as routerActions from '@/actions/fakerouter'
import * as experienceActions from '@/actions/experience'
import * as contentActions from '@/actions/content'
import useStore from '@/base/zustand'
import { convertRichText,convertStrapiRichText } from '@/utils/strings'
import style from './style'
import { API_BASE_URL } from '@/constants'
import NewQuestions from '../NewQuestions'
import { fetchStrapiContent } from '../../../actions/content'

const useStyles = createUseStyles(style)

const Begin = () => {
  const { setPageAnimationReady, pageAnimationReady, headerHeight } = useContext(GeneralContext)
  const { setAudioActive, setAudioControlsVisible, setFirstTimeAudio, isFirstTimeAudio } = useContext(AudioContext)
  const { setFirstTimeAnimation, setScales, setActive } = useStore()
  const [isDataFetched, setDataFetched] = useState(false)
  const [introductionScore, setIntroductionScore] = useState(0)
  const [introductionVariation, setIntroductionVariation] = useState(0)
  const $root = useRef()
  const slug = 'landing-page'
  /*------------------------------
  Redux Store
  ------------------------------*/
  const { currentLanguage,page, fakeLocation, dailyEvents } = useSelector((state) => ({
    content:state.content,
    page: state.content.['landing-page'] || {},
    fakeLocation: state.fakerouter.location,
    dailyEvents: state.events.dailyEvents,
    currentLanguage:state.locale.currentLanguage
  }), shallowEqual)

  const classes = useStyles({ headerHeight })

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const moveForward = useCallback(() => dispatch(routerActions.moveForward()), [dispatch])
  const setStartingExperience = useCallback((bool) => dispatch(experienceActions.setStartingExperience(bool)), [dispatch])
  const setEndedExperience = useCallback((bool) => dispatch(experienceActions.setEndedExperience(bool)), [dispatch])
  const fetchContent = useCallback((slug) => dispatch(contentActions.fetchStrapiContent(slug,'landing-pages')), [dispatch])
  const resetExperience = useCallback(() => dispatch(experienceActions.resetExperience()), [dispatch])
  const fetchLandingPage = useCallback(  (slug) => dispatch(contentActions.fetchStrapiContent(slug, "landing-pages")), [dispatch]);
  const location = useLocation()
  const history = useHistory()

  /*------------------------------
  Fetch Page Data
  ------------------------------*/
  useEffect(() => {
    // if (Object.keys(page).length === 0){};
    console.log('yey')
    fetchLandingPage(slug)

  }, [currentLanguage])
  // useEffect(()=>{
  //   if(Object.keys(page).length > 0){
  //     const a= page;
  //     let b= convertRichText(page.heading);
  //     debugger;
  //   }
  // },[page])
  useEffect(() => {
    localStorage.removeItem('expState')
    resetExperience()
  }, [])

  // Fetch Introduction score and percentage
  useEffect(() => {
    fetch(`${API_BASE_URL}/map/mind?level=world`).then((response) => response.json()).then((response) => {
      setIntroductionVariation(parseInt(response.data.variation))
      setIntroductionScore(parseInt(response.data.score * 100))
    }).catch((error) => {
      setIntroductionVariation(18)
      setIntroductionScore(73)
    })
  }, [])

  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0) setDataFetched(true)
  }, [page])

  useEffect(() => {
    if (location.hash === '#start-experience') {
      handleClickNext()
    }

    if (location.hash === '#prize') {
      localStorage.setItem('checkPrizeBox', true)
      handleClickNext()
    }
  }, [load])

  /*------------------------------
  Preload
  ------------------------------*/
  const [load] = usePagePrecache({
    init: isDataFetched,
    sources: [],
    callback: () => setPageAnimationReady(true),
  })

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && <Meta />
  }

  const handleClickNext = () => {
    // RESET
    setFirstTimeAnimation(true)
    setScales([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    setActive(-1)
    //

    setStartingExperience(false)
    setEndedExperience(false)
    setAudioControlsVisible(true)
    if (isFirstTimeAudio) {
      setFirstTimeAudio(false)
      setAudioActive(true)
    }
    moveForward()
  }

  /*------------------------------
  Render Hero
  ------------------------------*/
  const renderHero = () => {
    return (
      <div className={classes.hero}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
          <div className={classes.wrapper}>
            <div className={classes.wrapperInside}>
              <RevealText
                tag="h1"
                type="lines"
                value={convertStrapiRichText(page.heading)}
                visible={pageAnimationReady}
                lineHeight={1.2}
                delay={0}
                className={classes.beginTitle}
              />
              <RevealText
                tag="div"
                type="lines"
                value={convertStrapiRichText(page.bodyCopy)}
                visible={pageAnimationReady}
                lineHeight={1.4}
                delay={1.3}
                className={classes.beginText}
              />
              <RevealFadeIn
                delay={2}
                visible={pageAnimationReady}
              >
                <div className={classes.introButtonWrapper}>
                  <button
                    className={classes.btn}
                    onClick={handleClickNext}
                  >
                    <span>{page.button}</span>
                  </button>
                  <div className={classes.buttonSeparator} />
                  <div className={classes.firstTimeText}>
                    <RevealText
                      tag="div"
                      type="lines"
                      value={convertStrapiRichText(page.subCopy)}
                      visible={pageAnimationReady}
                      lineHeight={1.2}
                      delay={0}
                      className={classes.findOutButton}
                    />                 
                  </div>
                </div>
              </RevealFadeIn>
            </div>
            <div className={classes.introRight}>
              <h1>WORLD UPLIFT</h1>
              <h1 className={classes.percentage}>
                +
                {pageAnimationReady ? <CountUp start={0} end={introductionVariation} duration={2} /> : '0'}
                %
              </h1>
              <img src={page.background} style={{ position: 'absolute', right: 0, bottom: 0, zIndex: -1 }} />
              <div className={classes.score}>
                <h1>SCORE</h1>
                <h1>
                  {pageAnimationReady ? <CountUp start={0} end={introductionScore} duration={2} /> : '0'}
                  /100
                </h1>
              </div>
            </div>
          </div>
        </div>
        {/* <RevealText
          tag="div"
          type="lines"
          value={convertRichText(page.disclaimer)}
          visible={pageAnimationReady}
          lineHeight={1.2}
          delay={2.2}
          className={classes.beginDisclaimer}
        /> */}
      </div>
    )
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return (
      load && (
        <>
          <div className={classes.page}>
            {renderHero()}
          </div>
        </>
      )
    )
  }

  return (
    <LocomotiveScroll
      init={load}
      className={`page pageBegin ${classes.root}`}
      ref={$root}
    >
      {renderHelmet()}
      {renderContent()}
    </LocomotiveScroll>
  )
}

export default Begin
