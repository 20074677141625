import { useRef, useState, useEffect, useContext, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import copy from "copy-to-clipboard";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Context as GeneralContext } from "@/context";
import Meta from "@/components/Meta";
import RevealText from "@/components/RevealText";
import ScrollIcon from "@/components/ScrollIcon";
import DelayLink from "@/components/DelayLink";
import InViewTrigger from "@/components/InViewTrigger";
import RevealFadeIn from "@/components/RevealFadeIn";
import LocomotiveScroll from "@/components/LocomotiveScroll";
import usePagePrecache from "@/hooks/usePagePrecache";
import useWindowSize from "@/hooks/useWindowSize";
import * as experienceActions from "@/actions/experience";
import * as contentActions from "@/actions/content";
import { SITE, API, API_BASE_URL } from "@/constants";
import { convertRichText } from "@/utils/strings";
import style from "./style";
import LiteEndProgress from "../../../components/LiteEndProgress";

const useStyles = createUseStyles(style);

const LiteEventStateOfMind = () => {
  const {
    setPageAnimationReady,
    pageAnimationReady,
    setHeaderInverse,
    headerHeight,
  } = useContext(GeneralContext);
  const classes = useStyles({ headerHeight });
  const [isDataFetched, setDataFetched] = useState(false);
  const $root = useRef();
  const stub = useRef("lite-event-average");
  const [pageContentId, setPageContentId] = useState("");
  const { width, height } = useWindowSize();
  const [heightFactor, setHeightFactor] = useState(0.62);
  const [compheight, setCompHeight] = useState((height - 120) * heightFactor);

  useEffect(() => {
    if (width <= 719) {
      setHeightFactor(0.49);
    } else {
      setHeightFactor(0.67);
    }
  }, [width]);

  useEffect(() => {
    if (width >= 992) {
      let sWidth = width * 0.45;
      let sHeight = height * heightFactor;
      let size = sWidth > sHeight ? sHeight : sWidth;
      setCompHeight(sHeight);
    } else {
      let sWidth = width * 0.80;
      let sHeight = height * heightFactor;
      let size = sHeight;
      setCompHeight(sWidth);
    }
  }, [width, height, heightFactor]);

  /*------------------------------
  Redux Store
  ------------------------------*/
  const {
    currentLanguage,
    page,
    experience,
    strings,
    schema,
    isEndedExperience,
    eventName,
    eventId,
    events,
    score,
  } = useSelector(
    (state) => ({
      page: state.content[stub.current] || {},
      experience: state.experience,
      isLoggedIn: state.user.isLoggedIn,
      strings: state.options.strings,
      schema: state.locale.schema,
      currentLanguage: state.locale.currentLanguage,
      isEndedExperience: state.experience.isEndedExperience,
      eventName: state.liteExperience?.event_name || "",
      eventId: state.liteExperience.event_id,
      events: state.liteEvent.liteEvents,
      score: state.liteExperience?.eventStateOfMind || 0,
    }),
    shallowEqual
  );
  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch();
  const resetExperience = useCallback(
    () => dispatch(experienceActions.resetExperience()),
    [dispatch]
  );
  const fetchPage = useCallback(
    (id, slug) =>
      dispatch(contentActions.fetchEventContent(id, slug, "liteEndEventStateOfMind")),
    [dispatch]
  );

  const setEndedExperience = useCallback(
    (bool) => dispatch(experienceActions.setEndedExperience(bool)),
    [dispatch]
  );
  const history = useHistory();
  useEffect(() => {
    if (eventId && events.length > 0) {
      setPageContentId(
        events.find((e) => e.sys.id == eventId)?.fields.endPageEventStateOfMind.sys.id
      );
    }
  }, [eventId, events]);
  useEffect(() => {
    if (width <= 500) {
      setHeightFactor(0.35);
    } else {
      setHeightFactor(0.62);
    }
  }, [width]);
  /*------------------------------
  Fetch Page Data
  ------------------------------*/
  useEffect(() => {
    if (pageContentId && Object.keys(page).length === 0) {
      fetchPage(pageContentId, stub.current);
    }
  }, [pageContentId]);

  useEffect(() => {
    if (Object.keys(page).length > 0) fetchPage(pageContentId, stub.current);
  }, [currentLanguage]);
  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0) {
      setDataFetched(true);
    }
  }, [page]);

  /*------------------------------
  Header Inverse
  ------------------------------*/
  useEffect(() => {
    setHeaderInverse(true);
    return () => setHeaderInverse(false);
  }, []);

  /*------------------------------
  Preload
  ------------------------------*/
  const [load] = usePagePrecache({
    init: isDataFetched,
    callback: () => setPageAnimationReady(true),
  });

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && <Meta />;
  };

  /*------------------------------
  Render Sharing
  ------------------------------*/
  const renderEnd = () => {
    return (
      <div className={classes.hero}>
        <div
          className={classes.wrapper}
          style={{
            "--width": width,
            "--height": height,
          }}
        >
          <div className={classes.content}>
            <RevealText
              tag="h1"
              type="lines"
              value={page.heading.replace("${eventName}", eventName)}
              visible={pageAnimationReady}
              delay={1.3}
              className={classes.title}
            />
            <RevealText
              tag="div"
              type="lines"
              value={convertRichText(page.bodyCopy)}
              visible={pageAnimationReady}
              lineHeight={1.4}
              delay={1.3}
              className={"copy"}
            />
          </div>
          <div
            className={classes.progress}
            style={{
              "--width": width,
              "--height": height,
            }}
          >
            {pageAnimationReady && (
              <RevealFadeIn delay={1} inview={pageAnimationReady}>
                <>
                  <LiteEndProgress
                    color={"#FFFFFF"}
                    score={score}
                    size={`${compheight}px`}
                  />
                </>
              </RevealFadeIn>
            )}
          </div>
        </div>
      </div>
    );
  };

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return (
      load && (
        <>
          <div className={classes.page}>{renderEnd()}</div>
        </>
      )
    );
  };

  return (
    <LocomotiveScroll
      init={load}
      className={`page pageSharePage ${classes.root}`}
      ref={$root}
    >
      {renderHelmet()}
      {renderContent()}
    </LocomotiveScroll>
  );
};

export default LiteEventStateOfMind;
