import axios from "axios";
import omit from "omit.js";
import fileDownload from "js-file-download";
import {
  SET_CURRENT_EVENT,
  SET_SINGLE_EMOTION,
  SET_SPORT,
  SET_UPLIFTER_PROGRAM_ID,
  SET_STEP,
  SET_TIME,
  SET_START_TIME,
  SET_END_TIME,
  SET_AGE,
  SET_GENDER,
  SET_EXERTION,
  SET_LOTTERY,
  SET_PRIZE_MAIL_BODY,
  SET_EVENT_LOCATION,
  SET_USER_IMAGE,
  SET_UUID,
  SET_IMAGE,
  SET_IMAGE_DOWNLOAD,
  SET_IMAGE_THUMB,
  SET_IMAGE_GIF_1,
  SET_IMAGE_GIF_2,
  SET_IMAGE_GIF_3,
  SET_IMAGE_GIF_4,
  REQUEST_GIF,
  SET_TOTAL_UPLIFT,
  SET_STARTING_EXPERIENCE,
  SET_ENDED_EXPERIENCE,
  SET_EXPERIENCE,
  RESET_EXPERIENCE,
  REQUEST_EXPERIENCE,
  RECEIVE_EXPERIENCE,
  REQUEST_SAVE_EXPERIENCE,
  RECEIVE_SAVE_EXPERIENCE,
  DENIED_CAMERA,
  REQUEST_GEOCODING,
  RECEIVE_GEOCODING,
} from "@/actions/types";
import { setCountryCode } from "@/actions/events";
import { removeNullProperties } from "@/utils/array";
import { API } from "@/constants";

const setEvent = (id, name) => ({
  type: SET_CURRENT_EVENT,
  payload: name,
  id,
});

const setSport = (id) => ({
  type: SET_SPORT,
  payload: id,
});

const setUplifterProgramId = (id) => ({
  type: SET_UPLIFTER_PROGRAM_ID,
  payload: id,
});

const setUserImage = (value) => ({
  type: SET_USER_IMAGE,
  payload: value,
});

const setCameraDenied = () => ({ type: DENIED_CAMERA });

const setSingleEmotion = (value, key, status) => {
  return {
    type: SET_SINGLE_EMOTION,
    payload: value,
    key,
    status,
  };
};

const setStep = (value) => ({
  type: SET_STEP,
  payload: value,
});

const setTime = (value) => ({
  type: SET_TIME,
  payload: value,
});

const setStartTime = (number) => ({
  type: SET_START_TIME,
  payload: number,
});

const setEndTime = (number) => ({
  type: SET_END_TIME,
  payload: number,
});

const setAge = (number) => ({
  type: SET_AGE,
  payload: number,
});

const setGender = (number) => ({
  type: SET_GENDER,
  payload: number,
});

const setExertion = (number) => ({
  type: SET_EXERTION,
  payload: number,
});

const setLottery = (bool) => ({
  type: SET_LOTTERY,
  payload: bool,
});
const setPrizeMailBody = (text) => ({
  type: SET_PRIZE_MAIL_BODY,
  payload: text,
});
const setEventLocation = (object) => ({
  type: SET_EVENT_LOCATION,
  payload: object,
});

const setUUID = (value) => ({
  type: SET_UUID,
  payload: value,
});

const setImage = (value) => ({
  type: SET_IMAGE,
  payload: value,
});

const setImageDownload = (bytecode) => ({
  type: SET_IMAGE_DOWNLOAD,
  payload: bytecode,
});

const setImageThumb = (bytecode) => ({
  type: SET_IMAGE_THUMB,
  payload: bytecode,
});

const setImageGif1 = (bytecode) => ({
  type: SET_IMAGE_GIF_1,
  payload: bytecode,
});

const setImageGif2 = (bytecode) => ({
  type: SET_IMAGE_GIF_2,
  payload: bytecode,
});

const setImageGif3 = (bytecode) => ({
  type: SET_IMAGE_GIF_3,
  payload: bytecode,
});

const setImageGif4 = (bytecode) => ({
  type: SET_IMAGE_GIF_4,
  payload: bytecode,
});

const setTotalUplift = (bytecode) => ({
  type: SET_TOTAL_UPLIFT,
  payload: bytecode,
});

const setStartingExperience = (bool) => ({
  type: SET_STARTING_EXPERIENCE,
  payload: bool,
});

const setEndedExperience = (bool) => ({
  type: SET_ENDED_EXPERIENCE,
  payload: bool,
});

const setExperience = (value) => ({
  type: SET_EXPERIENCE,
  payload: value,
});

const resetExperience = () => ({ type: RESET_EXPERIENCE });

const requestExperience = () => ({ type: REQUEST_EXPERIENCE });

const receiveExperience = (data, uuid) => ({
  type: RECEIVE_EXPERIENCE,
  payload: data,
  uuid,
});

const fetchExperience = (uuid) => async (dispatch) => {
  dispatch(requestExperience());
  const response = await axios.get(`${API.GET_EXPERIENCE}/${uuid}`);
  dispatch(receiveExperience(removeNullProperties(response.data), uuid));
  return response;
};

const requestSaveExperience = () => ({ type: REQUEST_SAVE_EXPERIENCE });

const receiveSaveExperience = (data) => ({
  type: RECEIVE_SAVE_EXPERIENCE,
  payload: data,
});

const savePartialExperience = (data) => async (dispatch, getState) => {
  const token = getUserToken(getState());

  dispatch(requestSaveExperience());
  const lang = getState().locale.schema[getState().locale.currentLanguage].code;
  let response;
  try {
    if (getState().user.isLoggedIn) {
      response = await axios.put(
        `${API.SAVE_USER_PARTIAL_EXPERIENCE}`,
        {
          ...data,
          ...omit(getState().experience, [
            "isStartingExperience",
            "isCameraDenied",
            "step",
            "user_image",
          ]),
          lang,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else {
      response = await axios.put(`${API.SAVE_PARTIAL_EXPERIENCE}`, {
        ...data,
        ...omit(getState().experience, [
          "isStartingExperience",
          "isCameraDenied",
          "step",
          "user_image",
        ]),
        lang,
      });
    }

    dispatch(receiveSaveExperience(response.data));

    return response;
  } catch (error) {
    console.error(error);
  }
};

const viewPastExperience = (data) => async (dispatch) => {
  dispatch(receiveExperience(data));
};

const saveFullExperience = () => async (dispatch, getState) => {
  const token = getUserToken(getState());
  dispatch(requestSaveExperience());
  const lang = getState().locale.schema[getState().locale.currentLanguage].code;
  let response;
  try {
    if (getState().user.isLoggedIn) {
      response = await axios.put(
        `${API.SAVE_USER_FULL_EXPERIENCE}`,
        {
          ...omit(getState().experience, [
            "isStartingExperience",
            "isCameraDenied",
            "step",
            "user_image",
            "total_uplift",
            "gif_1",
            "gif_2",
            "gif_3",
            "gif_4",
          ]),
          lang,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else {
      response = await axios.put(`${API.SAVE_FULL_EXPERIENCE}`, {
        ...omit(getState().experience, [
          "isStartingExperience",
          "isCameraDenied",
          "step",
          "user_image",
          "total_uplift",
          "gif_1",
          "gif_2",
          "gif_3",
          "gif_4",
        ]),
        lang,
      });
    }

    dispatch(receiveSaveExperience(response.data));

    return response;
  } catch (error) {
    console.log(error);
  }
};

const saveExperienceImages = () => async (dispatch, getState) => {
  const token = getUserToken(getState());

  const response = await axios.put(
    `${API.SAVE_EXPERIENCE_IMAGES}`,
    {
      ...omit(getState().experience, [
        "isStartingExperience",
        "isCameraDenied",
        "step",
        "user_image",
        "total_uplift",
        "gif_1",
        "gif_2",
        "gif_3",
        "gif_4",
      ]),
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return response;
};
const requestGif = () => ({ type: REQUEST_GIF });

const gif = () => (dispatch, getState) => {
  dispatch(requestGif());
  axios({
    method: "post",
    url: `${API.GIF}`,
    responseType: "blob",
    data: {
      image_frames: 4,
      image_1: getState().experience.gif_1,
      image_2: getState().experience.gif_2,
      image_3: getState().experience.gif_3,
      image_4: getState().experience.gif_4,
      uuid: getState().experience.uuid,
    },
  }).then((res) => {
    fileDownload(res.data, "ASICS.mp4");
  });
};
const saveGifOnS3 = () => (dispatch, getState) => {
  console.warn("VIDEO");
  return axios({
    method: "post",
    url: `${API.GIF}`,
    responseType: "blob",
    data: {
      image_frames: 4,
      image_1: getState().experience.gif_1,
      image_2: getState().experience.gif_2,
      image_3: getState().experience.gif_3,
      image_4: getState().experience.gif_4,
      uuid: getState().experience.uuid,
    },
  });
};

const getGif = () => (dispatch, getState) => {
  dispatch(requestGif());
  axios({
    method: "get",
    url: `${API.GIF}/${getState().experience.uuid}`,
    responseType: "blob",
  }).then((res) => {
    fileDownload(res.data, "ASICS.mp4");
  });
};

const requestGeocoding = () => ({ type: REQUEST_GEOCODING });

const receiveGeocoding = (data) => ({
  type: RECEIVE_GEOCODING,
  payload: data,
});

const fetchGeocoding = (coordinate) => async (dispatch) => {
  dispatch(requestGeocoding());
  const params = {
    params: {
      latitude: coordinate[0],
      longitude: coordinate[1],
      localityLanguage: "en",
    },
  };
  const response = await axios.get(`${API.BIGDATACLOUD}`, params);
  // Save country_code for Tags purpose
  dispatch(setCountryCode(response.data.countryCode));
  dispatch(receiveGeocoding(response.data));
};

const fetchEventLocation =
  (coordinate, city = "") =>
  async (dispatch) => {
    let event_location = {
      lat: coordinate.lat,
      lon: coordinate.lon,
      city: "",
    };
    if (!city) {
      const params = {
        params: {
          latitude: coordinate.lat,
          longitude: coordinate.lon,
          localityLanguage: "en",
        },
      };
      const response = await axios.get(`${API.BIGDATACLOUD}`, params);
      event_location = {
        ...event_location,
        city: response.data.city,
      };
    } else {
      event_location = {
        ...event_location,
        city,
      };
    }
    dispatch(setEventLocation(event_location));
  };

const getUserToken = (state) => {
  let token = "";
  if (state.user.token) {
    token = state.user.token;
  } else {
    const state = JSON.parse(localStorage.getItem("loginState"));
    if (state && state.token) {
      token = state.token;
    }
  }

  return token;
};

export {
  fetchExperience,
  fetchGeocoding,
  fetchEventLocation,
  gif,
  getGif,
  saveGifOnS3,
  resetExperience,
  saveFullExperience,
  savePartialExperience,
  saveExperienceImages,
  setCameraDenied,
  setEvent,
  setImage,
  setImageDownload,
  setImageGif1,
  setImageGif2,
  setImageGif3,
  setImageGif4,
  setImageThumb,
  setSingleEmotion,
  setSport,
  setUplifterProgramId,
  setStartingExperience,
  setEndedExperience,
  setExperience,
  setStep,
  setTime,
  setStartTime,
  setEndTime,
  setAge,
  setGender,
  setExertion,
  setLottery,
  setPrizeMailBody,
  setEventLocation,
  setUUID,
  setTotalUplift,
  setUserImage,
  viewPastExperience,
};
