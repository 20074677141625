import axios from "axios";
import omit from "omit.js";
import fileDownload from "js-file-download";
import {
  SET_CURRENT_LITE_EVENT,
  SET_LITE_SINGLE_EMOTION,
  SET_SPORT,
  SET_UPLIFTER_PROGRAM_ID,
  SET_STEP,
  SET_TIME,
  SET_START_TIME,
  SET_END_TIME,
  SET_AGE,
  SET_GENDER,
  SET_EXERTION,
  SET_LOTTERY,
  SET_PRIZE_MAIL_BODY,
  SET_EVENT_LOCATION,
  SET_USER_IMAGE,
  SET_UUID,
  SET_IMAGE,
  SET_IMAGE_DOWNLOAD,
  SET_IMAGE_THUMB,
  SET_IMAGE_GIF_1,
  SET_IMAGE_GIF_2,
  SET_IMAGE_GIF_3,
  SET_IMAGE_GIF_4,
  REQUEST_GIF,
  SET_TOTAL_UPLIFT,
  SET_STARTING_EXPERIENCE,
  SET_ENDED_EXPERIENCE,
  SET_EXPERIENCE,
  RESET_EXPERIENCE,
  RESET_LITE_EXPERIENCE,
  REQUEST_EXPERIENCE,
  RECEIVE_LITE_EXPERIENCE,
  RECEIVE_EVENT_STATE_OF_MIND,
  RECEIVE_LITE_EXPERIENCE_AVGS,
  REQUEST_SAVE_EXPERIENCE,
  RECEIVE_LITE_SAVE_EXPERIENCE,
  RECEIVE_LITE_EVENT_STATE_OF_MIND,
  RECEIVE_SAVE_EXPERIENCE,
  DENIED_CAMERA,
  REQUEST_GEOCODING,
  RECEIVE_GEOCODING,
} from "@/actions/types";
import { setCountryCode } from "@/actions/events";
import { removeNullProperties } from "@/utils/array";
import { API } from "@/constants";
import { SET_LITE_EXPERIENCE_AVGS } from "./types";

const setLiteEvent = (id, name) => ({
  type: SET_CURRENT_LITE_EVENT,
  payload: name,
  id,
});
const saveLiteExperience = () => async (dispatch, getState) => {
  let response = await axios.put(
    `${API.SAVE_LITE_EXPERIENCE}`,
    getState().liteExperience
  );
  dispatch(receiveLiteSaveExperience(response.data));

  return response;
};
const getEventStateOfMind = (eventId) => async (dispatch, getState) => {
  let response = await axios.get(
    `${API.EVENT_STATE_OF_MIND.replace("${event_id}", eventId)}`
  );
  dispatch(receiveEventStateOfMind(response.data));
  return response;
};
const receiveEventStateOfMind = (score) => ({
  type: RECEIVE_LITE_EVENT_STATE_OF_MIND,
  payload: score,
});
const receiveLiteSaveExperience = (data) => ({
  type: RECEIVE_LITE_SAVE_EXPERIENCE,
  payload: data,
});

const _calculateGroupAvg = (score, length) => {
  return Math.round((length * score) / (length * 10));
};

const setLiteExperinceAverages = () => async (dispatch, getState) => {
  const emotions = getState().liteExperience.emotions;
  const emotionGroups = { cognitive: [], emotional: [] };
  Object.keys(emotions).forEach((key) => {
    if (emotions[key].group == "cognitive") {
      emotionGroups["cognitive"].push(emotions[key]);
    } else {
      emotionGroups["emotional"].push(emotions[key]);
    }
  });
  let cognitiveScore = 0;
  emotionGroups["cognitive"].forEach((item) => {
    cognitiveScore = cognitiveScore + item.post;
  });
  let emotionalScore = 0;
  emotionGroups["emotional"].forEach((item) => {
    emotionalScore = emotionalScore + item.post;
  });

  const emotionalLength = emotionGroups["emotional"].length;
  const cognitiveLength = emotionGroups["cognitive"].length;
  const score = cognitiveScore + emotionalScore;
  dispatch(
    receiveLiteExperinceAverages(
      cognitiveScore,
      emotionalScore,
      cognitiveLength,
      emotionalLength,
      score
    )
  );
};
const receiveLiteExperinceAverages = (
  cognitiveScore,
  emotionalScore,
  cognitiveLength,
  emotionalLength,
  expStateOfMind
) => {
  
  return {
    type: RECEIVE_LITE_EXPERIENCE_AVGS,
    cognitiveScore,
    emotionalScore,
    cognitiveLength,
    emotionalLength,
    expStateOfMind
  };
};
const setLiteSingleEmotion = (value, key, status) => {
  return {
    type: SET_LITE_SINGLE_EMOTION,
    payload: value,
    key,
    status,
  };
};

const setEventLocation = (object) => ({
  type: SET_EVENT_LOCATION,
  payload: object,
});

const setUUID = (value) => ({
  type: SET_UUID,
  payload: value,
});

const setStartingExperience = (bool) => ({
  type: SET_STARTING_EXPERIENCE,
  payload: bool,
});

const setEndedExperience = (bool) => ({
  type: SET_ENDED_EXPERIENCE,
  payload: bool,
});

const setExperience = (value) => ({
  type: SET_EXPERIENCE,
  payload: value,
});

const resetExperience = () => ({ type: RESET_LITE_EXPERIENCE });

const requestSaveExperience = () => ({ type: REQUEST_SAVE_EXPERIENCE });

const receiveSaveExperience = (data) => ({
  type: RECEIVE_SAVE_EXPERIENCE,
  payload: data,
});

const saveFullExperience = () => async (dispatch, getState) => {
  const token = getUserToken(getState());
  dispatch(requestSaveExperience());
  const lang = getState().locale.schema[getState().locale.currentLanguage].code;
  let response;
  if (getState().user.isLoggedIn) {
    response = await axios.put(
      `${API.SAVE_USER_FULL_EXPERIENCE}`,
      {
        ...omit(getState().experience, [
          "isStartingExperience",
          "isCameraDenied",
          "step",
          "user_image",
          "total_uplift",
          "gif_1",
          "gif_2",
          "gif_3",
          "gif_4",
        ]),
        lang,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } else {
    response = await axios.put(`${API.SAVE_FULL_EXPERIENCE}`, {
      ...omit(getState().experience, [
        "isStartingExperience",
        "isCameraDenied",
        "step",
        "user_image",
        "total_uplift",
        "gif_1",
        "gif_2",
        "gif_3",
        "gif_4",
      ]),
      lang,
    });
  }

  dispatch(receiveSaveExperience(response.data));

  return response;
};

const requestGeocoding = () => ({ type: REQUEST_GEOCODING });

const receiveGeocoding = (data) => ({
  type: RECEIVE_GEOCODING,
  payload: data,
});

const fetchGeocoding = (coordinate) => async (dispatch) => {
  dispatch(requestGeocoding());
  const params = {
    params: {
      latitude: coordinate[0],
      longitude: coordinate[1],
      localityLanguage: "en",
    },
  };
  const response = await axios.get(`${API.BIGDATACLOUD}`, params);
  // Save country_code for Tags purpose
  dispatch(setCountryCode(response.data.countryCode));
  dispatch(receiveGeocoding(response.data));
};

const fetchEventLocation =
  (coordinate, city = "") =>
  async (dispatch) => {
    let event_location = {
      lat: coordinate.lat,
      lon: coordinate.lon,
      city: "",
    };
    if (!city) {
      const params = {
        params: {
          latitude: coordinate.lat,
          longitude: coordinate.lon,
          localityLanguage: "en",
        },
      };
      const response = await axios.get(`${API.BIGDATACLOUD}`, params);
      event_location = {
        ...event_location,
        city: response.data.city,
      };
    } else {
      event_location = {
        ...event_location,
        city,
      };
    }
    dispatch(setEventLocation(event_location));
  };

export {
  fetchGeocoding,
  fetchEventLocation,
  resetExperience,
  saveFullExperience,
  setLiteEvent,
  setLiteSingleEmotion,
  setStartingExperience,
  setEndedExperience,
  setExperience,
  setEventLocation,
  setUUID,
  setLiteExperinceAverages,
  saveLiteExperience,
  getEventStateOfMind,
};
