import { createContext, useState, useEffect,useCallback } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import * as localeActions from '@/actions/locale'

import usePrevious from "@/hooks/usePrevious";

const Context = createContext({});
const { Provider, Consumer } = Context;

const LoadingContext = ({ children }) => {
  const [ready, setReady] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [siteLoaded, setSiteLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isFontReady, setFontReady] = useState(false);
  const [isGLBReady, setGLBReady] = useState(false);
  const [isLoaderExited, setLoaderExited] = useState(false);
  const [isLanguageExist, setLanguageExist] = useState(false);

  const { currentLanguage, pathname, schema } = useSelector(
    (state) => ({
      currentLanguage: state.locale.currentLanguage,
      pathname: state.router.location.pathname,
      schema: state.locale.schema,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const prevPathname = usePrevious(pathname);

  const fetchLocales = useCallback(
    () => dispatch(localeActions.fetchStrapiLocales()),
    [dispatch]
  );

  useEffect(() => {
    if (prevPathname !== undefined && prevPathname !== pathname) {
      setReady(false);
      setFirstLoad(false);
      setIsLoading(true);
    }
  }, [pathname]);

  useEffect(() => {
    fetchLocales();
  }, []);

  useEffect(() => {
    if (ready && Object.keys(schema).length > 0) setIsLoading(false);
  }, [ready, schema]);

  useEffect(() => {
    if (currentLanguage !== null) setLanguageExist(true);
  }, [currentLanguage]);

  return (
    <Provider
      value={{
        ready,
        setReady,
        firstLoad,
        setFirstLoad,
        siteLoaded,
        setSiteLoaded,
        isLoading,
        setIsLoading,
        isFontReady,
        setFontReady,
        isGLBReady,
        setGLBReady,
        isLoaderExited,
        setLoaderExited,
        isLanguageExist,
        setLanguageExist,
      }}
    >
      {Object.keys(schema).length > 0 && children}
    </Provider>
  );
};

export { Context, Consumer };

export default LoadingContext;
