import axios from "axios";
import { axiosInstance } from "@/base/axios";
import { REQUEST_SPORTS, RECEIVE_SPORTS } from "@/actions/types";
import { API, CONTENTFUL_SPACE, CONTENTFUL_TOKEN } from "@/constants";

const requestSports = () => ({ type: REQUEST_SPORTS });

const receiveSports = (data) => ({
  type: RECEIVE_SPORTS,
  payload: data,
});


const fetchStrapiSports = () => async (dispatch, getState) => {
  dispatch(requestSports());
  let sport;
  try {
    const localeCode =
      getState().locale.currentLanguage ?? document.documentElement.lang;
    const response = await axiosInstance.get(
      `sports?locale=${localeCode}&populate=*`
    );

    const sports = response.data.data
      .map((it) => {
        sport = it;
        return {
          ...it.attributes,
          id : it.attributes.identifier,
          image: `${API.STRAPI_CMS}${it.attributes?.icon?.data?.attributes?.url}`,
          icon: `${API.STRAPI_CMS}${it.attributes?.icon?.data?.attributes?.url}`,
        };
      })
      .sort((a, b) => a.order - b.order);
    dispatch(receiveSports(sports));
  } catch (error) {
    console.log(error);
  }
};


export { fetchStrapiSports };
