/* eslint-disable jsx-a11y/control-has-associated-label */
import classNames from 'classnames'
import { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import * as experienceActions from '@/actions/experience'
import useStore from '@/base/zustand'
import { convertRichText, removeParagraph } from '@/utils/strings'

/*------------------------------
Controls
------------------------------*/
const Controls = ({ className, settings, handleClickNext, step, strings, eventName, emotionPercentages }) => {
  const { beforeAfterFlag, setBeforeAfterFlag, scales, setActive, active, hover, setCursorVisibilities, firstTimeAnimation, debug } = useStore()
  const [firstTime, setFirstTime] = useState(true)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const setTotalUplift = useCallback((val) => dispatch(experienceActions.setTotalUplift(val)), [dispatch])

  // 5 MOST
  // Controllo i 5 valori con il maggior numero di differenza
  useEffect(() => {
    const differences = settings.map((i, ind) => (
      {
        id: ind,
        val: parseInt(((i.postScale * 100) - (i.prevScale * 100)).toFixed(0), 10),
      }
    )).sort((a, b) => {
      return b.val - a.val
    })
    differences.forEach((i, ind) => {
      i.cursorVisible = ind < 5
    })
    const diff = differences.sort((a, b) => {
      return a.id - b.id
    })
    const cursorsVisible = diff.map((i) => i.cursorVisible)
    setCursorVisibilities(cursorsVisible)
  }, [])

  useEffect(() => {
    if (firstTimeAnimation) {
      setTimeout(() => {
        setFirstTime(false)
      }, 7500 * debug)
    }
  }, [firstTimeAnimation])

  const setFlag = () => {
    setBeforeAfterFlag(!beforeAfterFlag)
  }
  useEffect(() => {
    // removal of header login , This might need a rework , quickfix
    document.getElementsByTagName('header')[1].children[0].children[0].children[1].style.display = 'none'
  })
  /*------------------------------
  Button on Controls
  ------------------------------*/
  const buttonLink = (s, ind) => {
    const difference = () => {
      return (
        !beforeAfterFlag ? (
          <span className="diff">
            (
            {(settings[ind].postScale * 100).toFixed(0)}
            /100)
          </span>
        ) : ''
      )
    }

    // const diff = (settings[ind].postScale * 100 - settings[ind].prevScale * 100).toFixed(0)

    const emotionPercentage = emotionPercentages.find((percentage) => {
      return percentage.emotion === s.key
    })
    const diff = emotionPercentage.variation ? emotionPercentage.variation.toFixed(0) : 0
    const sign = diff > 0 ? '+' : ''
    return (
      <button
        key={ind.toString()}
        style={{ '--color': s.color }}
        onClick={() => setActive(active !== ind ? ind : -1)}
        className={classNames({
          hover: active === ind || hover === ind,
        })}
      >
        {s.name}
        <span className="value">
          {beforeAfterFlag
            ? `${(settings[ind].prevScale * 100).toFixed(0)}/100`
            : `${sign}${diff}%`}
          {' '}
          {difference()}
        </span>
        <span className="line">
          <span
            className="lineValue"
            style={{ '--delay': `${ind * 0.1}s`, width: `${(scales[ind] * 100) / 2}%` }}
          />
        </span>
      </button>
    )
  }

  /*------------------------------
  Total Control
  ------------------------------*/
  const totalControl = () => {
    // const preScaleEmotional = settings.filter((s) => s.group === 'emotional').map((a) => Number(a.prevScale.toFixed(2)))
    // const preScaleCognitive = settings.filter((s) => s.group === 'cognitive').map((a) => Number(a.prevScale.toFixed(2)))
    const preScale = settings.map((a) => Number(a.prevScale.toFixed(2)))
    // const preAvarage = (
    //   (preScaleEmotional.reduce((a, b) => a + b) / preScaleEmotional.length)
    //   + (preScaleCognitive.reduce((a, b) => a + b) / preScaleCognitive.length)
    // ) / 2
    const preAvarage = preScale.reduce((a, b) => a + b) / preScale.length
    // const postScaleEmotional = settings.filter((s) => s.group === 'emotional').map((a) => Number(a.postScale.toFixed(2)))
    // const postScaleCognitive = settings.filter((s) => s.group === 'cognitive').map((a) => Number(a.postScale.toFixed(2)))
    const postScale = settings.map((a) => Number(a.postScale.toFixed(2)))
    // const postAvarage = (
    //   (postScaleEmotional.reduce((a, b) => a + b) / postScaleEmotional.length)
    //   + (postScaleCognitive.reduce((a, b) => a + b) / postScaleCognitive.length)
    // ) / 2
    const postAvarage = postScale.reduce((a, b) => a + b) / postScale.length

    // const diff = ((postAvarage * 100) - (preAvarage * 100)).toFixed(0)
    const diff = Math.abs((((postAvarage - preAvarage) / preAvarage) * 100).toFixed(0))
    const sign = diff > 0 ? '+' : ''

    const difference = () => {
      setTotalUplift(`${sign}${diff}%`)
      return (
        !beforeAfterFlag ? (
          <span className="diff">
            {(postAvarage * 100).toFixed(0)}
            /100
          </span>
        ) : ''
      )
    }

    return (
      <p
        className="Score"
        style={{ '--color': '#5163d2' }}
      >
        {strings['results.score']}
        : &nbsp;
        <span className="diff">
          {beforeAfterFlag
            ? `${(preAvarage * 100).toFixed(0)}/100 `
            : ''}
          {' '}
          {difference()}
        </span>
      </p>
    )
  }

  /*------------------------------
  Render Active
  ------------------------------*/
  const renderActiveBox = () => (
    <div className="activeBoxWrap">
      {settings.map((e, i) => {
        // console.log(settings[i])
        const pre = (settings[i].prevScale * 100).toFixed(0)
        const post = (settings[i].postScale * 100).toFixed(0)
        // const diff = (settings[i].postScale * 100 - settings[i].prevScale * 100).toFixed(0)
        const diff = Math.abs((((settings[i].postScale - settings[i].prevScale) / settings[i].prevScale) * 100).toFixed(0))
        const sign = diff > 0 ? '+' : ''
        return (
          <div
            key={i.toString()}
            className={classNames({
              activeBox: true,
              visible: i === active,
            })}
            style={{ '--color': e.color }}
          >

            <div className="activeBox__title">
              {e.name}
              {' '}
              <span className="diff">
                {beforeAfterFlag ? `${pre}/100` : `${post}/100`}
              </span>
              <div className="activeBox__values" style={{ fontSize: '20px', textTransform: 'capitalize' }}>{e.group}</div>
            </div>
            <div className="activeBox_bar">
              <div className="bar" style={{ '--color': e.color + 80 }}>
                <div style={{ '--color': e.color, '--width': beforeAfterFlag ? `${pre}%` : `${post}%` }} className="progress" />
              </div>
              <button
                className="button close"
                style={{ '--color': e.color }}
                onClick={() => setActive(-1)}
              />
            </div>
            <div className="activeBox__desc">{e.description}</div>
            <div className="colorList">
              {
                    settings.map((e, i) => {
                      return (
                        <button onClick={() => {
                          setActive(i)
                        }}
                        >
                          <span className={active === i ? 'active activeBox__buttonColor' : 'activeBox__buttonColor'} style={{ '--color': e.color }} />
                        </button>
                      )
                    })
                }
            </div>
          </div>
        )
      })}
    </div>
  )

  /*------------------------------
  Render PrevPost Button
  ------------------------------*/
  const renderPrevPostButton = (mobile) => (
    <button
      onClick={setFlag}
      className={classNames({
        button: true,
        prevPostButton: true,
        pre: beforeAfterFlag,
        post: !beforeAfterFlag,
        mobile,
      })}
    >
      <span>
        {strings['eventresults.before']}
      </span>
      <span>
        {strings['eventresults.after']}
      </span>
    </button>
  )

  /*------------------------------
  Render Heading Wrapper
  ------------------------------*/
  const renderHeadingWrapper = () => {
    const preScale = settings.map((a) => Number(a.prevScale.toFixed(2)))
    const preAvarage = preScale.reduce((a, b) => a + b) / preScale.length
    const postScale = settings.map((a) => Number(a.postScale.toFixed(2)))
    const postAvarage = postScale.reduce((a, b) => a + b) / postScale.length
    // TODO: Replace with translations
    return (
      <div
        className={classNames({
          heading: true,
          visible: active === -1 && !firstTime,
        })}
      >
        <h1 className="headingTitle">
          {eventName}
          {' '}
          Results
        </h1>
        <h1 className="headingScore">
          {totalControl() }
        </h1>
        <div className="activeBox_bar">
          <div className="bar" style={{ '--color': `#485bc7${80}` }}>
            <div style={{ '--color': '#485bc7', '--width': beforeAfterFlag ? `${(preAvarage * 100).toFixed(0)}%` : `${(postAvarage * 100).toFixed(0)}%` }} className="progress" />
          </div>
        </div>
        <p className="headingText">
            This is a visualisation of the Global Uplift results, an interactive representation of all participants state of mind score post exercise.
        </p>
        <p className="headingText">
          {strings['eventresults.click'] }
        </p>
      </div>
    )
  }

  return (
    <div className={className}>
      {renderHeadingWrapper()}
      <div
        className={classNames({
          loading: true,
          visible: active === -1 && firstTime,
        })}
      >
        {strings['worldupliftresults.loading']}
      </div>
      <div
        className={classNames({
          controlsLeft: true,
          visible: active === -1 && !firstTime,
        })}
      >

        <div
          className={classNames({
            resultHint: true,
            visible: active === -1 && !firstTime,
          })}
        >
          {strings['worldupliftresults.hint.scroll']}
        </div>
      </div>
      {renderActiveBox()}
      <div
        className={classNames({
          controls: true,
          visible: active === -1 && !firstTime,
        })}
      >
        <div className="group emotion">
          <h1>{strings['eventresults.traitgroup1']}</h1>
          <div className="groupBox">
            {settings
              .filter((item) => item.group === 'emotional')
              .map((s) => (
                buttonLink(s, s.id)
              ))}
          </div>
        </div>

        <div className="group emotional">
          <h1>{strings['eventresults.traitgroup2']}</h1>
          <div className="groupBox">
            {settings
              .filter((item) => item.group === 'cognitive')
              .map((s) => (
                buttonLink(s, s.id)
              ))}
          </div>
        </div>
      </div>
      <button
        className={classNames({
          buttonContinue: true,
          visible: !firstTime,
        })}
        onClick={handleClickNext}
      >
        <span>Global Uplift Map</span>
      </button>
    </div>
  )
}
export default Controls
