import axios from "axios";
import { API_BASE_URL, STRAPI_TOKEN,API } from "@/constants";

const createAxiosInstance = axios.create({
  baseURL : API_BASE_URL
})
axios.defaults.baseURL = API_BASE_URL;
const axiosInstance = axios.create({
  baseURL: `${API.STRAPI_CMS_API}/`,
  headers: {
    "Content-type": "application/json",
    Authorization: `bearer ${STRAPI_TOKEN}`,
  },
});

export { axiosInstance,createAxiosInstance };
