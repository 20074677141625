const { EventEmitter } = require('events')

const { URL_BASE, URL_TEST, DEFAULT_LANGUAGE, THEME_NAME } = process.config
// const isDev = process.env.NODE_ENV === 'development' || window.location.origin === URL_TEST

let asicsDomain = ''; let mainDomain = ''

if (process.env.NODE_ENV === 'production') {
  console.log('env: ', 'prod')
  asicsDomain = 'https://id.asics.com/'
  mainDomain = 'https://minduplifter.asics.com/'
  isDev = false
} else if (process.env.NODE_ENV === 'test') {
  console.log('env: ', 'test')
  asicsDomain = 'https://id-sandbox.asics.com/'
  mainDomain = 'https://test.upliftsolarflare.com/'
  isDev = false
} else {
  console.log('env: ', 'dev')
  asicsDomain = 'https://id-sandbox.asics.com/'
  mainDomain = 'https://test.upliftsolarflare.com/'
  isDev = true
}

module.exports = {
  SITE: !isDev ? window.location.origin : URL_BASE,
  API_BASE_URL: `${!isDev ? window.location.origin : URL_BASE}/api`,
  CURRENT_LANG: DEFAULT_LANGUAGE !== '' ? `/${DEFAULT_LANGUAGE}` : '/',
  LANGUAGES: window.languages || [],
  PATH: `${window.location.pathname.split('/')[1]}/` || '',
  COOKIE_POLICY_NAME: `user-has-accepted-cookies-${THEME_NAME}`,
  API: {
    SAVE_LITE_EXPERIENCE : '/lite-experience/save',
    EVENT_STATE_OF_MIND : '/lite-experience/${event_id}/state-of-mind',
    SAVE_PARTIAL_EXPERIENCE: '/experience/partial',
    SAVE_FULL_EXPERIENCE: '/experience/save',
    GET_EXPERIENCE: '/experience',
    REGISTER: '/register',
    LOGIN: '/login',
    LOGOUT: '/logout',
    EMAIL_RESET: '/password/email',
    PASSWORD_RESET: '/password/reset',
    SAVE_USER_PREFERENCES: '/user/preferences',
    SAVE_USER_PARTIAL_EXPERIENCE: '/user/experience/partial',
    SAVE_USER_FULL_EXPERIENCE: '/user/experience/save',
    SAVE_EXPERIENCE_IMAGES: '/experience/saveimages',
    PROFILE: '/user/me',
    GIF: '/gif',
    GET_VIDEO: '/experience/video',
    DOWNLOAD: '/download',
    MAP_MIND: '/map/mind',
    MAP_SPORTS: '/sport',
    MAP_EVENTS: '/event',
    STRAPI_CMS : `https://api.upliftsolarflare.com`,
    STRAPI_CMS_API : 'https://api.upliftsolarflare.com/api',
    CMS: 'https://cdn.contentful.com/spaces',
    BIGDATACLOUD: 'https://api.bigdatacloud.net/data/reverse-geocode-client',
    OAUTH_LOGIN: '/oauthLogin',
    OAUTH_URL: `${asicsDomain}lander?client_id=mind_uplifter&style=minduplifter&locale=en-US&grant_type=code&redirect_uri=${mainDomain}portal&privacy_url=${mainDomain}privacy&terms_url=${mainDomain}terms`,
    SAVE_PROGRAM: '/program/save',
  },
  EVENTS: {
    DATALAYER: {
      COOKIE_CONSENT: 'cookie-consent',
      SEE_RESULTS: 'see-results',
      PAGE_VIEW: 'pageview',
    },
  },
  THEME_NAME,
  LINK_DELAY: 0,
  RECAPTCHA_SITE_KEY: '',
  MAPBOX_TOKEN: 'pk.eyJ1Ijoic29sYXJmbGFyZXN0dWRpbyIsImEiOiJja3BwbjZ0ZDcweDljMnBwNHY2emRvYW1zIn0.6AwIpZ8-2B3BADpZOnIbBQ',
  CONTENTFUL_SPACE: `rslwz4qf7z1n/environments/${process.env.CONTENTFUL_ENVIRONMENT}`,
  CONTENTFUL_TOKEN: 'KVnevLsz1EQHs8uSqOi0aRkB4Ckj3LXxejLmOIa0V-o',
  STRAPI_TOKEN : '835ca6bbba976e2c6c63ecfaa8ea2d71622034998e01182464562825c2368c3f3ab476baa8fc19c8626cdb770dc68745ddd3a2b022be611ef1f165df927b6a17bc7c302e8070bfd7ba2c9a79fe51d7824248c4f111c742bba96287b032465eaf92c72c12e31e67a3468810765b9724147e515ee0403bebc1209dc3ea85d71566',
  MORPHCAST_API_KEY: '85abc5c6b0c17f3ef757d1bc04b2297bbe6f8b80013a',
  BIGDATACLOUD_API_KEY: '031a9f5b33fa4ff296531c4cd6d837d0',
  EMITTER: new EventEmitter(),
}
