import { useRef, useState, useEffect, useContext, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import copy from 'copy-to-clipboard'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Context as GeneralContext } from '@/context'
import Meta from '@/components/Meta'
import RevealText from '@/components/RevealText'
import ScrollIcon from '@/components/ScrollIcon'
import DelayLink from '@/components/DelayLink'
import InViewTrigger from '@/components/InViewTrigger'
import RevealFadeIn from '@/components/RevealFadeIn'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import usePagePrecache from '@/hooks/usePagePrecache'
import * as experienceActions from '@/actions/experience'
import * as contentActions from '@/actions/content'
import { SITE, API, API_BASE_URL } from '@/constants'
import { convertRichText } from '@/utils/strings'
import style from './style'

const useStyles = createUseStyles(style)

const SharePage = () => {
  const { setPageAnimationReady, pageAnimationReady, setHeaderInverse, headerHeight } = useContext(GeneralContext)
  const classes = useStyles({ headerHeight })
  const [isDataFetched, setDataFetched] = useState(false)
  const [tooltip, setTooltip] = useState(false)
  const $root = useRef()
  const stub = useRef('share-page2')

  /*------------------------------
  Redux Store
  ------------------------------*/
  const { currentLanguage, page, experience, strings, schema, isEndedExperience } = useSelector((state) => ({
    page: state.content[stub.current] || {},
    experience: state.experience,
    isLoggedIn: state.user.isLoggedIn,
    strings: state.options.strings,
    schema: state.locale.schema,
    currentLanguage: state.locale.currentLanguage,
    isEndedExperience: state.experience.isEndedExperience,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const resetExperience = useCallback(() => dispatch(experienceActions.resetExperience()), [dispatch])
  const fetchPage = useCallback((slug) => dispatch(contentActions.fetchStrapiContent(slug, 'page-shares')), [dispatch])
  const gif = useCallback(() => dispatch(experienceActions.gif()), [dispatch])
  const saveGifOnS3 = useCallback(() => dispatch(experienceActions.saveGifOnS3()), [dispatch])

  const setEndedExperience = useCallback((bool) => dispatch(experienceActions.setEndedExperience(bool)), [dispatch])
  const history = useHistory()

  /*------------------------------
  Fetch Page Data
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length === 0) fetchPage(stub.current)

    setInterval(() => {
      document.body.style.overflowY = 'visible'
      document.documentElement.style.scrollBehavior = 'smooth'
    }, 2000)
  }, [])
  useEffect(() => {
    if (Object.keys(page).length > 0 )
      fetchPage(stub.current);
  }, [currentLanguage])
  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0) {
      setDataFetched(true)
    }
  }, [page])

  /*------------------------------
  Header Inverse
  ------------------------------*/
  useEffect(() => {
    setHeaderInverse(true)
    return () => setHeaderInverse(false)
  }, [])

  /*------------------------------
  Set End Flag
  ------------------------------*/
  useEffect(() => {
    if (!isEndedExperience) {
      setEndedExperience(true)
    } else {
      resetExperience()
    }
  }, [])

  /*------------------------------
  Save Video On S3
  ------------------------------*/
  useEffect(() => {
    saveGifOnS3()
  }, [])

  /*------------------------------
  Preload
  ------------------------------*/
  const [load] = usePagePrecache({
    init: isDataFetched,
    sources:[],
    callback: () => setPageAnimationReady(true),
  })

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && <Meta />
  }

  /*------------------------------
  Handle Share Experience
  ------------------------------*/
  const handleShareExperience = () => {
    const { uuid } = experience
    copy(`${SITE}?share=${uuid}&lang=${schema[currentLanguage].code}`)
    setTooltip(true)
    setTimeout(() => setTooltip(false), 2500)
  }

  /*------------------------------
  Render MindMap
  ------------------------------*/
  const renderMindMap = () => {
    return (
      <div className={classes.mindMap}>
        <div className={classes.wrapper}>
          <InViewTrigger>
            <RevealFadeIn delay={0}>
              <div className={classes.mindMapImage}>
                <img
                  src={page?.mapImage}
                  alt={page?.mapImage}
                />
              </div>
            </RevealFadeIn>
          </InViewTrigger>
          <InViewTrigger className={classes.mindMapBox}>
            <RevealText
              tag="h1"
              type="lines"
              value={convertRichText(page.mapTitle)}
              lineHeight={1.2}
              delay={0}
              className={classes.title}
            />
            <RevealText
              tag="div"
              type="lines"
              value={convertRichText(page.mapText)}
              lineHeight={1.333}
              delay={1.2}
              className={classes.text}
            />
            <RevealFadeIn delay={1.8}>
              <div className={classes.ctaButtons}>
                <div className={classes.registerBox}>
                  <DelayLink
                    to="/world-uplift-map"
                    className={`${classes.signinBtn} white noStroke`}
                  >
                    <span>{strings['share.cta.map']}</span>
                  </DelayLink>
                </div>
              </div>
            </RevealFadeIn>
          </InViewTrigger>
        </div>
        <ScrollIcon inverse className={classes.scrollIcon} />
      </div>
    )
  }

  /*------------------------------
  Render Sharing
  ------------------------------*/
  const renderSharing = () => {
    return experience.uuid && (
      <div className={classes.hero}>
        <div className={classes.wrapper}>
          <div className={classes.heroBox}>
            <RevealText
              tag="h1"
              type="lines"
              value={convertRichText(page.shareTitle)}
              lineHeight={1.2}
              visible={pageAnimationReady}
              delay={0}
              className={classes.title}
            />
            <RevealText
              tag="div"
              type="lines"
              value={convertRichText(page.shareText)}
              lineHeight={1.333}
              visible={pageAnimationReady}
              delay={1.2}
              className={classes.text}
            />
            <RevealFadeIn
              visible={pageAnimationReady}
              delay={1.5}
            >
              <div className={classes.shareButtons}>
                <DelayLink
                  to={`https://www.facebook.com/sharer/sharer.php?u=${SITE}?share=${experience.uuid}`}
                  className={classes.roundBtn}
                >
                  <span className={classes.circle}><svg><use xlinkHref="#ico-facebook" /></svg></span>
                  <span className={classes.label}>{strings['general.facebook']}</span>
                </DelayLink>
                <DelayLink
                  to={`https://twitter.com/intent/tweet?url=${SITE}?share=${experience.uuid}`}
                  className={classes.roundBtn}
                >
                  <span className={classes.circle}><svg><use xlinkHref="#ico-twitter" /></svg></span>
                  <span className={classes.label}>{strings['general.twitter']}</span>
                </DelayLink>
                <a
                  href={`${API_BASE_URL}${API.DOWNLOAD}/${experience.uuid}`}
                  className={classes.roundBtn}
                >
                  <span className={`${classes.circle} ${classes.circleSm}`}><svg style={{ transform: 'translateY(-1px)' }}><use xlinkHref="#ico-download" /></svg></span>
                  <span className={classes.label}>{strings['general.download.image']}</span>
                </a>
                <button
                  onClick={gif}
                  className={classes.roundBtn}
                >
                  <span className={`${classes.circle} ${classes.circleSm}`}><svg style={{ transform: 'translateY(-1px)' }}><use xlinkHref="#ico-download" /></svg></span>
                  <span className={classes.label}>{strings['general.download.gif']}</span>
                </button>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: 20 }}>
                <button
                  className={classNames({
                    [classes.btn]: true,
                    white: true,
                    noStroke: true,
                  })}
                  onClick={() => { history.push('/world-uplift-map') }}
                  style={{ width: 250 }}
                >
                  <span>{page.mapButton}</span>
                </button>
                <button
                  className={classNames({
                    [classes.btn]: true,
                    white: true,
                    noStroke: true,
                  })}
                  onClick={() => { history.push('/portal') }}
                  style={{ width: 250 }}
                >
                  <span>{page.galleryButton}</span>
                </button>
              </div>
            </RevealFadeIn>
          </div>
          <RevealFadeIn
            visible={pageAnimationReady}
            delay={1.2}
            className={classes.heroImage}
          >
            <div className={classes.heroImg}>
              <img
                src={page.shareImage}
                alt={page.shareImage}
              />
            </div>
          </RevealFadeIn>
        </div>
        <div className={classes.downArrow}>
          <img src={page?.downArrow} />
        </div>
      </div>
    )
  }

  /*------------------------------
  Render Links
  ------------------------------*/
  const renderLinks = () => {
    return (
      <div className={classes.links}>
        <InViewTrigger className={classes.wrapper}>
          <RevealFadeIn className={classes.link}>
            <div className={classes.linkImage}>
              <img
                src={page.linkImage1}
                alt={page.linkImage1}
              />
            </div>
            <div className={classes.linkTitle}>{page.linkTitle1}</div>
            <div className={classes.linkText}>{page.linkText1}</div>
            <a
              className={classNames({
                [classes.btn]: true,
                white: true,
                noStroke: true,
              })}
              href={page.linkTarget1}
              target="_blank"
            >
              <span>{page.linkCta1}</span>
            </a>
          </RevealFadeIn>
          <RevealFadeIn className={classes.link}>
            <div className={classes.linkImage}>
              <img
                src={page.linkImage2}
                alt={page.linkImage2}
              />
            </div>
            <div className={classes.linkTitle}>{page.linkTitle2}</div>
            <div className={classes.linkText}>{page.linkText2}</div>
            <a
              className={classNames({
                [classes.btn]: true,
                white: true,
                noStroke: true,
              })}
              href={page.linkTarget2}
              target="_blank"
            >
              <span>{page.linkCta2}</span>
            </a>
          </RevealFadeIn>
          <RevealFadeIn className={classes.link}>
            <div className={classes.linkImage}>
              <img
                src={page.linkImage3}
                alt={page.linkImage3}
              />
            </div>
            <div className={classes.linkTitle}>{page.linkTitle3}</div>
            <div className={classes.linkText}>{page.linkText3}</div>
            <a
              className={classNames({
                [classes.btn]: true,
                white: true,
                noStroke: true,
              })}
              href={page.linkTarget3}
              target="_blank"
            >
              <span>{page.linkCta3}</span>
            </a>
          </RevealFadeIn>
        </InViewTrigger>
      </div>
    )
  }

  /*------------------------------
  Render Reload
  ------------------------------*/
  const renderReload = () => {
    return (
      <div className={classes.reload}>
        <button className={classes.reloadBtn} onClick={resetExperience}>
          <div className={classes.roundIco}>
            <svg width="22" height="20"><use xlinkHref="#ico-reload" /></svg>
          </div>
          <span>{strings['general.restart']}</span>
        </button>
      </div>
    )
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return load && (
      <>
        <div className={classes.page}>
          {renderSharing()}
          {/* {renderMindMap()} */}
          {renderLinks()}
          {renderReload()}
        </div>
      </>
    )
  }

  return (
    <LocomotiveScroll
      init={load}
      className={`page pageSharePage ${classes.root}`}
      ref={$root}
    >
      {renderHelmet()}
      {renderContent()}
    </LocomotiveScroll>
  )
}

export default SharePage
