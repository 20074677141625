import { RECEIVE_LOCALES, SET_LANGUAGE } from '@/actions/types'

const initialState = {
  currentLanguage: null,
  languages: [],
  schema: {},
  strings: {
    title: 'Choose Language',
    select: 'Select a Language',
    cta: 'Continue',
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_LOCALES:
      state = {
        ...state,
        schema: action.payload,
      }
      break
    case SET_LANGUAGE:
      state = {
        ...state,
        currentLanguage: action.payload,
      }
      break
    default:
      return { ...state }
  }
  return { ...state }
}
