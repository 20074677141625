import { useContext, useEffect, useCallback, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import queryString from "query-string";
import Loader from "@/components/Loader";
import UpliftHeader from "@/components/Header";
import LiteHeader from "@/components/LiteHeader";
import Nav from "@/components/Nav";
import LiteNav from "@/components/LiteNav";
import Overlay from "@/components/Overlay";
import Spinner from "@/components/Spinner";
import SVGSprite from "@/components/SVGSprite";
import GeolocationModal from "@/components/GeolocationModal";
import FaceScanModal from "@/components/FaceScanModal";
import ShareModal from "@/components/ShareModal";
import CongratsModal from "@/components/CongratsModal";
import useFontPreload from "@/hooks/useFontPreload";
import useGLBPreload from "@/hooks/useGLBPreload";
import useViewport from "@/hooks/useViewport";
import * as optionsActions from "@/actions/options";
import * as navActions from "@/actions/nav";
import * as stepsActions from "@/actions/steps";
import * as emotionsActions from "@/actions/emotions";
import * as sportsActions from "@/actions/sports";
import * as userActions from "@/actions/user";
import * as experienceActions from "@/actions/experience";
import * as layerActions from "@/actions/layer";
import * as eventsActions from "@/actions/events";
import * as routerActions from "@/actions/fakerouter";
import { Context } from "@/context";
import { Context as LoadingContext } from "@/context/loading";
import global from "@/global";
import UltraUpliftIntroModal from "./components/UltraUpliftModals/IntroModal";
import ChooseProgrammeModal from "./components/UltraUpliftModals/ChooseProgrammeModal";
import QuestionsAfterEmailModal from "./components/QuestionsAfterEmailModal";
import { daysBetween } from "@/utils/date";

const useStyles = createUseStyles(global);

const Layout = ({ children }) => {
  const { headerInverse, headerMap } = useContext(Context);
  const { isLoaderExited } = useContext(LoadingContext);
  const classes = useStyles({ headerInverse, headerMap });
  const [currentPosition, setCurrentPosition] = useState([]);
  const history = useHistory();
  const location = useLocation();
  /*------------------------------
  Font Preload
  ------------------------------*/
  useViewport();
  useFontPreload({ fonts: ["Graphik-Regular", "Graphik-Medium"] });
  useGLBPreload({ models: ["./frontend/static/models/face.glb"] });

  /*------------------------------
  Redux Store
  ------------------------------*/
  const {
    lastNotCompletedUUID,
    currentLanguage,
    mainNav,
    dashboardNav,
    extraNav,
    socialNav,
    userNav,
    position,
    countryCode,
    tags,
    user,
    pathname,
  } = useSelector(
    (state) => ({
      lastNotCompletedUUID: state.user.lastNotCompletedUUID,
      mainNav: state.nav.mainnav,
      extraNav: state.nav.extranav,
      dashboardNav: state.nav.dashboardnav,
      socialNav: state.nav.socialnav,
      userNav: state.nav.usernav,
      currentLanguage: state.locale.currentLanguage,
      position: state.user.position,
      countryCode: state.events.countryCode,
      tags: state.events.tags,
      user: state.user,
      pathname: state.router.location.pathname,
    }),
    shallowEqual
  );

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch();
  const fetchTags = useCallback(
    () => dispatch(eventsActions.fetchTags()),
    [dispatch]
  );
  const setCurrentTag = useCallback(
    (tag) => dispatch(eventsActions.setCurrentTag(tag)),
    [dispatch]
  );
  const fetchOptions = useCallback(
    () => dispatch(optionsActions.fetchStrapiOptions()),
    [dispatch]
  );
  const fetchMenus = useCallback(
    () => dispatch(navActions.fetchStrapiMenu()),
    [dispatch]
  );

  const fetchSteps = useCallback(
    () => dispatch(stepsActions.fetchStrapiSteps()),
    [dispatch]
  );
  const fetchEmotions = useCallback(
    () => dispatch(emotionsActions.fetchStrapiEmotions()),
    [dispatch]
  );
  const fetchSports = useCallback(
    () => dispatch(sportsActions.fetchStrapiSports()),
    [dispatch]
  );
  const fetchGeocoding = useCallback(
    (coord) => dispatch(experienceActions.fetchGeocoding(coord)),
    [dispatch]
  );
  const setPosition = useCallback(
    (p) => dispatch(userActions.setPosition(p)),
    [dispatch]
  );
  const openGeolocationModal = useCallback(
    () => dispatch(layerActions.openLayer({ id: "geolocation" })),
    [dispatch]
  );
  const fetchProfile = useCallback(
    () => dispatch(userActions.fetchProfile()),
    [dispatch]
  );
  const changeLocation = useCallback(
    (location) => dispatch(routerActions.changeLocation(location)),
    [dispatch]
  );
  const setExperience = useCallback(
    (data) => dispatch(experienceActions.setExperience(data)),
    [dispatch]
  );
  const fetchExperience = useCallback(
    (uuid) => dispatch(experienceActions.fetchExperience(uuid)),
    [dispatch]
  );

  /*------------------------------
  Manage Login
  ------------------------------*/
  useEffect(() => {
    if (localStorage.getItem("state")) localStorage.removeItem("state");
    fetchProfile();
  }, []);

  useEffect(() => {
    if (
      user.isLoggedIn &&
      location.pathname != "/questions-after-email-modal" &&
      location.pathname != "/ultra-uplift-questions-final"
    ) {
      if (user.uplifterPrograms) {
        user.uplifterPrograms.some((program) => {
          if (
            program.program_detail.completed &&
            (program.program_detail.q5 == "" ||
              program.program_detail.q6 == "" ||
              program.program_detail.q7 == "" ||
              program.program_detail.q8 == "")
          ) {
            history.push("/questions-after-email-modal");
            return;
          }
        });
      }
    }
  }, [user]);

  /*------------------------------
  Manage Geolocation
  ------------------------------*/
  useEffect(() => {
    if (position.length === 0 && isLoaderExited) {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            setCurrentPosition([pos.coords.latitude, pos.coords.longitude]);
            setPosition([pos.coords.longitude, pos.coords.latitude]);
          },
          (error) => {
            if (error.code === error.PERMISSION_DENIED) {
              openGeolocationModal();
            }
          }
        );
      }
    }
  }, [isLoaderExited]);

  /*------------------------------
  Reverse Geocoding from Coordinates
  ------------------------------*/
  useEffect(() => {
    if (currentPosition.length > 0) {
      fetchGeocoding(currentPosition);
    }
  }, [currentPosition]);

  useEffect(() => {
    if (tags.length > 0 && countryCode !== "") {
      const currentTag = tags.find((t) => t.name.slice(-2) === countryCode);
      setCurrentTag(currentTag?.name);
    }
  }, [countryCode, tags]);

  /*------------------------------
  Fetch all Strings
  ------------------------------*/
  useEffect(() => {
    if (currentLanguage) {
      fetchTags();
      fetchOptions();
      fetchMenus();
      fetchSteps();
      fetchEmotions();
      fetchSports();
    }
  }, [currentLanguage]);

  const manageQS = (qs, lastNotCompletedExpUUID) => {
    const uuid = qs.complete ? qs.complete : lastNotCompletedUUID;
    if (uuid) {
      // [http://URL?complete=175d27ae-aa6e-44f8-a9ca-e3b35c5d615d]
      // changeLocation('blank')
      fetchExperienceAndRedirect(uuid, !!qs.complete);
    }
  };

  useEffect(() => {
    const q = queryString.parse(location.search);
    manageQS(q, lastNotCompletedUUID);
    // Set current experience location from Query String
  }, [lastNotCompletedUUID]);

  const fetchExperienceAndRedirect = (uuid, queryString) => {
    fetchExperience(uuid).then((res) => {
      // Move to "confirm-timer" only if experience is partial
      if (res.status === 200 && res.data.completed === 0) {
        if (daysBetween(new Date(), new Date(res.data.created_at)) < 1) {
          if (location.pathname !== "portal") {
            changeLocation("confirm-timer");
            history.push("/");
          }
        } else {
          if (queryString) {
            history.push("expired-session");
          }
        }
      } else {
        changeLocation("begin");
      }
    });
  };
  // const Header = useCallback(
  //   ({ pathname, eventId }) => {
  //     if (pathname.includes("/lite") || (pathname.length>16 && !pathname.includes('-'))) {
  //       return <LiteHeader />;
  //     } else {
  //       return <UpliftHeader />;
  //     } 
  //   },
  //   [location.pathname]
  // );
  return (
    <div className={`${classes.root} content-root`}>
      <div className={classes.background} />
      <Loader />
      <Overlay />
      <Spinner />
      {location.pathname.includes('lite') ?  <LiteHeader /> : <UpliftHeader />}
      <Nav
        mainNav={mainNav}
        extraNav={extraNav}
        dashboardNav={dashboardNav}
        socialNav={socialNav}
        userNav={userNav}
      />
      <LiteNav mainNav={mainNav} extraNav={extraNav} socialNav={socialNav} />
      <div className="wrapper">{children}</div>
      <SVGSprite />
      <GeolocationModal />
      <FaceScanModal />
      <ShareModal />
      <QuestionsAfterEmailModal />
      <UltraUpliftIntroModal />
      <ChooseProgrammeModal />
      <CongratsModal />
    </div>
  );
};

export default Layout;
