const style = (theme) => {
  const output = {
    root: {
      color: theme.colors[3],
      paddingTop: (props) => props.headerHeight,
    },
    page: {
      minHeight: (props) => `calc(var(--vh) - ${props.headerHeight}px)`,
    },
    wrapper: {
      extend: theme.wrapper,
      position: 'relative',
      zIndex: 1,
    },
    btn: {
      extend: theme.button,
    },
    canvas: {
      position: 'fixed!important',
      zIndex: 1,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    controls: {
      fontFamily: theme.fonts[1],
      userSelect: 'none',

      '& .heading': {
        position: 'fixed',
        zIndex: 1,
        left: 50,
        top: '20rem',
        width: '28%',
        transform: 'translate(-100%, -50%)',
        opacity: 0,
        color: theme.colors[3],
        pointerEvents: 'none',
        transition: `transform 1s ${theme.easings['power3.out']} 0s, opacity 1s ${theme.easings['power3.out']} 0s`,
        '&.visible': {
          transform: 'translate(0, -50%)',
          transitionDelay: '.3s',
          opacity: 1,
          pointerEvents: 'all',
        },
        '& .headingText': {
          '& p': {
            marginBottom: '1rem',
          },
          '& b': {
            marginBottom: '0.4rem',
            display: 'inline-block',
          },
          '& a': {
            textDecoration: 'underline',
            color: theme.colors[3],
            // display: 'block',
          },
        },
        '& .activeBox_bar': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: '2.2rem',
          marginTop: '0.5rem',
          alignItems: 'center',
          '& .bar': {
            overflow: 'hidden',
            width: '30rem',
            height: '1.2rem',
            backgroundColor: 'var(--color)',
            borderRadius: 30,
          },
          '& .progress': {
            overflow: 'hidden',
            width: 'var(--width)',
            height: '1.2rem',
            backgroundColor: 'var(--color)',
            borderRadius: 30,
          },
        },
        '& h1': {
          fontSize: '3rem',
        },
        '& .headingTitle': {
          fontSize: '2.8rem',
        },
        '& .Score': {
          fontSize: '2rem',
          fontFamily: theme.fonts[1],
        },
        '& p': {
          margin: 0,
          fontSize: 16,
          fontFamily: theme.fonts[0],
        },
      },

      '& .button': {
        fontFamily: `${theme.fonts[1]}!important`,
        fontSize: 14,
        zIndex: 2,
        background: 'var(--color)',
        boxShadow: '0 10px 20px -10px rgba(0, 0, 0, .2)',
        color: theme.colors[1],
        border: 'none',
        borderRadius: 20,
        padding: '10px 20px',
        textTransform: 'uppercase',
        letterSpacing: '.1em',
        outline: 'none',
        cursor: 'pointer',
      },

      '& .buttonContinue': {
        extend: theme.button,
        position: 'fixed',
        zIndex: 2,
        bottom: 28,
        left: '50%',
        padding: [13, 45],
        transform: 'translate(-50%, 200%)',
        transition: `transform 1s ${theme.easings['power3.out']} 0s, opacity 1s ${theme.easings['power3.out']} 0s`,
        opacity: 0,
        pointerEvents: 'none',
        '&.visible': {
          transform: 'translate(-50%, 0%)',
          transitionDelay: '.3s',
          opacity: 1,
          pointerEvents: 'all',
        },
      },

      '& .controls': {
        position: 'fixed',
        zIndex: 10,
        top: (props) => props.headerHeight,
        right: 0,
        height: (props) => `calc(var(--vh) - ${props.headerHeight}px)`,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        WebkitOverflowScrolling: 'touch',
        justifyContent: 'space-between',
        width: 325,
        padding: '25px 20px 25px 0',
        transform: 'translateX(130%)',
        transition: `transform 1s ${theme.easings['power3.out']}`,

        '& .total': {
          display: 'none',
        },

        '& h1': {
          marginLeft: 35,
          marginBottom: 10,
          fontSize: 14,
          fontWeight: 'normal',
          color: theme.colors[3],
        },
        '& .groupBox': {
          padding: '30px 35px',
          borderRadius: 20,
          background: 'rgba(255, 255, 255, .5)',
          boxShadow: '0 10px 20px -10px rgba(0, 0, 0, .2)',
          backdropFilter: 'blur(10px)',
          marginBottom: 30,
        },
        '& button': {
          display: 'block',
          textAlign: 'left',
          fontFamily: `${theme.fonts[1]}!important`,
          fontSize: 14,
          width: '100%',
          position: 'relative',
          cursor: 'pointer',
          padding: '4px 0',
          letterSpacing: '0.04em',
          textTransform: 'capitalize',
          marginBottom: 8,
          color: 'var(--color)',
          transition: 'color .3s ease-in-out',
          '&:before': {
            content: '""',
            position: 'absolute',
            zIndex: -1,
            background: 'var(--color)',
            borderBottom: '1px solid rgba(0, 0, 0, .1)',
            width: 'calc(100% + 70px)',
            height: 'calc(100% + 6px)',
            top: -3,
            left: -35,
            transition: 'all .5s ease-in-out',
            transformOrigin: '0 0',
            transform: 'scaleX(0)',
          },
          '&:hover, &.hover': {
            color: theme.colors[1],
            '&:before': {
              transform: 'scaleX(1)',
            },
            '& .line': {
              '& .lineValue': {
                background: theme.colors[1],
                transitionDelay: '0s',
              },
            },
          },
          '& span.value': {
            position: 'absolute',
            right: 0,
            bottom: 0,
          },
          '& .diff': {
            display: 'inline-block',
            fontSize: 9,
            transform: 'translateY(-4px)',
          },
          '& .line': {
            height: '10px',
            width: 'calc(100% - 100px)',
            marginTop: '4px',
            display: 'block',
            '& .lineValue': {
              display: 'block',
              height: '100%',
              background: 'var(--color)',
              borderRadius: '20px',
              transition: 'width 1.2s cubic-bezier(0.54, 0.03, 0, 0.96) var(--delay), color .3s ease-in-out, background-color .3s ease-in-out',
              minWidth: '10px',
            },
          },
        },
      },

      '& .controlsLeft': {
        position: 'fixed',
        zIndex: 2,
        bottom: '25px',
        left: '20px',
        opacity: 0,
        transform: 'translateY(200%)',
        pointerEvents: 'none',
        transition: `transform 1s ${theme.easings['power3.out']} 0s, opacity 1s ${theme.easings['power3.out']} 0s`,
        '&.visible': {
          transform: 'translateY(0%)',
          transitionDelay: '.3s',
          opacity: 1,
          pointerEvents: 'all',
        },
        '& .mobile': {
          display: 'none',
        },
        '& .groupBox': {
          width: 325,
          padding: '30px 35px',
          borderRadius: 20,
          background: 'rgba(255, 255, 255, .5)',
          boxShadow: '0 10px 20px -10px rgba(0, 0, 0, .2)',
          backdropFilter: 'blur(10px)',
          marginBottom: 30,
          cursor: 'default',
          pointerEvents: 'none',
          '& button': {
            display: 'block',
            textAlign: 'left',
            fontFamily: `${theme.fonts[1]}!important`,
            fontSize: 14,
            width: '100%',
            position: 'relative',
            pointerEvents: 'none',
            padding: '4px 0',
            letterSpacing: '0.04em',
            textTransform: 'capitalize',
            color: 'var(--color)',
            transition: 'color .3s ease-in-out',
            '&:before': {
              content: '""',
              position: 'absolute',
              zIndex: -1,
              background: 'var(--color)',
              borderBottom: '1px solid rgba(0, 0, 0, .1)',
              width: 'calc(100% + 70px)',
              height: 'calc(100% + 6px)',
              top: -3,
              left: -35,
              transition: 'all .5s ease-in-out',
              transformOrigin: '0 0',
              transform: 'scaleX(0)',
            },
          },
          '& > button:not(:last-child)': {
            marginBottom: 8,
          },
        },
        '& span.value': {
          position: 'absolute',
          right: 0,
          bottom: 0,
        },
        '& .diff': {
          display: 'inline-block',
          fontSize: 9,
          transform: 'translateY(-4px)',
        },
        '& .line': {
          height: '10px',
          width: 'calc(100% - 100px)',
          marginTop: '4px',
          display: 'block',
          '& .lineValue': {
            display: 'block',
            height: '100%',
            background: 'var(--color)',
            borderRadius: '20px',
            transition: 'width 1.2s cubic-bezier(0.54, 0.03, 0, 0.96) var(--delay), color .3s ease-in-out, background-color .3s ease-in-out',
            minWidth: '10px',
          },
        },
      },
      '& .prevPostButton': {
        position: 'relative',
        backgroundColor: theme.colors[1],
        height: '50px',
        borderRadius: '25px',
        fontSize: '14px',
        fontFamily: theme.fonts[0],
        letterSpacing: 0,
        textTransform: 'none',
        color: theme.colors[3],
        padding: '4px',
        top: 'auto',
        left: 'auto',

        '& em': {
          fontStyle: 'normal',
          display: 'inline-block',
          overflow: 'hidden',
          verticalAlign: 'text-top',
          width: '60px',
          transition: 'width .3s ease-in-out',
        },

        '& span': {
          display: 'inline-block',
          padding: '13px 30px',
          height: '42px',
          borderRadius: '20px',
          transition: 'color .3s ease-in-out, background-color .3s ease-in-out',
        },

        '&.pre': {
          '& span:nth-child(1)': {
            background: theme.colors[3],
            color: theme.colors[1],
          },
          '& span:nth-child(2)': {
            '& em': {
              width: '20',
            },
          },
        },

        '&.post': {
          '& span:nth-child(2)': {
            background: theme.colors[3],
            color: theme.colors[1],
          },
          '& span:nth-child(1)': {
            '& em': {
              width: '0',
            },
          },
        },
      },

      '& .resultHint': {
        padding: 60,
        textAlign: 'center',
        fontFamily: theme.fonts[1],
        color: theme.colors[3],
        display: 'none',
        opacity: 0,
        transition: `opacity 1s ${theme.easings['power3.out']}`,
        '&.visible': {
          opacity: 1,
        },
      },

      '& .loading': {
        position: 'fixed',
        zIndex: 2,
        left: '50%',
        bottom: 40,
        fontFamily: theme.fonts[1],
        color: theme.colors[3],
        fontSize: 28,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        transition: `transform 1s ${theme.easings['power3.out']} 0s`,
        transform: 'translate(-50%, 80px)',
        '&.visible': {
          transform: 'translate(-50%, 0px)',
          transitionDelay: '.3s',
        },
      },

      '& .activeBoxWrap': {
        position: 'fixed',
        zIndex: 11,
        top: 150,
        left: 20,

        '& .activeBox': {
          position: 'absolute',
          zIndex: 1,
          top: 0,
          left: 0,
          width: 480,
          padding: '30px 35px',
          borderRadius: 20,
          backdropFilter: 'blur(10px)',
          transform: 'translateX(-130%)',
          transition: `transform 1s ${theme.easings['power3.out']} 0s`,
          '&.visible': {
            transform: 'translateX(0%)',
            transitionDelay: '.3s',
          },
          '& .colorList': {
            display: 'inline-block',
            '& span': {
              display: 'block',
              width: '1.5em',
              height: '1.5em',
              borderRadius: '100%',
              backgroundColor: 'var(--color)',
              margin: '4px',
              border: '1px transparent',
              backgroundClip: 'content-box',
              padding: '0.1em',
            },
            '& .active': {
              display: 'block',
              width: '1.5em',
              height: '1.5em',
              borderRadius: '100%',
              backgroundColor: 'var(--color)',
              margin: '4px',
              border: '1px solid black',
              borderColor: 'var(--color)',
              backgroundClip: 'content-box',
              padding: '0.1em',
            },
            '& button': {
              marginLeft: '4px',
              cursor: 'pointer',
            },
          },
          '& .activeBox_bar': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& .bar': {
              overflow: 'hidden',
              width: '85%',
              height: '1rem',
              backgroundColor: 'var(--color)',
              borderRadius: 30,
            },
            '& .progress': {
              overflow: 'hidden',
              width: 'var(--width)',
              height: '1rem',
              backgroundColor: 'var(--color)',
              borderRadius: 30,
            },
          },
          '& .close': {
            zIndex: 2,
            width: 35,
            height: 35,
            borderRadius: 32,
            padding: 0,
            marginLeft: '4px',
            '&:before': {
              content: '"<"',
              fontFamily: theme.fonts[0],
              position: 'absolute',
              transform: 'translate(-8px, -14px)',
              fontSize: 25,
            },
          },
        },

        '& .activeBox__title': {
          fontSize: 32,
          marginBottom: 6,
          color: 'var(--color)',
          '& .diff': {
            fontSize: 16,
            transform: 'translateY(-10px)',
            marginLeft: 6,
          },
        },
        '& .activeBox__desc': {
          marginBottom: 40,
          fontStyle: 'normal',
          fontSize: 16,
          color: 'var(--color)',

        },
        '& .activeBox__values': {
          fontSize: 28,
          color: 'var(--color)',
          '& em': {
            fontStyle: 'normal',
            opacity: 0.7,
          },
          '& strong': {
            display: 'inline-block',
            marginLeft: 6,
          },
          '& .diff': {
            fontSize: 14,
            display: 'inline-block',
            transform: 'translateY(-10px)',
            marginLeft: 6,
          },
        },
      },
    },

    active: {
      color: 'transparent',
      fontSize: '17vw',
      position: 'fixed',
      zIndex: 0,
      top: 0,
      left: 0,
      width: '100%',
      height: 'var(--vh)',
      userSelect: 'none',
      '& span': {
        fontFamily: theme.fonts[0],
        position: 'absolute',
        zIndex: 1,
        whiteSpace: 'nowrap',
        left: '65%',
        top: '0%',
        transform: 'translate(-50%, 30%)',
        WebkitTextFillColor: 'none',
        WebkitTextStrokeWidth: '1px',
        WebkitTextStrokeColor: 'var(--color)',
        textTransform: 'uppercase',
      },
      '& .nextLine': {
        fontFamily: theme.fonts[0],
        position: 'absolute',
        zIndex: 1,
        whiteSpace: 'nowrap',
        left: '75%',
        top: '17%',
        transform: 'translate(-50%, 30%)',
        WebkitTextFillColor: 'none',
        WebkitTextStrokeWidth: '1px',
        WebkitTextStrokeColor: 'var(--color)',
        textTransform: 'uppercase',
      },
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
    controls: {
      position: 'fixed',
      top: '0',
      width: '100%',
      left: '0',
      zIndex: '3',
      overflowY: 'scroll',
      overflowX: 'hidden',
      height: 'var(--vh)',
      paddingTop: 'calc(var(--vh) - 158px)',
      overscrollBehaviorY: 'none',
      overscrollBehaviorX: 'none',
      WebkitOverflowScrolling: 'touch',
      textAlign: 'center',
      paddingBottom: 50,
      '& .prevPostButton': {
        order: 999,
        fontSize: '0.8rem',
      },
      '& .activeBoxWrap': {
        position: 'fixed',
        zIndex: 11,
        top: 60,
        left: 15,
        textAlign: 'left',
        '& .activeBox': {
          position: 'absolute',
          zIndex: 1,
          top: 0,
          left: 0,
          width: 360,
          padding: '30px 35px',
          borderRadius: 20,
          backdropFilter: 'blur(10px)',
          transform: 'translateX(-130%)',
          transition: `transform 1s ${theme.easings['power3.out']} 0s`,
          '&.visible': {
            transform: 'translateX(0%)',
            transitionDelay: '.3s',
          },
          '& .colorList': {
            display: 'inline-block',
            marginBottom: '20px',
            '& span': {
              display: 'block',
              width: '1.2em',
              height: '1.2em',
              borderRadius: '100%',
              backgroundColor: 'var(--color)',
              margin: '0px',
              border: '1px transparent',
              backgroundClip: 'content-box',
              padding: '0.1em',
            },
            '& .active': {
              display: 'block',
              width: '1.2em',
              height: '1.2em',
              borderRadius: '100%',
              backgroundColor: 'var(--color)',
              margin: '0px',
              border: '1px solid black',
              borderColor: 'var(--color)',
              backgroundClip: 'content-box',
              padding: '0.1em',
            },
            '& button': {
              marginLeft: '4px',
              cursor: 'pointer',
            },
          },
          '& .activeBox_bar': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& .bar': {
              overflow: 'hidden',
              width: '15rem',
              height: '1rem',
              backgroundColor: 'var(--color)',
              borderRadius: 30,
            },
            '& .progress': {
              overflow: 'hidden',
              width: 'var(--width)',
              height: '1rem',
              backgroundColor: 'var(--color)',
              borderRadius: 30,
            },
          },
          '& .close': {
            zIndex: 2,
            width: 25,
            height: 25,
            borderRadius: 32,
            padding: 0,
            marginLeft: '4px',
            '&:before': {
              content: '"<"',
              fontFamily: theme.fonts[0],
              position: 'absolute',
              transform: 'translate(-6px, -12px)',
              fontSize: 20,
            },
          },
        },

        '& .activeBox__title': {
          fontSize: 40,
          marginBottom: 6,
          color: 'var(--color)',
          '& .diff': {
            fontSize: 16,
            transform: 'translateY(-10px)',
            marginLeft: 6,
          },
        },
        '& .activeBox__desc': {
          marginBottom: 40,
          fontStyle: 'normal',
          fontSize: 16,
          color: 'var(--color)',

        },
        '& .activeBox__values': {
          fontSize: 28,
          color: 'var(--color)',
          '& em': {
            fontStyle: 'normal',
            opacity: 0.7,
          },
          '& strong': {
            display: 'inline-block',
            marginLeft: 6,
          },
          '& .diff': {
            fontSize: 14,
            display: 'inline-block',
            transform: 'translateY(-10px)',
            marginLeft: 6,
          },
        },
      },
      '& .heading': {
        position: 'absolute',
        zIndex: 1,
        left: '2rem',
        width: '90%',
        top: '18rem',
        transform: 'translate(-100%, -50%)',
        opacity: 0,
        color: theme.colors[3],
        pointerEvents: 'none',
        transition: `transform 1s ${theme.easings['power3.out']} 0s, opacity 1s ${theme.easings['power3.out']} 0s`,
        '&.visible': {
          transform: 'translate(0, -50%)',
          transitionDelay: '.3s',
          opacity: 1,
          pointerEvents: 'all',
        },
        '& .activeBox_bar': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: '1rem',
          alignItems: 'center',
          '& .bar': {
            overflow: 'hidden',
            width: '15rem',
            height: '0.8rem',
            backgroundColor: 'var(--color)',
            borderRadius: 30,
          },
          '& .progress': {
            overflow: 'hidden',
            width: 'var(--width)',
            height: '0.8rem',
            backgroundColor: 'var(--color)',
            borderRadius: 30,
          },
        },
        '& h1': {
          fontSize: '1.6rem',
          textAlign: 'left',
        },
        '& .Score': {
          fontSize: '1.1rem',
          marginBottom: '0.2rem',
          fontFamily: theme.fonts[1],
        },
        '& p': {
          margin: 0,
          fontSize: 12,
          textAlign: 'left',
          fontFamily: theme.fonts[0],
        },
      },
      '& .controls': {
        position: 'static',
        width: 'calc(100% - 20px)',
        marginLeft: '20px',
        height: 'auto!important',
        overflow: 'hidden',
        textAlign: 'left',
        '&.visible': {
          transform: 'translateX(0%)',
          transitionDelay: '.3s',
        },
        '& .total': {
          display: 'block',
        },
        '& .groupBox': {
          padding: '20px ​25px',
        },
        '& h1': {
          marginLeft: '25px',
        },
      },
      '& .controlsLeft': {
        position: 'static',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 80,
        '& .groupBox': {
          margin: '0 auto',
        },
        '& .mobile': {
          display: 'block',
        },
      },
      '& .buttonContinue': {
        position: 'static',
        transform: 'translate(0%, 200%)',
        '&.visible': {
          transform: 'translate(0%, 0%)',
        },
      },

      '& .resultHint': {
        display: 'block',
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    canvas: {
      position: 'fixed!important',
      zIndex: 1,
      top: '28%',
      left: 0,
      width: '100%',
      height: '100%',
    },
    controls: {
      '& .activeBoxWrap': {
        '& .activeBox': {
          padding: [20, 30],
        },
        '& .activeBox__desc': {
          marginBottom: 20,
          fontSize: 13,
        },
        '& .activeBox__title': {
          fontSize: 22,
          marginBottom: 10,
        },
        '& .activeBox__values': {
          fontSize: 20,
          '& .diff': {
            fontSize: 12,
          },
        },
      },
      '& .loading': {
        fontSize: 20,
      },
      '& .prevPostButton': {
        position: 'relative',
        backgroundColor: theme.colors[1],
        height: '30px',
        borderRadius: '25px',
        fontSize: '10px',
        fontFamily: theme.fonts[0],
        letterSpacing: 0,
        textTransform: 'none',
        color: theme.colors[3],
        padding: '3px',
        marginRight: '3rem',
        top: 'auto',
        left: '0',
        '& em': {
          fontStyle: 'normal',
          display: 'inline-block',
          overflow: 'hidden',
          verticalAlign: 'text-top',
          width: '60px',
          transition: 'width .3s ease-in-out',
        },
        '& span': {
          display: 'inline-block',
          padding: '7px 20px',
          height: '24px',
          borderRadius: '20px',
          transition: 'color .3s ease-in-out, background-color .3s ease-in-out',
        },
      },
    },
    active: {
      top: 320,
      '& .nextLine': {
        fontFamily: theme.fonts[0],
        position: 'absolute',
        zIndex: 1,
        whiteSpace: 'nowrap',
        left: '75%',
        top: '5%',
        transform: 'translate(-50%, 30%)',
        WebkitTextFillColor: 'none',
        WebkitTextStrokeWidth: '1px',
        WebkitTextStrokeColor: 'var(--color)',
        textTransform: 'uppercase',
      },
    },
  }

  return output
}

export default style
