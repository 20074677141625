import { forwardRef, useCallback } from 'react'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { createUseStyles, useTheme } from 'react-jss'
import { useHistory } from 'react-router-dom'
import * as routerActions from '@/actions/fakerouter'
import style from './style'

const useStyles = createUseStyles(style)

const Logo = forwardRef(({ className,}, ref) => {
  const classes = useStyles()
  const theme = useTheme()

  const history = useHistory()

  const strings = useSelector((state) => state.options.strings)

  const dispatch = useDispatch()
  const changeLocation = useCallback((page) => dispatch(routerActions.changeLocation(page)), [dispatch])

  const handleMainPage = () => {
    history.push('/')
    changeLocation('begin')
  }

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [className]: className,
      })}
      ref={ref}
    >
      <button
          className={classes.logoButton}
          onClick={handleMainPage}
        > 
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 482.6 170.08"
          width={theme.header.logo.width}
          height={theme.header.logo.height}
          fill={theme.header.logo.fill}
        >
          <title>{`${strings['general.site_title']} Logo`}</title>
          <path d="M316.22 226.77c-23.42 0-49.22 14.34-65.41 27.91l1.28 1.85c25.13-17.62 60-29.08 69.64-11.9 5.08 9.08-3.55 27.39-15.69 41.86 2.91-6.39 1-18.66-15.93-18.66-28.15 0-83.53 37.28-83.53 75.42 0 15.28 10.59 25.25 28 25.25 46.74 0 110.13-76.61 110.13-115.09.05-13.59-7.77-26.64-28.49-26.64zm-65.59 100.52c-8.09 0-12-6.22-9.95-13.41 4.19-14.91 31.12-37 49.47-37 13.39 0 12.36 12 4.52 22.19l-4.33 4.8c-15.56 15.26-29.98 23.42-39.71 23.42zM419.63 267.22a22.07 22.07 0 00-16.25-7.66H376l-4.64 17.31h25.77l2.47.26a5.75 5.75 0 013.67 2c1.06 1.47 1.22 3.66.46 6.51l-1.22 4.56h-16.2c-14 0-33.47 10-37.81 26.23-2.17 8.08-.27 15.07 3.93 20.22s12 7.92 20.51 7.92h34.15l9-33.49 6.6-24.61c2.56-9.65-.24-15.8-3.06-19.25zm-43.24 60.07a8.05 8.05 0 01-6.4-2.68 7.83 7.83 0 01-1.21-7.05c1.54-5.78 7.77-10.3 14.16-10.3h15l-5.37 20zM607.39 259.56a33 33 0 00-19.85 6.34c-5.65 4.17-8.76 9.2-10.61 16.12-4 15 7.76 22.25 17.25 27.38 6.79 3.68 12.66 6.86 11.4 11.56-.9 3.38-2.38 6.33-10.74 6.33h-25.52l-4.63 17.31h28.25c7.57 0 14.56-1.58 20.2-5.9s8.83-10.39 10.8-17.77c1.89-7 .61-13.73-3.91-18.74-3.76-4.2-9.18-7.12-14-9.7-6.12-3.29-11.88-6.4-10.87-10.17.61-2.27 2.88-5.45 7.82-5.45h24.82l4.64-17.31zM459.26 259.56a33 33 0 00-19.86 6.34c-5.64 4.17-8.75 9.2-10.62 16.12-4 15 7.79 22.25 17.26 27.38 6.8 3.68 12.67 6.86 11.41 11.56-.91 3.38-2.38 6.33-10.75 6.33h-25.51l-4.64 17.31h28.25c7.58 0 14.57-1.58 20.2-5.9s8.83-10.39 10.81-17.77c1.88-7 .6-13.73-3.9-18.74-3.77-4.2-9.19-7.12-14-9.7-6.11-3.29-11.88-6.4-10.86-10.17.61-2.27 2.88-5.45 7.81-5.45h24.81l4.65-17.31zM497.81 259.56l-22.16 85h18.71l22.15-85zM566.32 259.56c-33 .12-50.2 22.07-55.68 42.52-6.49 24.24 6.42 42.52 30 42.52h15.82l4.63-17.31H545.3c-8.84 0-21-7-16.09-25.21 3.89-14.52 18-25.21 33.57-25.21h10.05l4.63-17.31z" transform="translate(-178.23 -212.6)" />
        </svg>
      </button>
    
    </div>
  )
})

export default Logo
