const style = (theme) => {
  const output = {
    root: {},
    title: {
      extend: theme.typography.headings,
      fontSize: 36,
      marginBottom: 30,
      textAlign: 'center',
    },
    btn: {
      extend: theme.button,
    },
    buttons: {
      marginTop: 50,
      display: 'flex',
      justifyContent: 'center',
    },
    disclaimer: {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      bottom: 30,
      maxWidth: 500,
      fontSize: 14,
      margin: [0, 'auto'],
      color: theme.colors[3],
      textAlign: 'center',
      opacity: 0,
      '& p': {
        opacity: 0.5,
      },
      '& a': {
        '--color': theme.colors[3],
        extend: theme.link,
      },
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {}

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    root: {
      width: '90%',
    },
    title: {
      fontSize: 24,
      marginBottom: 20,
    },
    buttons: {
      marginTop: 20,
    },
    disclaimer: {
      width: '90%',
      maxWidth: 'auto',
      fontSize: 12,
    },
  }

  return output
}

export default style
