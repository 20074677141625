import { useRef, useEffect, useContext, useState, useCallback } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import classNames from 'classnames'
import gsap from 'gsap'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp'
import MapboxWorker from 'worker-loader?filename=frontend/map.[chunkhash:4].worker.js!mapbox-gl/dist/mapbox-gl-csp-worker' // eslint-disable-line import/no-unresolved
import axios from 'axios'
import Logo from '@/components/Logo'
import SelectField from '@/components/SelectField'
import Estimate from '@/components/Estimate'
import { Context as GeneralContext } from '@/context'
import Meta from '@/components/Meta'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import usePagePrecache from '@/hooks/usePagePrecache'
import useMediaQuery from '@/hooks/useMediaQuery'
import { MAPBOX_TOKEN, API, API_BASE_URL } from '@/constants'
import * as mapActions from '@/actions/map'
import * as eventsActions from '@/actions/events'
import * as layerActions from '@/actions/layer'
import Tutorial from './Tutorial/index'
import style from './style'
import countriesGeoLocations from './countriesgeoLocation.json'

mapboxgl.workerClass = MapboxWorker
mapboxgl.accessToken = MAPBOX_TOKEN

const useStyles = createUseStyles(style)

const WorldUpliftMap = () => {
  const { setPageAnimationReady, headerHeight, setHeaderMap, setHeaderInverse } = useContext(GeneralContext)
  const classes = useStyles({ headerHeight })
  const $root = useRef()
  const theme = useTheme()
  const $mapContainer = useRef()
  const $mapInstance = useRef()
  const $mindAllWorldData = useRef()
  const [isCountriesAdded, setCountriesAdded] = useState(false)
  const [filterEmotion, setFilterEmotion] = useState()
  const [filterSport, setFilterSport] = useState()
  const [firstTimeSportTab, setfirstTimeSportTab] = useState(true)
  const [filterEvent, setFilterEvent] = useState()
  const $mapExpresionFilter = useRef()
  const $currentColor = useRef()
  const $stateResponseStatus = useRef('waiting')
  const [worldMarkersArray, setworldMarkersArray] = useState()
  const [filterType, setFilterType] = useState('mind')
  const [isMapReady, setMapReady] = useState(false)
  const [currentPosition, setCurrentPosition] = useState([-44.93290868112209, 37.52588916602657]) // eslint-disable-line
  const [mediaQueryKey] = useMediaQuery()
  const [zoom, setZoom] = useState(2) // eslint-disable-line
  const [isSourceFirstTime, setSourceFirstTime] = useState(false)
  const filterTypeRef = useRef()
  const filterSportRef = useRef('')
  const currentLevelRef = useRef()
  const $sidebarScoreMobile = useRef()

  /*------------------------------
  Redux Store
  ------------------------------*/
  const {
    bbox,
    city,
    country,
    countryNames,
    currentEmotion,
    currentEvent,
    currentLanguage,
    currentLevel,
    currentSport,
    currentZone,
    currentZoneName,
    emotions,
    events,
    isFilterOpen,
    isResultsOpen,
    isLegendOpen,
    map,
    region,
    schema,
    sportPlayed,
    sports,
    strings,
    userPosition,
    world,
    topPlayed,
  } = useSelector((state) => ({
    bbox: state.map.bbox,
    city: state.map[filterType].city,
    country: state.map[filterType].country,
    countryNames: state.map.countryNames,
    currentEmotion: state.map.currentEmotion,
    currentEvent: state.map.currentEvent,
    currentLanguage: state.locale.currentLanguage,
    currentLevel: state.map.currentLevel,
    currentSport: state.map.currentSport,
    currentZone: state.map.currentZone,
    currentZoneName: state.map.currentZoneName,
    emotions: state.emotions.items,
    events: state.events.totalEvents,
    isFilterOpen: state.layer.layers.some((layer) => layer.id === 'filter' && layer.isOpen),
    isResultsOpen: state.layer.layers.some((layer) => layer.id === 'results' && layer.isOpen),
    isLegendOpen: state.layer.layers.some((layer) => layer.id === 'legend' && layer.isOpen),
    map: state.map,
    region: state.map[filterType].region,
    schema: state.locale.schema,
    sportPlayed: state.map.sportPlayed,
    sports: state.sports.items,
    strings: state.options.strings,
    userPosition: state.user.position,
    world: state.map[filterType].world,
    topPlayed: state.map.sports.world.top_5_sports,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchEvents = useCallback(() => dispatch(eventsActions.fetchStrapiEvents(undefined, 'country_all')), [dispatch])
  const setMapLevel = useCallback((level) => dispatch(mapActions.setMapLevel(level)), [dispatch])
  const setMapEmotion = useCallback((emotion) => dispatch(mapActions.setMapEmotion(emotion)), [dispatch])
  const setMapZone = useCallback((zone) => dispatch(mapActions.setMapZone(zone)), [dispatch])
  const setMapZoneName = useCallback((name) => dispatch(mapActions.setMapZoneName(name)), [dispatch])
  const setMapSport = useCallback((sport) => dispatch(mapActions.setMapSport(sport)), [dispatch])
  const setMapEvent = useCallback((event) => dispatch(mapActions.setMapEvent(event)), [dispatch])
  const setMapCurrentCountry = useCallback((countryCode) => dispatch(mapActions.setMapCurrentCountry(countryCode)), [dispatch])
  const fetchMapMind = useCallback((level, emo, zone) => dispatch(mapActions.fetchMapMind(level, emo, zone)), [dispatch])
  const fetchMapSport = useCallback((level, sport, zone) => dispatch(mapActions.fetchMapSport(level, sport, zone)), [dispatch])
  const fetchMapEvent = useCallback(() => dispatch(mapActions.fetchMapEvent()), [dispatch])
  const fetchMapSportList = useCallback(() => dispatch(mapActions.fetchMapSportList()), [dispatch])
  const resetMapMarkers = useCallback(() => dispatch(mapActions.resetMapMarkers()), [dispatch])
  const openFilter = useCallback(() => dispatch(layerActions.openLayer({ id: 'filter' })), [dispatch])
  const closeFilter = useCallback(() => dispatch(layerActions.closeLayer({ id: 'filter' })), [dispatch])
  const openResults = useCallback(() => dispatch(layerActions.openLayer({ id: 'results' })), [dispatch])
  const closeResults = useCallback(() => dispatch(layerActions.closeLayer({ id: 'results' })), [dispatch])
  const openLegend = useCallback(() => dispatch(layerActions.openLayer({ id: 'legend' })), [dispatch])
  const closeLegend = useCallback(() => dispatch(layerActions.closeLayer({ id: 'legend' })), [dispatch])

  /*------------------------------
  Manage Header
  ------------------------------*/
  useEffect(() => {
    resetMapMarkers()
    setHeaderMap(true)
    setHeaderInverse(true)
    return () => {
      setHeaderMap(false)
      setHeaderInverse(false)
    }
  }, [])

  /*------------------------------
  Set Center on Geolocation Coords
  ------------------------------*/
  useEffect(() => {
    if (userPosition.length > 0 && isMapReady) {
      $mapInstance.current.easeTo({
        center: userPosition,
      })
    }
  }, [userPosition, isMapReady])

  /*------------------------------
  Preload
  ------------------------------*/
  const [realLoad, setRealLoad] = useState(false)
  const [load] = usePagePrecache({ // eslint-disable-line
    init: true,
    sources: [],
    callback: () => {
      fetchEvents()
      setPageAnimationReady(true)
    },
  })

  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (events.length > 0 && sports.length > 0 && emotions.length > 0) setRealLoad(true)
  }, [events, sports, emotions])

  /*------------------------------
  Init Map
  ------------------------------*/
  useEffect(() => {
    if (realLoad && currentLanguage) {
      // Initialize Map
      $mapInstance.current = new mapboxgl.Map({
        container: $mapContainer.current,
        style: 'mapbox://styles/solarflarestudio/cl38jvlci004x14qvfc1z80eb?fresh=true',
        center: currentPosition,
        zoom,
        antialias: true,
        minZoom: 2,
      })
      // Set current Language on Country Label
      const lang = schema[currentLanguage].mapCode
      $mapInstance.current.on('load', () => {
        setMapReady(true)
        $mapInstance.current.setLayoutProperty('country-label', 'text-field', [
          'match',
          ['get', 'name_en'],
          ['Hong Kong', 'Macau', 'China'],
          '',
          ['get', `name_${lang}`],
        ])
        $mapInstance.current.addSource('countries', {
          type: 'vector',
          url: 'mapbox://mapbox.country-boundaries-v1',
        })
        // THIS SOURCE SHOULD BE MOVED TO PROPER ONE so more Geo Jsons can be added.
        $mapInstance.current.addSource('usa-states-source', {
          type: 'geojson',
          data: 'https://sf-geojsons.s3.eu-west-2.amazonaws.com/USAStates.geojson',
        })
        $mapInstance.current.addLayer(
          {
            id: 'usa-states',
            type: 'fill',
            source: 'usa-states-source',
            paint: {
              'fill-color': theme.colors[3],
              'fill-opacity': 0,
            },
          },
          'admin-0-boundary',
        )
        // Add base layer for Colors
        $mapInstance.current.addLayer(
          {
            id: 'countries-join',
            type: 'fill',
            source: 'countries',
            'source-layer': 'country_boundaries',
            paint: {
              'fill-color': theme.colors[1],
            },
          },
          'admin-0-boundary',
        )
      })
    }
    return () => {
      // Destroy Map
      if (realLoad) $mapInstance.current.remove()
    }
  }, [realLoad, currentPosition, currentLanguage])

  /*------------------------------
  Hide Country Labels on Deeper Levels
  ------------------------------*/
  // useEffect(() => {
  //   if (filterType === 'events') {
  //     $mapInstance.current?.setLayoutProperty('country-label', 'visibility', 'none')
  //   } else {
  //     $mapInstance.current?.setLayoutProperty('country-label', 'visibility', 'visible')
  //   }
  // }, [filterType])

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return realLoad && <Meta title="World Uplift Map" />
  }

  /*------------------------------
  Update a ref with filterType
  ------------------------------*/
  useEffect(() => {
    filterTypeRef.current = filterType
  }, [filterType])

  /*------------------------------
  Update a ref with filterSport
  ------------------------------*/
  useEffect(() => {
    filterSportRef.current = currentSport?.id || ''
  }, [currentSport])

  /*------------------------------
  Update a ref with filterType
  ------------------------------*/
  useEffect(() => {
    currentLevelRef.current = currentLevel
  }, [currentLevel])

  const handleStateColouring = (countryCode) => {
    const matchExpression = ['match', ['get', 'COUNTRY']]

    // THIS INTERVAL SHOULD BE REMOVED WHEN A PROPER API ENDPOINT IS DONE
    // This will do a check if the data from the api is ready, if not will try again in 200 ms
    const interval = setInterval(() => {
      if ($stateResponseStatus.current === 'ready') {
        /* MIND ----------------------------------------------*/
        if (filterTypeRef.current === 'mind') {
          const countryRowFound = $mindAllWorldData.current.filter((countryRow) => {
            return countryRow.code === countryCode
          })
          countryRowFound[0].features.forEach((row) => {
            let dominant
            if (row.score.length > 5) {
              dominant = row.score.reduce((prev, current) => ((prev.score > current.score) ? prev : current))
            }
            let color
            if (dominant) {
              // Emotions All
              const dominantEmotions = emotions.find((emo) => emo.key === dominant.name)
              color = theme.getRgba(dominantEmotions.color, 1)
            } else {
              // Total Uplift or Single Emotion
              let newColor = 0
              if (row.score <= 0.10) {
                newColor = 0.05
              } else if (row.score <= 0.30) {
                newColor = 0.15
              } else if (row.score <= 0.50) {
                newColor = 0.25
              } else if (row.score <= 0.60) {
                newColor = 0.40
              } else if (row.score <= 0.65) {
                newColor = 0.50
              } else if (row.score <= 0.70) {
                newColor = 0.60
              } else if (row.score <= 0.75) {
                newColor = 0.70
              } else if (row.score <= 0.80) {
                newColor = 0.80
              } else if (row.score <= 0.90) {
                newColor = 0.90
              } else {
                newColor = 1
              }
              console.log($currentColor.current)
              color = theme.getRgba($currentColor.current, newColor)

              //  color = theme.getRgba(currentEmotion.color || theme.colors[3], row.score)
            }
            matchExpression.push(row.title, color)
          })
          // Set color to Country with no data
          matchExpression.push(theme.getRgba($currentColor.current || theme.colors[3], 0.01))

          if ($mapInstance.current?.getLayer('usa-states')) {
            $mapInstance.current.setPaintProperty('usa-states', 'fill-color', matchExpression)
            $mapInstance.current.setPaintProperty('usa-states', 'fill-opacity', 1)
            const countryExpresion = $mapExpresionFilter.current
            countryExpresion[$mapExpresionFilter.current.lastIndexOf('US') + 1] = theme.getRgba($currentColor.current || theme.colors[3], 0.01)
            $mapInstance.current.setPaintProperty('countries-join', 'fill-color', countryExpresion)
          }
        }
        if (filterTypeRef.current === 'sports') {
          const countryRowFound = $mindAllWorldData.current.filter((countryRow) => {
            return countryRow.code === countryCode
          })
          countryRowFound[0].features.forEach((row) => {
            let dominant
            if (row.score.length > 5) {
              dominant = row.score.reduce((prev, current) => ((prev.score > current.score) ? prev : current))
            }
            let color
            if (dominant) {
              // Emotions All
              const dominantEmotions = emotions.find((emo) => emo.key === dominant.name)
              color = theme.getRgba(dominantEmotions.color, 1)
            } else {
              // Total Uplift or Single Emotion
              let newColor = 0
              if (row.score <= 0.10) {
                newColor = 0.05
              } else if (row.score <= 0.30) {
                newColor = 0.15
              } else if (row.score <= 0.50) {
                newColor = 0.25
              } else if (row.score <= 0.60) {
                newColor = 0.40
              } else if (row.score <= 0.65) {
                newColor = 0.50
              } else if (row.score <= 0.70) {
                newColor = 0.60
              } else if (row.score <= 0.75) {
                newColor = 0.70
              } else if (row.score <= 0.80) {
                newColor = 0.80
              } else if (row.score <= 0.90) {
                newColor = 0.90
              } else {
                newColor = 1
              }
              color = theme.getRgba($currentColor.current, newColor)

              //  color = theme.getRgba(currentEmotion.color || theme.colors[3], row.score)
            }
            matchExpression.push(row.title, color)
          })
          // Set color to Country with no data
          matchExpression.push(theme.getRgba($currentColor.current || theme.colors[3], 0.01))

          if ($mapInstance.current?.getLayer('usa-states')) {
            $mapInstance.current.setPaintProperty('usa-states', 'fill-color', matchExpression)
            $mapInstance.current.setPaintProperty('usa-states', 'fill-opacity', 1)
            const countryExpresion = $mapExpresionFilter.current
            countryExpresion[$mapExpresionFilter.current.lastIndexOf('US') + 1] = theme.getRgba($currentColor.current || theme.colors[3], 0.01)
            $mapInstance.current.setPaintProperty('countries-join', 'fill-color', countryExpresion)
          }
        }
        clearInterval(interval)
      }
    }, 200)
  }
  /*------------------------------
  Handle Click on Country
  ------------------------------*/
  const handleClickOnCountry = (e) => {
    const states = $mapInstance.current.queryRenderedFeatures(e.point, {
      layers: ['statedata'],
    })
    if (
      states[0]
      && filterTypeRef.current !== 'events'
      && !(filterTypeRef.current === 'sports' && filterSportRef.current === '')
      && (currentLevelRef.current === 'world' || currentLevelRef.current === 'country')
    ) {
      let countryCode
      if (states[0].properties.name_en === 'Kuril Islands') {
        countryCode = 'RU'
      } else {
        countryCode = states[0].properties.iso_3166_1
      }
      if (countryCode === 'MO' || countryCode === 'CN') return
      setMapLevel('country')
      setMapZone(countryCode)
      setMapCurrentCountry(countryCode)
      setMapZoneName(countryNames[currentLanguage][countryCode])
      const boundingBox = bbox[countryCode][1]
      $mapInstance.current.fitBounds(boundingBox, { padding: 0 })
      handleStateColouring(countryCode)
    }
  }

  /*------------------------------
  Add/Remove Click on Country
  ------------------------------*/
  useEffect(() => {
    if (realLoad && currentLevel === 'world') {
      // $mapInstance.current.on('click', (e) => handleClickOnCountry(e))
    }
  }, [realLoad, currentLevel])

  /*------------------------------
  Create Country Layer on Mind Filter Type
  ------------------------------*/
  const changeColorLayer = (features) => {
    $stateResponseStatus.current = 'waiting'
    const matchExpression = ['match', ['get', 'iso_3166_1']]
    const filters = [
      'all',
      ['match', ['get', 'worldview'], ['all', 'US'], true, false],
      ['match', ['get', 'name_en'], ['Hong Kong', 'Macau', 'China'], false, true], // Remove Color from layer
    ]

    /* MIND ----------------------------------------------*/
    if (filterType === 'mind') {
      features.forEach((row) => {
        let dominant
        if (row.score.length > 5) {
          dominant = row.score.reduce((prev, current) => ((prev.score > current.score) ? prev : current))
        }
        let color
        if (dominant) {
          // Emotions All
          const dominantEmotions = emotions.find((emo) => emo.key === dominant.name)
          color = theme.getRgba(dominantEmotions.color, 1)
        } else {
          // Total Uplift or Single Emotion
          let newColor = 0
          if (row.score <= 0.10) {
            newColor = 0.05
          } else if (row.score <= 0.30) {
            newColor = 0.15
          } else if (row.score <= 0.50) {
            newColor = 0.25
          } else if (row.score <= 0.60) {
            newColor = 0.40
          } else if (row.score <= 0.65) {
            newColor = 0.50
          } else if (row.score <= 0.70) {
            newColor = 0.60
          } else if (row.score <= 0.75) {
            newColor = 0.70
          } else if (row.score <= 0.80) {
            newColor = 0.80
          } else if (row.score <= 0.90) {
            newColor = 0.90
          } else {
            newColor = 1
          }
          color = theme.getRgba(currentEmotion.color || theme.colors[3], newColor)

          //  color = theme.getRgba(currentEmotion.color || theme.colors[3], row.score)
        }
        matchExpression.push(row.code, color)
      })
      // set All world features from countries this should be changed later on to use a single country endpoint or array of contries that will have state map colours
      axios.get(`${API_BASE_URL}/map/mind?level=world&zone=all&country_code=all${currentEmotion?.key !== undefined ? `&emotion=${currentEmotion?.key}` : ''}`).then((response) => {
        $mindAllWorldData.current = response.data.data.features
        $stateResponseStatus.current = 'ready'
      })
      $currentColor.current = currentEmotion?.color || theme.colors[3]
      // Set color to Country with no data
      matchExpression.push(theme.getRgba(theme.colors[3], 0.01))

      if ($mapInstance.current?.getLayer('countries-join')) {
        $mapInstance.current.setFilter('countries-join', filters)
        $mapInstance.current.setPaintProperty('countries-join', 'fill-color', matchExpression)
      }
    }
    /* SPORTS ----------------------------------------------*/
    if (filterType === 'sports') {
      if (features.length > 0) {
        features
          .filter((f) => f.score !== 0)
          .forEach((row) => {
            let newColor = 0
            if (row.score <= 0.10) {
              newColor = 0.05
            } else if (row.score <= 0.30) {
              newColor = 0.15
            } else if (row.score <= 0.50) {
              newColor = 0.25
            } else if (row.score <= 0.60) {
              newColor = 0.40
            } else if (row.score <= 0.65) {
              newColor = 0.50
            } else if (row.score <= 0.70) {
              newColor = 0.60
            } else if (row.score <= 0.75) {
              newColor = 0.70
            } else if (row.score <= 0.80) {
              newColor = 0.80
            } else if (row.score <= 0.90) {
              newColor = 0.90
            } else {
              newColor = 1
            }
            const color = theme.getRgba(theme.colors[13], newColor)
            matchExpression.push(row.code, color)
          })
        // Set color to Country with no data
        matchExpression.push(theme.getRgba(theme.colors[13], 0.01))
        $currentColor.current = theme.colors[13]
        $mindAllWorldData.current = features
        $stateResponseStatus.current = 'ready'
        if ($mapInstance.current?.getLayer('countries-join')) {
          $mapInstance.current.setPaintProperty('countries-join', 'fill-color', matchExpression)
        }
      } else {
        $mapInstance.current.setPaintProperty('countries-join', 'fill-color', theme.colors[13])
      }
    }
    /* EVENTS ----------------------------------------------*/
    if (filterType === 'events') {
      if ($mapInstance.current?.getLayer('countries-join')) {
        // TODO: Optimize code to reduce
        // Here we get the markers that are going to be set, and we get the country with mapbox places, and change hte colour of that country
        const eventsList = world.features.map(({ event_id }) => event_id)
        const eventsMarkers = events
          .filter((e) => eventsList.includes((e.contentfulId??e.id)))
          .map((e) => ({
            id: (e.contentfulId??e.id),
            ...e,
          }))
          .map((e) => ({
            id: (e.contentfulId??e.id),
            title: e.title,
            coordinates: `${e.where?.lat}, ${e.where?.lon}`,
            score: world.features.find((w) => w.event_id === (e.contentfulId??e.id)).score,
            variation: world.features.find((w) => w.event_id === (e.contentfulId??e.id)).variation,
            users: world.features.find((w) => w.event_id === (e.contentfulId??e.id)).user,
            dominant_sport_id: world.features.find((w) => w.event_id === (e.contentfulId??e.id)).dominant_sport_id,
          }))

        const eventss = eventsMarkers.map((eventMarker) => {
          if (!eventMarker.coordinates.includes('undefined')) {
            const coordinates = eventMarker.coordinates.replace(' ', '').split(',')
            return axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${coordinates[1]},${coordinates[0]}.json?types=country&access_token=${mapboxgl.accessToken}`).then((response) => {
              return response
            })
          }
        })
        Promise.all(eventss).then((responses) => {
          const countries = responses.filter((response) => response !== undefined).map((response) => {
            if (response.data.features.length > 0) {
              return response.data.features[0].properties.short_code.toUpperCase()
            }
          })
          const uniqueCountries = [...new Set(countries)]
          uniqueCountries.forEach((countryCode) => {
            const color = theme.getRgba(theme.colors[14], 1)
            matchExpression.push(countryCode, color)
          })
          // Set color to Country with no data
          matchExpression.push(theme.getRgba(theme.colors[14], 0.01))
          $mapInstance.current.setPaintProperty('countries-join', 'fill-color', matchExpression)
        })
      }
    }
    $mapExpresionFilter.current = matchExpression
  }

  /*------------------------------
  Init Color Tiles Map
  ------------------------------*/
  useEffect(() => {
    if (realLoad
        && $mapInstance.current
        && world?.features?.length > 0
        && !isCountriesAdded
    ) {
      $mapInstance.current.on('load', () => {
        changeColorLayer(world.features)
        setCountriesAdded(true)
      })
    }
  }, [realLoad, world, isCountriesAdded])

  /*------------------------------
  FETCH MAP MIND API
  ------------------------------*/
  useEffect(() => {
    if (filterType === 'mind' && realLoad) fetchMapMind()
  }, [currentEmotion, currentZone, filterType, realLoad, currentLevel])

  /*------------------------------
  FETCH MAP SPORT API
  ------------------------------*/
  useEffect(() => {
    if (filterType === 'sports' && realLoad) {
      if (sportPlayed.length === 0) fetchMapSportList()
      fetchMapSport()
    }
  }, [filterType, currentZone, currentSport, realLoad, currentLevel])

  /*------------------------------
  FETCH MAP EVENT
  ------------------------------*/
  useEffect(() => {
    if (filterType === 'events' && realLoad) fetchMapEvent()
  }, [filterType, realLoad])

  /*------------------------------
  Change Country Layer only when World node changes
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(world).length > 0) {
      changeColorLayer(world.features)
    }
  }, [world])

  /*------------------------------
  Handle Click on Marker
  ------------------------------*/
  const handleClickOnMarker = (e) => {
    const coords = e.features[0].geometry.coordinates
    const { title } = e.features[0].properties
    if (currentLevelRef.current === 'country') {
      setMapLevel('region')
      $mapInstance.current.flyTo({ center: coords, zoom: 8 })
    }
    if (currentLevelRef.current === 'region') {
      setMapLevel('city')
      $mapInstance.current.flyTo({ center: coords, zoom: 12 })
    }
    if (currentLevelRef.current === 'city') {
      $mapInstance.current.flyTo({ center: coords })
    }
    if (filterTypeRef.current === 'events') {
      setMapLevel('city')
      setMapEvent(e.features[0].properties)
      $mapInstance.current.flyTo({ center: coords, zoom: 12 })
    }
    if (currentLevelRef.current === 'world' && (filterType !== 'events')) {
      handleClickOnCountry(e)
    } else {
      setMapZone(title)
      setMapZoneName(title)
    }
  }

  /*------------------------------
  Handle Click On Clusters
  ------------------------------*/
  const handleClickOnClusters = (e) => {
    const features = $mapInstance.current.queryRenderedFeatures(e.point, {
      layers: ['clusters', 'unclustered-circle'],
    })
    const clusterId = features[0].properties.cluster_id
    $mapInstance.current.getSource('markers').getClusterExpansionZoom(
      clusterId,
      (err, z) => {
        if (err) return

        $mapInstance.current.flyTo({
          center: features[0].geometry.coordinates,
          zoom: (z + 0.2), // Fix bug on click clusters expansion zoom
        })
      },
    )
  }

  /*------------------------------
  Handle Pointer Props
  ------------------------------*/
  const handlePointerProps = (value) => {
    $mapInstance.current.getCanvas().style.cursor = value
  }

  /*------------------------------
  Remove Current Markers
  ------------------------------*/
  const removeCurrentMarkers = () => {
    if ($mapInstance.current?.getLayer('unclustered-text')) $mapInstance.current.removeLayer('unclustered-text')
    if ($mapInstance.current?.getLayer('unclustered-circle')) $mapInstance.current.removeLayer('unclustered-circle')
    if ($mapInstance.current?.getLayer('clusters')) $mapInstance.current.removeLayer('clusters')
    if ($mapInstance.current?.getLayer('cluster-count')) $mapInstance.current.removeLayer('cluster-count')
    if ($mapInstance.current?.getSource('markers')) $mapInstance.current.removeSource('markers')
  }

  /*------------------------------
  Add Markers
  ------------------------------*/
  const addMarkers = (list, clusterGrouping = 0) => {
    if (!$mapInstance.current.getSource('markers')) {
      $mapInstance.current.addSource('markers', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: list.features.filter((f) => f.score !== 0).map((feature, index) => ({
            id: index,
            type: 'Feature',
            geometry: { type: 'Point', coordinates: feature.coordinates.split(',').reverse() },
            properties: {
              title: feature.title,
              ...feature.score && {
                score: feature.score,
              },
              ...feature.variation && {
                variation: feature.variation,
              },
              ...feature.user && {
                users: feature.user,
              },
              ...feature.dominant_sport_id && {
                dominant_sport_id: feature.dominant_sport_id,
              },
            },
          })),
        },
        cluster: true,
        clusterMaxZoom: currentLevel === 'country' ? 3 : 14,
        clusterRadius: clusterGrouping > 0 ? clusterGrouping : 50,
      })
    }
    if (!$mapInstance.current.getLayer('unclustered-text')) {
      $mapInstance.current.addLayer({
        id: 'unclustered-text',
        type: 'symbol',
        source: 'markers',
        filter: ['!has', 'point_count'],
        layout: {
          'text-field': ['get', 'title'],
          'text-font': ['Graphik Medium'],
          'text-offset': [0, 1.25],
          'text-anchor': 'top',
          'text-size': 14,
        },
        paint: {
          'text-color': '#ffffff',
          'text-halo-color': '#485bc7',
          'text-halo-width': 1,
        },
      })
    }
    if (!$mapInstance.current.getLayer('unclustered-circle')) {
      $mapInstance.current.addLayer({
        id: 'unclustered-circle',
        type: 'circle',
        source: 'markers',
        filter: ['!has', 'point_count'],
        paint: {
          'circle-color': '#ffffff',
          'circle-radius': 10,
          'circle-stroke-color': '#000000',
          'circle-stroke-opacity': 0.2,
          'circle-stroke-width': 3,
        },
        transition: {
          duration: 300,
          delay: 0,
        },
      })
    }
    if (!$mapInstance.current.getLayer('clusters')) {
      $mapInstance.current.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'markers',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': '#ffffff',
          'circle-radius': {
            property: 'point_count',
            stops: [
              [0, 15],
              [50, 25],
              [100, 35],
            ],
          },
          'circle-stroke-color': '#000000',
          'circle-stroke-opacity': 0.2,
          'circle-stroke-width': 3,
        },
      })
    }
    if (!$mapInstance.current.getLayer('cluster-count')) {
      $mapInstance.current.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'markers',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '+',
          'text-font': ['Graphik Medium'],
          'text-size': 16,
          'text-offset': [0, 0.25],
          'text-anchor': 'center',
        },
        paint: {
          'text-color': '#485bc7',
        },
        transition: {
          duration: 0,
          delay: 0,
        },
      })
    }
    if (!isSourceFirstTime) {
      $mapInstance.current.on('click', 'unclustered-circle', handleClickOnMarker)
      $mapInstance.current.on('click', 'unclustered-text', handleClickOnMarker)
      $mapInstance.current.on('click', 'clusters', handleClickOnClusters)

      // Change the cursor to a pointer when the it enters a feature in the 'circle' layer.
      $mapInstance.current.on('mouseenter', 'unclustered-circle', () => {
        handlePointerProps('pointer')
      })
      $mapInstance.current.on('mouseenter', 'unclustered-text', () => handlePointerProps('pointer'))
      $mapInstance.current.on('mouseenter', 'clusters', () => handlePointerProps('pointer'))

      // Change it back to a pointer when it leaves.
      $mapInstance.current.on('mouseleave', 'unclustered-circle', () => {
        handlePointerProps('')
      })
      $mapInstance.current.on('mouseleave', 'unclustered-text', () => handlePointerProps(''))
      $mapInstance.current.on('mouseleave', 'clusters', () => handlePointerProps(''))
    }
    setSourceFirstTime(true)
  }

  /*------------------------------
  Animate Score Mobile
  ------------------------------*/
  const animateScoreMobile = () => {
    if ($sidebarScoreMobile.current) {
      gsap.killTweensOf($sidebarScoreMobile.current)
      gsap.fromTo($sidebarScoreMobile.current, { y: 100 }, { y: 0, duration: 0.2 })
    }
  }

  /*------------------------------
  Display Markers
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(country).length > 0) {
      // Remove all markers before added into
      removeCurrentMarkers()
      // Add all markers of this zone
      addMarkers(country)
      // Animate Score Mobile
      animateScoreMobile()
    }
  }, [country])

  useEffect(() => {
    if (Object.keys(region).length > 0) {
      // Remove all markers before added into
      removeCurrentMarkers()
      // Add all markers of this zone
      addMarkers(region)
      // Animate Score Mobile
      animateScoreMobile()
    }
  }, [region])

  useEffect(() => {
    if (Object.keys(city).length > 0) {
      // Animate Score Mobile
      animateScoreMobile()
    }
  }, [city])

  useEffect(() => {
    if (Object.keys(world).length > 0 && filterType === 'events') {
      // Remove all markers before added into
      removeCurrentMarkers()

      const eventsList = world.features.map(({ event_id }) => event_id)
      const eventsMarkers = events
        .filter((e) => eventsList.includes((e.contentfulId??e.id)))
        .map((e) => ({
          id: (e.contentfulId??e.id),
          ...e,
        }))
        .map((e) => ({
          id: (e.contentfulId??e.id),
          title: e.title,
          coordinates: `${e.where?.lat}, ${e.where?.lon}`,
          score: world.features.find((w) => w.event_id === (e.contentfulId??e.id)).score,
          variation: world.features.find((w) => w.event_id === (e.contentfulId??e.id)).variation,
          users: world.features.find((w) => w.event_id === (e.contentfulId??e.id)).user,
          dominant_sport_id: world.features.find((w) => w.event_id === (e.contentfulId??e.id)).dominant_sport_id,
        }))
      const listMarkers = { features: eventsMarkers }
      // Add all markers of this zone
      addMarkers(listMarkers)
    }
    if ((filterType === 'mind' || filterType === 'sports') && $mapInstance.current && map[filterType].world.features) {
      removeCurrentMarkers()
      let ArrayFeatures = []
      const datafiltered = countriesGeoLocations.filter((geolocation) => {
        const dataFound = map[filterType].world.features.filter((feature) => {
          return feature.code === geolocation.country
        })
        return dataFound.length > 0
      })
      datafiltered.forEach((countryGeoLocation) => {
        ArrayFeatures = ArrayFeatures.concat({ title: '', coordinates: `${countryGeoLocation.latitude},${countryGeoLocation.longitude}` })
      })
      const list = { features: ArrayFeatures }
      addMarkers(list, 75)
    }
  }, [world])

  /*------------------------------
  Remove all Markers on Level Change & Filter Type
  ------------------------------*/
  useEffect(() => {
    if (filterType !== 'events' && currentLevel !== 'city') removeCurrentMarkers()
  }, [currentLevel])

  useEffect(() => {
    removeCurrentMarkers()
  }, [filterType])

  useEffect(() => {
    if (filterType === 'sports' && map.sports.world.top_5_sports !== undefined && firstTimeSportTab) {
      const sport = sports.filter((s) => {
        return s.id === map.sports.world.top_5_sports[0]
      })
      setFilterSport(sport[0].name)
      setMapSport(sport[0].id)
      setfirstTimeSportTab(false)
    }
  }, [topPlayed])

  /*------------------------------
  Handle Click Close
  ------------------------------*/
  const handleClickClose = () => {
    if (filterType === 'sports' || filterType === 'mind') {
      setMapLevel('world')
      setMapZone('')
      setTimeout(() => {
        $mapInstance.current.setPaintProperty('usa-states', 'fill-opacity', 0.01)
      }, 1100)
    } else {
      setMapLevel('world')
      setMapZone('')
      if (filterType !== 'events') resetMapMarkers()
      setMapEvent('')
    }
  }

  /*------------------------------
  On Click Zoom
  ------------------------------*/
  const onClickZoom = (type) => {
    const pos = $mapInstance.current.getCenter()
    const currentZoom = $mapInstance.current.getZoom()
    if (type === 'in') $mapInstance.current.flyTo({ center: pos, zoom: currentZoom + 1 })
    if (type === 'out' && currentZoom > 2.5) $mapInstance.current.flyTo({ center: pos, zoom: currentZoom - 1 })
  }

  /*------------------------------
  Render Fixed UI
  ------------------------------*/
  const renderFixedUI = () => {
    return (
      <>
        <header id="headerForTooltip1" className={classes.header}>
          <Logo className={classes.logo} />
        </header>
        {mediaQueryKey <= 1 && (
          <>
            <div className={classes.sideLeftCmd}>
              <button // eslint-disable-line
                className={classNames({
                  [classes.sidebarClose]: true,
                  sports: filterType === 'sports',
                  events: filterType === 'events',
                  visible: currentLevel !== 'world',
                })}
                onClick={handleClickClose}
              >
                <span />
              </button>
            </div>
            <div className={classes.sideRightCmd}>
              <div className={classes.zoomCmd}>
                <button onClick={() => onClickZoom('in')}>+</button>
                <button onClick={() => onClickZoom('out')}>−</button>
              </div>
              <div>
                <button
                  onClick={openFilter}
                  className={classNames({
                    [classes.filterCmd]: true,
                    notificationEmotion: filterType === 'mind' && currentEmotion !== '' && currentEmotion.key !== 'none',
                    notificationSport: filterType === 'sports' && currentSport !== '' && currentSport.name !== '',
                    visible: currentLevel === 'world',
                  })}
                >
                  <svg><use xlinkHref="#ico-filter" /></svg>
                </button>
              </div>
              <div>
                <button
                  onClick={openLegend}
                  className={classNames({
                    [classes.filterCmd]: true,
                    visible: filterType === 'mind',
                  })}
                >
                  <svg><use xlinkHref="#ico-info" /></svg>
                </button>
              </div>
            </div>
          </>
        )}
        {mediaQueryKey > 1 && (
          <footer
            className={classNames({
              [classes.footer]: true,
              sports: filterType === 'sports',
              events: filterType === 'events',
            })}
            style={{ '--emotion-color': currentEmotion.color || theme.colors[3] }}
          >
            <div className={classes.footerContent}>
              {/* <div className={classes.footerTop}>
                <svg><use xlinkHref="#ico-users" /></svg>
                {(filterType === 'events' && currentLevel !== 'world')
                  ? currentEvent.users
                  : map[filterType][currentLevel].users}
              </div>
              <div className={classes.footerBottom}>
                {strings['map.filter.participants']}
                {(currentZoneName && currentLevel !== 'world') && ` / ${currentZoneName}`}
              </div> */}
            </div>
          </footer>
        )}
        {(mediaQueryKey > 1 && filterType === 'mind') && (
          <div
            className={classes.sidebarRight}
            style={{ '--emotion-color': currentEmotion.color || theme.colors[3], '--emotion-color-start': theme.getRgba(currentEmotion.color || theme.colors[3], 0.3) }}
            id="barKeyIdForTutorial"
          >
            <div className={classes.legendWrapper}>
              {currentEmotion.key === 'all' ? (
                <ul className={classes.swatchList}>
                  {emotions.map((emo, i) => (
                    <li
                      key={i.toString()}
                      style={{ '--emotion-color': emo.color }}
                    >
                      <div className={classes.swatch} />
                      <div className={classes.swatchName}>{emo.name}</div>
                    </li>
                  ))}
                </ul>
              ) : (
                <>
                  <h4>{strings['map.filter.uplift']}</h4>
                  <div className={classes.gradient} />
                  <div className={classes.values}>
                    <span>0</span>
                    <span>100</span>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </>
    )
  }

  /*------------------------------
  Render Map
  ------------------------------*/
  const renderMap = () => {
    return (
      <div
        id="mapIdForTutorial"
        className={classes.mapContainer}
        ref={$mapContainer}
      />
    )
  }
  /*------------------------------
  Render Filter Nav
  ------------------------------*/
  const renderFilterNav = () => {
    return (
      <ul>
        {[{ id: 'mind', name: strings['map.filter.mind'] },
          { id: 'sports', name: strings['map.filter.sports'] },
          { id: 'events', name: strings['map.filter.events'] },
        ].map((filter) => (
          <li
            key={filter.id}
            className={classNames({
              [classes.filterButtonActive]: filterType === filter.id,
            })}
          >
            <button
              className={classNames({
                [classes.filterButton]: true,
                [classes.mindButton]: filter.id === 'mind',
                [classes.sportsButton]: filter.id === 'sports',
                [classes.eventsButton]: filter.id === 'events',
              })}
              onClick={() => setFilterType(filter.id)}
            >
              {filter.name}
            </button>
          </li>
        ))}
      </ul>
    )
  }

  /*------------------------------
  Render Filter Overlay Mobile
  ------------------------------*/
  const renderFilterOverlayMobile = () => {
    const emotionsOptions = [
      { id: 'none', name: strings['map.select.generalscore'] },
      // { id: 'all', name: strings['map.select.mind.all'] },
      ...emotions
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((emo) => ({ id: emo.key, name: emo.name })),
    ]
    const sportOptions = [
      { id: 999, name: strings['map.select.sport.all'] },
      ...sports.map((sport) => ({ id: sport.id, name: sport.name })),
    ]
    const eventOptions = events.map((ev) => ({ id: (e.contentfulId??e.id), name: ev.title }))
    return (
      <div
        className={classNames({
          [classes.filterMobile]: true,
          visible: isFilterOpen,
          disabled: !isMapReady,
        })}
      >
        <div className="heading">{strings['map.filterby.label']}</div>
        <div className="scroller">
          {filterType === 'mind' && (
            <ul>
              {emotionsOptions.map((e, i) => (
                <li // eslint-disable-line
                  key={i.toString()}
                  className={classNames({
                    active: currentEmotion?.key === e.id,
                  })}
                  onClick={() => {
                    setFilterEmotion(e.name)
                    setMapEmotion(e.id)
                    closeFilter()
                  }}
                >
                  {e.name}
                </li>
              ))}
            </ul>
          )}
          {filterType === 'sports' && (
            <ul>
              {sportOptions.map((e, i) => (
                <li // eslint-disable-line
                  key={i.toString()}
                  className={classNames({
                    active: currentSport?.id === e.id,
                  })}
                  onClick={() => {
                    setFilterSport(e.name)
                    setMapSport(e.id)
                    closeFilter()
                  }}
                >
                  {e.name}
                </li>
              ))}
            </ul>
          )}
          {filterType === 'events' && (
            <ul>
              {eventOptions.map((e, i) => (
                <li // eslint-disable-line
                  key={i.toString()}
                  onClick={() => {
                    setFilterEvent({ label: e.name, value: e.id })
                    setMapEvent(e.id)
                    closeFilter()
                  }}
                >
                  {e.name}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    )
  }

  /*------------------------------
  Render Legend Overlay Mobile
  ------------------------------*/
  const renderLegendOverlayMobile = () => {
    return (
      <div
        className={classNames({
          [classes.resultsMobile]: true,
          visible: isLegendOpen,
          disabled: !isMapReady,
        })}
      >
        <button // eslint-disable-line
          className={classNames({
            [classes.sidebarClose]: true,
          })}
          onClick={closeLegend}
        >
          <span />
        </button>
        <div
          className={classes.sidebarRight}
          style={{ '--emotion-color': currentEmotion.color || theme.colors[3], '--emotion-color-start': theme.getRgba(currentEmotion.color || theme.colors[3], 0.3) }}
        >
          <div className={classes.legendWrapper}>
            {currentEmotion.key === 'all' ? (
              <ul className={classes.swatchList}>
                {emotions.map((emo, i) => (
                  <li
                    key={i.toString()}
                    style={{ '--emotion-color': emo.color }}
                  >
                    <div className={classes.swatch} />
                    <div className={classes.swatchName}>{emo.name}</div>
                  </li>
                ))}
              </ul>
            ) : (
              <>
                <h4>{strings['map.filter.uplift']}</h4>
                <div className={classes.gradient} />
                <div className={classes.values}>
                  <span>0%</span>
                  <span>100%</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }

  /*------------------------------
  Render Emotion Filter
  ------------------------------*/
  const renderEmotionFilter = () => {
    const options = [
      { id: 'none', name: strings['map.select.generalscore'] },
      // { id: 'all', name: strings['map.select.mind.all'] },
      ...emotions
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((emo) => ({ id: emo.key, name: emo.name, color: emo.color })),
    ]
    return (
      <SelectField
        className={classes.select}
        label={strings['map.select.mind.placeholder']}
        name="chooseTrait"
        value={filterEmotion}
        idToSet="IdForTutorialSelectMind"
        defaultValue={options.find((option) => option.name === filterEmotion)}
        options={options}
        setFieldValue={(label, value) => {
          setFilterEmotion(label)
          setMapEmotion(value)
        }}
      />
    )
  }

  /*------------------------------
  Render Sports Filter
  ------------------------------*/
  const renderSportsFilter = () => {
    const options = [
      ...sports.map((sport) => ({ id: sport.id, name: sport.name })),
    ]
    return (
      <SelectField
        className={classes.select}
        label={strings['map.select.sport.placeholder']}
        name="chooseSport"
        value={filterSport}
        defaultValue={options.find((option) => option.id === filterSport)}
        options={options}
        setFieldValue={(label, value) => {
          setFilterSport(label)
          setMapSport(value)
        }}
      />
    )
  }

  /*------------------------------
  Render Events Filter
  ------------------------------*/
  const renderEventsFilter = () => {
    if (world?.features?.length > 0) {
      const eventsList = world.features.map(({ event_id }) => event_id)
      const eventsMarkers = events.filter((e) => eventsList.includes(e.contentfulId??e.id))
      const options = eventsMarkers.map((ev) => ({ id: (ev.contentfulId??ev.id), name: ev.title }))
      return (
        <SelectField
          key={`events_select_key__${filterEvent?.label}`}
          className={classes.select}
          label={strings['map.select.events.placeholder']}
          name="chooseEvent"
          options={options}
          setFieldValue={(label, value) => setFilterEvent({ label, value })}
        />
      )
    }
    return null
  }

  /*------------------------------
  Manage change Events select
  ------------------------------*/
  useEffect(() => {
    if (filterEvent) {
      const eventsList = world.features.map(({ event_id }) => event_id)
    
      const eventsMarkers = events
        .filter((e) => eventsList.includes((e.contentfulId??e.id)))
        .map((e) => ({
          id: (e.contentfulId??e.id),
          ...e,
        }))
        .map((e) => ({
          id: (e.contentfulId??e.id),
          title: e.title,
          coordinates: e.where ? [e.where.lon, e.where.lat] : '',
          score: world.features.find((w) => w.event_id === (e.contentfulId??e.id)).score,
          variation: world.features.find((w) => w.event_id === (e.contentfulId??e.id)).variation,
          users: world.features.find((w) => w.event_id === (e.contentfulId??e.id)).user,
          dominant_sport_id: world.features.find((w) => w.event_id === (e.contentfulId??e.id)).dominant_sport_id,
        }))
      const event = eventsMarkers.find((em) => em.id === filterEvent.value)
      setMapLevel('city')
      setMapEvent(event)
      if (event.coordinates !== '') $mapInstance.current.flyTo({ center: event.coordinates, zoom: 12 })
      setMapZone(event.title)
      setMapZoneName(event.title)
    }
  }, [filterEvent])

  /*------------------------------
  Render Result Detail
  ------------------------------*/
  const renderResultDetail = () => {
    const currentSportID = map[filterType][currentLevel].dominant_sport_id || currentEvent.dominant_sport_id
    return (
      <>
        <h4 className={classes.filterResultTitle}>
          {currentLevel === 'world' && strings['map.filter.world']}
          {(currentLevel !== 'world' && currentLevel !== 'country') && currentZoneName}
          {' '}
          {currentLevel === 'world' && strings['map.filter.results']}
        </h4>
        <div className={classes.filterEstimate}>
          {(currentLevel !== 'world' && filterType === 'events') && (
            <Estimate
              value={(currentEvent.score?.toFixed(2) || 0)}
              variation={(currentEvent.variation?.toFixed(2) || 0)}
              trait={strings['map.filter.uplift.events']}
            />
          )}
          {(currentLevel === 'world' && filterType === 'events') && (
            <Estimate
              value={(map[filterType][currentLevel].score?.toFixed(2) || 0)}
              variation={(map[filterType][currentLevel].variation?.toFixed(2) || 0)}
              trait={strings['map.filter.uplift.events']}
            />
          )}
          {filterType !== 'events' && (
            <Estimate
              value={(map[filterType][currentLevel].score?.toFixed(2) || 0)}
              variation={(map[filterType][currentLevel].variation?.toFixed(2) || 0)}
              trait={currentEmotion.name || strings['map.filter.uplift']}
              color={currentEmotion.color}
            />
          )}
        </div>
        {filterType !== 'events' && (
          <div
            className={classNames({
              [classes.filterSport]: true,
              visible: currentSportID,
            })}
          >
            {currentSportID && (
              <div className={classes.filterSportIcon}>
                <img
                  src={`/frontend/static/images/sports/${currentSportID}.png`}
                  alt={sports.find((s) => s.id === currentSportID)?.name}
                />
              </div>
            )}
            <div className={classes.filterSportInfo}>
              {sports.find((sport) => sport.id === currentSportID)?.name && (
                <>
                  <h5>{sports.find((sport) => sport.id === currentSportID)?.name}</h5>
                  <p>{strings['map.filter.most.practiced.sport']}</p>
                </>
              )}
            </div>
          </div>
        )}
      </>
    )
  }

  /*------------------------------
  Render Filter Result Sports
  ------------------------------*/
  const renderFilterResultSports = () => {
    const sportImage = (id) => `/frontend/static/images/sports/${id}.png`
    return (
      <>
        <h4 className={classes.filterResultTitle}>{strings['map.filter.top5sports']}</h4>
        <div className={classes.filterSportListWrapper}>
          <ul
            className={classNames({
              [classes.filterSportList]: true,
            })}
          >
            {map.sports.world?.top_5_sports?.map((sport, i) => (
              <li // eslint-disable-line
                key={i.toString()}
                onClick={() => {
                  setFilterSport(sports.find((s) => s.id === sport)?.name)
                  setMapSport(sport)
                }}
              >
                <div className={classes.filterSportIcon}>
                  {sport && (
                    <img
                      src={sportImage(sport)}
                      alt={sports.find((s) => s.id === sport)?.name}
                    />
                  )}
                </div>
                <div className={classes.filterSportInfo}>
                  <h5>{sports.find((s) => s.id === sport)?.name}</h5>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </>
    )
  }

  /*------------------------------
  Render Content Result Sports
  ------------------------------*/
  const renderContentResultSports = () => {
    return (
      <>
        {Object.keys(currentSport).length > 0 && (
          <div className={classes.filterSportBox}>
            <div className={classes.filterSportIcon}>
              <img
                src={`/frontend/static/images/sports/${currentSport.id}.png`}
                alt={currentSport.id}
              />
            </div>
            <span>{currentSport.name}</span>
          </div>
        )}
        <h4 className={classes.filterResultTitle}>
          {(currentLevel !== 'world' && currentLevel !== 'country') && currentZoneName}
        </h4>
        <div className={classes.filterEstimate}>
          <Estimate
            value={(map[filterType][currentLevel].score?.toFixed(2) || 0)}
            variation={map[filterType][currentLevel].variation?.toFixed(2) || 0}
            trait={strings['map.filter.uplift']}
          />
        </div>
      </>
    )
  }

  /*------------------------------
  Render Filter Content
  ------------------------------*/
  const renderFilterContent = () => {
    return (
      <>
        <div className={classes.filterSelect}>
          {currentLevel === 'world' && (
            <>
              {filterType === 'mind' && renderEmotionFilter()}
              {filterType === 'sports' && renderSportsFilter()}
              {filterType === 'events' && renderEventsFilter()}
            </>
          )}
        </div>
        <div
          className={classNames({
            [classes.filterResults]: true,
            [classes.filterResultSport]: filterType === 'sports' && currentLevel !== 'world',
            [classes.filterResultEvents]: filterType === 'events' && currentLevel !== 'world',
          })}
        >
          {(filterType !== 'sports' && !(currentLevel === 'world' && filterType === 'events')) && renderResultDetail()}
          {(filterType === 'sports' && currentLevel === 'world') && renderFilterResultSports()}
          {(filterType === 'sports' && currentLevel !== 'world') && renderContentResultSports()}
        </div>
      </>
    )
  }

  /*------------------------------
  Render Results Overlay Mobile
  ------------------------------*/
  const renderResultsOverlayMobile = () => {
    return (
      <div
        className={classNames({
          [classes.resultsMobile]: true,
          visible: isResultsOpen,
        })}
      >
        <button // eslint-disable-line
          className={classNames({
            [classes.sidebarClose]: true,
            sports: filterType === 'sports',
            events: filterType === 'events',
          })}
          onClick={closeResults}
        >
          <span />
        </button>
        <div
          className={classNames({
            [classes.filterResults]: true,
            [classes.filterResultSport]: filterType === 'sports' && currentLevel !== 'world',
            [classes.filterResultEvents]: filterType === 'events' && currentLevel !== 'world',
          })}
        >
          {filterType !== 'sports' && renderResultDetail()}
          {(filterType === 'sports' && currentLevel === 'world') && renderFilterResultSports()}
          {(filterType === 'sports' && currentLevel !== 'world') && renderContentResultSports()}
        </div>
        <footer
          className={classNames({
            [classes.footer]: true,
            sports: filterType === 'sports',
            events: filterType === 'events',
          })}
          style={{ '--emotion-color': currentEmotion.color || theme.colors[3] }}
        >
          <div className={classes.footerContent}>
            {/* <div className={classes.footerTop}>
              <svg><use xlinkHref="#ico-users" /></svg>
              {(filterType === 'events' && currentLevel !== 'world')
                ? currentEvent.users
                : map[filterType][currentLevel].users}
            </div>
            <div className={classes.footerBottom}>
              {strings['map.filter.participants']}
              {(currentZoneName && currentLevel !== 'world') && ` / ${currentZoneName}`}
            </div> */}
          </div>
        </footer>
      </div>
    )
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return realLoad && (
      <>
        <div id="sidebarIdForTutorial" className={classes.sidebar}>
          {renderFixedUI()}
          {mediaQueryKey > 1 ? (
            <div // DESKTOP
              className={classNames({
                [classes.sidebarContent]: true,
                visible: currentLevel === 'world',
              })}
            >
              <nav
                className={classNames({
                  [classes.filterNav]: true,
                  disabled: !isMapReady,
                })}
                id="NavIdForTutorialfocus"
              >
                {renderFilterNav()}
              </nav>
              <div
                className={classNames({
                  [classes.filterContent]: true,
                  disabled: !isMapReady,
                })}
              >
                {renderFilterContent()}
              </div>
            </div>
          ) : (
            <div // MOBILE
              className={classNames({
                [classes.sidebarContentMobile]: true,
                visible: currentLevel === 'world',
              })}
              id="SidebarContentMobile"
            >
              <nav
                className={classNames({
                  [classes.filterNav]: true,
                  disabled: !isMapReady,
                })}
              >
                {renderFilterNav()}
              </nav>
            </div>
          )}
          {mediaQueryKey <= 1 && (
            <div // eslint-disable-line
              className={classNames({
                [classes.sidebarScoreMobile]: true,
                visible: currentLevel === 'world',
                disabled: !isMapReady,
              })}
              id="SidebarScoreMobile"
              ref={$sidebarScoreMobile}
              {...(filterType === 'mind' && { // eslint-disable-line
                style: { '--color': currentEmotion.color || theme.colors[3] },
              })}
              {...(filterType === 'sports' && { // eslint-disable-line
                style: { '--color': theme.colors[13] },
              })}
              {...(filterType === 'events' && { // eslint-disable-line
                style: { '--color': theme.colors[14] },
              })}
              onClick={openResults}
            >
              <div className="text">
                {filterType === 'mind' && (currentEmotion.name || strings['map.filter.uplift'])}
                {filterType === 'sports' ? currentLevel === 'world' ? strings['map.filter.top5sports'] : strings['map.filter.uplift'] : ''} {/* eslint-disable-line */}
                {filterType === 'events' && strings['map.filter.uplift.events']}
                {(currentZoneName && (currentLevel !== 'world' && currentLevel !== 'country')) && ` / ${currentZoneName}`}
              </div>
              <div className={classes.scoreValue}>
                <div className={classes.scoreValueButton}>
                  <span />
                </div>
              </div>
            </div>
          )}
          {mediaQueryKey > 1 && (
            <div
              className={classNames({
                [classes.sidebarContentCountry]: true,
                visible: currentLevel !== 'world',
              })}
            >
              <button // eslint-disable-line
                className={classNames({
                  [classes.sidebarClose]: true,
                  sports: filterType === 'sports',
                  events: filterType === 'events',
                })}
                onClick={handleClickClose}
              >
                <span />
              </button>
              <div className={classes.filterContent}>
                {renderFilterContent()}
              </div>
            </div>
          )}
        </div>
        {mediaQueryKey <= 1 && (
          <>
            {renderFilterOverlayMobile()}
            {renderResultsOverlayMobile()}
            {renderLegendOverlayMobile()}
          </>
        )}
        {renderMap()}
      </>
    )
  }

  return (
    <>
      <LocomotiveScroll
        init={realLoad}
        className={`page pageWorldUpliftMap ${classes.root}`}
        ref={$root}
      >
        {renderHelmet()}
        {renderContent()}
        <Tutorial isMapReady />
      </LocomotiveScroll>
    </>
  )
}

export default WorldUpliftMap
