import { useState, useRef, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import gsap from 'gsap'
import style from './style'
import RevealText from '@/components/RevealText'
import RevealFadeIn from '@/components/RevealFadeIn'
import useMediaQuery from '@/hooks/useMediaQuery'
import usePrevious from '@/hooks/usePrevious'

const useStyles = createUseStyles(style)

function ArrowLeft(props) {
  const disabeld = props.disabled ? ' arrow--disabled' : ''
  return (
    <button className="arrow" onClick={props.onClick}>
      <svg
        className={` arrow--left${disabeld}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      </svg>
    </button>
  )
}

function ArrowRight(props) {
  const disabeld = props.disabled ? ' arrow--disabled' : ''
  return (
    <button className="arrow" onClick={props.onClick}>
      <svg
        className={` arrow--right${disabeld}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      </svg>
    </button>
  )
}

const SLIDES = 7

const EmotionsGallery = ({
  items,
  className,
}) => {
  const [mediaQueryKey] = useMediaQuery()

  const transform = useRef([
    {
      x: -275,
      mdX: -80,
      scale: 0.28,
    },
    {
      x: -215,
      mdX: -62,
      scale: 0.35,
    },
    {
      x: -140,
      mdX: -38,
      scale: 0.40,
    },

    {
      x: 0,
      mdX: 0,
      scale: 0.75,
    },

    {
      x: 140,
      mdX: 38,
      scale: 0.40,
    },
    {
      x: 215,
      mdX: 62,
      scale: 0.35,
    },
    {
      x: 275,
      mdX: 80,
      scale: 0.28,
    },
    {
      x: 285,
      mdX: 85,
      scale: 0,
    },
    {
      x: 0,
      mdX: 0,
      scale: 0,
    },
    {
      x: 0,
      mdX: 0,
      scale: 0,
    },
  ])
  const classes = useStyles()
  const [currentSlide, setCurrentSlide] = useState(3)
  const prevSlide = usePrevious(currentSlide)
  const [currentName, setCurrentName] = useState('FOCUSED')
  const [currentDirection, setCurrentDirection] = useState(null)
  const [currentColor, setCurrentColor] = useState('#67DAD2')
  const [currentVisible, setCurrentVisible] = useState(true)
  const [currentDesc, setCurrentDesc] = useState('Your degree of focus to a given task over a sustained period of time. A steady reading indicates a high level of concentration and, in turn, heightened productivity.')
  const [firstStart, setFirstStart] = useState(true)
  const [progress, setProgress] = useState(0)
  // const [progress, setProgress] = useState(0)
  const sliderRef = useRef()

  useEffect(() => {
    setCurrentVisible(false)
    // debugger
    setCurrentName(items[currentSlide].name)
    setCurrentColor(items[currentSlide].color)
    setCurrentDesc(items[currentSlide].description)
    // console.groupCollapsed('useEffect')
    switch (currentDirection) {
      case 'left':
        // for (let index = -Math.ceil(SLIDES / 2), i = SLIDES; index < Math.ceil(SLIDES / 2); index++) {
        for (let index = 0; index < items.length; index++) {
          // let fromX = -Math.ceil(SLIDES / 2) ? transform.current[i] :
          const outIndex = (((prevSlide - Math.floor(SLIDES / 2)) % items.length) + items.length) % items.length
          const inIndex = (((currentSlide + Math.floor(SLIDES / 2)) % items.length) + items.length) % items.length
          const scale = { to: 0, from: 0 }
          const x = { to: 0, from: 0 }
          const el = sliderRef.current.children[index]

          if (index === outIndex) {
            el.style.zIndex = 1
            x.to = mediaQueryKey < 2 ? transform.current[0].mdX : transform.current[0].x
            x.from = mediaQueryKey < 2 ? transform.current[0].mdX : transform.current[0].x
            scale.to = 0
            scale.from = transform.current[0].scale
          } else if (index === inIndex) {
            el.style.zIndex = 3

            x.from = mediaQueryKey < 2 ? transform.current[SLIDES].mdX : transform.current[SLIDES].x
            x.to = mediaQueryKey < 2 ? transform.current[SLIDES - 1].mdX : transform.current[SLIDES - 1].x
            scale.from = 0
            scale.to = transform.current[SLIDES - 1].scale
          } else {
            el.style.zIndex = 2

            const iFrom = (((index - (prevSlide - Math.floor(SLIDES / 2))) % items.length) + items.length) % items.length
            const iTo = (((index - 1 - (prevSlide - Math.floor(SLIDES / 2))) % items.length) + items.length) % items.length

            x.from = mediaQueryKey < 2 ? transform.current[iFrom].mdX : transform.current[iFrom].x
            x.to = mediaQueryKey < 2 ? transform.current[iTo].mdX : transform.current[iTo].x
            scale.from = transform.current[iFrom].scale
            scale.to = transform.current[iTo].scale
          }

          gsap.killTweensOf(el)
          gsap.fromTo(el, {
            x: x.from,
            scale: scale.from,
          }, {
            duration: 0.5,
            x: x.to,
            scale: scale.to,
          })
          // i--
        }

        break
      case 'right':
        for (let index = 0; index < items.length; index++) {
          // let fromX = -Math.ceil(SLIDES / 2) ? transform.current[i] :
          const outIndex = (((prevSlide + Math.floor(SLIDES / 2)) % items.length) + items.length) % items.length
          const inIndex = (((currentSlide - Math.floor(SLIDES / 2)) % items.length) + items.length) % items.length
          const scale = { to: 0, from: 0 }
          const x = { to: 0, from: 0 }
          const el = sliderRef.current.children[index]

          if (index === outIndex) {
            el.style.zIndex = 1
            x.to = mediaQueryKey < 2 ? transform.current[SLIDES].mdX : transform.current[SLIDES].x
            x.from = mediaQueryKey < 2 ? transform.current[SLIDES - 1].mdX : transform.current[SLIDES - 1].x
            scale.to = 0
            scale.from = transform.current[SLIDES - 1].scale
          } else if (index === inIndex) {
            el.style.zIndex = 3

            x.from = mediaQueryKey < 2 ? -transform.current[SLIDES].mdX : -transform.current[SLIDES].x
            x.to = mediaQueryKey < 2 ? transform.current[0].mdX : transform.current[0].x
            scale.from = 0
            scale.to = transform.current[0].scale
          } else {
            el.style.zIndex = 2

            // const iFrom = (((index + (prevSlide - Math.floor(SLIDES / 2))) % items.length) + items.length) % items.length
            // const iTo = (((index + 1 - (prevSlide - Math.floor(SLIDES / 2))) % items.length) + items.length) % items.length

            const iFrom = (((index - (prevSlide - Math.floor(SLIDES / 2))) % items.length) + items.length) % items.length
            const iTo = ((((index + 1) - (prevSlide - Math.floor(SLIDES / 2))) % items.length) + items.length) % items.length

            x.from = mediaQueryKey < 2 ? transform.current[iFrom].mdX : transform.current[iFrom].x
            x.to = mediaQueryKey < 2 ? transform.current[iTo].mdX : transform.current[iTo].x
            scale.from = transform.current[iFrom].scale
            scale.to = transform.current[iTo].scale
          }

          gsap.killTweensOf(el)
          gsap.fromTo(el, {
            x: x.from,
            scale: scale.from,
          }, {
            duration: 0.5,
            x: x.to,
            scale: scale.to,
          })
          // i--
        }

        break
      default:
        break
    }
    console.groupEnd('useEffect')
    setTimeout(() => {
      setCurrentVisible(true)
    }, 100)
  }, [currentSlide])

  return (
    <div className={classNames({
      [classes.root]: true,
      className,
    })}
    >
      {/* change hardcoded emotion */}
      {currentVisible ? (
        <RevealText
          tag="h1"
          type="lines"
          value={currentName}
          visible={currentVisible}
          lineHeight={1}
          style={{
            '--color': currentColor,
          }}
          className={classes.emotionTitle}
        />
      ) : (
        <h1
          style={{
            '--color': currentColor,
            opacity: 0,
            lineHeight: 1.25,
          }}
          className={classes.emotionTitle}
        >
          <div style={{
            display: 'block',
            textAlign: 'center',
            position: 'relative',
            transformOrigin: '50% 100%',
            transform: 'translate(0, 0)',
          }}
          >

            placeholder
          </div>
        </h1>
      )}
      <div
        className={classes.emotionSlider}
      >
        <div
          ref={sliderRef}
        >

          {items.map((item, i) => {
            return (
              <div
                key={i.toString()}
                className={classNames({
                  [classes.slide]: true,
                  'emotion-slider__slide': true,
                })}
                data-name={item.name}
                                // data-description={item.description}
                style={{
                  '--color': item.color,
                  transform: `translate(${mediaQueryKey < 2 ? transform.current[i].mdX : transform.current[i].x}px, 0) scale(${transform.current[i].scale})`,
                }}
              >
                <div className={classes.ball} />
              </div>
            )
          })}
        </div>

        <div className={classNames({
          [classes.arrowWrapper]: true,
          [classes.arrowLeft]: true,
        })}
        >
          <ArrowLeft onClick={() => {
            if (!currentVisible) return
            setCurrentDirection('left')
            setCurrentSlide((currentSlide + 1) % items.length)
          }}
          />
        </div>
        <div className={classNames({
          [classes.arrowWrapper]: true,
          [classes.arrowRight]: true,
        })}
        >
          <ArrowRight onClick={() => {
            if (!currentVisible) return
            setCurrentDirection('right')
            setCurrentSlide(currentSlide - 1 < 0 ? items.length - 1 : currentSlide - 1)
          }}
          />
        </div>
      </div>
      {currentVisible ? (
        <RevealFadeIn
          visible={currentVisible}
          inview={currentVisible}
                    // className={classes.heroText}
          className={classes.emotionDescription}
        >
          {/* <div dangerouslySetInnerHTML={{ __html: convertRichText(page.resultsDescription) }} /> */}
          <p>
            {currentDesc}
          </p>
        </RevealFadeIn>
      ) : (
        <p
          className={classes.emotionDescription}
          style={{ opacity: 0 }}
        >
          Lorem
        </p>
      )}
    </div>
  )
}

export default EmotionsGallery
