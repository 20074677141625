import axios from 'axios'
import {axiosInstance} from '@/base/axios'
import { REQUEST_QUESTIONS, RECEIVE_QUESTIONS } from '@/actions/types'
import { API, CONTENTFUL_SPACE, CONTENTFUL_TOKEN } from '@/constants'

const requestQuestions = () => ({ type: REQUEST_QUESTIONS })

const receiveQuestions = (data) => ({
  type: RECEIVE_QUESTIONS,
  payload: data,
})


const fetchStrapiQuestions = (step) => async (dispatch, getState) => {
  const key =  step===1?'ultra-uplift-questions' : 'ultra-uplift-final-questions';
  const localeCode = getState().locale.currentLanguage ?? document.documentElement.lang;
  const response = await axiosInstance.get(`${key}?locale=${localeCode}`);
  let orderedQuestions = response.data.data.map((it) => it.attributes)
    .sort((a, b) => a.identifier - b.identifier);
  dispatch(receiveQuestions(orderedQuestions))
}
export {
  fetchStrapiQuestions,
}
