import * as array from './array'
import * as decodeEntities from './decodeEntities'
import * as dom from './dom'
import * as math from './math'
import * as path from './path'
import * as strings from './strings'
import * as debounce from './debounce'
import * as locale from './locale'
export default {
  array,
  decodeEntities,
  dom,
  math,
  path,
  strings,
  debounce,
  locale
}
