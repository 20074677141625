import { useRef, useState, useEffect, useContext, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Context as GeneralContext } from '@/context'
import Meta from '@/components/Meta'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import RevealText from '@/components/RevealText'
import RevealFadeIn from '@/components/RevealFadeIn'
import SelectField from '@/components/SelectField'
import usePagePrecache from '@/hooks/usePagePrecache'
import * as experienceActions from '@/actions/experience'
import * as contentActions from '@/actions/content'
import * as routerActions from '@/actions/fakerouter'
import * as questionsActions from '@/actions/questions'
import { convertRichText } from '@/utils/strings'
import style from './style'

const useStyles = createUseStyles(style)

const ChooseAgeGender = () => {
  const { setPageAnimationReady, pageAnimationReady, headerHeight } = useContext(GeneralContext)
  const classes = useStyles({ headerHeight })
  const [isDataFetched, setDataFetched] = useState(false)
  const [fieldLabelGender, setFieldLabelGender] = useState()
  const [fieldValueGender, setFieldValueGender] = useState()
  const [fieldValueAge, setFieldValueAge] = useState()
  const [fieldLabelAge, setFieldLabelAge] = useState()
  const $root = useRef()

  /*------------------------------
  Redux Store
  ------------------------------*/
  const { fakeLocation, page, strings, isLoggedIn, profile,currentLanguage } = useSelector((state) => ({
    page: state.content['gender-age-page'] || {},
    fakeLocation: state.fakerouter.location,
    strings: state.options.strings,
    isLoggedIn: state.user.isLoggedIn,
    profile: state.user.profile,
    currentLanguage:state.locale.currentLanguage
  }), shallowEqual)

  const [genderOptions, setGenderOptions] = useState([
    { id: 1, name: strings['select.man.gender'] },
    { id: 2, name: strings['select.woman.gender'] },
    { id: 3, name: strings['select.nonbinary.gender'] },
    { id: 0, name: strings['select.undefined.genderV2'] },
  ])

  const [ageOptions, setAgeOptions] = useState([
    { id: 101, name: '0-10'},
    { id: 102, name: '11-20'},
    { id: 103, name: '21-30'},
    { id: 104, name: '31-40'},
    { id: 105, name: '41-50'},
    { id: 106, name: '51-60'},
    { id: 107, name: '61-70'},
    { id: 108, name: '71-80'},
    { id: 109, name: '81-90'},
    { id: 110, name: '91-100'},
    { id: 0,  name: strings['select.undefined.genderV2'] },
  ])

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const setExperienceGender = useCallback((number) => dispatch(experienceActions.setGender(number)), [dispatch])
  const setExperienceAge = useCallback((number) => dispatch(experienceActions.setAge(number)), [dispatch])
  const fetchContent = useCallback((slug) => dispatch(contentActions.fetchStrapiContent(slug,'gender-age-pages')), [dispatch])
  const moveForward = useCallback(() => dispatch(routerActions.moveForward()), [dispatch])
  
  /*------------------------------
  Fetch Content
  ------------------------------*/
  useEffect(() => { 
    if (Object.keys(page).length === 0) fetchContent('gender-age-page')
  }, [])
  useEffect(() => {
    if (Object.keys(page).length > 0 )
      fetchContent('gender-age-page');
  }, [currentLanguage])
  //
  let aboutBackgroundImage =page?.fullBackground;
  const resizeBg = () => {
    if (window.innerWidth < 768) {
      aboutBackgroundImage = page?.mobileBackground;
    }
  }
  resizeBg();

  window.addEventListener('resize', () => {
    resizeBg();
  });

  /*------------------------------
  Set age & gender coming through user profile
  ------------------------------*/
  useEffect(() => {
    if(profile.gender != null){ 
      setFieldValueGender(profile.gender)
      setFieldLabelGender( genderOptions.filter(option => option.id === profile.gender).map(option => option.name) )
    } 
    if(profile.age != null){
      setFieldValueAge(profile.age)
      setFieldLabelAge( ageOptions.filter(option => option.id === profile.age).map(option => option.name) )
    }
  }, [profile])

  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0) setDataFetched(true)
  }, [page])

  /*------------------------------
  Preload
  ------------------------------*/
  const [load] = usePagePrecache({
    init: isDataFetched,
    sources: [],
    callback: () => setPageAnimationReady(true),
  })

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && <Meta />
  }

  /*------------------------------
  Handle Click Next
  ------------------------------*/
  const handleClickNext = () => {
    setExperienceGender(fieldValueGender)
    setExperienceAge(fieldValueAge)
    moveForward()
  }

  /*------------------------------
  Render Choose Sport
  ------------------------------*/
  const renderChooseSport = () => {
    return (
      <div className={classes.page} style={{ background: `url(${aboutBackgroundImage}) center center no-repeat` }}>
        <div className={classes.hero}>
          <div className={classes.wrapper}>
            <div className={classes.box}>
              <RevealText
                tag="h1"
                type="lines"
                value={page.heading}
                visible={pageAnimationReady}
                lineHeight={1.4}
                delay={0}
                className={classes.title}
              />
              <RevealText
                tag="div"
                type="lines"
                value={convertRichText(page.bodyCopy)}
                visible={pageAnimationReady}
                lineHeight={1.333}
                delay={1}
                className={classes.hint}
              />
              <RevealFadeIn
                delay={0.5}
                inview={pageAnimationReady}
                className={classes.genderSelect}
              >
                <SelectField
                  label={page.genderDropDown}     // to test updated content, change with strings['select.placeholder.gender'], and change contentful value 
                  name="chooseGender"
                  value={fieldLabelGender}
                  options={genderOptions}
                  isDisabled={ profile.gender == null ? false : true}
                  setFieldValue={(label, value) => {
                    setFieldLabelGender(label)
                    setFieldValueGender(value)
                  }}
                />
              </RevealFadeIn>

              <RevealFadeIn
                delay={1.5}
                inview={pageAnimationReady}
                className={classes.ageSelect}
              >
                <SelectField
                  label={page.ageDropDown}        // to test updated content, change with strings['select.placeholder.age'], and change contentful value 
                  name="chooseAge"
                  value={fieldLabelAge}
                  options={ageOptions}
                  isDisabled={ profile.age == null ? false : true}
                  setFieldValue={(label, value) => {
                    setFieldLabelAge(label)
                    setFieldValueAge(value)
                  } }
                />
              </RevealFadeIn>
              
              <div className={classes.buttons}>
                <RevealFadeIn
                  delay={1.8}
                  inview={pageAnimationReady}
                >
                  <button
                    onClick={handleClickNext}
                    className={classNames({
                      [classes.btn]: true,
                      disabled: fieldValueGender===undefined || fieldValueAge===undefined,
                    })}
                  >
                    <span>{page.button1}</span>
                  </button>
                </RevealFadeIn>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return (
      load && (
        <>
          {renderChooseSport()}
        </>
      )
    )
  }

  return (
    <>
      <LocomotiveScroll
        init={load}
        className={`page pageChooseAgeGender ${classes.root}`}
        ref={$root}
      >
        {renderHelmet()}
        {renderContent()}
      </LocomotiveScroll>
    </>
  )
}

export default ChooseAgeGender
