import { useRef, forwardRef, useState, useCallback, useEffect } from 'react'
import injectSheet from 'react-jss'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import classNames from 'classnames'
import SelectField from '@/components/SelectField'
import RevealText from '@/components/RevealText'
import RevealFadeIn from '@/components/RevealFadeIn'
import * as optionsActions from '@/actions/options'
import * as contentActions from '@/actions/content'

import { convertRichText } from '@/utils/strings'
import style from './style'

const LanguageSwitcher = forwardRef(({
  classes,
  className,
  onClickNext,
  visible,
}, ref) => {
  const [fieldValue, setFieldValue] = useState()
  const langCode = useRef()
  const [disclaimer, setDisclaimer] = useState()

  /*------------------------------
  Redux Store
  ------------------------------*/
  const { localeStrings, languages, schema,content,page } = useSelector((state) => ({
    languages: state.locale.languages,
    page: state.content['language-selection'] || {},
    schema: state.locale.schema,
    localeStrings: state.locale.strings,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchSingleString = useCallback((key) => dispatch(optionsActions.fetchSingleString(key)), [dispatch])
  const fetchPage = useCallback((slug,type) => dispatch(contentActions.fetchStrapiContent(slug, type)), [dispatch])

  /*------------------------------
  Fetch disclaimer string
  ------------------------------*/
  useEffect(() => {
    fetchSingleString('general.lang.cookie.disclaimer').then((res) => {
      setDisclaimer(res)
    })
  }, [])

  useEffect(() => {
    if (Object.keys(page).length === 0) fetchPage('language-selection','languageSelection')
  }, [])

  const handleClickNext = () => {
    onClickNext(langCode.current)
  }

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [className]: className,
      })}
      ref={ref}
    >
     {page.heading && <RevealText
        tag="h1"
        type="lines"
        value={page.heading}
        visible={visible}
        lineHeight={1.4}
        delay={0.2}
        className={classes.title}
      />}
      <RevealFadeIn
        delay={0.5}
        inview={visible}
      >
        <SelectField
          label={page.dropDown}
          name="chooseLanguage"
          value={fieldValue}
          options={languages.map((lang) => ({ id: lang.code, name: schema[lang.code].title }))}
          setFieldValue={(label, value) => {
            langCode.current = value
            setFieldValue(label)
          }}
        />
      </RevealFadeIn>
      <div className={classes.buttons}>
        <RevealFadeIn
          delay={0.5}
          inview={visible}
        >
          <button
            onClick={handleClickNext}
            className={classNames({
              [classes.btn]: true,
              disabled: !fieldValue,
            })}
          >
            <span>{page.button}</span>
          </button>
        </RevealFadeIn>
      </div>
      <RevealFadeIn
        delay={1}
        inview={visible}
        className={classes.disclaimer}
      >
        <div dangerouslySetInnerHTML={{ __html: convertRichText(disclaimer) }} />
      </RevealFadeIn>
    </div>
  )
})

LanguageSwitcher.defaultProps = {
  withLabel: false,
}

export default injectSheet(style)(LanguageSwitcher)
