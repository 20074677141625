import { createUseStyles } from "react-jss";
import classNames from "classnames";
import style from "./style";
import gsap from "gsap";
import { Tween, Linear } from "gsap/gsap-core";
import { useCallback, useEffect,useMemo } from "react";
import CountUp from "react-countup";

const useStyles = createUseStyles(style);

const LiteEmotionProgress = ({ color, score, size }) => {
  const offset = useMemo(
    () => (Math.PI * 50 * 2 * (1 - Number(score) / 10)).toString() + "px",
    [score]
  );
  const classes = useStyles({ color, score });
  const animation = () => {};
  useEffect(() => {
    animation();
  }, []);
  return (
    <div
      className={classNames({ [classes.parent]: true, [classes.div]: true })}
      style={{ "--size":  size}}
    
    >
      <div
        className={classNames({ [classes.score]: true })}
        style={{ "--color": color }}
      >
        <h1>
          <CountUp start={0} end={score} duration={1} />
        </h1>
      </div>
      <div
        className={classNames({
          [classes.dividend]: true,
          ["farLeft"]: score == 10,
        })}
        style={{ "--color": color }}
      >
        <h1>/10</h1>
      </div>
      <div className={classes.circleWrapper}>
        <svg
          className={classNames({ [classes.svg]: true })}
          viewBox="0 0 100 100"
          width="100%"
          height="100%"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid meet"
          style={{ "--svgOffset": offset }}
        >
          <circle
            cx="0"
            cy="0"
            r="50px"
            className={classes.countdownBackground}
          />
          <circle
            cx="0"
            cy="0"
            r="50px"
            className={classNames({
              [classes.countdownProgress]: true,
              [classes.jsCountdownProgress]: true,
            })}
          />
        </svg>
      </div>
    </div>
  );
};

export default LiteEmotionProgress;
