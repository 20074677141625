import { useRef, useState, useEffect, useContext, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Context as GeneralContext } from '@/context'
import Meta from '@/components/Meta'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import RevealText from '@/components/RevealText'
import RevealFadeIn from '@/components/RevealFadeIn'
import SelectField from '@/components/SelectField'
import usePagePrecache from '@/hooks/usePagePrecache'
import * as experienceActions from '@/actions/experience'
import * as layerActions from '@/actions/layer'
import * as contentActions from '@/actions/content'
import * as routerActions from '@/actions/fakerouter'
import useStore from '@/base/zustand'
import { convertRichText } from '@/utils/strings'
import style from './style'

const useStyles = createUseStyles(style)

const ChooseSport = () => {
  const { setSelectedSport } = useStore()
  const { setPageAnimationReady, pageAnimationReady, headerHeight } = useContext(GeneralContext)
  const classes = useStyles({ headerHeight })
  const [isDataFetched, setDataFetched] = useState(false)
  const [fieldValue, setFieldValue] = useState()
  const $root = useRef()

  /*------------------------------
  Redux Store
  ------------------------------*/
  const { fakeLocation, page, sports, strings, isLoggedIn, experience, sport_id,currentLanguage} = useSelector((state) => ({
    page: state.content['choose-sport'] || {},
    sports: state.sports.items,
    fakeLocation: state.fakerouter.location,
    strings: state.options.strings,
    isLoggedIn: state.user.isLoggedIn,
    experience: state.experience,
    sport_id: state.experience.sport_id,
    currentLanguage:state.locale.language
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const setSport = useCallback((id) => dispatch(experienceActions.setSport(id)), [dispatch])
  const openModal = useCallback(() => dispatch(layerActions.openLayer({ id: 'email' })), [dispatch])
  const fetchContent = useCallback((slug) => dispatch(contentActions.fetchStrapiContent(slug)), [dispatch])
  const moveNext = useCallback(() => dispatch(routerActions.changeLocation('timer')), [dispatch])
  const savePartialExperience = useCallback((data) => dispatch(experienceActions.savePartialExperience(data)), [dispatch])

  /*------------------------------
  Fetch Content
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length === 0) fetchContent(fakeLocation)
  }, [])
  useEffect(() => {
    if (Object.keys(page).length > 0 )
      fetchContent(fakeLocation);
  }, [currentLanguage])
  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0 && sports.length > 0) setDataFetched(true)
  }, [page, sports])

  //
  let chooseBackgroundImage = page?.fullBackground;
  const resizeBg = () => {
    if (window.innerWidth < 768) {
      chooseBackgroundImage = page?.mobileBackground;
    }
  }
  resizeBg();


  window.addEventListener('resize', () => {
    resizeBg();
  });

  /*------------------------------
  Preload
  ------------------------------*/
  const [load] = usePagePrecache({
    init: isDataFetched,
    sources: [],
    callback: () => setPageAnimationReady(true),
  })

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && <Meta />
  }

  /*------------------------------
  Handle Click Next
  ------------------------------*/
  const handleClickNext = () => {
    const { id } = sports.find((s) => s.name === fieldValue)
    setSport(id)
    setSelectedSport(id)
    if(!isLoggedIn){
      openModal();
    } else {
      savePartialExperience()
      moveNext()
    }
  }

  useEffect(() => {
    if(sport_id>0){
      localStorage.setItem('expState', JSON.stringify({ experience: experience}));   // save current exp of redux state 
    }
  }, [sport_id])

  /*------------------------------
  Render Choose Sport
  ------------------------------*/
  const renderChooseSport = () => {
    return (
      <div className={classes.page} style={{ background: `url(${chooseBackgroundImage}) center center no-repeat` }}>
        <div className={classes.hero}>
          <div className={classes.wrapper}>
            <div className={classes.box}>
              <RevealText
                tag="h1"
                type="lines"
                value={convertRichText(page.title)}
                visible={pageAnimationReady}
                lineHeight={1.4}
                delay={0}
                className={classes.title}
              />
              <RevealText
                tag="div"
                type="lines"
                value={convertRichText(page.text)}
                visible={pageAnimationReady}
                lineHeight={1.333}
                delay={1.3}
                className={classes.text}
              />
              <RevealFadeIn
                delay={0.5}
                inview={pageAnimationReady}
              >
                <SelectField
                  label={strings['select.placeholder.sport']}
                  name="chooseExercise"
                  value={fieldValue}
                  options={sports.map(({ id, name }) => ({ id, name }))}
                  withIcon
                  setFieldValue={(label) => setFieldValue(label)}
                  sports={sports}
                />
              </RevealFadeIn>
              <div className={classes.buttons}>
                <RevealFadeIn
                  delay={2}
                  inview={pageAnimationReady}
                >
                  <button
                    onClick={handleClickNext}
                    className={classNames({
                      [classes.btn]: true,
                      disabled: !fieldValue,
                    })}
                  >
                    <span>{page.nextLabel || strings['general.continue']}</span>
                  </button>
                </RevealFadeIn>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return (
      load && (
        <>
          {renderChooseSport()}
        </>
      )
    )
  }

  return (
    <>
      <LocomotiveScroll
        init={load}
        className={`page pageChooseSport ${classes.root}`}
        ref={$root}
      >
        {renderHelmet()}
        {renderContent()}
      </LocomotiveScroll>
    </>
  )
}

export default ChooseSport
