import axios from "axios";
import { axiosInstance } from "@/base/axios";
import { REQUEST_EMOTIONS, RECEIVE_EMOTIONS } from "@/actions/types";
import { API, CONTENTFUL_SPACE, CONTENTFUL_TOKEN } from "@/constants";

const requestEmotions = () => ({ type: REQUEST_EMOTIONS });

const receiveEmotions = (data) => ({
  type: RECEIVE_EMOTIONS,
  payload: data,
});


const fetchStrapiEmotions = () => async (dispatch, getState) => {
  dispatch(requestEmotions());
  try {
    const localeCode =
      getState().locale.currentLanguage ?? document.documentElement.lang;
    const response = await axiosInstance.get(`emotions?locale=${localeCode}`);
    const emotions = response.data.data
      .map((it) => {
        return {
          ...it.attributes,
          id: it.attributes.identifier,
        };
      })
      .sort((a, b) => a.order - b.order);
    dispatch(receiveEmotions(emotions));
  } catch (error) {
    console.log(error);
  }
};
export {  fetchStrapiEmotions };
