import { createUseStyles } from "react-jss";
import classNames from "classnames";
import gsap from "gsap";
import { Tween, Linear } from "gsap/gsap-core";
import { useEffect, useState } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import CountUp from "react-countup";
import LiteProgress from "../LiteProgress";
import LiteEmotionProgress from "../LiteEmotionProgress";



const LiteProgresContainer = ({ activeEmotion, emotionData, score }) => {
  const { width, height } = useWindowSize();
  const [heightFactor, setHeightFactor] = useState(0.8);
  const [compheight, setHeight] = useState((height - 120) * heightFactor);
  useEffect(() => {
    if (width <= 900) {
      setHeightFactor(0.34);
    } else {
      setHeightFactor(0.8);
    }
  }, [width]);

  useEffect(() => {
    if(width >=992){
      let sWidth = ((width - 200) * 0.45);
      let sHeight= ((height - 120) * heightFactor);
      let size = sWidth > sHeight ? sHeight : sWidth
      setHeight(size)
    }else{
      let sWidth = ((width) * 0.45);
      let sHeight= ((height) * heightFactor);
      let size = sHeight
      setHeight(size)
    }
    
  }, [width, height, heightFactor]);

  return !!activeEmotion ? (
    <LiteEmotionProgress
      key={`${activeEmotion.color}${height}${score}`}
      color={activeEmotion.color}
      score={emotionData[activeEmotion.key].post}
      size={`${compheight}px`}
    />
  ) : (
    <LiteProgress
      score={score}
      color="#485BC7"
      size={`${compheight}px`}
    />
  );
};

export default LiteProgresContainer;
