import { useRef, useState, useEffect, useContext, useCallback } from "react";
import { createUseStyles } from "react-jss";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Canvas } from "react-three-fiber";
import { Context as GeneralContext } from "@/context";
import LocomotiveScroll from "@/components/LocomotiveScroll";
import FaceScanModal from "@/components/FaceScanModal";
import usePagePrecache from "@/hooks/usePagePrecache";
import useStore from "@/base/zustand";
import * as routerActions from "@/actions/fakerouter";
import * as experienceActions from "@/actions/experience";
import * as questionsActions from "@/actions/questions";
import * as layerActions from "@/actions/layer";
import FaceEmotions from "./FaceEmotions";
import FaceDetect from "./FaceDetect";
import style from "./style";
import React from 'react';
const useStyles = createUseStyles(style);

const FaceScanCamera = (props) => {
  const $faceFilter = useRef();
  const { setPageAnimationReady, pageAnimationReady, headerHeight } =
    useContext(GeneralContext);
  const {
    setIsFaceScanFinish,
    setCameraEnabled,
    isCameraEnabled,
    setFaceDetected,
    setStartGettingEmotions,
    setStartFaceScan,
    setFaceInCamera,
    setTimerStarted,
    isModalClosed,
    setModalClosed,
  } = useStore();
  const classes = useStyles({ headerHeight });
  const [isDataFetched, setDataFetched] = useState(false);
  const $root = useRef();

  /*------------------------------
  Redux Store
  ------------------------------*/
  const { step } = useSelector(
    (state) => ({
      step: state.experience.step,
    }),
    shallowEqual
  );

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch();
  const changeLocation = useCallback(
    (slug) => dispatch(routerActions.changeLocation(slug)),
    [dispatch]
  );
  const setCameraDenied = useCallback(
    () => dispatch(experienceActions.setCameraDenied()),
    [dispatch]
  );
  const fetchQuestions = useCallback(
    (step) => dispatch(questionsActions.fetchStrapiQuestions(step)),
    [dispatch]
  );
  const openModal = useCallback(
    (modal) => dispatch(layerActions.openLayer({ id: modal })),
    [dispatch]
  );

  /*------------------------------
  Fetch Questions
  ------------------------------*/
  useEffect(() => {
    fetchQuestions(step);
  }, [step]);

  /*------------------------------
  Skip Camera Method
  ------------------------------*/
  const skipCamera = () => {
    setCameraDenied();
    changeLocation("facial-scan-denied");
  };

  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;
    if (isModalClosed || !(props.first === true)) {
      if (navigator.getUserMedia) {
        navigator.getUserMedia(
          { video: true },
          (stream) => {
            const result = stream.getVideoTracks().some((track) => {
              return track.enabled && track.readyState === "live";
            });
            if (result) {
              setDataFetched(true);
            }
          },
          () => skipCamera()
        );
      } else {
        skipCamera();
      }
    }
    return () => {
      setFaceDetected(false);
      setIsFaceScanFinish(false);
      setCameraEnabled("unset");
    };
  }, [isModalClosed]);

  /*------------------------------
  Start Getting Emotions
  ------------------------------*/
  useEffect(() => {
    if (isCameraEnabled === "enabled") setStartGettingEmotions(true);
  }, [isCameraEnabled]);

  /*------------------------------
  Preload
  ------------------------------*/
  const [load] = usePagePrecache({
    init: isDataFetched,
    sources: [],
    callback: () => setPageAnimationReady(true),
  });

  /*------------------------------
  Reset Face Scan Init
  ------------------------------*/
  useEffect(() => {
    if (step === 2) {
      setFaceDetected(false);
      setIsFaceScanFinish(false);
      setCameraEnabled("unset");
      setStartFaceScan(false);
      setFaceInCamera(false);
      setTimerStarted(false);
      setModalClosed(false);
    }
  }, [step]);
  useEffect(() => {
    if (props.first) {
      openModal("face-scan-modal");
    }
  }, []);
  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return (
      load && (
        <>
          <div className={classes.page}>
            <Canvas
              className={classes.canvas}
              colorManagement={false}
              pixelRatio={[1, 2]}
              shadowMap={true}
              gl={{ antialias: false }}
              camera={{ fov: 50, position: [0, 0, 10], near: 0.1, far: 100 }}
              onCreated={({ camera }) => camera.lookAt(0, 0, 0)}
            >
              <FaceDetect
                isFirstFaceStep={props.first}
                faceFilterRef={$faceFilter}
              />
            </Canvas>
            <canvas
              ref={$faceFilter}
              className={classes.canvasFace}
              width={window.innerWidth}
              height={window.innerHeight}
            />
            <FaceEmotions
              first={props.first}
              faceFilterRef={$faceFilter}
              ready={pageAnimationReady}
            />
          </div>
        </>
      )
    );
  };

  return (
    <LocomotiveScroll
      init={load}
      className={`page pageFaceScanCamera ${classes.root}`}
      ref={$root}
    >
      {(isModalClosed || !(props.first === true)) && renderContent()}
    </LocomotiveScroll>
  );
};

export default React.memo(FaceScanCamera);
