import { OPEN_LAYER, CLOSE_LAYER, CLOSE_ALL_LAYER } from "@/actions/types";

const getLayerModel = ({ id }) => ({
  id,
  isOpen: false,
});

const initialState = {
  layers: [
    getLayerModel({ id: "liteMenu" }),
    getLayerModel({ id: "menu" }),
    getLayerModel({ id: "email" }),
    getLayerModel({ id: "filter" }),
    getLayerModel({ id: "results" }),
    getLayerModel({ id: "legend" }),
    getLayerModel({ id: "geolocation" }),
    getLayerModel({ id: "restart" }),
    getLayerModel({ id: "generic-restart" }),
    getLayerModel({ id: "share" }),
    getLayerModel({ id: "congrats" }),
    getLayerModel({ id: "intro-modal" }),
    getLayerModel({ id: "questions-after-email-modal" }),
    getLayerModel({ id: "choose-programme-modal" }),
    getLayerModel({ id: "prize-login" }),
    getLayerModel({ id: "face-scan-modal" }),
  ],
  layerIsOpen: false,
};

export default (state = initialState, action) => {
  if (typeof action === "undefined") return state;
  switch (action.type) {
    case OPEN_LAYER: {
      return {
        ...state,
        layerIsOpen: true,
        layers: state.layers.map((l) =>
          l.id === action.payload.id
            ? { ...l, ...action.payload, isOpen: true }
            : l
        ),
      };
    }

    case CLOSE_LAYER: {
      return {
        ...state,
        layerIsOpen: false,
        layers: state.layers.map((l) =>
          l.id === action.payload.id
            ? { ...l, ...action.payload, isOpen: false }
            : l
        ),
      };
    }

    case CLOSE_ALL_LAYER: {
      return {
        ...state,
        layerIsOpen: false,
        layers: state.layers.map((l) => ({
          ...l,
          ...action.payload,
          isOpen: false,
        })),
      };
    }
    default:
      return state;
  }
};
