import axios from "axios";
import { REQUEST_NAV, RECEIVE_NAV, REQUEST_NAV_ITEMS } from "@/actions/types";
import { API, CONTENTFUL_TOKEN, CONTENTFUL_SPACE } from "@/constants";
import { axiosInstance } from "@/base/axios";
const requestNavItems = () => ({ type: REQUEST_NAV_ITEMS });



const requestNav = () => ({ type: REQUEST_NAV });

const receiveNav = (data) => ({
  type: RECEIVE_NAV,
  payload: data,
});


const fetchStrapiMenu = () => async (dispatch, getState) => {
  dispatch(requestNav());
  const localeCode =
    getState().locale.currentLanguage ?? document.documentElement.lang;
  const response = await axiosInstance.get(
    `menus?locale=${localeCode}&populate=*`
  );
  const navs = response.data.data
    .map((it) => it.attributes)
    .reduce(
      (obj, i) =>{
        return   Object.assign(obj, {
          [i.slug]: i.navigation_items.data.map((nav) => nav.attributes),
        })},
      {}
    );

  dispatch(receiveNav(navs));
};
export {  fetchStrapiMenu };
