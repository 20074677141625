import { useRef, useState, useEffect, useContext, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Context as GeneralContext } from '@/context'
import Meta from '@/components/Meta'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import RevealText from '@/components/RevealText'
import RevealFadeIn from '@/components/RevealFadeIn'
import usePagePrecache from '@/hooks/usePagePrecache'
import * as routerActions from '@/actions/fakerouter'
import * as contentActions from '@/actions/content'
import { convertRichText } from '@/utils/strings'
import style from './style'

const useStyles = createUseStyles(style)

const CountdownFaceScan = () => {
  const { setPageAnimationReady, pageAnimationReady, headerHeight } = useContext(GeneralContext)
  const classes = useStyles({ headerHeight })
  const [isDataFetched, setDataFetched] = useState(false)
  const $root = useRef()

  /*------------------------------
  Redux Store
  ------------------------------*/
  const { page, isCameraDenied, fakeLocation, strings,currentLanguage } = useSelector((state) => ({
    page: state.content['countdown-face-scan'] || {},
    fakeLocation: state.fakerouter.location,
    isCameraDenied: state.experience.isCameraDenied,
    strings: state.options.strings,
    currentLanguage:state.locale.currentLanguage
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const moveForward = useCallback(() => dispatch(routerActions.moveForward()), [dispatch])
  const fetchContent = useCallback((slug) => dispatch(contentActions.fetchStrapiContent(slug)), [dispatch])

  /*------------------------------
  Skip if camera is disabled & Fetch Content
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length === 0) fetchContent(fakeLocation)
    if (isCameraDenied) moveForward()
  }, [])
  useEffect(() => {
    if (Object.keys(page).length > 0 )
      fetchContent(fakeLocation);
  }, [currentLanguage])
  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0) setDataFetched(true)
  }, [page])

  /*------------------------------
  Preload
  ------------------------------*/
  const [load] = usePagePrecache({
    init: isDataFetched,
    sources: [],
    callback: () => setPageAnimationReady(true),
  })

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && <Meta />
  }

  /*------------------------------
  Handle Click Next
  ------------------------------*/
  const handleClickNext = () => {
    moveForward()
  }

  /*------------------------------
  Render Countdown
  ------------------------------*/
  const renderCountdown = () => {
    return (
      <div className={classes.page} style={{background: `url(${page?.fullBackground}) center center no-repeat`}}>
        <div className={classes.hero}>
          <div className={classes.wrapper}>
            <RevealText
              tag="h1"
              type="lines"
              value={convertRichText(page.title)}
              visible={pageAnimationReady}
              lineHeight={1.2}
              delay={0}
              className={classes.title}
            />
            <RevealText
              tag="div"
              type="lines"
              value={convertRichText(page.text)}
              visible={pageAnimationReady}
              lineHeight={1.333}
              delay={1.3}
              className={classes.text}
            />
            <RevealFadeIn
              delay={1.5}
              visible={pageAnimationReady}
            >
              <button
                onClick={handleClickNext}
                className={classes.btn}
              >
                <span>{page.nextLabel || strings['general.continue']}</span>
              </button>
            </RevealFadeIn>
            <RevealText
              tag="div"
              type="lines"
              value={convertRichText(page.disclaimer)}
              visible={pageAnimationReady}
              lineHeight={1.333}
              delay={2.3}
              className={classes.hint}
            />
          </div>
        </div>
      </div>
    )
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return (
      load && (
        <>
          {renderCountdown()}
        </>
      )
    )
  }

  return (
    <>
      <LocomotiveScroll
        init={load}
        className={`page pageCountdownFaceScan ${classes.root}`}
        ref={$root}
      >
        {renderHelmet()}
        {renderContent()}
      </LocomotiveScroll>
    </>
  )
}

export default CountdownFaceScan
