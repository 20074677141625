import axios from "axios";
import {
  REQUEST_EVENTS,
  RECEIVE_LITE_EVENTS,
  REQUEST_EVENT_IMAGE,
  RECEIVE_EVENT_IMAGE,
  REQUEST_TAGS,
  RECEIVE_TAGS,
  SET_COUNTRY_CODE,
  SET_CURRENT_TAG,
} from "@/actions/types";
import { API, CONTENTFUL_SPACE, CONTENTFUL_TOKEN } from "@/constants";

const requestEvents = () => ({ type: REQUEST_EVENTS });
const requestTags = () => ({ type: REQUEST_TAGS });

const receiveLiteEvents = (data) => ({
  type: RECEIVE_LITE_EVENTS,
  payload: data,
});

const receiveLiteTags = (data) => ({
  type: RECEIVE_TAGS,
  payload: data,
});

const fetchLiteEvents = (today, tag) => async (dispatch, getState) => {
  const countryTag = `country_${(
    localStorage.getItem("country_code") || "undefined"
  ).toLowerCase()}`;
  tag += `,${countryTag}`;
  dispatch(requestEvents());
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      content_type: "liteEvent",
      locale: getState().locale.currentLanguage,
      ...(today && {
        "fields.startDate[lte]": today, // '2021-06-01'
        "fields.endDate[gte]": today, // '2021-06-01'
      }),
    },
  };
  const response = await axios.get(
    `${API.CMS}/${CONTENTFUL_SPACE}/entries`,
    params
  );
  const filteredResponse = response.data.items.filter(
    (it) => it.sys.id !== "1QNJUjr8Sr9ZWMJ8lOaWAF"
  );
  dispatch(receiveLiteEvents(filteredResponse));
};

const fetchLiteTags = () => async (dispatch) => {
  dispatch(requestTags());
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
    },
  };
  const response = await axios.get(
    `${API.CMS}/${CONTENTFUL_SPACE}/tags`,
    params
  );
  dispatch(receiveTags(response.data.items));
};

const requestImage = () => ({ type: REQUEST_EVENT_IMAGE });

const receiveLiteEventImage = (data, key) => ({
  type: RECEIVE_EVENT_IMAGE,
  payload: data,
  key,
});

const fetchLiteEventImage = (id) => async (dispatch, getState) => {
  dispatch(requestImage());
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      locale: getState().locale.currentLanguage,
    },
  };
  const response = await axios.get(
    `${API.CMS}/${CONTENTFUL_SPACE}/assets/${id}`,
    params
  );
  dispatch(receiveEventImage(response.data.fields.file, "eventImage"));
};

const fetchLiteSelectedEventImage = (id) => async (dispatch, getState) => {
  dispatch(requestImage());
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      locale: getState().locale.currentLanguage,
    },
  };
  const response = await axios.get(
    `${API.CMS}/${CONTENTFUL_SPACE}/assets/${id}`,
    params
  );
  const { file } = response.data.fields;
  dispatch(receiveEventImage(file, "selectedEventImage"));
  return file;
};

const fetchLiteEventLogo = (id) => async (dispatch, getState) => {
  dispatch(requestImage());
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      locale: getState().locale.currentLanguage,
    },
  };
  const response = await axios.get(
    `${API.CMS}/${CONTENTFUL_SPACE}/assets/${id}`,
    params
  );
  dispatch(receiveEventImage(response.data.fields.file, "eventLogo"));
  const { file } = response.data.fields;
  return file;
};

const fetchLiteExistentCountryCode = () => {
  const currentCountryCode = localStorage.getItem("country_code") || null;
  return {
    type: SET_COUNTRY_CODE,
    payload: currentCountryCode,
  };
};

const setLiteCountryCode = (code) => {
  localStorage.setItem("country_code", code);
  return {
    type: SET_COUNTRY_CODE,
    payload: code,
  };
};

const setLiteCurrentTag = (tag) => {
  return {
    type: SET_CURRENT_TAG,
    payload: tag,
  };
};

export {
  fetchLiteEvents,
  fetchLiteTags,
  fetchLiteEventImage,
  fetchLiteSelectedEventImage,
  fetchLiteEventLogo,
  fetchLiteExistentCountryCode,
  setLiteCountryCode,
  setLiteCurrentTag,
};
