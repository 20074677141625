import { useEffect, useState, useCallback, useLayoutEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import ResultsEvent from '@/pages/WorldUpliftResults/Experience/EventResult'
import * as localeActions from '@/actions/locale'
import * as experienceActions from '@/actions/experience'
import { API_BASE_URL, API } from '@/constants'

const WorldUpliftResults = () => {
  const { emotions } = useSelector((state) => ({
    emotions: state.emotions.items,
  }), shallowEqual)

  const dispatch = useDispatch()
  const setLanguage = useCallback((code) => dispatch(localeActions.setLanguage(code)), [dispatch])
  const setEmotion = useCallback((value, key, s) => dispatch(experienceActions.setSingleEmotion(value, key, s)), [dispatch])
  const [render, setRender] = useState(false)
  const [uplift, setUplift] = useState(0)
  const [eventName, setEventName] = useState('')
  const [emotionPercentages, setEmotionPercentages] = useState([])
  setLanguage('en-US')
  useEffect(() => {
    let percentageArray=[]
    const responses = emotions.map(async (emotion) => {
      return axios.get(`${API.MAP_MIND}`, { params: { level: 'world', emotion: emotion.key } }).then((response) => {
        return { response, emotion }
      })
    })
    Promise.all(responses).then((responsesCompleted) => {
      responsesCompleted.forEach((response) => {
        percentageArray.push({ emotion: response.emotion.key, variation: response.response.data.data.variation })
        setEmotion(response.response.data.data.score, response.emotion.key, 'post')
      })
      setUplift(10)
      setEmotionPercentages(percentageArray)
      setEventName('Global Uplift')
      setTimeout(() => {
        setRender(true)
      }, 2000)
    })
  }, [])

  if (render) {
    return (
      <ResultsEvent key="event-uplift-results" emotionPercentages={emotionPercentages} totalUplift={uplift} eventName={eventName} />
    )
  }
  return <></>
}

export default WorldUpliftResults
