import {
  DENIED_CAMERA,
  RECEIVE_EXPERIENCE,
  RECEIVE_GEOCODING,
  RECEIVE_LITE_SAVE_EXPERIENCE,
  RECEIVE_EVENT_STATE_OF_MIND,
  RESET_LITE_EXPERIENCE,
  SET_EXPERIENCE,
  SET_CURRENT_LITE_EVENT,
  SET_IMAGE_DOWNLOAD,
  SET_IMAGE_GIF_1,
  SET_IMAGE_GIF_2,
  SET_IMAGE_GIF_3,
  SET_IMAGE_GIF_4,
  SET_IMAGE_THUMB,
  SET_LITE_SINGLE_EMOTION,
  SET_SPORT,
  SET_UPLIFTER_PROGRAM_ID,
  SET_STARTING_EXPERIENCE,
  SET_ENDED_EXPERIENCE,
  SET_STEP,
  SET_TIME,
  SET_START_TIME,
  SET_END_TIME,
  SET_AGE,
  SET_GENDER,
  SET_EVENT_LOCATION,
  SET_EXERTION,
  SET_LOTTERY,
  SET_PRIZE_MAIL_BODY,
  SET_UUID,
  SET_IMAGE,
  SET_TOTAL_UPLIFT,
  SET_USER_IMAGE,
  RECEIVE_LITE_EXPERIENCE_AVGS,
  RECEIVE_LITE_EVENT_STATE_OF_MIND
} from "@/actions/types";

const initialState = {
  uuid: 0,
  event_location: {
    lat: 0,
    lon: 0,
    city: "",
  },
  // event_id : 'uly0a9rkV1T3QnL4ULetF',
  emotions: {
    focused: { post: 0, group: "cognitive" },
    alert: { post: 0, group: "cognitive" },
    calm: { post: 0, group: "cognitive" },
    energized: { post: 0, group: "cognitive" },
    positive: { post: 0, group: "emotional" },
    content: { post: 0, group: "emotional" },
    relaxed: { post: 0, group: "emotional" },
    composed: { post: 0, group: "emotional" },
    resilient: { post: 0, group: "emotional" },
    confident: { post: 0, group: "emotional" },
  },
  expStateOfMind: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_LITE_EXPERIENCE_AVGS:
      state = {
        ...state,
        cognitiveScore : action.cognitiveScore,
        emotionalScore : action.emotionalScore,
        cognitiveLength : action.cognitiveLength,
        emotionalLength : action.emotionalLength,
        expStateOfMind: action.expStateOfMind,
      };
      break;
    case SET_CURRENT_LITE_EVENT:
      state = {
        ...state,
        event_id: action.id,
        event_name: action.payload,
      };
      break;
    case SET_UUID:
      state = {
        ...state,
        uuid: action.payload,
      };
      break;
    case SET_LITE_SINGLE_EMOTION:
      state = {
        ...state,
        emotions: {
          ...state.emotions,
          [action.key]: {
            ...state.emotions[action.key],
            [action.status]: parseFloat(action.payload),
          },
        },
      };
      break;
    case SET_EVENT_LOCATION:
      state = {
        ...state,
        event_location: {
          ...action.payload,
        },
      };
      break;
    case RESET_LITE_EXPERIENCE:
      state = {
        ...initialState,
        ...(state.country && {
          // eslint-disable-line
          country: state.country,
        }),
        ...(state.city && {
          // eslint-disable-line
          city: state.city,
        }),
        ...(state.region && {
          // eslint-disable-line
          region: state.region,
        }),
      };
      break;
    case SET_EXPERIENCE:
      state = {
        ...action.payload.experience,
      };
      break;
    case RECEIVE_LITE_SAVE_EXPERIENCE:
      state = {
        ...state,
        uuid: action.payload.uuid,
      };
      break;
    case RECEIVE_LITE_EVENT_STATE_OF_MIND:
      state = {
        ...state,
        eventStateOfMind: action.payload,
      };
      break;
    case RECEIVE_GEOCODING:
      state = {
        ...state,
        country: action.payload.countryCode,
        city:
          action.payload.city !== ""
            ? action.payload.city
            : action.payload.locality,
        region: action.payload.principalSubdivision,
      };
      break;
    default:
      return { ...state };
  }
  return { ...state };
};
