import hexToRgba from 'hex-rgba'
import generateBreakPoints from 'react-jss-grid/utils/breakpoints'
import { SITE, THEME_NAME } from '@/constants'

const padding = [0, '80px']
const paddingMobile = [0, '20px']

export const sizes = {
  xs: 575,
  sm: 576,
  md: 720,
  lg: 992,
  xl: 1025,
  xxl: 1440,
}
const media = {
  xs: `@media (max-width: ${sizes.sm - 1}px)`,
  sm: `@media (min-width: ${sizes.sm}px)`,
  md: `@media (min-width: ${sizes.md}px)`,
  lg: `@media (min-width: ${sizes.lg}px)`,
  xl: `@media (min-width: ${sizes.xl}px)`,
  xxl: `@media (min-width: ${sizes.xxl}px)`,
}
const mediaInverse = {
  xs: `@media (max-width: ${sizes.sm - 1}px)`,
  sm: `@media (max-width: ${sizes.md - 1}px)`,
  md: `@media (max-width: ${sizes.lg - 1}px)`,
  lg: `@media (max-width: ${sizes.xl - 1}px)`,
  xl: `@media (max-width: ${sizes.xxl - 1}px)`,
  xxl: `@media (min-width: ${sizes.xxl}px)`,
  smLand: `@media (max-width: ${sizes.md - 1}px) and (orientation: landscape)`,
}

function supportPassive() {
  let passiveIfSupported = false
  try {
    window.addEventListener('test', null, Object.defineProperty({}, 'passive', { get: () => passiveIfSupported = { passive: true } }))
  } catch (err) {
    window.console.log('error:: passive event')
  }
  return passiveIfSupported
}

const colors = [
  '#232323', // #0 Dark
  '#ffffff', // #1 White
  '#000000', // #2 Black
  '#485bc7', // #3 Blue Indigo Asics
  '#001e62', // #4 Blue Navy Asics
  '#acacac', // #5 Grey
  '#e2e2e2', // #6 Medium Grey
  '#fbfff7', // #7 Light Grey
  '#a0dab3', // #8 Verde Asics
  '#adafd6', // #9 Lilla
  '#71d9d2', // #10 Cyan
  '#d73331', // #11 Rosso
  '#eac02d', // #12 Yellow Selection CSS
  '#6dbb73', // #13 Asics Sport Green
  '#febe66', // #14 Asics Events Orange
  '#ede04b', // #15 Asics Yellow
  '#f3d5f3', // #16 Grape Taffy
  '#fff68b'  // #17 Milan
]

const fonts = [
  '"Graphik-Regular", "Helvetica Neue", Helvetica, Arial, sans-serif', // [0]
  '"Graphik-Medium", "Helvetica Neue", Helvetica, Arial, sans-serif', // [1]
  '"Helvetica Neue", Helvetica, Arial, sans-serif', // [2]
]

const easings = {
  'power3.out': 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', // [0]
  'power3.inOut': 'cubic-bezier( 0.645,  0.045,  0.355,  1.000 )', // [1]
  'power4.out': 'cubic-bezier(0.76, 0, 0.24, 1)', // [2]
}

const general = {
  fontFamily: fonts[0],
  fontSize: 18,
  lineHeight: 1.333,
  color: colors[4],
  [mediaInverse.sm]: {
    fontSize: 16,
  },
}

const zindex = {
  language: 99,
  overlay: 101,
  mainNav: 102,
  header: 103,
  cursor: 102,
  modal: 103,
  cookiebar: 300,
  spinner: 499,
  loader: 500,
}

const headings = {
  fontFamily: fonts[1],
  fontWeight: 'normal',
  lineHeight: 1.2,
  marginTop: 0,
  padding: 0,
  color: colors[3],
  '& strong, & b': {
    fontWeight: 'bold',
  },
  '& p': {
    margin: 0,
  },
}

const typography = {
  general: {
    extend: general,
  },
  headings: {
    extend: headings,
  },
}

const titleSize = {
  fontSize: 60,
  [mediaInverse.sm]: {
    fontSize: 38,
  },
}

const button = {
  cursor: 'pointer',
  padding: [18, 45],
  color: colors[1],
  fontSize: 14,
  backgroundColor: colors[3],
  fontFamily: fonts[1],
  borderRadius: 28,
  display: 'inline-block',
  lineHeight: 1.333,
  overflow: 'hidden',
  transition: 'color .3s ease-in-out',
  minWidth: 200,
  textAlign: 'center',
  '& span': {
    display: 'inline-block',
    position: 'relative',
    zIndex: 1,
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: 1,
      left: 0,
      bottom: 0,
      width: '100%',
      height: 1,
      backgroundColor: 'currentColor',
      transition: `transform .6s ${easings['power3.out']}`,
      transform: 'scaleX(0)',
      transformOrigin: '100% 0',
    },
  },
  '@media (hover: hover)': {
    '&:hover': {
      '& span': {
        '&:before': {
          transform: 'scaleX(1)',
          transformOrigin: '0 0',
        },
      },
    },
  },
  '&.white': {
    background: colors[1],
    color: colors[3],
    boxShadow: `inset 0 0 0 1px ${colors[3]}`,
    '& span:before': {
      backgroundColor: colors[3],
    },
  },
  '&.noStroke': {
    boxShadow: 'none',
  },
  '&.disabled': {
    pointerEvents: 'none',
    opacity: 0.4,
  },
  [mediaInverse.sm]: {
    fontSize: 12,
    padding: [18, 35],
    '&:before': {
      left: 35,
      width: 'calc(100% - 70px)',
    },
  },
}

const link = {
  position: 'relative',
  zIndex: 1,
  display: 'inline-block',
  '&:before': {
    content: '""',
    position: 'absolute',
    zIndex: 1,
    left: 0,
    bottom: 1,
    width: '100%',
    height: 1,
    backgroundColor: 'var(--color)',
    transition: `transform .6s ${easings['power3.out']}`,
    transform: 'scaleX(0)',
    transformOrigin: '100% 0',
  },
  '@media (hover: hover)': {
    '&:hover': {
      '&:before': {
        transform: 'scaleX(1)',
        transformOrigin: '0 0',
      },
    },
  },
}
const linkInverse = {
  position: 'relative',
  zIndex: 1,
  display: 'inline-block',
  '&:before': {
    content: '""',
    position: 'absolute',
    zIndex: 1,
    left: 0,
    bottom: 1,
    width: '100%',
    height: 1,
    backgroundColor: 'var(--color)',
    transition: `transform .6s ${easings['power3.out']}`,
    transform: 'scaleX(1)',
    transformOrigin: '100% 0',
  },
  '@media (hover: hover)': {
    '&:hover': {
      '&:before': {
        transform: 'scaleX(0)',
        transformOrigin: '0 0',
      },
    },
  },
}

const wrapper = {
  padding: [0, '3.2vw'],
  width: '100%',
  maxWidth: 1920,
  margin: [0, 'auto'],
  // [mediaInverse.md]: {
  //   padding: [0, '4vw'],
  // },
}

const wrapperHeader = {
  margin: [0, '3.2vw'],
  // [mediaInverse.md]: {
  //   padding: [0, '4vw'],
  // },
}

const smallWrapper = {
  padding: [0, '9.5vw'],
  // [mediaInverse.md]: {
  //   padding: [0, '4vw'],
  // },
}

const flex = {
  verticalCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  horizontalCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
}

export const theme = {
  IMAGE_URL: `${SITE}wp-content/themes/${THEME_NAME}/images/`,
  AUDIO_URL: `${SITE}wp-content/themes/${THEME_NAME}/audio/`,
  media,
  mediaInverse,
  colors,
  zindex,
  wrapper,
  wrapperHeader,
  smallWrapper,
  easings,
  fonts,
  typography,
  titleSize,
  button,
  link,
  linkInverse,
  detect: {
    hasTouchWin: navigator.msMaxTouchPoints && navigator.msMaxTouchPoints > 1,
    hasPointer: !!window.navigator.msPointerEnabled,
    hasTouch: 'ontouchstart' in document,
    passive: supportPassive(),
    isIos: !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform),
    isSafari: navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && navigator.userAgent.indexOf('CriOS') === -1 && navigator.userAgent.indexOf('FxiOS') === -1,
    isIE11: !!window.MSInputMethodContext && !!document.documentMode,
    isNativeScroll: ('ontouchstart' in document && window.innerWidth <= 1085) || (!!window.MSInputMethodContext && !!document.documentMode) || window.innerWidth < 1024,
    // isNativeScroll: true,
    dom2webgl: !(!!window.MSInputMethodContext && !!document.documentMode) && !('ontouchstart' in document && window.innerWidth <= 1280), // Non IE11 e Non touch con larghezza > 1280
  },
  maxWidth: 1600,
  padding,
  paddingMobile,
  header: {
    top: 30,
    bottom: 20,
    topMobile: 20,
    logo: {
      width: 135,
      height: 45,
      fill: colors[3]
    },
    burger: {
      wrap: 45,
      width: 15,
      height: 10,
    },
  },
  animations: {
    ease: 'cubic-bezier(0.650, 0.075, 0.400, 0.935)',
    time: '0.6s',
  },
  getRgba: (col, opacity) => hexToRgba(col, opacity * 100),
  breakpoints: generateBreakPoints({
    values: sizes,
  }),
  flex,
}
