import { Suspense } from 'react'
// import { PostProcessing } from './postprocessing'
import PlaneShadow from './planeShadow'
import { Sphere } from './sphere'

/*------------------------------
Main
------------------------------*/
const Main = ({ settings ,emotionPercentages}) => {
  return (
    <>
      <Suspense fallback={null}>
        <PlaneShadow />
        <Sphere settings={settings} emotionPercentages={emotionPercentages} />
        {/* <PostProcessing /> */}
      </Suspense>
    </>
  )
}

export default Main
