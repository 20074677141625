import {
  LITE_FAKEROUTER_CHANGE_LOCATION,
  LITE_FAKEROUTER_MOVE_FORWARD,
  LITE_FAKEROUTER_MOVE_BACKWARD,
  RESET_EXPERIENCE,
} from "@/actions/types";

const initialState = {
  release: 1,
  location: "lite-begin", // da cambiare se si vuole partire da un'altra pagina
  locations: [
    { id: 0, name: "lite-begin" },
    { id: 1, name: "lite-choose-event" },
    { id: 2, name: "lite-questions" },
    { id: 3, name: "lite-result" },
    { id: 4, name: "lite-end" },
    { id: 5, name: "lite-event-state-of-mind" },
  ],
};

export default (state = initialState, action) => {
  const currentIndex = state.locations.find(
    (l) => l.name === state.location
  ).id;

  switch (action.type) {
    case LITE_FAKEROUTER_CHANGE_LOCATION:
      state = {
        ...state,
        location: action.payload,
        index: state.locations.find((l) => l.name === action.payload).id,
      };
      break;
    case LITE_FAKEROUTER_MOVE_FORWARD:
      state = {
        ...state,
        location: state.locations.find((l) => l.id === currentIndex + 1).name,
        index: currentIndex + 1,
      };
      break;
    case LITE_FAKEROUTER_MOVE_BACKWARD:
      state = {
        ...state,
        location: state.locations.find((l) => l.id === currentIndex - 1).name,
        index: currentIndex - 1,
      };
      break;
    case RESET_EXPERIENCE:
      state = {
        ...state,
        location: "lite-begin",
        index: 0,
        startTime: 0,
        endTime: 0,
      };
      break;
    default:
      return { ...state };
  }
  return { ...state };
};
