import axios from 'axios'
import {axiosInstance} from '@/base/axios'
import { REQUEST_QUESTIONS, RECEIVE_QUESTIONS } from '@/actions/types'
import { API, CONTENTFUL_SPACE, CONTENTFUL_TOKEN } from '@/constants'

const requestQuestions = () => ({ type: REQUEST_QUESTIONS })

const receiveQuestions = (data) => ({
  type: RECEIVE_QUESTIONS,
  payload: data,
})


const fetchStrapiQuestions = (step) => async (dispatch, getState) => {
  dispatch(requestQuestions())
  try {
    const key =  step===1?'pre-questions':'post-questions';
    const localeCode =
      getState().locale.currentLanguage ?? document.documentElement.lang;
    const response = await axiosInstance.get(`${key}?locale=${localeCode}&filters[slug][$eq]=${key}`);
    const questions = response.data.data[0]["attributes"];
    dispatch(receiveQuestions(questions));
  } catch (error) {
    console.log(error);
  }
}
export {
  fetchStrapiQuestions
}
