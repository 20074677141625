const style = (theme) => {
  const output = {
    backgroundTutorialQuestion: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity:0,
      zIndex: 20,
      '& .divBackground': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        opacity: 0.6,
        backgroundColor: '#485BC7',
        borderRadius: 1,
      },
      '& .divWhiteTutorialQuestion': {
        position: 'absolute',
        top:'30%',
        left:'35%',
        width:'30%',
        paddingTop:'3rem',
        paddingLeft:'5rem',
        paddingRight:'5rem',
        backgroundColor: 'white',
        borderRadius: '25px',
        textAlign:'center',
        zIndex: 21,
        '& .welcomeTitle':{
          color:'#485BC7',
          fontSize:'1.7rem',
          paddingBottom:'1rem'
        },
        '& .welcomeDescription':{
          color:'#485BC7',
          fontSize:'0.8rem',
        },
        '& .welcomeButtonsDiv':{
          color:'#485BC7',
          fontSize:'0.8rem',
          flex:1,
          flexDirection:'column',
          '& .welcomeButtonTakeTour':{
            width:'8rem',
            color:'white',
            border:'solid transparent 2px',
            backgroundColor:'#485BC7',
            paddingTop:'0.5rem',
            paddingLeft:'1rem',
            paddingRight:'1rem',
            borderRadius:'25px',
            cursor:'pointer',
            paddingBottom:'0.5rem',
            marginTop:'1rem',
            fontSize:'0.8rem',
          },
          '& .welcomeButtonTakeTour:hover':{
            color:'#485BC7',
            backgroundColor:'white',
            paddingTop:'0.5rem',
            paddingLeft:'1rem',
            paddingRight:'1rem',
            borderRadius:'25px',
            border:'solid #485BC7 2px',
            cursor:'pointer',
            paddingBottom:'0.5rem',
            marginTop:'1rem',
            fontSize:'0.8rem',
          },
          '& .welcomeButtonSkip':{
            width:'8rem',
            color:'#485BC7',
            border:'solid #485BC7 2px',
            backgroundColor:'transparent',
            paddingTop:'0.5rem',
            paddingLeft:'1rem',
            paddingRight:'1rem',
            borderRadius:'25px',
            cursor:'pointer',
            paddingBottom:'0.5rem',
            marginTop:'1rem',
            fontSize:'0.8rem',
          },
          '& .welcomeButtonSkip:hover':{
            color:'white',
            backgroundColor:'#485BC7',
            paddingTop:'0.5rem',
            paddingLeft:'1rem',
            paddingRight:'1rem',
            borderRadius:'25px',
            border:'solid #485BC7 2px',
            cursor:'pointer',
            paddingBottom:'0.5rem',
            marginTop:'1rem',
            fontSize:'0.8rem',
          },
        }
      },
    },
    tutorialToolTip: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity:0,
      zIndex: 20,
      '& .divBackground': {
        boxShadow:'0 0 5px 5px #485BC7',
        position: 'absolute',
        top:0,
        left:0,
        width: '80%',
        height: '80%',
        opacity: 0.6,
        borderRadius: 1,
        zIndex:19
      },
      '& .divBackground2': {
        boxShadow:'inset 0 0 5px 5px #485BC7',
        position: 'absolute',
        top:0,
        left:0,
        width: '80%',
        height: '80%',
        opacity: 0.6,
        borderRadius: 1,
        zIndex:19
      },
      '& .divBlueTutorialQuestion': {
        position: 'absolute',
        top:'30%',
        left:'35%',
        width:'30%',
        paddingTop:'2rem',
        paddingLeft:'2rem',
        paddingRight:'2rem',
        backgroundColor: '#485BC7',
        borderRadius: '25px',
        textAlign:'center',
        zIndex: 21,
        '& .tooltipDescription':{
          color:'white',
          fontSize:'0.7rem',
        },
        '& .tooltipButtonsDiv':{
          fontSize:'0.8rem',
          paddingBottom:'1rem',
          flex:1,
          '& .tooltipButtonContinue':{
            width:'8rem',
            color:'#485BC7',
            border:'solid white 2px',
            backgroundColor:'white',
            paddingTop:'0.5rem',
            paddingLeft:'1rem',
            paddingRight:'1rem',
            borderRadius:'25px',
            cursor:'pointer',
            paddingBottom:'0.5rem',
            marginTop:'1rem',
            fontSize:'0.8rem',
          },
          '& .tooltipButtonContinue:hover':{
            color:'white',
            backgroundColor:'#485BC7',
            paddingTop:'0.5rem',
            paddingLeft:'1rem',
            paddingRight:'1rem',
            borderRadius:'25px',
            border:'solid white 2px',
            cursor:'pointer',
            paddingBottom:'0.5rem',
            marginTop:'1rem',
            fontSize:'0.8rem',
          },
          '& .tooltipButtonClose':{
            width:'8rem',
            color:'white',
            border:'solid white 2px',
            backgroundColor:'#485BC7',
            paddingTop:'0.5rem',
            paddingLeft:'1rem',
            paddingRight:'1rem',
            borderRadius:'25px',
            cursor:'pointer',
            paddingBottom:'0.5rem',
            marginLeft:'1rem',
            fontSize:'0.8rem',
          },
          '& .tooltipButtonClose:hover':{
            color:'#485BC7',
            backgroundColor:'white',
            paddingTop:'0.5rem',
            paddingLeft:'1rem',
            paddingRight:'1rem',
            borderRadius:'25px',
            border:'solid white 2px',
            cursor:'pointer',
            paddingBottom:'0.5rem',
            marginLeft:'1rem',
            fontSize:'0.8rem',
          },
        }
      },
    },
  }

  /*------------------------------
  LG
  ------------------------------*/
  output[theme.mediaInverse.lg] = {
    backgroundTutorialQuestion: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity:0,
      zIndex: 20,
      '& .divBackground': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        opacity: 0.6,
        backgroundColor: '#485BC7',
        borderRadius: 1,
      },
      '& .divWhiteTutorialQuestion': {
        position: 'absolute',
        top:'35%',
        left:'15%',
        width:'70%',
        paddingTop:'3rem',
        paddingLeft:'3rem',
        paddingRight:'3rem',
        backgroundColor: 'white',
        borderRadius: '25px',
        textAlign:'center',
        zIndex: 21,
        '& .welcomeTitle':{
          color:'#485BC7',
          fontSize:'1.7rem',
          paddingBottom:'1rem'
        },
        '& .welcomeDescription':{
          color:'#485BC7',
          fontSize:'0.8rem',
        },
        '& .welcomeButtonsDiv':{
          color:'#485BC7',
          fontSize:'0.8rem',
          flex:1,
          flexDirection:'column',
          '& .welcomeButtonTakeTour':{
            width:'8rem',
            color:'white',
            border:'solid transparent 2px',
            backgroundColor:'#485BC7',
            paddingTop:'0.5rem',
            paddingLeft:'1rem',
            paddingRight:'1rem',
            borderRadius:'25px',
            cursor:'pointer',
            paddingBottom:'0.5rem',
            marginTop:'1rem',
            fontSize:'0.8rem',
          },
          '& .welcomeButtonTakeTour:hover':{
            color:'#485BC7',
            backgroundColor:'white',
            paddingTop:'0.5rem',
            paddingLeft:'1rem',
            paddingRight:'1rem',
            borderRadius:'25px',
            border:'solid #485BC7 2px',
            cursor:'pointer',
            paddingBottom:'0.5rem',
            marginTop:'1rem',
            fontSize:'0.8rem',
          },
          '& .welcomeButtonSkip':{
            width:'8rem',
            color:'#485BC7',
            border:'solid #485BC7 2px',
            backgroundColor:'transparent',
            paddingTop:'0.5rem',
            paddingLeft:'1rem',
            paddingRight:'1rem',
            borderRadius:'25px',
            cursor:'pointer',
            paddingBottom:'0.5rem',
            marginTop:'1rem',
            fontSize:'0.8rem',
          },
          '& .welcomeButtonSkip:hover':{
            color:'white',
            backgroundColor:'#485BC7',
            paddingTop:'0.5rem',
            paddingLeft:'1rem',
            paddingRight:'1rem',
            borderRadius:'25px',
            border:'solid #485BC7 2px',
            cursor:'pointer',
            paddingBottom:'0.5rem',
            marginTop:'1rem',
            fontSize:'0.8rem',
          },
        }
      },
    },
    tutorialToolTip: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity:0,
      zIndex: 20,
      '& .divBackground': {
        boxShadow:'0 0 5px 5px #485BC7',
        position: 'absolute',
        top:0,
        left:0,
        width: '80%',
        height: '80%',
        opacity: 0.6,
        borderRadius: 1,
        zIndex:19
      },
      '& .divBackground2': {
        boxShadow:'inset 0 0 5px 5px #485BC7',
        position: 'absolute',
        top:0,
        left:0,
        width: '80%',
        height: '80%',
        opacity: 0.6,
        borderRadius: 1,
        zIndex:19
      },
      '& .divBlueTutorialQuestion': {
        position: 'absolute',
        top:'30%',
        left:'35%',
        width:'30%',
        paddingTop:'2rem',
        paddingLeft:'2rem',
        paddingRight:'2rem',
        backgroundColor: '#485BC7',
        borderRadius: '25px',
        textAlign:'center',
        zIndex: 21,
        '& .tooltipDescription':{
          color:'white',
          fontSize:'0.7rem',
        },
        '& .tooltipButtonsDiv':{
          fontSize:'0.8rem',
          paddingBottom:'1rem',
          flex:1,
          '& .tooltipButtonContinue':{
            width:'8rem',
            color:'#485BC7',
            border:'solid white 2px',
            backgroundColor:'white',
            paddingTop:'0.5rem',
            paddingLeft:'1rem',
            paddingRight:'1rem',
            borderRadius:'25px',
            cursor:'pointer',
            paddingBottom:'0.5rem',
            marginTop:'0.7rem',
            marginLeft:'1rem',
            fontSize:'0.8rem',
          },
          '& .tooltipButtonContinue:hover':{
            color:'white',
            backgroundColor:'#485BC7',
            paddingTop:'0.5rem',
            paddingLeft:'1rem',
            paddingRight:'1rem',
            borderRadius:'25px',
            border:'solid white 2px',
            cursor:'pointer',
            paddingBottom:'0.5rem',
            marginTop:'0.7rem',
            marginLeft:'1rem',
            fontSize:'0.8rem',
          },
          '& .tooltipButtonClose':{
            width:'8rem',
            color:'white',
            border:'solid white 2px',
            backgroundColor:'#485BC7',
            paddingTop:'0.5rem',
            paddingLeft:'1rem',
            paddingRight:'1rem',
            borderRadius:'25px',
            cursor:'pointer',
            paddingBottom:'0.5rem',
            marginTop:'0.7rem',
            marginLeft:'1rem',
            fontSize:'0.8rem',
          },
          '& .tooltipButtonClose:hover':{
            color:'#485BC7',
            backgroundColor:'white',
            paddingTop:'0.5rem',
            paddingLeft:'1rem',
            paddingRight:'1rem',
            borderRadius:'25px',
            border:'solid white 2px',
            cursor:'pointer',
            paddingBottom:'0.5rem',
            marginTop:'0.7rem',
            marginLeft:'1rem',
            fontSize:'0.8rem',
          },
        }
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    backgroundTutorialQuestion: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity:0,
      zIndex: 20,
      '& .divBackground': {
        position: 'absolute',
        width: '100%',
        height: '100%',
        opacity: 0.6,
        backgroundColor: '#485BC7',
        borderRadius: 1,
      },
      '& .divWhiteTutorialQuestion': {
        position: 'absolute',
        top:'25%',
        left:'15%',
        width:'70%',
        paddingTop:'3rem',
        paddingLeft:'3rem',
        paddingRight:'3rem',
        backgroundColor: 'white',
        borderRadius: '25px',
        textAlign:'center',
        zIndex: 21,
        '& .welcomeTitle':{
          color:'#485BC7',
          fontSize:'1.7rem',
          paddingBottom:'1rem'
        },
        '& .welcomeDescription':{
          color:'#485BC7',
          fontSize:'0.8rem',
        },
        '& .welcomeButtonsDiv':{
          color:'#485BC7',
          fontSize:'0.8rem',
          flex:1,
          flexDirection:'column',
          '& .welcomeButtonTakeTour':{
            width:'8rem',
            color:'white',
            border:'solid transparent 2px',
            backgroundColor:'#485BC7',
            paddingTop:'0.5rem',
            paddingLeft:'1rem',
            paddingRight:'1rem',
            borderRadius:'25px',
            cursor:'pointer',
            paddingBottom:'0.5rem',
            marginTop:'1rem',
            fontSize:'0.8rem',
          },
          '& .welcomeButtonTakeTour:hover':{
            color:'#485BC7',
            backgroundColor:'white',
            paddingTop:'0.5rem',
            paddingLeft:'1rem',
            paddingRight:'1rem',
            borderRadius:'25px',
            border:'solid #485BC7 2px',
            cursor:'pointer',
            paddingBottom:'0.5rem',
            marginTop:'1rem',
            fontSize:'0.8rem',
          },
          '& .welcomeButtonSkip':{
            width:'8rem',
            color:'#485BC7',
            border:'solid #485BC7 2px',
            backgroundColor:'transparent',
            paddingTop:'0.5rem',
            paddingLeft:'1rem',
            paddingRight:'1rem',
            borderRadius:'25px',
            cursor:'pointer',
            paddingBottom:'0.5rem',
            marginTop:'1rem',
            fontSize:'0.8rem',
          },
          '& .welcomeButtonSkip:hover':{
            color:'white',
            backgroundColor:'#485BC7',
            paddingTop:'0.5rem',
            paddingLeft:'1rem',
            paddingRight:'1rem',
            borderRadius:'25px',
            border:'solid #485BC7 2px',
            cursor:'pointer',
            paddingBottom:'0.5rem',
            marginTop:'1rem',
            fontSize:'0.8rem',
          },
        }
      },
    },
    tutorialToolTip: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity:0,
      zIndex: 20,
      '& .divBackground': {
        boxShadow:'0 0 5px 5px #485BC7',
        position: 'absolute',
        top:0,
        left:0,
        width: '80%',
        height: '80%',
        opacity: 0.6,
        borderRadius: 1,
        zIndex:19
      },
      '& .divBackground2': {
        boxShadow:'inset 0 0 5px 5px #485BC7',
        position: 'absolute',
        top:0,
        left:0,
        width: '80%',
        height: '80%',
        opacity: 0.6,
        borderRadius: 1,
        zIndex:19
      },
      '& .divBlueTutorialQuestion': {
        position: 'absolute',
        top:'30%',
        left:'35%',
        width:'30%',
        paddingTop:'1rem',
        paddingLeft:'1rem',
        paddingRight:'1rem',
        backgroundColor: '#485BC7',
        borderRadius: '25px',
        textAlign:'center',
        zIndex: 21,
        '& .tooltipDescription':{
          color:'white',
          fontSize:'0.7rem',
        },
        '& .tooltipButtonsDiv':{
          fontSize:'0.8rem',
          paddingBottom:'1rem',
          flex:1,
          '& .tooltipButtonContinue':{
            width:'8rem',
            color:'#485BC7',
            border:'solid white 2px',
            backgroundColor:'white',
            paddingTop:'0.5rem',
            paddingLeft:'1rem',
            paddingRight:'1rem',
            borderRadius:'25px',
            cursor:'pointer',
            paddingBottom:'0.5rem',
            marginTop:'0.7rem',
            marginLeft:'1rem',
            fontSize:'0.8rem',
          },
          '& .tooltipButtonContinue:hover':{
            color:'white',
            backgroundColor:'#485BC7',
            paddingTop:'0.5rem',
            paddingLeft:'1rem',
            paddingRight:'1rem',
            borderRadius:'25px',
            border:'solid white 2px',
            cursor:'pointer',
            paddingBottom:'0.5rem',
            marginTop:'0.7rem',
            marginLeft:'1rem',
            fontSize:'0.8rem',
          },
          '& .tooltipButtonClose':{
            width:'8rem',
            color:'white',
            border:'solid white 2px',
            backgroundColor:'#485BC7',
            paddingTop:'0.5rem',
            paddingLeft:'1rem',
            paddingRight:'1rem',
            borderRadius:'25px',
            cursor:'pointer',
            paddingBottom:'0.5rem',
            marginTop:'0.7rem',
            marginLeft:'1rem',
            fontSize:'0.8rem',
          },
          '& .tooltipButtonClose:hover':{
            color:'#485BC7',
            backgroundColor:'white',
            paddingTop:'0.5rem',
            paddingLeft:'1rem',
            paddingRight:'1rem',
            borderRadius:'25px',
            border:'solid white 2px',
            cursor:'pointer',
            paddingBottom:'0.5rem',
            marginTop:'0.7rem',
            marginLeft:'1rem',
            fontSize:'0.8rem',
          },
        }
      },
    },
  }

  return output
}

export default style
