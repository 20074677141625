const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      top: 0,
      right: 0,
      width: '37vw',
      height: 'var(--vh)',
      background: theme.colors[3],
      color: theme.colors[1],
      borderTopLeftRadius: 22,
      borderBottomLeftRadius: 22,
      zIndex: theme.zindex.mainNav,
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: 50,
      paddingRight: 50,
      pointerEvents: 'none',
    },
    rootOpened: {
      pointerEvents: 'all',
    },
    container: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    header: {
      height: (props) => `${props.headerHeight}px`,
      display: 'flex',
      alignItems: 'center',
      paddingTop: 30,
      paddingBottom: 20,
      marginBottom: 60,
      fontSize: 14,
      '& a': {
        color: theme.colors[1],
        position: 'relative',
        '&::before': {
          content: '""',
          bottom: 0,
          left: 0,
          display: 'block',
          height: 1,
          position: 'absolute',
          background: 'currentColor',
          transition: 'width 0.4s ease 0s',
          width: 0,
        },
        '@media (hover: hover)': {
          '&:hover': {
            '&::before': {
              width: '100%',
            },
          },
        },
      },
    },
    notLoggedHeader: {
      display: 'flex',
      alignItems:'center',
      justifyContent:'center'
    },
    loggedHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent:'center'
    },
    avatar: {
      width: 50,
      height: 50,
      lineHeight: '50px',
      background: theme.colors[10],
      border: `2px solid ${theme.colors[1]}`,
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 16,
      fontFamily: theme.fonts[1],
      marginRight: 15,
      userSelect: 'none',
      '&::before': {
        content: 'none !important',
      },
    },
    username: {
      fontFamily: theme.fonts[1],
    },
    userLink: {
      cursor: 'pointer',
      fontSize: 14,
      color: theme.colors[1],
      position: 'relative',
      '&::before': {
        content: '""',
        bottom: 0,
        left: 0,
        display: 'block',
        height: 1,
        position: 'absolute',
        background: 'currentColor',
        transition: 'width 0.4s ease 0s',
        width: 0,
      },
      '@media (hover: hover)': {
        '&:hover': {
          '&::before': {
            width: '100%',
          },
        },
      },
    },
    footer: {
      paddingTop: 50,
      paddingBottom: 50,
      fontSize: 12,
      '& a': {
        '--color': theme.colors[1],
        color: 'currentColor',
        extend: theme.link,
      },
    },
    footerSocial: {
      marginBottom: 15,
      '& ul': {
        display: 'flex',
      },
      '& li:not(:last-child)': {
        marginRight: 10,
      },
      '& img': {
        width: 15,
      },
      '& a': {
        '&::before': {
          content: 'none',
        },
      },
    },
    nav: {
      fontSize: 24,
      '& a, & button': {
        position: 'relative',
        fontFamily: theme.fonts[1],
        color: theme.colors[1],
        lineHeight: 1.8,
        textAlign: 'left',
        whiteSpace: 'nowrap',
        '&::after': {
          content: '""',
          bottom: -10,
          left: -20,
          display: 'block',
          height: 1,
          position: 'absolute',
          background: theme.colors[9],
          transition: 'width 0.4s ease 0s',
          width: 100,
        },
        '@media (hover: hover)': {
          '&:hover': {
            '&::after': {
              width: 'calc(100% + 20px)',
            },
          },
        },
      },
    },
    mainNav: {
      fontSize: 35,
      '& button': {
        fontSize: 35,
        cursor: 'pointer',
        lineHeight: 1,
      },
    },
    active: {
      pointerEvents: 'none',
      cursor: 'default',
      '&:after': {
        width: 'calc(100% + 20px) !important',
      },
    },
    visible: {
      opacity: 1,
    },
  }

  /*------------------------------
  LG
  ------------------------------*/
  output[theme.mediaInverse.lg] = {
    root: {
      width: '50vw',
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
    root: {
      width: '70vw',
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    root: {
      width: '100vw',
      maxHeight: 'unset',
      height: 'calc(var(--vh) + 150px)',
      borderRadius: 0,
      paddingLeft: '3.2vw',
      paddingRight: '3.2vw',
      paddingBottom: 150,
    },
    nav: {
      '& a, & button': {
        '&::after': {
          left: 0,
          width: 80,
        },
        '@media (hover: hover)': {
          '&:hover': {
            '&::after': {
              width: '100%',
            },
          },
        },
      },
    },
    mainNav: {
      fontSize: 28,
      '& button': {
        fontSize: 28,
        whiteSpace: 'normal',
      },
    },
    header:{
      justifyContent:'center',
      marginRight:'42px'
    },
    notLoggedHeader: {
      marginBottom:'15px',
      gap:'10px'
    },
    loggedHeader: {
      marginBottom:'15px'
    },
  }

  return output
}

export default style
