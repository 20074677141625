/* eslint-disable react/jsx-props-no-spreading */
import { useRef, useMemo, useEffect, useState } from 'react'
import classNames from 'classnames'
import gsap from 'gsap'
import { createUseStyles } from 'react-jss'
import { mapRange } from '@/utils/math'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useResize } from '@/components/Handlers'
import RevealText from '@/components/RevealText'
import { convertRichText } from '@/utils/strings'
import ReactHtmlParser from 'react-html-parser'

import stylePre from './style'
import stylePost from './stylePost'

const useStylesPre = createUseStyles(stylePre)
const useStylesPost = createUseStyles(stylePost)

const CardWithTrueFalse = ({
  className,
  title,
  index,
  isActive,
  onClick,
  step,
  hint,
  ...props
}) => {
  const classesPre = useStylesPre(stylePre)
  const classesPost = useStylesPost(stylePost)
  const $spinner = useRef()
  // const $buttons = useRef()
  const $ball = useRef()
  // const $num = useRef()
  const arr = new Array(11).fill()
  const [active, setActive] = useState(0)
  const [answer, setAnswer] = useState()
  const [clicked, setClicked] = useState(null)
  const [isDragging, setIsDragging] = useState(false)
  const [radius, setRadius] = useState(132)
  const timer = useRef()

  const classes=(step===1)?classesPre:classesPost;
  const { strings } = useSelector(
    (state) => ({
      strings: state.options.strings,
    }),
    shallowEqual
  );
  useResize(() => {
    setRadius((window.innerWidth > 720 && window.innerHeight < 640) ? 100 : 132)
  })

  /*------------------------------
  Handle Click
  ------------------------------*/
  const handleClick = (val) => {
    setClicked(true)
    setAnswer(val)
    setActive(val);
    // gsap.to($buttons.current, {
    //   scale: 1.1,
    //   rotate: -10,
    //   duration: 0.1,
    //   delay: 0.2,
    //   ease: 'power3.out',
    //   onComplete: () => {
    //     gsap.to($buttons.current, {
    //       rotate: 0,
    //       scale: 1,
    //       duration: 0.6,
    //       ease: 'elastic.out(1, .4)',
    //     })
    //   },
    // })
  }

  useEffect(() => {
    if (clicked) {
      clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        onClick(index, active)
      }, 1000)
    }
  }, [clicked, active])

  /*------------------------------
  Animations
  ------------------------------*/
  useEffect(() => {
    // if (clicked) return
    const theta = (active / arr.length)

    // $buttons.current.querySelectorAll('button').forEach((b, ind) => {
    //   gsap.to(b, {
    //     opacity: ind <= active ? 1 : 0.5,
    //     duration: 0.3,
    //   })
    // })

    // gsap.to($num.current, {
    //   y: `-${theta * $num.current.clientHeight}px`,
    //   ease: 'power3.out',
    //   duration: 0.4,
    // })

    gsap.to($ball.current, {
      rotation: theta * 360,
      duration: 0.4,
      ease: 'power3.out',
    })

    gsap.to($spinner.current, {
      strokeDasharray: `${theta * 151} 151`,
      duration: 0.4,
      ease: 'power3.out',
    })
  }, [active])

  const handleMouseDown = (e) => {
    setIsDragging(true)
    setClicked(false)
    // const { x, y, width, height } = $buttons.current.getBoundingClientRect()
    const clientX = e.clientX || e.touches[0].clientX
    const clientY = e.clientY || e.touches[0].clientY
    const mouseX = clientX - (x + width / 2)
    const mouseY = clientY - (y + height / 2)
    const angle = Math.atan2(mouseX, mouseY)
    const deg = mapRange(angle, Math.PI, -Math.PI, 0, 1)

    const result = Math.min(arr.length - 1, (deg * arr.length).toFixed(0))
    setActive(result)
  }
  const handleMouseUp = () => {
    if (isDragging && clicked === false && active !== 0) {
      setClicked(true)
    }
    setIsDragging(false)
  }

  const handleMouseMove = (e) => {
    if (isDragging) {
      // const { x, y, width, height } = $buttons.current.getBoundingClientRect()
      const clientX = e.clientX || e.touches[0].clientX
      const clientY = e.clientY || e.touches[0].clientY
      const mouseX = clientX - (x + width / 2)
      const mouseY = clientY - (y + height / 2)
      const angle = Math.atan2(mouseX, mouseY)
      const deg = mapRange(angle, Math.PI, -Math.PI, 0, 1)

      const result = Math.min(arr.length - 1, (deg * arr.length).toFixed(0))
      setActive(result)
    }
  }

  const handleTouchDown = (e) => {
    handleMouseDown(e)
    handleMouseMove(e)
    e.preventDefault()
  }

  useEffect(() => {
    // $buttons.current.addEventListener('touchstart', handleTouchDown, true)
    // $buttons.current.addEventListener('mousedown', handleTouchDown, true)

    window.addEventListener('touchmove', handleMouseMove, false)
    window.addEventListener('mousemove', handleMouseMove, false)

    window.addEventListener('touchend', handleMouseUp, false)
    window.addEventListener('mouseup', handleMouseUp, false)
    window.addEventListener('mouseleave', handleMouseUp, false)
    window.addEventListener('contextmenu', handleMouseUp, false)

    return () => {
      window.removeEventListener('touchmove', handleMouseMove)
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('touchend', handleMouseUp)
      window.removeEventListener('mouseup', handleMouseUp)
      window.removeEventListener('mouseleave', handleMouseUp)
      window.removeEventListener('contextmenu', handleMouseUp)
    }
  }, [isDragging, active, clicked])

  const buttons = useMemo(() => {
    return arr.map((b, ind) => {
      const theta = ind / arr.length
      const offset = Math.PI * 0.967
      const x = -Math.sin(theta * Math.PI * 2 + offset) * radius
      const y = Math.cos(theta * Math.PI * 2 + offset) * radius

      return (
        <button
          key={ind.toString()}
          className={classes.button}
          style={{
            transform: `translate(${x}px, ${y}px) rotate(${theta * 360}deg)`,
          }}
        >
          { (ind==0)?'':ind }
        </button>
      )
    })
  }, [radius])

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [className]: true,
        [classes.active]: isActive,
        [classes.firstQuestion]: index === 0,
      })}
      {...props}
    >
      <div className={classes.title}>{title}</div>
      <div className={classes.buttons} style={{padding: 30}}>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
          <button onClick={() => {handleClick('yes')}} className={`${classes.booleanButton} ${answer == 'yes' ? classes.activeButton : ''}`}>{strings['general.yes']}</button>
          <span style={{display: 'block', width: 20, textAlign: 'center'}}></span>
          <button onClick={() => {handleClick('no')}} className={`${classes.booleanButton} ${answer == 'no' ? classes.activeButton : ''}`}>{strings['general.no']}</button>
        </div>
      </div>
      <div className={classNames({
        [classes.flag]: true,
        [classes.visibleFlag]: answer && active !== 0,
      })}
      >
        <svg><use xlinkHref="#ico-true" /></svg>
      </div>

      
      <div className={classes.hint}>
        { isActive &&
          ReactHtmlParser(convertRichText(hint))
        }
      </div>

    </div>
  )
}

CardWithTrueFalse.defaultProps = {
  onClick: undefined,
}

export default CardWithTrueFalse
