import axios from 'axios'
import {axiosInstance} from '@/base/axios'
import { REQUEST_STEPS, RECEIVE_STEPS } from '@/actions/types'
import { API, CONTENTFUL_SPACE, CONTENTFUL_TOKEN } from '@/constants'

const requestSteps = () => ({ type: REQUEST_STEPS })

const receiveSteps = (data) => ({
  type: RECEIVE_STEPS,
  payload: data,
})


const fetchStrapiSteps = () => async (dispatch, getState) => {
  dispatch(requestSteps())
  const localeCode = getState().locale.currentLanguage ?? document.documentElement.lang;
  const response = await axiosInstance.get(`steps?locale=${localeCode}`);
  const orderedSteps = response.data.data
    .map((it) => it.attributes)
    .sort((a, b) => a.order - b.order);
  dispatch(receiveSteps(orderedSteps))
}
export {
  fetchStrapiSteps,
}
