import axios from "axios";
import {
  REQUEST_CONTENT,
  RECEIVE_CONTENT,
  REQUEST_CONTENT_IMAGE,
} from "@/actions/types";
import {
  API,
  CONTENTFUL_SPACE,
  CONTENTFUL_TOKEN,
  STRAPI_TOKEN,
} from "@/constants";
import { axiosInstance } from "@/base/axios";
const requestContent = () => ({ type: REQUEST_CONTENT });

const receiveContent = (data, slug) => ({
  type: RECEIVE_CONTENT,
  payload: data,
  key: slug,
});

const requestImage = () => ({ type: REQUEST_CONTENT_IMAGE });


const fetchStrapiContent =
  (slug, type = "experiences") =>
  async (dispatch, getState) => {
    dispatch(requestContent());
    const localeCode =
      getState().locale.currentLanguage ?? document.documentElement.lang;
    const response = await axiosInstance.get(
      `${type}?filters[slug][$eq]=${slug}&locale=${localeCode}&populate=*`
    );
    try {
      const page = response.data.data[0]["attributes"];
    Object.keys(page).forEach((key) => {
      if (
        typeof page[key] === "object" &&
        page[key] !== null &&
        page[key]["data"] !== null
      ) {
        if (page[key]["data"]["attributes"]) {
          page[
            key
          ] = `${API.STRAPI_CMS}${page[key]["data"]["attributes"]["url"]}`;
        }
      }
    });
    await dispatch(receiveContent(page, slug));
    } catch (error) {
      console.log(error)
      await dispatch(receiveContent({}, slug));
    }
    
    
  };

const fetchEventContent = (id, slug, type) => async (dispatch, getState) => {
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      content_type: type,
      locale: getState().locale.currentLanguage,
      "sys.id": id,
    },
  };
  const response = await axios.get(
    `${API.CMS}/${CONTENTFUL_SPACE}/entries`,
    params
  );
  const page = response.data.items[0].fields;
  const images = [];
  const list = Object.values(page).filter((it) => it.sys);
  if (list.length > 0) {
    list.map(async (i) => {
      // images.push(await dispatch(fetchImage(i.sys.id)));
      await dispatch(receiveContent({ ...page, images }, slug));
    });
  } else {
    await dispatch(receiveContent(page, slug));
  }
};


const fetchStrapiBackground = () => async (dispatch, getState) => {
  dispatch(requestContent());
  const localeCode =
    getState().locale.currentLanguage ?? document.documentElement.lang;
  const response = await axiosInstance.get(
    `background-pages?locale=${localeCode}&populate=*`
  );

  let page = response.data.data;
  page.sort(
    (first, second) =>
      Number(first["attributes"].slug.substr(-1)) -
      Number(second["attributes"].slug.substr(-1))
  );

  const resp =  await axiosInstance.get(
    `glossary-pages?locale=${localeCode}&populate=*`
  );
  
  page[3] = resp.data.data[0];
  page.map(async (background, index) => {
    Object.keys(background.attributes).forEach((key) => {
      if (
        typeof background.attributes[key] === "object" &&
        background.attributes[key] !== null &&
        background.attributes[key]["data"] !== null
      ) {
        if (background.attributes[key]["data"]["attributes"]) {
          background.attributes[
            key
          ] = `${API.STRAPI_CMS}${background.attributes[key]["data"]["attributes"]["url"]}`;
        }
      }
    });
    return (page[index] = { ...page[index].attributes });
  })
  await dispatch(receiveContent(page, "backgroundPages"));
};
export { fetchEventContent, fetchStrapiContent,fetchStrapiBackground };
