/* eslint-disable jsx-a11y/control-has-associated-label */
import classNames from 'classnames'
import { useEffect, useState, useCallback, useRef, useContext } from 'react'
import { useDispatch } from 'react-redux'
import { useTheme } from 'react-jss'
import * as experienceActions from '@/actions/experience'
import useStore from '@/base/zustand'
import { convertRichText } from '../../../utils/strings'
import { useResize } from '@/components/Handlers'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import useMediaQuery from '@/hooks/useMediaQuery'

/*------------------------------
Controls
------------------------------*/
const Controls = ({ className, settings, handleClickNext, step, strings, page, sport, city, duration }) => {
  const {
    setTooltipBounds,
    setTooltipOpen,
    isTooltipOpen,
    tooltipMargin,
    tooltipStep,
    setTooltipStep,
    beforeAfterFlag,
    setBeforeAfterFlag,
    scales,
    setActive,
    active,
    hover,
    setCursorVisibilities,
    firstTimeAnimation,
    debug,
  } = useStore()
  const [firstTime, setFirstTime] = useState(true)

  /*------------------------------
    Redux Actions
    ------------------------------*/
  const dispatch = useDispatch()
  const theme = useTheme()
  const setTotalUplift = useCallback((val) => dispatch(experienceActions.setTotalUplift(val)), [dispatch])
  const { scrollbar } = useContext(ScrollbarContext)

  const $titleRef = useRef()
  const $groupRef = useRef()
  const $rootRef = useRef()
  const [mediaQueryKey] = useMediaQuery()

  // 5 MOST
  // Controllo i 5 valori con il maggior numero di differenza
  useEffect(() => {
    const differences = settings.map((i, ind) => (
      {
        id: ind,
        val: parseInt(((i.postScale * 100) - (i.prevScale * 100)).toFixed(0), 10),
      }
    )).sort((a, b) => {
      return b.val - a.val
    })
    differences.forEach((i, ind) => {
      i.cursorVisible = ind < 5
    })
    const diff = differences.sort((a, b) => {
      return a.id - b.id
    })
    const cursorsVisible = diff.map((i) => i.cursorVisible)
    setCursorVisibilities(cursorsVisible)
  }, [])

  useEffect(() => {
    if (firstTimeAnimation) {
      setTimeout(() => {
        setFirstTime(false)
        setTimeout(() => {
          // debugger
          const bounds = $titleRef.current.getBoundingClientRect()
          setTooltipBounds({
            top: `${bounds.top - tooltipMargin}px`,
            left: `${bounds.left - tooltipMargin}px`,
            right: `${bounds.left + bounds.width + tooltipMargin}px`,
            bottom: `${bounds.top + bounds.height + tooltipMargin}px`,
          })
          setTooltipOpen(true)
        }, 2000 * debug)
      }, 7500 * debug)
    } else if (tooltipStep === 0) {
      const bounds = $titleRef.current.getBoundingClientRect()
      setTooltipBounds({
        top: `${bounds.top - tooltipMargin}px`,
        left: `${bounds.left - tooltipMargin}px`,
        right: `${bounds.left + bounds.width + tooltipMargin}px`,
        bottom: `${bounds.top + bounds.height + tooltipMargin}px`,
      })
    } else if (tooltipStep === 1 && mediaQueryKey < 2) {
      let bounds = $groupRef.current.getBoundingClientRect()

      $rootRef.current.scroll({
        top: bounds.top - bounds.height / 2,
        behavior: 'smooth',
      })
      setTimeout(() => {
        bounds = $groupRef.current.getBoundingClientRect()
        setTooltipBounds({
          top: `${bounds.top - tooltipMargin}px`,
          // left: `${bounds.left + tooltipMargin}px`,
          left: `${bounds.left}px`,
          right: `${bounds.left + bounds.width + tooltipMargin}px`,
          bottom: `${bounds.top + bounds.height}px`,
        })
      }, 750)
      // $rootRef.current.scrollTo(0, bounds.top - bounds.height / 2)
    } else if (mediaQueryKey < 2) {
      $rootRef.current.scroll({
        top: 0,
        behavior: 'smooth',
      })
      // $rootRef.current.scrollTo(0, 0)
    }
  }, [firstTimeAnimation, tooltipStep])
  // }, [firstTimeAnimation, tooltipStep, isTooltipOpen])

  useResize(() => {
    if (isTooltipOpen && tooltipStep === 0) {
      const bounds = $titleRef.current.getBoundingClientRect()
      setTooltipBounds({
        top: `${bounds.top - tooltipMargin}px`,
        left: `${bounds.left - tooltipMargin}px`,
        right: `${bounds.left + bounds.width + tooltipMargin}px`,
        bottom: `${bounds.top + bounds.height + tooltipMargin}px`,
      })
    } else if (isTooltipOpen && tooltipStep === 1) {
      const bounds = $groupRef.current.getBoundingClientRect()
      setTooltipBounds({
        top: `${bounds.top - tooltipMargin}px`,
        left: `${bounds.left - tooltipMargin}px`,
        right: `${bounds.left + bounds.width + tooltipMargin}px`,
        bottom: `${bounds.top + bounds.height + tooltipMargin}px`,
      })
      scrollbar.scrollTo($groupRef.top + $groupRef.height, {
        callback: () => {
          console.log('scroll')
        },
      })
    }
  }, [isTooltipOpen, tooltipStep])

  const setFlag = () => {
    if (isTooltipOpen) return
    setBeforeAfterFlag(!beforeAfterFlag)
  }

  /*------------------------------
    Button on Controls
    ------------------------------*/
  const buttonLink = (s, ind) => {
    let index = -1
    settings.forEach((se, i) => {
      if (se.id === ind) {
        index = i
      }
    })
    const difference = () => {
      return (
        !beforeAfterFlag ? (
          <span className="diff">
            (
            {(settings[index].postScale * 100).toFixed(0)}
            /100)
          </span>
        ) : ''
      )
    }

    // const diff = (settings[ind].postScale * 100 - settings[ind].prevScale * 100).toFixed(0)
    const diff = Math.abs((((settings[index].postScale - settings[index].prevScale) / settings[index].prevScale) * 100).toFixed(0))
    const sign = diff > 0 ? '+' : ''
    return (
      <button
        key={index.toString()}
        style={{ '--color': s.color }}
        onClick={() => setActive(active !== index ? index : -1)}
        className={classNames({
          hover: active === index || hover === index,
        })}
      >
        {s.name}
        <span className="value">
          {beforeAfterFlag
            ? `${(settings[index].prevScale * 100).toFixed(0)}/100`
            : `${sign}${diff}%`}
          {' '}
          {difference()}
        </span>
        <span className="line">
          <span
            className="lineValue"
            style={{ '--delay': `${index * 0.1}s`, width: `${(scales[index] * 100) / 2}%` }}
          />
        </span>
      </button>
    )
  }

  /*------------------------------
    Total Control
    ------------------------------*/
  const totalControl = () => {
    // const preScaleEmotional = settings.filter((s) => s.group === 'emotional').map((a) => Number(a.prevScale.toFixed(2)))
    // const preScaleCognitive = settings.filter((s) => s.group === 'cognitive').map((a) => Number(a.prevScale.toFixed(2)))
    const preScale = settings.map((a) => Number(a.prevScale.toFixed(2)))
    // const preAvarage = (
    //   (preScaleEmotional.reduce((a, b) => a + b) / preScaleEmotional.length)
    //   + (preScaleCognitive.reduce((a, b) => a + b) / preScaleCognitive.length)
    // ) / 2
    const preAvarage = preScale.reduce((a, b) => a + b) / preScale.length
    // const postScaleEmotional = settings.filter((s) => s.group === 'emotional').map((a) => Number(a.postScale.toFixed(2)))
    // const postScaleCognitive = settings.filter((s) => s.group === 'cognitive').map((a) => Number(a.postScale.toFixed(2)))
    const postScale = settings.map((a) => Number(a.postScale.toFixed(2)))
    // const postAvarage = (
    //   (postScaleEmotional.reduce((a, b) => a + b) / postScaleEmotional.length)
    //   + (postScaleCognitive.reduce((a, b) => a + b) / postScaleCognitive.length)
    // ) / 2
    const postAvarage = postScale.reduce((a, b) => a + b) / postScale.length

    // const diff = ((postAvarage * 100) - (preAvarage * 100)).toFixed(0)
    const diff = Math.abs((((postAvarage - preAvarage) / preAvarage) * 100).toFixed(0))
    const sign = diff > 0 ? '+' : ''

    const difference = () => {
      setTotalUplift(`${sign}${diff}%`)
      return (
        !beforeAfterFlag ? (
          <span className="diff">
            {(postAvarage * 100).toFixed(0)}
            /100
          </span>
        ) : ''
      )
    }

    return (
      <p
        className="Score"
        style={{ '--color': '#5163d2' }}
      >
        {page.score}
        : &nbsp;
        <span className="diff">
          {beforeAfterFlag
            ? `${(preAvarage * 100).toFixed(0)}/100 `
            : ''}
          {' '}
          {difference()}
        </span>
      </p>
    )
  }

  /*------------------------------
    Render PrevPost Button
    ------------------------------*/
  const renderPrevPostButton = (mobile) => (
    <button
      onClick={setFlag}
      className={classNames({
        button: true,
        prevPostButton: true,
        pre: beforeAfterFlag,
        post: !beforeAfterFlag,
        mobile,
      })}
    >
      <span>
        {page.preExerciseButton}
      </span>
      <span>
        {page.postExerciseButton}
      </span>
    </button>
  )

  /*------------------------------
    Render Active
    ------------------------------*/
  const renderActiveBox = () => (
    <div className="activeBoxWrap">
      {settings.map((e, i) => {
        // console.log(settings[i])
        const pre = (settings[i].prevScale * 100).toFixed(0)
        const post = (settings[i].postScale * 100).toFixed(0)
        // const diff = (settings[i].postScale * 100 - settings[i].prevScale * 100).toFixed(0)
        // const diff = Math.abs((((settings[i].postScale - settings[i].prevScale) / settings[i].prevScale) * 100).toFixed(0))
        // const sign = diff > 0 ? '+' : ''
        return (
          <div
            key={i.toString()}
            className={classNames({
              activeBox: true,
              visible: i === active,
            })}
            style={{ '--color': e.color }}
          >

            <div className="activeBox__title">
              {e.name}
              {' '}
              <span className="diff">
                {beforeAfterFlag ? `${pre}/100` : `${post}/100`}
              </span>
              <div className="activeBox__values" style={{ fontSize: '20px', textTransform: 'capitalize' }}>{e.group == 'emotional' ? strings['results.traitgroup1'] : strings['results.traitgroup2']}</div>
            </div>
            <div className="activeBox_bar">
              <div className="bar" style={{ '--color': e.color + 80 }}>
                <div style={{ '--color': e.color, '--width': beforeAfterFlag ? `${pre}%` : `${post}%` }} className="progress" />
              </div>
              <button
                className="button close"
                style={{ '--color': e.color }}
                onClick={() => setActive(-1)}
              />
            </div>
            <div className="activeBox__desc">{e.description}</div>
            <div className="colorList">
              {
                                settings.map((setting, index) => {
                                  return (
                                    <button onClick={() => {
                                      setActive(index)
                                    }}
                                    >
                                      <span className={active === index ? 'active activeBox__buttonColor' : 'activeBox__buttonColor'} style={{ '--color': setting.color }} />
                                    </button>
                                  )
                                })
                            }
            </div>
            {renderPrevPostButton()}
          </div>
        )
      })}
    </div>
  )

  /*------------------------------
    Render Heading Wrapper
    ------------------------------*/
  const renderHeadingWrapper = () => {
    const preScale = settings.map((a) => Number(a.prevScale.toFixed(2)))
    const preAvarage = preScale.reduce((a, b) => a + b) / preScale.length
    const postScale = settings.map((a) => Number(a.postScale.toFixed(2)))
    const postAvarage = postScale.reduce((a, b) => a + b) / postScale.length
    // TODO: Replace with translations
    return (
      <div
        className={classNames({
          heading: true,
          visible: active === -1 && !firstTime,
        })}
        style={{ zIndex: isTooltipOpen && (tooltipStep === 0) ? 1010 : null }}
        ref={$titleRef}
      >
        <h1 className="headingTitle">{beforeAfterFlag ? page.heading : page.heading2}</h1>
        <h1 className="headingScore">
          {totalControl() }
        </h1>
        <div className="activeBox_bar">
          <div className="bar" style={{ '--color': `#485bc7${80}` }}>
            <div style={{ '--color': '#485bc7', '--width': beforeAfterFlag ? `${(preAvarage * 100).toFixed(0)}%` : `${(postAvarage * 100).toFixed(0)}%` }} className="progress" />
          </div>
        </div>
        {/* <p className="headingText" dangerouslySetInnerHTML={{ __html: convertRichText(strings['results.description']) }} /> */}
        <p className="headingText">
          <span>{page.exercise}</span>
          <br />
          <b>{sport !== '' ? sport.name : ''}</b>
          <br />
          <span>{page.totalTime}</span>
          <br />
          <b>{duration}</b>
          <br />
          <span>{page.location}</span>
          <br />
          <b>{city || 'N/A'}</b>
          <br />
          <span>{page.clickOnTrait}</span>
          <br />
          <br />
          <span>
            <a href="#" onClick={(e) => { e.preventDefault(); setTooltipStep(0); setTooltipOpen(true) }}>
              <b><u>{page.clickTutorial}</u></b>
            </a>
            {' '}
            {page.understandText}
          </span>
          <br />
          <br />
        </p>
        {renderPrevPostButton(false)}
      </div>
    )
  }

  return (
    <div
      className={className}
      ref={$rootRef}
      style={{
        zIndex: isTooltipOpen && (tooltipStep === 0)
          ? 'unset' : (isTooltipOpen && tooltipStep === 1 && mediaQueryKey < 2)
            ? 1001 : null,
        position: isTooltipOpen && (tooltipStep === 0 || (tooltipStep === 1 && mediaQueryKey < 2)) ? 'absolute' : null,
        pointerEvents: isTooltipOpen ? 'none' : null,
      }}
    >
      {renderHeadingWrapper()}
      <div
        className={classNames({
          loading: true,
          visible: active === -1 && firstTime,
        })}
      >
        {strings['results.loading']}
      </div>
      <div
        className={classNames({
          controlsLeft: true,
          visible: active === -1 && !firstTime,
        })}
      >

        <div
          className={classNames({
            resultHint: true,
            visible: active === -1 && !firstTime,
          })}
        >
          {strings['results.hint.scroll']}
        </div>
      </div>
      {renderActiveBox()}
      <div
        className={classNames({
          controls: true,
          visible: active === -1 && !firstTime,
        })}
      >
        <div
          className="group emotion"
          ref={$groupRef}
          style={{
            zIndex: isTooltipOpen && (tooltipStep === 1) ? 1001 : null,
          }}
        >
          <h1>{page.emotional}</h1>
          <div className="groupBox">
            {settings
              .filter((item) => item.group === 'emotional')
              .map((s) => (
                buttonLink(s, s.id)
              ))}
          </div>
        </div>

        <div className="group emotional">
          <h1>{page.cognitive}</h1>
          <div className="groupBox">
            {settings
              .filter((item) => item.group === 'cognitive')
              .map((s) => (
                buttonLink(s, s.id)
              ))}
          </div>
        </div>
      </div>
      <button
        className={classNames({
          buttonContinue: true,
          visible: !firstTime && active === -1,
        })}
        onClick={handleClickNext}
      >
        <span>{step === 1 ? page.restartButton : page.button}</span>
      </button>
    </div>
  )
}
export default Controls
