import { LITE_RECEIVE_QUESTIONS } from '@/actions/types'

const initialState = {
  items: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LITE_RECEIVE_QUESTIONS:
      state = {
        ...state,
        items: action.payload,
      }
      break
    default:
      return { ...state }
  }
  return { ...state }
}
