import { useRef, useEffect, useContext, useState, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory, useLocation, Link } from 'react-router-dom'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Context as GeneralContext } from '@/context'
import Meta from '@/components/Meta'
import Form from '@/components/Form'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import usePagePrecache from '@/hooks/usePagePrecache'
import SelectField from '@/components/SelectField'
import * as experienceActions from '@/actions/experience'
import * as routerActions from '@/actions/fakerouter'
import * as userActions from '@/actions/user'
import * as contentActions from '@/actions/content'
import * as layerActions from '@/actions/layer'
import * as upliftProgramActions from '@/actions/upliftProgram'
import { getYYYYMMDD,daysBetween } from '@/utils/date'
import { getSlug } from '@/utils/path'
import queryString from 'query-string'
import { API } from '@/constants'
import style from './style'
import classNames from 'classnames'
import useQuery from '../../hooks/useQuery'
const useStyles = createUseStyles(style)

const Portal = () => {
  const { setPageAnimationReady, headerHeight, setHeaderInverse } = useContext(GeneralContext)
  const classes = useStyles({ headerHeight })
  const [isDataFetched, setDataFetched] = useState(false)
  const [sortValue, setSortValue] = useState(0)
  const [sortLabel, setSortLabel] = useState()
  const [sortedExperiences, setSortedExperiences] = useState()
  const [options, setOptions] = useState()
  const $root = useRef()
  const history = useHistory()
  const location = useLocation()
  const stub = useRef('portal')
  const [currentPage, setCurrentPage] = useState('gallery');
  const query  = useQuery();

  /*------------------------------
  Redux Store
  ------------------------------*/
  const { user, isLoggedIn, page, ultraUplifterTab, profile, strings, sports, experiences, uplifterPrograms, form, modal, fakeLocation, upliftProgram,currentLanguage } = useSelector((state) => ({
    // isLoggedIn: state.user.isLoggedIn,
    isLoggedIn: true,
    page: state.content['user-gallery'] || {},
    ultraUplifterTab: state.content['user-gallery-ultra-uplifter'] || {},
    profile: state.user.profile,
    experiences: state.user.experiences,
    uplifterPrograms: state.user.uplifterPrograms || [],
    strings: state.options.strings,
    sports: state.sports.items,
    form: state.form[stub.current],
    modal: state.experience.modal,
    fakeLocation: state.fakerouter.location,
    upliftProgram: state.upliftProgram || {},
    currentLanguage:state.locale.currentLanguage
  }), shallowEqual)

  uplifterPrograms.sort((a, b) => {
    return new Date(b.program_detail.created_at) - new Date(a.program_detail.created_at);
  });
  
  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const moveToResults = useCallback(() => dispatch(routerActions.changeLocation('results')), [dispatch])
  const moveToTimer = useCallback(() => dispatch(routerActions.changeLocation('timer')), [dispatch])
  const moveToConfirmTimer = useCallback(() => dispatch(routerActions.changeLocation('confirm-timer')), [dispatch])
  const resetExperience = useCallback(() => dispatch(experienceActions.resetExperience()), [dispatch])
  const viewPastExperience = useCallback((data) => dispatch(experienceActions.viewPastExperience(data)), [dispatch])
  const fetchProfile = useCallback(() => dispatch(userActions.fetchProfile()), [dispatch])
  const fetchLogout = useCallback(() => dispatch(userActions.fetchLogout()), [dispatch])
  const fetchPreferences = useCallback((data) => dispatch(userActions.fetchPreferences(data)), [dispatch])
  const setStartingExperience = useCallback((bool) => dispatch(experienceActions.setStartingExperience(bool)), [dispatch])
  const fetchPage = useCallback((slug,type) => dispatch(contentActions.fetchStrapiContent(slug, type)), [dispatch])
  const fetchLogin = useCallback((data) => dispatch(userActions.fetchLogin(data)), [dispatch])
  const setExperience = useCallback((data) => dispatch(experienceActions.setExperience(data)), [dispatch])
  const savePartialExperience = useCallback((data) => dispatch(experienceActions.savePartialExperience(data)), [dispatch])
  const openModal = useCallback((modal) => dispatch(layerActions.openLayer({ id: modal })), [dispatch])
  const setUUID = useCallback((value) => dispatch(experienceActions.setUUID(value)), [dispatch])
  const setImage = useCallback((value) => dispatch(experienceActions.setImage(value)), [dispatch])
  const moveToEvents = useCallback(() => dispatch(routerActions.changeLocation('choose-event')), [dispatch])
  const fetchExperience = useCallback((uuid) => dispatch(experienceActions.fetchExperience(uuid)),[dispatch]);
  /*------------------------------
  Fetch Page Data
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length === 0) fetchPage('user-gallery','user-galleries')
    if (Object.keys(ultraUplifterTab).length === 0) fetchPage('user-gallery-ultra-uplifter','user-gallery-ultra-uplifters')

    setInterval(() => {
      document.body.style.overflowY = 'visible';
      document.documentElement.style.scrollBehavior = 'smooth';
    }, 2000);
  }, [])
  useEffect(() => {
    if (Object.keys(page).length > 0 )
      fetchPage('user-gallery','user-galleries');
    if (Object.keys(ultraUplifterTab).length > 0)
      fetchPage('user-gallery-ultra-uplifter','user-gallery-ultra-uplifters')
  }, [currentLanguage])
  useEffect(() => {
    setSortedExperiences(experiences)

    let sortingParams = [
      { id: 101, name: strings['portal.sorting.time_descending'] },
      { id: 102, name: strings['portal.sorting.time_ascending'] },
      { id: 103, name: strings['portal.sorting.this_week'] },
      { id: 104, name: strings['portal.sorting.this_month'] },
      { id: 105, name: strings['portal.sorting.uplift_asc'] },
      { id: 106, name: strings['portal.sorting.uplift_desc'] },
      { id: 107, name: strings['portal.sorting.score_asc'] },
      { id: 108, name: strings['portal.sorting.score_desc'] },
    ]

    let filterParams = (sports.filter(s => experiences.some(exp => exp.sport_id === s.id)))
    let optionsList = [...sortingParams, ...filterParams ];
    setOptions(optionsList)
  }, [experiences])

  /*------------------------------
  Fetch Experience & Redirect
  ------------------------------*/
  const fetchExperienceAndRedirect = (uuid) => {
    fetchExperience(uuid).then((res) => {
      if (res.status === 200 && res.data.completed === 0) {
        if (daysBetween(new Date(), new Date(res.data.created_at)) < 1) {
            moveToConfirmTimer();
            return history.push('/');
        }
      }
    }).catch((e)=>{
      console.log(e)
    });
    return history.push('/portal')
  };

  /*------------------------------
  Check OAuth Login
  ------------------------------*/
  useEffect( async () => { 
    const qs = new queryString.parse(location.search); 
    const loginState = JSON.parse(localStorage.getItem('loginState'));

    if(qs.code){
      const response = await fetchLogin(qs.code);
      if (response.status !== 200){
        window.location.replace(API.OAUTH_URL);
      } else {
        if(localStorage.getItem('redirectToEventSelect')){
          localStorage.removeItem('redirectToEventSelect')
          history.push('/#prize')
        } else if (localStorage.getItem('expOAuthLogin')) {
          setExperience(JSON.parse(localStorage.getItem('expState')))
          savePartialExperience({email : profile.email})
          localStorage.removeItem('expOAuthLogin')
          moveToTimer()
          history.push('/')
        } else {
          if(JSON.parse(localStorage.getItem('openUltraUplifter'))===true){
            history.push('/portal?tab=ultra-uplift')
            localStorage.removeItem('openUltraUplifter');
          }else{
            if(response.data.lastNotCompletedUUID!==null){
              return fetchExperienceAndRedirect(response.data.lastNotCompletedUUID)
            }
            history.push('/portal')
          }
        }
      }
    } else if (!loginState) {
      if(qs.tab==='ultra-uplift'){
        localStorage.setItem('openUltraUplifter',true);
      }
      window.location.replace(API.OAUTH_URL);
    } else {
      fetchProfile()
    }
  }, [])

  useEffect(()=>{
    if(uplifterPrograms && query.get("tab")==='ultra-uplift'){
      if (uplifterPrograms?.length == 0) {
        handleClickUltraUplift();
      } else {
        setCurrentPage('ultra-uplift')
      }
    }else{
      setCurrentPage('gallery')
    }
  },[location.search,uplifterPrograms])

  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0) setDataFetched(true)
  }, [page])

  /*------------------------------
  Redirect to Login Page or Fetch Profile
  ------------------------------*/
  useEffect(() => {
    //if (!isLoggedIn) history.push('/')
    //if (isLoggedIn) fetchProfile()
    if(isLoggedIn && fakeLocation=='share-page'){
      const length = experiences.length + 1
      if (length == 1 || length == 3 || length % 5 == 0) {
        openModal('congrats')
      }
    } 
  }, [isLoggedIn])

  /*------------------------------
  Invert Header
  ------------------------------*/
  useEffect(() => {
    setHeaderInverse(true)
    setStartingExperience(true)
    return () => setHeaderInverse(false)
  }, [])

  /*------------------------------
  Preload
  ------------------------------*/
  const [load] = usePagePrecache({
    init: isDataFetched,
    sources: [],
    callback: () => setPageAnimationReady(true),
  })

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && <Meta title={page.heading} />
  }

  /*------------------------------
  Handle Restart Experience
  ------------------------------*/
  const handleRestartExperience = () => {
    resetExperience();
    history.push('/')
    moveToEvents(); 
  }

  const handleLogout = () => {
    fetchLogout()
    resetExperience()
    history.push('/')
  }

  /*------------------------------
  Render User
  ------------------------------*/
  const renderUser = () => {
    const { nickname } = profile
    return (
      <div className={classes.profile}>
        <div className={classes.profileAvatar}>
          {nickname?.charAt(0).toUpperCase()}
        </div>
        <div className={classes.profileInfo}>
          <div className={classes.username}>{nickname}</div>
          <button className={classes.logout} onClick={handleLogout}>
            {page.logout}
          </button>
        </div>
      </div>
    )
  }

  /*------------------------------
  Handle Click Gallery Item
  ------------------------------*/
  const handleClickGalleryItem = (data) => {
    viewPastExperience(data)
    history.push('/')
    moveToResults()
  }

  const handleClickUltraUplift = () => {
    openModal('intro-modal');
  }
  const handleClickUltraUpliftOnUltraTab = () => {
    if(uplifterPrograms){
      const anyCompleted = uplifterPrograms.some(program => {
        return program.program_detail?.completed === 1
      })
      if(anyCompleted) return openModal('choose-programme-modal');
    }
    return openModal('intro-modal');
  }
  /*------------------------------
  Handle Share Model
  ------------------------------*/
  const handleShareModel = (exp) => {
    setUUID(exp.uuid)
    setImage(exp.image)
    openModal('share')
  }

  const handleSortAndFilter = (type) => {
    let newSortedExperiences

    if(type<100){                                                                     // filter by spor
      newSortedExperiences = experiences.filter(exp => exp.sport_id === type)         // sort by time desc
    } 
    else if(type==101){ // sort by time desc
      newSortedExperiences = experiences.sort((a, b) => {
        return new Date(b.startTime) - new Date(a.startTime);
      })
    } 
    else if(type==102){ // sort by time desc
      newSortedExperiences = experiences.sort((a, b) => {
        return new Date(a.startTime) - new Date(b.startTime);
      })
    } 
    else if(type==103){ // filter this week
      let wb = new Date();      
      wb.setDate(wb.getDate()-(wb.getDay()-1))
      newSortedExperiences = experiences.filter( exp => { 
        return (new Date(exp.created_at) >= wb)
      }) 
    } 
    else if(type==104){ // filter this month
      let currentMonth = new Date().getMonth()+1
      let currentYear = new Date().getFullYear()
      newSortedExperiences = experiences.filter(exp => {
        var [year, month] = exp.created_at.split('-')
        return (currentMonth === +month) && (currentYear == year)
      })
    }
    else if(type==105){ // filter Uplift % Low to High
      newSortedExperiences = experiences.sort((a, b) => {
        return a.uplift - b.uplift;
      })
    }
    else if(type==106){ // filter Uplift % High to Low
      newSortedExperiences = experiences.sort((a, b) => {
        return b.uplift - a.uplift;
      })
    }
    else if(type==107){ // filter Score Low to High
      newSortedExperiences = experiences.sort((a, b) => {
        return a.score - b.score;
      })
    }
    else if(type==108){ // filter Score High to Low
      newSortedExperiences = experiences.sort((a, b) => {
        return b.score - a.score;
      })
    }
    setSortedExperiences(newSortedExperiences)
  }

  const formatDate = (dateString) => {
    var date = new Date(dateString);
    return date.getDate() + '.' + (date.getMonth()+1) + '.' + date.getFullYear();
  }

  const checkDisableButton = () => {
    const uncompleted = uplifterPrograms.filter(program => {
      return program.program_detail.completed != '1';
    });

    return uplifterPrograms.length != 0 && uncompleted.length > 0;
  }

  /*------------------------------
  Render Dashboard
  ------------------------------*/
  const renderDashboard = () => {
    return (
      <div className={classes.dashboard}>
        <ul className={classes.dashboardNav}>
          <li className={classNames({
            currentPage: currentPage === 'gallery'
          })} onClick={() =>                 history.push('portal')        }>{page.tab}</li>
          {<li className={classNames({
            currentPage: currentPage === 'ultra-uplift'
          })} onClick={
            () => {
              if (uplifterPrograms?.length == 0) {
                handleClickUltraUplift();
              } else {
                history.push('portal?tab=ultra-uplift')
              }
            }
          }>{ultraUplifterTab.tab}</li>}
        </ul>
        {currentPage == 'gallery' ? <div className={classes.dashboardContent}>
          <div className={classes.dashboardTitle}>
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
              <h2>{page.heading}</h2>
              <div className={classes.quantity}><span>{experiences.length}</span></div>
            </div>

            <div className={classes.sortDiv}>
              <SelectField className={classes.sortSelect}
                  label={page.sortDropDown}
                  name="sort"
                  value={sortLabel}
                  options={ options }
                  setFieldValue={(label, value) => {
                    setSortLabel(label)
                    setSortValue(value)
                    handleSortAndFilter(value)
                  }}
                />              
            </div>
          </div>
          <div className={classes.grid}>
            <div className={classes.item}>
              <button className={classes.reload} onClick={handleRestartExperience}>
                <div className={classes.reloadBtn}>
                  <div className={classes.roundIco}>
                    <svg width="51" height="46"><use xlinkHref="#ico-plus" /></svg>
                    <span className={classes.createLabel}>{page.startUplift}</span>
                  </div>
                  {/* <span dangerouslySetInnerHTML={{ __html: strings['dashboard.restart'] }} /> */}
                </div>
              </button>
            </div>

            {sortedExperiences?.map((exp, i) => (
              <div className={classes.item} key={i.toString()}>
                <div className={classes.itemInner}>
                  <button className={classes.itemImg} onClick={() => handleClickGalleryItem(exp)}>
                    {exp?.image ?
                        <img src={exp.image} alt="" />
                        :
                        <img src={'/frontend/static/images/general/noPrograms.png'} alt="" />
                        }
                    <span className={classes.sportLabel}>{sports.find((sport) => sport.id === exp.sport_id)?.name}</span>
                  </button>

                  {(exp.uplifter_program_id && exp.uplifter_program) ? (
                    <div className={classes.ultraUpliftBadge}>
                      {
                        exp.uplifter_program.duration == '7' && (<img  src={'/frontend/static/images/portal/7-days.svg'}  />)
                      }
                      {
                        exp.uplifter_program.duration == '14' && (<img  src={'/frontend/static/images/portal/14-days.svg'}  />)
                      }
                      {
                        exp.uplifter_program.duration == '30' && (<img  src={'/frontend/static/images/portal/30-days.svg'}  />)
                      }
                    </div>
                  ) : ''}

                  <div className={classes.shareDiv}>
                    <button className={classes.shareBtn} onClick={() => handleShareModel(exp)} >
                      {page.shareButton}
                    </button>
                  </div>
                </div>

                <div className={classes.itemInfo}>
                  <span className={classes.itemDate}>{getYYYYMMDD(new Date(exp.created_at))}</span>
                  <span className={classes.itemExertion}>{page.exertion}: {exp.exertion}</span>
                  <span className={classes.itemTime}>{ (exp.endTime-exp.startTime) > 12000 ? (new Date(exp.endTime - exp.startTime).toISOString().substr(11, 8)) : '00:20:00' }</span>
                  {/* <span className={classes.itemTime}>{new Date(exp.time * 1000).toISOString().substr(11, 5)}</span> */}
                </div>

                <div className={classes.stateOfMind}>
                  <span>{strings['portal.state_of_mind']}: { parseInt(exp.score * 100) }/100 ({ parseInt(exp.uplift * 100) }%)</span>
                </div>
              </div>
            ))}
          </div>
          <div className={classes.buttonsWrapper}>
            <Link
              to="/world-uplift-map"
              className={classes.btn}
            >
              <span>{page.wumButton}</span>
            </Link>
            <button
              className={classes.btn}
              onClick={handleClickUltraUplift}
              disabled={checkDisableButton()}
            >
              <span>{page.becomeUltraUplifterButton}</span>
            </button>
          </div>
        </div> : ''}
        {/* ULTRA UPLIFT TAB */}
        {currentPage == 'ultra-uplift' &&
          <div className={classes.dashboardContent}>
          <div className={classes.dashboardTitle}>
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
              <h2>{ultraUplifterTab.heading}</h2>
              <div className={classes.quantity}><span>{uplifterPrograms.length}</span></div>
            </div>
          </div>
          <div className={classes.verticalGrid}>
            {
              uplifterPrograms?.map((exp, i) => (
              <div className={classes.verticalItemWrapper}>
                <div className={classes.cardWrapper}>
                  <div className={classes.item} key={i.toString()}>
                    {exp.exp_count > 3 ? <div className={classes.ultraUpliftBackground}></div> : ''}
                    {exp.exp_count > 2 ? <div className={classes.ultraUpliftBackground} style={{left: '5%', top: '5%'}}></div> : ''}
                    {exp.exp_count > 1 ? <div className={classes.ultraUpliftBackground} style={{left: '10%', top: '10%'}}></div> : ''}
                    <div className={classes.itemInner}>
                      {exp.last_exp ? 
                      <button className={classes.itemImg} onClick={() => handleClickGalleryItem(exp.last_exp)}>
                        {exp.last_exp?.image ?
                        <img src={exp.last_exp.image} alt="" />
                        :
                        <img src={'/frontend/static/images/general/noPrograms.png'} alt="" />
                        }
                        {exp.last_exp && <span className={classes.sportLabel}>{sports?.find((sport) => sport.id === exp.last_exp.sport_id)?.name}</span>}
                      </button> :
                      (
                        exp.program_detail.completed == '1' ?
                        <button className={classes.itemImg}>
                          <img src={'/frontend/static/images/general/no-program-completed.png'} alt="" />
                          {exp.last_exp && <span className={classes.sportLabel}>{sports?.find((sport) => sport.id === exp.last_exp.sport_id)?.name}</span>}
                        </button>
                        :
                        <button className={classes.itemImg} onClick={handleRestartExperience}>
                          <div className={classes.reloadBtn}>
                            <div className={classes.roundIco}>
                              <svg width="51" height="46"><use xlinkHref="#ico-plus" /></svg>
                              <span className={classes.createLabel}>{page.startUplift}</span>
                            </div>
                            {/* <span dangerouslySetInnerHTML={{ __html: strings['dashboard.restart'] }} /> */}
                          </div>
                        </button>
                      )
                      }

                      <div className={classes.ultraUpliftBadge}>
                      {
                        exp.program_detail.duration == '7' && (<img src={'/frontend/static/images/portal/7-days.svg'} />)
                      }
                      {
                        exp.program_detail.duration == '14' && (<img  src={'/frontend/static/images/portal/14-days.svg'}  />)
                      }
                      {
                        exp.program_detail.duration == '30' && (<img src={'/frontend/static/images/portal/30-days.svg'}  />)
                      }
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.upliftDetails}>
                  <h3>{ultraUplifterTab.programName && ultraUplifterTab.programName.replace('[Program_Duration]', exp.program_detail.duration)} - {formatDate(exp.program_detail.created_at)}</h3>
                  <h5>{exp.program_detail.completed == '1' ? exp.exp_count + ' ' + ultraUplifterTab.programUpliftsCompleted : ultraUplifterTab.programInProgress}</h5>
                  <table>
                    <tbody>
                      {exp.last_exp ?
                      <>
                        <tr>
                          <td>{ultraUplifterTab.programAverageUplift}</td>
                          <td><b>+{parseInt(exp.avarage_uplift * 100)}%</b></td>
                        </tr>
                        <tr>
                          <td>{ultraUplifterTab.programTopThreeActivities}</td>
                          <td>
                            {exp.most_common_sports.map(sportId => {
                              const sportImage = `/frontend/static/images/sports/${sportId?.sport_id}.png`
                              return (<div class="image" style={{overflow: 'hidden', borderRadius: '100%', border: '1px solid #485bc7'}}><img src={sportImage} /></div>)
                            })}
                          </td>
                        </tr>
                      </>
                      :
                      <tr>
                        <td>
                          {exp.program_detail.completed && !exp.last_exp ?
                          <p className={classes.noUplift} style={{width: '100%'}}>{ultraUplifterTab.noDataToShow}</p>
                          :
                          <p className={classes.noUplift}>{ultraUplifterTab.startToBeginGettingData}</p>
                          }
                        </td>
                      </tr>
                      }
                    </tbody>
                  </table>
                  {exp.min_positive_exp && <p>
                    {ultraUplifterTab.programLastExpSummary?.replace('[Sport_Name]', sports.find(sport => (sport.id == exp.min_positive_exp?.sport_id))?.name)?.replace('[Exertion]', exp.min_positive_exp?.exertion)?.replace('[Mind_Uplift]', parseInt(exp.min_positive_exp?.uplift * 100))?.replace('[Duration]', parseInt(exp.min_positive_exp?.time / 60))}
                  </p>}
                </div>
              </div>
              ))
            }
          </div>

          <div className={classes.buttonsWrapper}>
            <Link
              to="/world-uplift-map"
              className={classes.btn}
            >
              <span>{page.wumButton}</span>
            </Link>
            {uplifterPrograms.some(program => (program.program_detail.completed != '1')) ?
              <Link
                to="/"
                className={classes.btn}
              >
                <span>{ultraUplifterTab.startUpliftButton}</span>
              </Link>
            :
            <button
              className={classes.btn}
              onClick={handleClickUltraUpliftOnUltraTab}
            >
              <span>{ultraUplifterTab.startUltraUpliftButton}</span>
            </button>
            }
          </div>
        </div>
        }
      </div>
    )
  }

  /*------------------------------
  Render Choice
  ------------------------------*/
  const renderChoice = () => {
    return (
      <div className={classes.choice}>
        <h3>{strings['form.communications']}</h3>
        <Form
          className={classes.form}
          fields={form.fields}
          submitLabel={strings['form.update']}
          initialValues={{
            marketing: profile.marketing || false,
          }}
          sendContactForm={fetchPreferences}
          inverseColor
        />
      </div>
    )
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return load && (
      <div className={classes.wrapper}>
        {renderUser()}
        {renderDashboard()}
        {/* {renderChoice()} */}
      </div>
    )
  }

  return (
    <>
      { isLoggedIn
        ? (
          <>
          <LocomotiveScroll
            init={load}
            className={`page pagePortal ${classes.root}`}
            ref={$root}
          >
            {renderHelmet()}
            {renderContent()}
          </LocomotiveScroll>
          </>
        )
        : <div />}
    </>
  )
}

export default Portal
