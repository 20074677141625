import gsap from 'gsap'
import { Vector3, Color } from 'three'
import { useRef, useEffect, useMemo, useState } from 'react'
import { useFrame } from 'react-three-fiber'
import useStore from '@/base/zustand'

const Cursor = ({ settings, index }) => {
  const group = useRef()
  const meshColor = useRef()
  const textureRef = useRef()
  const canvasRef = useRef()
  const i = settings[index]
  const color = useRef(settings[index].color)
  const { x, y, z } = i.cursor
  const pos = useMemo(() => {
    new Vector3(x, y, z).normalize().multiplyScalar(0)
  }, [])
  const { scales, setActive, active, cursorDown, setHover, beforeAfterFlag } = useStore()
  const [inHover, setInHover] = useState()

  /*------------------------------
  Shader
  ------------------------------*/
  const shaderArgs = useMemo(() => {
    return (
      {
        transparent: true,
        uniforms: {
          uColor: { value: new Color(color.current) },
        },
        vertexShader: /* glsl */`
          varying vec2 vUv;
          void main() {
            vUv = uv;
            vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
            gl_Position = projectionMatrix * mvPosition;
          }
        `,
        fragmentShader: /* glsl */`
          varying vec2 vUv;
          uniform sampler2D uTexture;
          uniform vec3 uColor;
          void main() {
            vec2 uv = vUv;
            float d = length(uv - .5);
            gl_FragColor = vec4(vec3(uColor), smoothstep(.76, .77, 1. - d));
          }
        `,
      }
    )
  }, [])

  /*------------------------------
  Shader
  ------------------------------*/
  // const shaderArgsTexture = useMemo(() => {
  //   return (
  //     {
  //       transparent: true,
  //       uniforms: {
  //         uTexture: { value: pinTexture },
  //       },
  //       vertexShader: /* glsl */`
  //         varying vec2 vUv;
  //         void main() {
  //           vUv = uv;
  //           vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
  //           gl_Position = projectionMatrix * mvPosition;
  //         }
  //       `,
  //       fragmentShader: /* glsl */`
  //         varying vec2 vUv;
  //         uniform sampler2D uTexture;
  //         void main() {
  //           vec2 uv = vUv;
  //           vec4 color = texture2D(uTexture, uv);
  //           gl_FragColor = color;
  //         }
  //       `,
  //     }
  //   )
  // }, [])

  useEffect(() => {
    // const scale = scales[index] * cursorVisibilities[index] // ATTIVO SOLO LA VISIBILITA PER 5
    const scale = scales[index] // ATTIVO LA VISIBILITA A TUTTI
    const scalar = scale !== 0 ? 1.5 + scale : 0
    const newPos = new Vector3(x, y, z).normalize().multiplyScalar(scalar)
    gsap.to([meshColor.current.position], {
      x: newPos.x,
      y: newPos.y,
      z: newPos.z,
      duration: 3,
      stagger: 0.2,
      delay: 0.4,
      ease: 'power3.out',
    })
  }, [scales])

  /*------------------------------
  Cursor Down
  ------------------------------*/
  useEffect(() => {
    const condition = cursorDown || active !== -1

    gsap.killTweensOf(group.current.scale)
    gsap.to(group.current.scale, {
      x: condition ? 0 : 1,
      y: condition ? 0 : 1,
      z: condition ? 0 : 1,
      duration: condition ? 0.4 : 2.4,
      delay: condition ? 0 : 0.7 + index * 0.01,
      ease: 'power3.inOut',
    })
  }, [cursorDown, active])

  /*------------------------------
  Pointer Hover
  ------------------------------*/
  useEffect(() => {
    gsap.killTweensOf([meshColor.current.scale])
    gsap.to([meshColor.current.scale], {
      x: !inHover ? 1 : 1.3,
      y: !inHover ? 1 : 1.3,
      z: !inHover ? 1 : 1.3,
      duration: !inHover ? 0.8 : 0.4,
      ease: 'power3.out',
    })
  }, [inHover])
  const canvas = document.createElement('canvas')
  canvas.width = 320
  canvas.height = 80
  const ctx = canvas.getContext('2d')
  ctx.fillStyle = settings[index].color

  const createCanvas = () => {
    const pre = (settings[index].prevScale * 100).toFixed(0)
    const post = (settings[index].postScale * 100).toFixed(0)
    // const diff = (settings[i].postScale * 100 - settings[i].prevScale * 100).toFixed(0)
    const diff = Math.abs((((settings[index].postScale - settings[index].prevScale) / settings[index].prevScale) * 100).toFixed(0))
    const sign = diff > 0 ? '+' : ''

    const barSize = beforeAfterFlag ? pre : post

    ctx.clearRect(0, 0, canvas.width, canvas.height)

    const roundedRectangle = (x, y, width, height, radius) => {
      if (width < 2 * radius) radius = width / 2
      if (height < 2 * radius) radius = height / 2
      ctx.beginPath()
      ctx.moveTo(x + radius, y)
      ctx.arcTo(x + width, y, x + width, y + height, radius)
      ctx.arcTo(x + width, y + height, x, y + height, radius)
      ctx.arcTo(x, y + height, x, y, radius)
      ctx.arcTo(x, y, x + width, y, radius)
      ctx.closePath()
    }
    const pbHeight = 20
    const pbRadius = 20
    const pbButton = 30
    // ctx.fillRect(0,0, canvas.width*0.5, canvas.height*0.5)

    ctx.font = '18px sans-serif'
    const lastPosition = ctx.measureText(settings[index].name)
    ctx.fillText(settings[index].name, 20, 30)
    ctx.font = '14px sans-serif'
    if (beforeAfterFlag) {
      ctx.fillText(`${barSize}/100 `, lastPosition.width + 30, 30)
    } else {
      ctx.fillText(`${barSize}/100 (${sign}${diff}%)`, lastPosition.width + 30, 30)
    }

    const percent = parseInt(barSize) / 100
    const barLength = 250

    ctx.save()
    ctx.globalAlpha = 0.3
    roundedRectangle(20, 40, barLength, pbHeight, pbRadius)
    ctx.fill()
    ctx.restore()
    roundedRectangle(20, 40, barLength * percent, pbHeight, pbRadius)
    ctx.fill()

    roundedRectangle(277, 35, pbButton, pbButton, pbButton)
    ctx.fill()

    ctx.beginPath()
    ctx.lineWidth = 1.3
    ctx.strokeStyle = 'white'
    ctx.moveTo(292, 44)
    ctx.lineTo(292, 56)
    ctx.moveTo(286, 50)
    ctx.lineTo(298, 50)
    ctx.stroke()

    canvasRef.current = canvas
  }
  createCanvas()
  useFrame(({ camera }) => {
    meshColor.current.lookAt(camera.position)
    createCanvas()
    textureRef.current.needsUpdate = true
  })
  return (
    <>
      <group ref={group}>
        <mesh
          ref={meshColor}
          position={pos}
          onClick={() => setActive(index)}
          onPointerDown={() => setActive(index)}
          onPointerOver={() => {
            setInHover(true)
            setHover(index)
          }}
          onPointerOut={() => {
            setInHover(false)
            setHover(-1)
          }}
        >
          <planeBufferGeometry args={[1.6, 0.43]} />
          <meshBasicMaterial
            attach="material"
            color={settings[index].color}
            opacity={1}
            transparent={true}
          >
            <canvasTexture ref={textureRef} attach="map" image={canvas} />
          </meshBasicMaterial>
        </mesh>
      </group>
    </>
  )
}

const Cursors = ({ settings }) => {
  return (
   
    settings.map((i) => <Cursor settings={settings} index={i.id} key={i.name} />)
  )
}
export default Cursors
