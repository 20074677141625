/* eslint-disable jsx-a11y/alt-text */
import { useRef, useEffect, useContext, useState, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Link, useLocation, useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { Context as GeneralContext } from '@/context'
import Meta from '@/components/Meta'
import InViewTrigger from '@/components/InViewTrigger'
import RevealText from '@/components/RevealText'
import KeenGallery from '@/components/KeenGallery'
import RevealImage from '@/components/RevealImage'
import RevealFadeIn from '@/components/RevealFadeIn'
import ScrollIcon from '@/components/ScrollIcon'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import useHandleAllMediaWithCb from '@/hooks/useHandleAllMediaWithCb'
import usePagePrecache from '@/hooks/usePagePrecache'
import * as contentActions from '@/actions/content'
import { convertRichText } from '@/utils/strings'
import { getSlug } from '@/utils/path'
import style from './style'

const useStyles = createUseStyles(style)

const QuestionsCompleted = () => {
  const { setPageAnimationReady, pageAnimationReady, headerHeight } = useContext(GeneralContext)
  const $root = useRef()
  const location = useLocation()
  const history = useHistory()
  const stub = useRef(getSlug(location.pathname))

  /*------------------------------
  Redux Store
  ------------------------------*/
  const { page, emotions, currentLanguage, routerAction } = useSelector((state) => ({
    page: state.content[stub.current] || {},
    emotions: state.emotions.items,
    routerAction: state.router.action,
    currentLanguage: state.locale.currentLanguage,
  }), shallowEqual)

  const classes = useStyles({
    headerHeight,
  })

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchPage = useCallback((slug) => dispatch(contentActions.fetchStrapiContent(slug)), [dispatch])

  /*------------------------------
  Fetch Page Data
  ------------------------------*/
  useEffect(() => {
    if (currentLanguage && Object.keys(page).length === 0) fetchPage(stub.current)
  }, [currentLanguage])

  useEffect(() => {
    setInterval(() => {
      document.body.style.overflowY = 'visible';
      document.documentElement.style.scrollBehavior = 'smooth';
    }, 2000);

    document.body.style.overflowY = 'visible';
    const scrollStyle = document.createElement('style');
    const cssCode = '* {scrollbar-width: auto;scrollbar-color: #39363a transparent;}*::-webkit-scrollbar {width: 10px;}*::-webkit-scrollbar-track {background: transparent;}*::-webkit-scrollbar-thumb {background-color: #39363a;border-radius: 15px; border: none; border-right: 2px solid #fff;}';
    document.body.appendChild(scrollStyle);
    if (scrollStyle.styleSheet){
      // This is required for IE8 and below.
      scrollStyle.styleSheet.cssText = cssCode;
    } else {
      scrollStyle.appendChild(document.createTextNode(cssCode));
    }
  }, []);
  

  let resultsBackgroundImage = page?.resultsBackground;
  const resizeBg = () => {
    if (window.innerWidth < 768) {
      resultsBackgroundImage = page?.resultsMobileBackground;
    }
  }
  resizeBg();

  /*------------------------------
  Preload
  ------------------------------*/
  const [realLoad, setRealLoad] = useState(false)

  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (
      Object.keys(page).length > 0
    ) {
      setRealLoad(true)
    }
  }, [page])

  /*------------------------------
  Executes a callback on loading each image
  ------------------------------*/
  useHandleAllMediaWithCb({
    init: realLoad,
    ref: $root.current?.ref,
  })

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return realLoad && <Meta />
  }

  /*------------------------------
  Handle Click Close
  ------------------------------*/
  const handleClickClose = () => {
    if (routerAction !== 'POP') {
      history.goBack()
    } else {
      history.push('/')
    }
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return realLoad && (
      <>
        <div className={classes.page} style={{ background: `url(${page?.fullBackground}) center center no-repeat`, backgroundSize: 'cover' }}>
        <div className={classes.hero}>
          <div className={classes.wrapper}>
            <div className={classes.box}>
              <RevealText
                tag="h1"
                type="lines"
                value={convertRichText(page.title)}
                visible={true}
                lineHeight={1.4}
                delay={0}
                className={classes.title}
              />
              <RevealText
                tag="div"
                type="lines"
                value={convertRichText(page.text)}
                visible={true}
                lineHeight={1.333}
                delay={1}
                className={classes.hint}
              />
              <div className={classes.buttons}>
                <RevealFadeIn
                  delay={1.8}
                  inview={true}
                >
                  <button
                    className={classNames({
                      [classes.btn]: true,
                    })}
                    onClick={() => {
                      history.push('/#start-experience');
                    }}
                  >
                    <span>{page.nextLabel}</span>
                  </button>
                </RevealFadeIn>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }

  return (
    <div
      className={`page pageQuestionsCompleted ${classes.root}`}
      // ref={$rootVirtual}
      // data-scroll-container
    >
      <>
        {/* <LocomotiveScroll */}
          {/* init={realLoad} */}
          {/* className={`page pageQuestionsCompleted ${classes.root}`} */}
          {/* ref={$root} */}
        {/* > */}
          {renderHelmet()}
          {renderContent()}
        {/* </LocomotiveScroll> */}
      </>
    </div>
  )
}

export default QuestionsCompleted
