import axios from "axios";
import {axiosInstance} from "@/base/axios";
import {
  REQUEST_OPTIONS,
  RECEIVE_OPTIONS,
  REQUEST_STRING,
} from "@/actions/types";
import {
  API,
  CONTENTFUL_TOKEN,
  CONTENTFUL_SPACE,
  STRAPI_TOKEN,
} from "@/constants";

const requestOptions = () => ({ type: REQUEST_OPTIONS });

const receiveOptions = (data) => ({
  type: RECEIVE_OPTIONS,
  payload: data,
});

const fetchOptions = () => async (dispatch, getState) => {
  dispatch(requestOptions());
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      content_type: "string",
      locale: getState().locale.currentLanguage,
      limit: 200,
    },
  };
  const response = await axios.get(
    `${API.CMS}/${CONTENTFUL_SPACE}/entries`,
    params
  );
  const themeOptions = response.data.items
    .map((it) => it.fields)
    .reduce(
      (obj, item) =>
        Object.assign(obj, { [item.key]: item.value || item.value2 }),
      {}
    );
  dispatch(receiveOptions(themeOptions));
};
const fetchStrapiOptions = () => async (dispatch, getState) => {
  dispatch(requestOptions());
  const localeCode =
    getState().locale.currentLanguage ?? document.documentElement.lang;
  const response = await axiosInstance.get(
    `strings?filters&locale=${localeCode}&pagination[start]=0&pagination[limit]=999`
  );
  const themeOptions = response.data.data
    .map((it) => it.attributes)
    .reduce(
      (obj, item) =>
        Object.assign(obj, { [item.key]: item.value || item.value2 }),
      {}
    );
  dispatch(receiveOptions(themeOptions));
};
const requestString = () => ({ type: REQUEST_STRING });

const fetchSingleString = (key) => async (dispatch) => {
  dispatch(requestString());
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      content_type: "string",
      locale: "en-US",
      "fields.key": key,
    },
  };
  const response = await axios.get(
    `${API.CMS}/${CONTENTFUL_SPACE}/entries`,
    params
  );
  const string = response.data.items
    .map((it) => it.fields)
    .reduce((obj, item) => item.value || item.value2, "");
  return string;
};

export { fetchOptions,fetchStrapiOptions, fetchSingleString };
