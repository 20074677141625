const style = (theme) => {
  const output = {
    root: {
      color: theme.colors[0],
      backgroundPosition: "center center",
      backgroundRepeatX: "no-repeat",
      backgroundRepeatY: "repeat",
      backgroundSize: "contain",
    },
    page: {
      overflow: "hidden",
      minHeight: (props) => `calc(var(--vh) - ${props.headerHeight}px)`,
    },
    wrapper: {
      extend: theme.wrapper,
      position: "relative",
      zIndex: 1,
    },
    mobileWrapper: {},
    buttonWrapper: {},
    btn: {
      extend: theme.button,
      height: 46,
      padding: [15, 50],
      "& span": {
        fontSize: 18,
        lineHeight: "16px",
      },
      "&.continueBtn": {
        position: "fixed",
        bottom: "5%",
      },
    },
    ghostBtn: {
      extend: theme.button,
      backgroundColor: "transparent",
      color: "#485BC7",
      border: "1px solid #485BC7",
      transition: "all 350ms",

      "&:hover": {
        backgroundColor: "#485BC7",
        color: "#fff",
      },
    },

    // Hero
    hero: {
      color: theme.colors[3],
      textAlign: "left",
      position: "relative",
      zIndex: 1,
      fontSize: 18,
      minHeight: "var(--vh)",
    },

    // Find Out Link
    findOutButton: {
      "& a": {
        textDecoration: "underline",
        fontWeight: "bold",
        color: "#485bc7",
      },
    },

    buttonSeparator: {
      height: 40,
    },

    // First Time
    firstTimeText: {
      marginBottom: 10,
    },

    // Begin
    begin: {
      padding: [170, 0, 80],
      position: "relative",
      zIndex: 3,
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
    wrapper: {
      padding: [60, 120, 0, 82],
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
      width: "100%",
      margin: [0, "auto"],

      "& > div:first-child": {
        width: "41%",
      },
    },
    wrapperInside: {
      zIndex: 2,
    },
    resultHeading: {
      display  : 'inline-block!Important',
      lineHeight : '0!important',
      textAlign: "left",
      marginBottom: 'calc(var(--sHeight)*0.02px)',
      width: "100%",
      fontWeight: "bold",
      fontSize: 'calc(var(--sWidth)*0.036px)',
      color: "var(--color)",
    },
    resultSubheading: {
      extend: [theme.titleSize],
      textAlign: "left",
      marginBottom: 'calc(var(--sHeight)*0.04px)',
      width: "100%",
      fontWeight: "bold",
      fontSize: 46,
      lineHeight: 50,
      color: "var(--color)",
    },
    beginText: {
      maxWidth: 700,
      fontSize: 'calc(var(--sWidth)*0.014px)',
      fontWeight: "400",
      margin: [0, 0, 25],
      textAlign: "left",
      lineHeight: "100%",
      "& a": {
        "--color": theme.colors[3],
        color: "currentColor",
        extend: theme.link,
      },
      "& p": {
        marginBottom: 10,
      },
      color: "var(--color)",
    },
    beginDisclaimer: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      bottom: 30,
      maxWidth: 500,
      fontSize: 14,
      margin: [0, "auto"],
      opacity: 0.5,
      "& a": {
        "--color": theme.colors[3],
        extend: theme.link,
      },
    },

    percentage: {
      marginTop: "-25px!important",
      textAlign: "right",
    },
    score: {
      position: "absolute",
      right: "13%",
      bottom: "2%",
      "& h1": {
        fontWeight: "400",
        fontSize: "460px",
        lineHeight: "506px",
        letterSpacing: "3%",
      },
    },
    introButtonWrapper: {
      display: "inline-block",
      textAlign: "center",
    },
    "@keyframes grow": {
      from: {
        width: "0%",
      },
      to: {
        width: "var(--width)",
      },
    },
    resultBarsWrapper: {
      marginBottom : '30px',
      "& .activeBox_bar": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        paddingBottom: "1.5rem",
        "& .label": {
          fontSize: "28px",
          fontWeight: 400,
          lineHeight: "30px",
          marginBottom: "1px",
        },
        "& .barWrapper": {
          display: "flex",
          alignItems: "center",
          "& .bar": {
            overflow: "hidden",
            width: "491px",
            height: "22px",
            backgroundColor: "var(--color)",
            borderRadius: 30,
            marginRight: "40px",
          },
          "& .progress": {
            overflow: "hidden",
            width: "var(--width)",
            height: "22px",
            backgroundColor: "var(--color)",
            borderRadius: 30,
            animation: "$grow 3s forwards",
          },
          "& .score": {
            fontSize: "30px",
            fontWeight: "bold",
          },
        },
      },
    },
    metricCircles: {
      marginBottom: "40px",
      "& .label": {
        fontSize: "28px",
        fontWeight: "400",
        marginBottom: "22px",
      },
      "& .circles": {
        "& .emotion": {
          width: "40px",
          height: "40px",
          marginRight: "15px",
          "&:hover": {
            "& .circleBorder": {
              visibility: "visible",
            },
          },
          "&.active": {
            "& .circleBorder": {
              visibility: "visible",
            },
          },
          "& .circleBorder": {
            fill: "none",
            stroke: "var(--color)",
            strokeWidth: 3,
            visibility: "hidden",
          },
        },
      },
      "& .smallCircles": {
        display: "none",
      },
      "& .bigCircles": {
        display: "block",
      },
    },
  };
  // Needed custom screen size
  output["@media (min-width: 1601px) and (max-width:1900px)"] = {
    resultHeading: {
      textAlign: "left",
      marginBottom: 10,
      width: "100%",
      fontWeight: "bold",
      fontSize: 50,
      lineHeight: 50,
      color: "var(--color)",
    },
    resultSubheading: {
      fontSize: 36,
    },
    beginText: {
      maxWidth: 700,
      fontSize: "24px",
      fontWeight: "bold",
      margin: [0, 0, 25],
      textAlign: "left",
      lineHeight: "100%",
    },
  };
  // Needed custom screen size
  output["@media (max-width: 1600px)"] = {
    wrapperInside: {
      width: "100%",
    },
    beginTitle: {
      width: "100%",
      fontSize: 40,
    },
    introRight: {
      width: "95%",

      "& h1": {
        fontSize: 60,
        "-webkit-text-stroke-width": "1px",
        letterSpacing: 3,
        textAlign: "right",
      },

      "& img": {
        right: "-15%!important",
      },
    },
    percentage: {
      marginTop: "20px!important",
    },
    score: {
      "& h1": {
        fontWeight: "normal",
        fontSize: 40,
        marginBottom: "0px",
        letterSpacing: 0,
      },
    },
  };

  // Needed custom screen size
  output["@media (max-width: 1750px)"] = {
    introRight: {
      "& h1": {
        fontSize: 85,
      },
    },
  };

  // HD Macbook
  output["@media (max-width: 1440px)"] = {
    wrapperInside: {
      width: "50%!important",
      paddingLeft: 90,
    },
    resultHeading: {
      fontSize: "40px",
    },
    resultSubheading: {
      fontSize: "21px",
    },
    beginText: {
      fontSize: "18px",
    },
    resultBarsWrapper: {
      "& .activeBox_bar": {
        paddingBottom: "0.5rem",
        "& .label": {
          fontSize: "21px",
          marginBottom: "1px",
        },
        "& .barWrapper": {
          "& .bar": {
            width: "300px",
            height: "15px",
            marginRight: "40px",
          },
          "& .progress": {
            width: "var(--width)",
            height: "15px",
          },
          "& .score": {
            fontSize: "21px",
          },
        },
      },
    },
    metricCircles: {
      marginBottom: "20px",
      "& .label": {
        fontSize: "18px",
        fontWeight: "400",
        marginBottom: "0px",
      },
      "& .circles": {
        "& .emotion": {
          width: "35px",
          height: "35px",
          marginRight: "0px",
        },
      },
    },
  };

  // Ipad PRO
  output["@media (max-width: 1200px)"] = {
    wrapperInside: {
      width: "60%!important",
      paddingLeft: 0,
    },
    resultHeading: {
      fontSize: "36px",
    },
    resultSubheading: {
      fontSize: "18px",
      marginBottom: "10px",
    },
    beginText: {
      fontSize: "14px",
      marginBottom: "10px",
    },
    resultBarsWrapper: {
      "& .activeBox_bar": {
        paddingBottom: "0.5rem",
        "& .label": {
          fontSize: "18px",
          marginBottom: "1px",
        },
        "& .barWrapper": {
          "& .bar": {
            width: "300px",
            height: "15px",
            marginRight: "40px",
          },
          "& .progress": {
            width: "var(--width)",
            height: "15px",
          },
          "& .score": {
            fontSize: "18px",
          },
        },
      },
    },
    metricCircles: {
      marginBottom: "20px",
      "& .label": {
        fontSize: "14px",
        fontWeight: "400",
        marginBottom: "0px",
      },
      "& .circles": {
        "& .emotion": {
          width: "35px",
          height: "35px",
          marginRight: "0px",
        },
      },
    },
  };
  //  PRO
  output[
    "@media (min-width: 901px) and (max-width: 1300px), (min-height: 700) and (max-height: 900)"
  ] = {
    wrapper: {
      padding: [120, 40, 0, 40],
    },
  };
  /*------------------------------
    LG
    ------------------------------*/
  output[theme.media.lg] = {
    wrapper: {
      display: "flex",
    },
    mobileWrapper: {
      display: "none",
    },
  };
  output[theme.mediaInverse.lg] = {
    wrapper: {
      display: "none",
    },
    mobileWrapper: {
      paddingTop: "calc(var(--height)*0.13px)",
      display: "flex",
      flexDirection: "column",
      gap: "calc(var(--height)*0.02px)",
      width: "100%",
      marginLeft: "calc(var(--width)*0.07px)",
      marginRight: "calc(var(--width)*0.07px)",
    },
    wrapperInside: {
      width: "100%!important",
    },
    resultHeading: {
      fontSize: "var(--fontSize)",
      fontWeight: "bold",
      marginBottom: "calc(var(--height)*0.011px)",
    },
    resultSubheading: {
      fontSize: "var(--fontSize)",
      fontWeight: "bold",
      marginBottom: "calc(var(--height)*0.024px)",
    },
    beginText: {
      fontSize: "var(--fontSize)",
      marginBottom: 0,
    },
    resultBarsWrapper: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "calc((var(--height)-50)*0.024px)",
      "& .activeBox_bar": {
        "& .label": {
          fontSize: "var(--fontSize)",
        },
        "& .barWrapper": {
          "& .bar": {
            width: `calc(var(--width)*0.23px)`,
            marginRight: `calc(var(--width)*0.03px)`,
          },
          "& .score": {
            fontSize: "var(--fontSize)",
          },
        },
      },
    },
    metricCircles: {
      marginBottom: 0,
      "& .label": {
        fontSize: "var(--fontSize)",
      },
      "& .bigCircles": {
        "& .emotion": {
          width: "calc(var(--height)*0.014px)",
          height: "calc(var(--height)*0.014px)",
          marginRight: "calc(var(--height)*0.008px)",
        },
      },
    },
    btn: {
      position: "unset",
      height: "calc(var(--height)*0.049px)",
      width: "calc(var(--width)*0.3630px)",
      minWidth: "0",
      padding: "0 0 0 0",
      "& span": {
        fontSize: "calc(var(--width)*0.029px)",
      },
      "&.continueBtn": {
        position: "inherit",
        bottom: "51px",
      },
      "&.desktopBtn": {
        display : 'none'
      },
    },
    buttonWrapper: {
      marginTop: "1vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "46px",
    },
  };

  /*------------------------------
    MD
    ------------------------------*/
  output[theme.mediaInverse.md] = {};

  return output;
};

export default style;
