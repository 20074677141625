const style = (theme) => {
    const output = {  
      select: {
        '& .customSelect__control': {
          color: theme.colors[3],
          background: 'transparent',
          fontSize: 14,
          fontFamily: theme.fonts[1],
          borderRadius: 30,
          '-webkit-mask-image': '-webkit-radial-gradient(white, black)',
          padding: '8px 18px 8px 26px',
          border: `1px solid white`,
          overflow: 'hidden',
          '&:hover': {
            border: `1px solid white`,
          },
        },
        '& .customSelect__placeholder': {
          color: 'white',
        },
        '& .customSelect__single-value': {
          color: theme.colors[3],
        },
        '& .customSelect__menu': {
          color: theme.colors[3],
          background: theme.colors[1],
          fontSize: 14,
          fontFamily: theme.fonts[1],
          borderRadius: 30,
          overflow: 'hidden',
          '-webkit-mask-image': '-webkit-radial-gradient(white, black)',
          textAlign: 'left',
          border: `1px solid ${theme.colors[3]}`,
          padding: [5, 12],
        },
        '& .customSelect__value-container': {
          padding: 0,
        },
        '& .customSelect__indicator-separator': {
          display: 'none',
        },
        '& .customSelect__dropdown-indicator': {
          '& svg': {
            fill: 'white',
          },
        },
        '& .customSelect__control--is-focused': {
          border: `1px solid white`,
          boxShadow: 'none',
        },
        '& .customSelect__option': {
          borderRadius: 20,
          fontSize: 14,
          padding: [12, 16],
        },
        '& .customSelect__option:active, & .customSelect__option--is-focused': {
          background: theme.getRgba(theme.colors[3], 0.2),
          color: theme.colors[3],
        },
        '& .customSelect__option--is-selected, & .customSelect__option--is-selected:active': {
          background: theme.getRgba(theme.colors[3], 0.4),
          color: theme.colors[3],
        },
  
        // Container has value
        '& .customSelect__value-container--has-value': {
          background: theme.colors[3],
          boxShadow: `0 0 0 100px ${theme.colors[3]}`,
          '& .customSelect__single-value': {
            color: theme.colors[1],
          },
          '& + .customSelect__indicators': {
            position: 'relative',
            zIndex: 1,
            pointerEvents: 'none',
            '& svg': {
              fill: theme.colors[1],
            },
          },
        },
      },
      formControl: {
        padding: '0 10px',
        position: 'relative',
        zIndex: 1,
      },
      formControlSelect: {
        width: '236px',
        zIndex: 2,
      },
    }
  
    /*------------------------------
    MD
    ------------------------------*/
    output[theme.mediaInverse.md] = {
      select: {
        '& .customSelect__control': {
          fontSize: 16,
        },
        '& .customSelect__menu': {
          fontSize: 16,
        },
      },
    }
  
    /*------------------------------
    SM
    ------------------------------*/
    output[theme.mediaInverse.sm] = {
      formControl: {
        width: '195px',
      },
      select:{
        '& .customSelect__placeholder': {
            fontSize:'13px'
          },
        '& .customSelect__single-value': {
            fontSize:'13px'
        },
      }
    }
  
    return output
  }
  
  export default style
  