const style = (theme) => {
  const output = {
    root: {
      color: theme.colors[1],
      background: 'transparent',
      paddingTop: (props) => props.headerHeight,
      height: '100vh',
    },
    page: {
      minHeight: (props) => `calc(var(--vh) - ${props.headerHeight}px)`,
    },
    btn: {
      extend: theme.button,
    },

    // Mapbox
    mapContainer: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      width: '100%',
      overflow: 'hidden',
    },

    // Sidebar
    sidebar: {
      position: 'fixed',
      zIndex: 1,
      top: 0,
      left: 0,
      width: '21.5vw',
      height: 'var(--vh)',
      userSelect: 'none',
      pointerEvents: 'none',
    },
    // Header
    header: {
      pointerEvents: 'all',
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      width: '100%',
      boxShadow: '3px 4px 30px 0px rgba(27, 27, 27, 0.13)',
      backgroundColor: theme.colors[1],
      borderBottomRightRadius: 15,
      paddingTop: 30,
      paddingBottom: 20,
      height: (props) => `${props.headerHeight}px`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    logo: {},
    // Footer
    footer: {
      position: 'absolute',
      zIndex: 1,
      bottom: 0,
      left: 0,
      height: 100,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      backgroundColor: 'var(--emotion-color)',
      fallbacks: {
        backgroundColor: theme.colors[3],
      },
      boxShadow: '3px 4px 30px 0px rgba(27, 27, 27, 0.13)',
      padding: [0, '15%'],
      borderTopRightRadius: 15,
      transition: `background-color 1s ${theme.easings['power3.out']}`,
      '&.sports': {
        backgroundColor: theme.colors[13],
      },
      '&.events': {
        backgroundColor: theme.colors[14],
      },
    },
    sidebarRight: {
      position: 'fixed',
      right: 30,
      bottom: 20,
      height: 60,
      padding: [10, 25],
      width: '26vw',
      backgroundColor: theme.colors[1],
      borderRadius: 50,
      boxShadow: '3px 4px 30px 0px rgba(27, 27, 27, 0.13)',
      color: 'var(--emotion-color)',
      fallbacks: {
        color: theme.colors[3],
      },
      transition: `color 1s ${theme.easings['power3.out']}`,
    },
    values: {},
    legendWrapper: {
      overflow: 'auto',
      pointerEvents: 'all',
      display: 'flex',
      flexDirection: 'column',
      '& > *:not(:last-child)': {
        marginBottom: 5,
      },
      '& h4': {
        textAlign: 'center',
        fontFamily: theme.fonts[1],
        fontSize: 12,
        lineHeight: 1,
      },
      '& $values': {
        display: 'flex',
        justifyContent: 'space-between',
        fontFamily: theme.fonts[1],
        fontSize: 10,
      },
    },
    gradient: {
      height: 10,
      borderRadius: 20,
      width: '100%',
      background: 'linear-gradient(90deg, var(--emotion-color-start) 0%, var(--emotion-color) 100%)',
      fallbacks: {
        background: theme.colors[3],
      },
      transition: `all 1s ${theme.easings['power3.out']}`,
    },
    swatchList: {
      display: 'flex',
      '& > *': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '&:not(:last-child)': {
          marginRight: 20,
        },
      },
    },
    swatch: {
      borderRadius: '50%',
      width: 20,
      height: 20,
      backgroundColor: 'var(--emotion-color)',
    },
    swatchName: {
      fontFamily: theme.fonts[1],
      color: 'var(--emotion-color)',
      fontSize: 10,
      marginTop: 5,
    },
    footerContent: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    footerTop: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 23,
      fontFamily: theme.fonts[1],
      lineHeight: 1,
      minHeight: 24,
      '& svg': {
        width: 30,
        height: 15,
        fill: theme.colors[1],
        marginRight: 20,
      },
    },
    footerBottom: {
      marginTop: 5,
      fontSize: 13,
      textTransform: 'capitalize',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },

    // Content
    sidebarContent: {
      position: 'absolute',
      height: (props) => `calc(100% - (${props.headerHeight}px + 10px))`,
      width: '100%',
      flex: 1,
      paddingLeft: '10%',
      paddingRight: '10%',
      paddingBottom: 100,
      marginTop: (props) => `calc(${props.headerHeight}px + 10px)`,
      paddingTop: 40,
      backgroundColor: theme.colors[1],
      borderTopRightRadius: 15,
      boxShadow: '3px 4px 30px 0px rgba(27, 27, 27, 0.13)',
      display: 'flex',
      flexDirection: 'column',
      transition: `transform 0.5s ${theme.easings['power3.out']}`,
      transform: 'translateX(-100%)',
      '&.visible': {
        transform: 'translateX(0)',
        pointerEvents: 'all',
      },
    },
    sidebarContentCountry: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      flex: 1,
      paddingLeft: '10%',
      paddingRight: '10%',
      paddingBottom: 100,
      paddingTop: 40,
      backgroundColor: theme.colors[1],
      borderTopRightRadius: 15,
      boxShadow: '3px 4px 30px 0px rgba(27, 27, 27, 0.13)',
      display: 'flex',
      flexDirection: 'column',
      transition: `transform 0.5s ${theme.easings['power3.out']}`,
      transform: 'translateX(-100%)',
      '&.visible': {
        transform: 'translateX(0)',
        pointerEvents: 'all',
      },
    },
    sidebarClose: {
      position: 'absolute',
      zIndex: 2,
      top: -40,
      left: 'calc(50% - 40px)',
      width: 80,
      height: 80,
      borderRadius: '50%',
      backgroundColor: theme.colors[3],
      cursor: 'pointer',
      transition: `background-color 0.3s ${theme.easings['power3.out']}`,
      borderColor: () => 'transparent',
      boxShadow: '0px 0px 0px 4px rgba(0, 0, 0, 0.15)',
      '& span': {
        width: 20,
        height: 2,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(45deg)',
        backgroundColor: theme.colors[1],
      },
      '&::after': {
        content: '""',
        width: 20,
        height: 2,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(-45deg)',
        backgroundColor: theme.colors[1],
      },
      '&.sports': {
        backgroundColor: theme.colors[13],
      },
      '&.events': {
        backgroundColor: theme.colors[14],
      },
    },
    filterNav: {
      '& ul': {
        display: 'flex',
      },
      '& li': {
        borderRadius: 15,
        flexBasis: '33.33%',
        '&$filterButtonActive': {
          '& $filterButton': {
            backgroundColor: theme.colors[3],
            color: theme.colors[1],
            cursor: 'default',
          },
          '& $sportsButton': {
            backgroundColor: theme.colors[13],
          },
          '& $eventsButton': {
            backgroundColor: theme.colors[14],
          },
        },
      },
      '&.disabled': {
        pointerEvents: 'none',
      },
    },
    filterButton: {
      width: '100%',
      textAlign: 'center',
      paddingTop: 20,
      paddingBottom: 20,
      borderRadius: 15,
      fontSize: 14,
      fontFamily: theme.fonts[1],
      color: theme.getRgba(theme.colors[3], 0.4),
      cursor: 'pointer',
      transition: `all 0.2s ${theme.easings['power3.out']}`,
      '&:hover': {
        '&:not($filterButtonActive)': {
          background: theme.getRgba(theme.colors[3], 0.4),
          color: theme.colors[1],
        },
      },
    },
    sportsButton: {},
    eventsButton: {},
    filterButtonActive: {},
    filterContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      margin: [40, 0],
      flex: 1,
      '&.disabled': {
        pointerEvents: 'none',
      },
    },
    select: {
      marginBottom: 0,
      padding: 0,
      '& .customSelect__control': {
        border: 'none',
        borderBottom: `2px solid ${theme.getRgba(theme.colors[3], 0.2)}`,
        borderRadius: 0,
        padding: '8px 18px 8px 0px',
        '&:hover': {
          border: 'none',
          borderBottom: `2px solid ${theme.getRgba(theme.colors[3], 0.2)}`,
        },
      },
      '& .customSelect__value-container--has-value': {
        background: 'transparent',
        boxShadow: 'none',
        '& .customSelect__single-value': {
          color: theme.colors[3],
        },
        '&+.customSelect__indicators svg': {
          fill: theme.colors[3],
        },
      },
      '& .customSelect__menu': {
        boxShadow: 'none',
        marginTop: 0,
        background: '#F7F7F7',
        borderColor: '#E4E4E4',
        borderTop: 'none',
        borderRadius: 0,
        borderBottomRightRadius: 15,
        borderBottomLeftRadius: 15,
      },
      '& .customSelect__option': {
        padding: [8, 16],
      },
      '& .customSelect__option--is-focused': {
        background: theme.getRgba(theme.colors[3], 0.05),
      },
    },
    filterResultTitle: {
      fontSize: 22,
      lineHeight: 1,
      color: theme.colors[3],
      fontFamily: theme.fonts[1],
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 10,
      borderBottom: '1px solid rgba(194, 194, 194, 0.4)',
    },
    filterResultSport: {
      '& $filterResultTitle': {
        color: theme.colors[13],
        textAlign: 'center',
      },
      '& $filterSportIcon': {
        margin: '0 auto',
      },
    },
    filterSportBox: {
      textAlign: 'center',
      marginBottom: 20,
      '& span': {
        color: theme.colors[13],
        fontSize: 12,
        marginTop: 5,
      },
    },
    filterResultEvents: {
      '& $filterResultTitle': {
        color: theme.colors[14],
        textAlign: 'center',
      },
    },
    filterEstimate: {
      padding: [20, 10],
    },
    filterSport: {
      minHeight: 70,
      paddingTop: 20,
      borderTop: '1px solid rgba(194, 194, 194, 0.4)',
      display: 'flex',
      alignItems: 'center',
      opacity: 0,
      transition: `opacity 0.3s ${theme.easings['power3.out']}`,
      '&.visible': {
        opacity: 1,
      },
    },
    filterSportIcon: {
      position: 'relative',
      zIndex: 1,
      width: 50,
      borderRadius: '50%',
      border: `2px solid ${theme.colors[13]}`,
      marginRight: 15,
      background: theme.colors[1],
      '&::before': {
        content: '""',
        paddingBottom: '100%',
        display: 'block',
      },

      '& img': {
        position: 'absolute',
        zIndex: 1,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '75%',
        height: '75%',
        objectFit: 'contain',
        filter: 'saturate(0) contrast(200%)',
      },

      '&::after': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        background: theme.colors[13],
        mixBlendMode: 'lighten',
      },
    },
    filterSportInfo: {
      '& h5': {
        fontSize: 14,
        fontFamily: theme.fonts[1],
        fontWeight: 'normal',
        color: theme.colors[13],
        margin: 0,
      },
      '& p': {
        color: theme.colors[3],
        fontSize: 12,
        margin: 0,
      },
    },

    filterSportListWrapper: {
      height: '90%',
      overflowY: 'auto',
      marginBottom: '-40px',
    },
    filterSportList: {
      '& li': {
        padding: 20,
        display: 'flex',
        alignItems: 'center',
        transition: `all 0.3s ${theme.easings['power3.out']}`,
        cursor: 'pointer',
        '@media (hover: hover)': {
          '&:hover': {
            background: 'rgba(0, 0, 0, 0.03)',
          },
        },
        '&:not(:last-child)': {
          borderBottom: '1px solid rgba(194, 194, 194, 0.4)',
        },
      },
    },

    marker: {
      cursor: 'pointer',
      textAlign: 'center',
    },
    markerBullet: {
      margin: '0 auto',
      position: 'relative',
      width: 20,
      height: 20,
      borderRadius: '50%',
      display: 'block',
      '& span': {
        position: 'relative',
        display: 'block',
        zIndex: 2,
        backgroundColor: theme.colors[1],
        width: '100%',
        height: '100%',
        borderRadius: '50%',
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 25,
        height: 25,
        background: 'rgba(0, 0, 0, 0.2)',
        borderRadius: '50%',
      },
    },
    markerLabel: {
      display: 'block',
      marginTop: 10,
      position: 'relative',
      zIndex: 1,
      fontSize: 14,
      lineHeight: 1,
      color: theme.colors[1],
      fontFamily: theme.fonts[1],
      userSelect: 'none',
      whiteSpace: 'nowrap',
      '&::before': {
        content: 'attr(data-text)',
        position: 'absolute',
        zIndex: -1,
        top: 0,
        left: 0,
        color: theme.colors[1],
        '-webkit-text-stroke': `2px ${theme.colors[3]}`,
      },
    },
    sidebarContentMobile: {},
    sidebarScoreMobile: {},
    scoreValue: {},
    scoreValueButton: {},
    backdrop: {},
    sideLeftCmd: {},
    sideRightCmd: {},
    zoomCmd: {},
    filterCmd: {},
    filterMobile: {},
    resultsMobile: {},
    filterResults: {
      paddingTop: '1rem',
    },
  }

  /*------------------------------
  LG
  ------------------------------*/
  output[theme.mediaInverse.lg] = {
    // Sidebar
    sidebar: {
      width: '40vw',
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    root: {
      height: '100vh',
    },
    // Sidebar
    sidebar: {
      width: '100vw',
    },
    // Header
    header: {
      paddingLeft: 10,
      borderBottomLeftRadius: 15,
      justifyContent: 'flex-start',
    },
    logo: {
      width: 100,
      '& svg': {
        width: '100%',
      },
    },
    // Sidebar Content
    sidebarContentMobile: {
      pointerEvents: 'all',
      position: 'fixed',
      zIndex: 2,
      bottom: 0,
      left: 0,
      width: '100%',
      padding: 10,
      height: 75,
      backgroundColor: theme.colors[1],
      borderTopRightRadius: 15,
      borderTopLeftRadius: 15,
      boxShadow: '3px 4px 30px 0px rgba(27, 27, 27, 0.13)',
      transition: `transform 0.5s ${theme.easings['power3.out']}`,
      transform: 'translateY(75px)',
      '&.visible': {
        transform: 'translateY(0)',
      },
    },
    // Sidebar Score
    sidebarScoreMobile: {
      pointerEvents: 'all',
      position: 'fixed',
      zIndex: 1,
      bottom: 0,
      left: 20,
      right: 20,
      padding: '10px 20px',
      backgroundColor: theme.colors[1],
      borderTopRightRadius: 15,
      borderTopLeftRadius: 15,
      boxShadow: '3px 4px 30px 0px rgba(27, 27, 27, 0.13)',
      '--color': theme.colors[3],
      color: 'var(--color)',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: 14,
      fontFamily: theme.fonts[1],
      transition: `all 0.5s ${theme.easings['power3.out']}`,
      '&.visible': {
        bottom: 75,
      },
      '& .text': {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
      '&.disabled': {
        pointerEvents: 'none',
      },
    },
    scoreValue: {
      display: 'flex',
      alignItems: 'center',
    },
    scoreValueButton: {
      width: 44,
      height: 44,
      border: `1px solid ${theme.colors[3]}`,
      borderRadius: '50%',
      marginLeft: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& span': {
        position: 'relative',
        zIndex: 1,
        width: 4,
        height: 4,
        backgroundColor: theme.colors[3],
        borderRadius: '50%',
        '&::before, &::after': {
          content: '""',
          position: 'absolute',
          zIndex: 1,
          width: 4,
          height: 4,
          backgroundColor: theme.colors[3],
          borderRadius: '50%',
        },
        '&::before': {
          left: -6,
        },
        '&::after': {
          left: 6,
        },
      },
    },
    sideLeftCmd: {
      position: 'fixed',
      zIndex: 1,
      left: 10,
      top: (props) => `${props.headerHeight + 10}px`,
      width: 35,
      display: 'flex',
      flexDirection: 'column',
      '& $sidebarClose': {
        position: 'relative',
        top: 'auto',
        left: 'auto',
        width: 35,
        height: 35,
        opacity: 0,
        transition: `opacity 0.5s ${theme.easings['power3.out']}`,
        boxShadow: '0px 0px 0px 4px rgba(0, 0, 0, 0.2)',
        '&::before': {
          content: 'none',
        },
        '&.visible': {
          opacity: 1,
          pointerEvents: 'all',
        },
      },
    },
    sideRightCmd: {
      position: 'fixed',
      zIndex: 1,
      right: 10,
      top: (props) => `${props.headerHeight + 10}px`,
      width: 35,
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        '&:not(:first-child)': {
          marginTop: 15,
        },
      },
    },
    zoomCmd: {
      position: 'relative',
      zIndex: 2,
      pointerEvents: 'all',
      backgroundColor: theme.colors[1],
      borderRadius: 20,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      boxShadow: '0px 0px 0px 4px rgba(0, 0, 0, 0.2)',
      '& > *': {
        width: '100%',
        color: theme.colors[3],
        fontFamily: theme.fonts[1],
        fontSize: 18,
        lineHeight: 1,
        padding: [10, 0],
        '&:not(:last-child)': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
        },
      },
    },
    filterCmd: {
      width: 35,
      height: 35,
      backgroundColor: theme.colors[1],
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0px 0px 0px 4px rgba(0, 0, 0, 0.2)',
      position: 'relative',
      zIndex: 1,
      opacity: 0,
      transition: `opacity 0.5s ${theme.easings['power3.out']}`,
      '&.visible': {
        opacity: 1,
        pointerEvents: 'all',
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        width: 15,
        height: 15,
        borderRadius: '50%',
        backgroundColor: theme.colors[3],
        right: -5,
        top: -5,
        opacity: 0,
        transition: `opacity 0.5s ${theme.easings['power3.out']}`,
      },
      '& svg': {
        width: 15,
        height: 15,
        fill: theme.colors[3],
      },
      '&.notificationEmotion': {
        '&::before': {
          opacity: 1,
          backgroundColor: theme.colors[3],
        },
      },
      '&.notificationSport': {
        '&::before': {
          opacity: 1,
          backgroundColor: theme.colors[13],
        },
      },
    },
    filterMobile: {
      position: 'fixed',
      zIndex: 110,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      height: '60%',
      padding: '30px 20px 0 20px',
      backgroundColor: theme.colors[1],
      borderRadius: 20,
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      opacity: 0,
      pointerEvents: 'none',
      transition: `opacity 0.5s ${theme.easings['power3.out']}`,
      '&.visible': {
        opacity: 1,
        pointerEvents: 'all',
      },
      '& .heading': {
        fontSize: 20,
        fontFamily: theme.fonts[1],
        lineHeight: 1,
        color: theme.colors[3],
        padding: '0 10px 20px 20px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
      },
      '& .scroller': {
        flex: 1,
        overflowY: 'auto',
        '& ul': {
          padding: [20, 0],
        },
        '& li': {
          padding: [15, 20],
          backgroundColor: theme.colors[1],
          borderRadius: 10,
          color: theme.colors[3],
          fontSize: 16,
          '&.active': {
            fontFamily: theme.fonts[1],
            backgroundColor: theme.getRgba(theme.colors[3], 0.3),
          },
        },
      },
      '&.disabled': {
        pointerEvents: 'none',
      },
    },
    resultsMobile: {
      position: 'fixed',
      zIndex: 110,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      height: 'auto',
      backgroundColor: theme.colors[1],
      borderRadius: 20,
      display: 'flex',
      flexDirection: 'column',
      opacity: 0,
      pointerEvents: 'none',
      transition: `opacity 0.5s ${theme.easings['power3.out']}`,
      '&.visible': {
        opacity: 1,
        pointerEvents: 'all',
      },
      '& $filterResults': {
        padding: '80px 20px 0 20px',
      },
      '& footer': {
        position: 'relative',
        marginTop: 30,
        borderRadius: 20,
      },
      '& $sidebarRight': {
        right: 'unset',
        bottom: 'unset',
        position: 'relative',
        zIndex: 1,
        width: 'auto',
        height: 'auto',
        minHeight: 200,
        // maxHeight: '60%',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        borderRadius: 0,
        display: 'flex',
        alignItems: 'center',
      },
      '& $legendWrapper': {
        width: '100%',
        marginTop: 40,
        pointerEvents: 'none',
        '& h4': {
          textAlign: 'left',
          fontSize: 24,
          marginBottom: 20,
        },
      },
      '& $values': {
        fontSize: 16,
        marginTop: 10,
      },
      '& $swatchList': {
        flexDirection: 'column',
        '& > *': {
          flexDirection: 'row',
          '&:not(:last-child)': {
            marginRight: 0,
            marginBottom: 20,
          },
        },
      },
      '& $swatch': {
        width: 30,
        height: 30,
      },
      '& $swatchName': {
        marginTop: 0,
        marginLeft: 20,
        fontSize: 16,
      },
    },
  }

  return output
}

export default style
