const countdownStrokeWidth = 4;
const divSize = "20px";
const circleSize = 'var(--size)';
const r = 50;
const style = (theme) => {
  const output = {
    score: {
      position: "absolute",
      left: "50%",
      top: "40%",
      transform: "translate(-50%, -50%)",
      "& h1": {
        fontWeight: "normal",
        fontSize: 'calc(var(--size)*0.46)',
      },
      "& h1": {
        "-webkit-text-stroke-width": "2px",
        "-webkit-text-stroke-color": "#FFFFFF",
        color: "transparent",
        fontWeight: "400",
        fontSize: 'calc(var(--size)*0.46)',
        lineHeight: "506px",
        letterSpacing: "3%",
      },
    },
    dividend: {
      position: "absolute",
      left: "50%",
      top: "75%",
      transform: "translate(-50%, -50%)",
      "& h1": {
        "-webkit-text-stroke-width": "2px",
        "-webkit-text-stroke-color": "#FFFFFF",
        color: "transparent",
        fontWeight: "400",
        fontSize: 'calc(var(--size)*0.18)',
        marginBottom: "-10px",
        lineHeight: "212",
        letterSpacing: 0,
      },
    },
    jsCountdownProgress: {},
    div: {
      display: "block",
      margin: "0 auto",
      width: "100%",
    },
    circleWrapper: {},
    svg: {
      position: "absolute",
      left: "50%",
      top: "50%",
      overflow: "visible !important",
      "& circle": {
        transform: "rotate(-90deg)",
        width: "auto",
        height: "auto",
      },
    },
    countdownBackground: {
      fill: "none",
      stroke: (props) => props.color,
      strokeOpacity: "30%",
      strokeWidth: countdownStrokeWidth,
    },
    countdownProgress: {
      fill: "none",
      stroke: (props) => props.color,
      strokeWidth: countdownStrokeWidth,
      strokeDasharray: Math.PI * r * 2,
      strokeDashoffset: Math.PI * r * 2,
      strokeLinecap: "round",
      animation: "$dash 3s linear forwards running",
      // visibility:'hidden',
    },

    "@keyframes dash ": {
      from: {
        strokeDashoffset: Math.PI * r * 2,
      },
      to: {
        strokeDashoffset: "var(--svgOffset)",
      },
    },
    parent: {
      position: "relative",
      width: circleSize,
      height: circleSize,
      borderRadius: " 50%",
      overflow: "visible",
    },
  };
  output["@media (max-width: 1440px)"] = {
    parent: {
      width: circleSize,
      height: circleSize,
    },
    countdownProgress: {
      strokeDasharray: Math.PI * r * 2,
      strokeDashoffset: Math.PI * r * 2,
      // visibility:'hidden',
    },
    circleWrapper: {
      width: "400px",
      height: "400px",
    },
    score: {
      "& h1": {
        fontSize: 'calc(var(--size)*0.46)',
        lineHeight: "20px",
        letterSpacing: "3%",
      },
    },
    dividend: {
      "& h1": {
        fontWeight: "400",
        fontSize: 'calc(var(--size)*0.18)',
        lineHeight: "20px",
      },
    },
  };
  output["@media (max-width: 1200px)"] = {
    parent: {
      width: circleSize,
      height: circleSize,
    },
    countdownProgress: {
      strokeDasharray: Math.PI * r * 2,
      strokeDashoffset: Math.PI * r * 2,
      // visibility:'hidden',
    },
    circleWrapper: {
      width: "400px",
      height: "400px",
    },
    score: {
      "& h1": {
        fontSize: 'calc(var(--size)*0.46)',
        lineHeight: "20px",
        letterSpacing: "3%",
      },
    },
    dividend: {
      "& h1": {
        fontWeight: "400",
        fontSize: 'calc(var(--size)*0.18)',
        lineHeight: "20px",
      },
    },
  };
  /*------------------------------
    LG
    ------------------------------*/

  output[theme.mediaInverse.sm] = {
    parent: {
      width:circleSize,
      height: circleSize,
    },
    svg: {
      position: "absolute",
      overflow: "visible !important",
      "& circle": {
        transform: "rotate(-90deg)",
        width: "auto",
        height: "auto",
      },
    },
    score: {
      position: "absolute",
      top: "40%",
      transform: "translate(-50%, -50%)",
      "& h1": {
        fontWeight: "normal",
        fontSize: 'calc(var(--size)*0.46)',
      },
      "& h1": {
        color: "transparent",
        fontWeight: "400",
        fontSize: 'calc(var(--size)*0.46)',
        lineHeight: "80px",
        letterSpacing: "3%",
      },
    },
    dividend: {
      top: "75%",
      "& h1": {
        color: "transparent",
        fontWeight: "400",
        fontSize: 'calc(var(--size)*0.18)',
        marginBottom: "-10px",
        lineHeight: "212",
        letterSpacing: 0,
      },
    },
  };
  return output;
};

export default style;
