import content from "@/reducers/content";
import emotions from "@/reducers/emotions";
import liteEvent from "@/reducers/lite-events";
import events from "@/reducers/events";
import experience from "@/reducers/experience";
import liteExperience from "@/reducers/lite-experience";
import fakerouter from "@/reducers/fakerouter";
import liteFakeRouter from "@/reducers/lite-fakerouter";
import form from "@/reducers/form";
import layer from "@/reducers/layer";
import locale from "@/reducers/locale";
import map from "@/reducers/map";
import nav from "@/reducers/nav";
import options from "@/reducers/options";
import questions from "@/reducers/questions";
import liteQuestions from "@/reducers/lite-questions";
import sports from "@/reducers/sports";
import steps from "@/reducers/steps";
import user from "@/reducers/user";
import upliftProgram from "@/reducers/upliftProgram";

export default {
  content,
  emotions,
  liteEvent,
  events,
  experience,
  liteExperience,
  fakerouter,
  liteFakeRouter,
  form,
  layer,
  locale,
  map,
  nav,
  options,
  liteQuestions,
  questions,
  sports,
  steps,
  user,
  upliftProgram,
};
