import { useEffect, useState, useRef, useContext, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory } from 'react-router-dom'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Canvas } from 'react-three-fiber'
import { Context as GeneralContext } from '@/context'
import Meta from '@/components/Meta'
import RevealText from '@/components/RevealText'
import TooltipOverlay from '@/components/TooltipOverlay'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import usePagePrecache from '@/hooks/usePagePrecache'
import useStore from '@/base/zustand'
import { convertTime } from '@/utils/date'
import * as routerActions from '@/actions/fakerouter'
import * as experienceActions from '@/actions/experience'
import * as contentActions from '@/actions/content'
import { setZeroValue } from '@/utils/math'
import Controls from './controls'
import Main from './three/main'
import style from './style'
import { useResize } from '@/components/Handlers'
import useMediaQuery from '@/hooks/useMediaQuery'

const useStyles = createUseStyles(style)

const Results = () => {
  const {
    active,
    beforeAfterFlag,
    isTooltipOpen,
    tooltipStep,
    tooltipMargin,
    setTooltipOpen,
    setTooltipBounds,
    setTooltipOpacity,
  } = useStore()
  const { setPageAnimationReady, headerHeight } = useContext(GeneralContext)

  const classes = useStyles({ headerHeight })
  const [isDataFetched, setDataFetched] = useState(false)
  const $root = useRef()
  const $totalUpliftRef = useRef()
  const $percentRef = useRef()
  const history = useHistory()
  const [firstTime, setFirsttime] = useState(true)
  const [mediaQueryKey] = useMediaQuery()

  /*------------------------------
  Redux Store
  ------------------------------*/
  const { emotions, experience, step, strings, isLoggedIn, experiences, totalUplift, page, sportSelected, startTime, endTime, city, sports,currentLanguage } = useSelector((state) => ({
    emotions: state.emotions.items,
    experience: state.experience.emotions,
    step: state.experience.step,
    page: state.content['result-page'],
    strings: state.options.strings,
    isLoggedIn: state.user.isLoggedIn,
    experiences: state.user.experiences,
    totalUplift: state.experience.total_uplift,
    sportSelected: state.experience.sport_id,
    startTime: state.experience.startTime,
    endTime: state.experience.endTime,
    city: state.experience.city,
    sports: state.sports.items,
    currentLanguage:state.locale.currentLanguage
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const changeLocation = useCallback((location) => dispatch(routerActions.changeLocation(location)), [dispatch])
  const resetExperience = useCallback(() => dispatch(experienceActions.resetExperience()), [dispatch])
  const saveFullExperience = useCallback(() => dispatch(experienceActions.saveFullExperience()), [dispatch])
  const fetchPage = useCallback((slug, type) => dispatch(contentActions.fetchStrapiContent(slug, type)), [dispatch])

  // useEffect(() => {
  //   let a = page;
  //   debugger;
  // }, [page])

  useEffect(() => {
    if (!page) fetchPage('result-page', 'result-pages')
  }, [])

  useEffect(() => {
    saveFullExperience()
  }, [])

  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (emotions.length > 0) setDataFetched(true)
  }, [emotions])

  /*------------------------------
  Set Scale value
  ------------------------------*/
  useEffect(() => {
    Object.keys(experience).forEach((e) => {
      /*------------------------------
      Origina Pre & Post
      Commentare per debug
      ------------------------------*/
      const { pre, post } = experience[e]
      emotions.find((exp) => exp.key === e).prevScale = pre
      emotions.find((exp) => exp.key === e).postScale = post
      // emotions[index].postScale = post

      /*------------------------------
      Fake Pre e Post Emotions
      per debug
      ------------------------------*/
      // emotions[index].prevScale = Math.random() * 0.5
      // emotions[index].postScale = Math.random() * 0.5 + 0.5
    })
  }, [experience])

  /*------------------------------
  Debug Save Thumb
  ------------------------------*/
  // useEffect(() => {
  //   window.console.log('debug ---->', window.location.search.indexOf('debug') !== -1)
  //   if (window.location.search.indexOf('debug') !== -1) changeLocation()
  // }, [])

  /*------------------------------
  Preload
  ------------------------------*/
  const [load] = usePagePrecache({
    init: isDataFetched,
    sources: [],
    callback: () => setPageAnimationReady(true),
  })

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && <Meta />
  }

  /*------------------------------
  Render Emotion Name
  ------------------------------*/
  const renderEmotionName = () => {
    return load && (
    <div className={classes.active} style={{ fontSize: '9vw' }}>
      {emotions.map((e, i) => {
        const diff = Math.abs((((e.postScale - e.prevScale) / e.prevScale) * 100).toFixed(0))
        const result = e.postScale - e.prevScale
        let sign
        if (result > 0) {
          sign = '+'
        } else if (result < 0) {
          sign = '-'
        } else {
          sign = ''
        }
        return (
          <div>
            <RevealText
              key={`rt1${i.toString()}`}
              tag="span"
              type="lines"
              visible={active === i}
              value={`${e.name}`}
              lineHeight={1.2}
              delay={0}
              style={{ '--color': e.color }}
              className={classes.heroTitle}
            />
            <RevealText
              key={`rt2${i.toString()}`}
              tag="span"
              type="lines"
              visible={active === i && !beforeAfterFlag}
              value={`${sign + diff}%`}
              lineHeight={1.2}
              delay={0}
              style={{ '--color': e.color }}
              className="nextLine"
            />
          </div>
        )
      })}
    </div>
    )
  }
  /*------------------------------
  Render Emotion Name
  ------------------------------*/
  const renderTotalUplift = () => {
    const preScale = emotions.map((a) => Number(a.prevScale.toFixed(2)))
    // const preAvarage = (
    //   (preScaleEmotional.reduce((a, b) => a + b) / preScaleEmotional.length)
    //   + (preScaleCognitive.reduce((a, b) => a + b) / preScaleCognitive.length)
    // ) / 2
    const preAvarage = preScale.reduce((a, b) => a + b) / preScale.length
    // const postScaleEmotional = settings.filter((s) => s.group === 'emotional').map((a) => Number(a.postScale.toFixed(2)))
    // const postScaleCognitive = settings.filter((s) => s.group === 'cognitive').map((a) => Number(a.postScale.toFixed(2)))
    const postScale = emotions.map((a) => Number(a.postScale.toFixed(2)))
    // const postAvarage = (
    //   (postScaleEmotional.reduce((a, b) => a + b) / postScaleEmotional.length)
    //   + (postScaleCognitive.reduce((a, b) => a + b) / postScaleCognitive.length)
    // ) / 2
    const postAvarage = postScale.reduce((a, b) => a + b) / postScale.length

    // const diff = ((postAvarage * 100) - (preAvarage * 100)).toFixed(0)
    const diff = Math.abs((((postAvarage - preAvarage) / preAvarage) * 100).toFixed(0))
    const sign = diff > 0 ? '+' : ''
    return load && (
    // test
    <div
      className={classes.active}
      ref={$totalUpliftRef}
      style={{
        fontSize: '9vw',
        zIndex: isTooltipOpen && tooltipStep === 2 ? 1010 : null,
        pointerEvents: isTooltipOpen && tooltipStep === 2 ? 'none' : null,
      }}
    >
      <div
        className="nextLine"
        ref={$percentRef}
        style={{
          width: mediaQueryKey > 2 ? mediaQueryKey > 4 ? 655 : 461 : null,
          height: mediaQueryKey > 2 ? mediaQueryKey > 4 ? 300 : 213 : null,
        }}
      >
        <RevealText
          key="TotalUplift"
          tag="span"
          type="lines"
          visible={active === -1 && !beforeAfterFlag}
          value={`${sign}${diff}%`}
          lineHeight={1.2}
          delay={firstTime ? 10 : 0}
          style={{ '--color': 'darkblue' }}
          className="nextLine"
        />
      </div>
    </div>
    )
  }
  /*------------------------------
  Handle Click Next on STEP 1
  ------------------------------*/
  const handleClickNext = () => {
    changeLocation('results-save-thumb')
  }

  /*------------------------------
  Handle Click Restart on STEP 2
  ------------------------------*/
  const handleRestartExperience = () => {
    resetExperience()
  }
  setTimeout(() => {
    setFirsttime(false)
  }, 10000)

  /*------------------------------
  Handle Tooltip
  ------------------------------*/
  useEffect(() => {
    if (tooltipStep === 2 && isTooltipOpen) {
      // debugger
      const bounds = $percentRef.current.getBoundingClientRect()
      setTooltipBounds({
        top: `${bounds.bottom + tooltipMargin * 4}px`,
        left: `${mediaQueryKey < 2 ? tooltipMargin : bounds.left - tooltipMargin}px`,
        right: `${bounds.left + tooltipMargin * 4}px`,
        bottom: `${bounds.bottom + tooltipMargin * 3}px`,
      })
    }
  }, [tooltipStep, isTooltipOpen])

  useResize(() => {
    if (isTooltipOpen && tooltipStep === 2 && $percentRef.current) {
      const bounds = $percentRef.current.getBoundingClientRect()
      setTooltipBounds({
        top: `${bounds.bottom + tooltipMargin * 4}px`,
        left: `${mediaQueryKey < 2 ? tooltipMargin : bounds.left - tooltipMargin}px`,
        right: `${bounds.left + tooltipMargin * 4}px`,
        bottom: `${bounds.bottom + tooltipMargin * 3}px`,
      })
    }
  }, [isTooltipOpen, tooltipStep])
  const { hours, minutes, seconds } = convertTime((endTime - startTime), true)
  const foundSports = sports.filter((item) => item.id === sportSelected)
  let sport
  if (foundSports.length > 0) {
    sport = foundSports[0]
  } else {
    sport = ''
  }
  const duration = `${setZeroValue(hours)}:${setZeroValue(minutes)}:${setZeroValue(seconds)}`
  return (
    <>
      <LocomotiveScroll
        init={load}
        className={`page pageResults ${classes.root}`}
        ref={$root}
      >
        {renderHelmet()}
      </LocomotiveScroll>
      {load && (
        page ? (
          <TooltipOverlay page={page} />
        ) : ''
      )}
      {renderEmotionName()}
      {renderTotalUplift()}
      {load && (
        <>
          <Canvas
            colorManagement={false}
            pixelRatio={[1, 2]}
            gl={{ antialias: false }}
            camera={{ fov: 50, position: [0, 0, 10], near: 0.1, far: 100 }}
            onCreated={({ camera }) => camera.lookAt(0, 0, 0)}
            className={classes.canvas}
          >
            <Main settings={emotions} />
          </Canvas>
          {
            page ? (
              <Controls
                className={classes.controls}
                settings={emotions}
                handleClickNext={step === 1 ? handleRestartExperience : handleClickNext}
                step={step}
                duration={duration}
                city={city}
                sport={sport}
                strings={strings}
                page={page}
              />
            ) : ''
          }
        </>
      )}
    </>
  )
}

export default Results
