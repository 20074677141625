import gsap from 'gsap'
import { Color, TextureLoader } from 'three'
import { useMemo, useRef, useEffect } from 'react'
import { useLoader, useFrame, useThree } from 'react-three-fiber'
import useStore from '@/base/zustand'
import useMediaQuery from '@/hooks/useMediaQuery'
import { fragmentShader, vertexShader } from './shaders/sphereShaders'
import Cursors from './cursors'

export const Sphere = ({ settings,emotionPercentages }) => {
  const { camera, gl } = useThree()
  const { beforeAfterFlag, setScales, scales, active, setCursorDown, firstTimeAnimation, setFirstTimeAnimation, debug } = useStore()
  const material = useRef()
  const groupRotation = useRef()
  const groupDrag = useRef()
  const sphere = useRef()
  const wireframe = useRef()
  const isDragging = useRef(false)
  const mouse = useRef({ x: 0, y: 0 })
  const dragSpeed = useRef({ x: 0, y: 0 })
  const texture = useLoader(TextureLoader, '/frontend/static/images/results/brushes-map.jpg')
  const [mediaQueryKey] = useMediaQuery()

  /*------------------------------
  Uniforms
  ------------------------------*/
  const uniforms = settings.map((s) => {
    const { pos, uv, uvMap, color, scale, prevScale, postScale } = s
    return ({
      pos,
      uv,
      uvMap,
      color: new Color(color),
      scale,
      prevScale,
      postScale,
    })
  })

  /*------------------------------
  Shader
  ------------------------------*/
  const shaderArgs = useMemo(() => ({
    uniforms: {
      uAlpha: { value: 1 },
      uNoise: { value: 0 },
      uTime: { value: 0 },
      data: { value: uniforms },
      uTexture: { value: texture },
    },
    transparent: true,
    vertexShader,
    fragmentShader,
  }), [])
  const shaderArgsWireframe = useMemo(() => ({
    uniforms: {
      uAlpha: { value: 0.5 },
      uTime: { value: 0 },
      uNoise: { value: 1 },
      data: { value: uniforms },
      uTexture: { value: texture },
    },
    transparent: true,
    vertexShader,
    fragmentShader,
  }), [])

  /*------------------------------
  Scale Spikes
  ------------------------------*/
  useEffect(() => {
    if (firstTimeAnimation) {
      gsap.killTweensOf(groupRotation.current.rotation)
      gsap.fromTo(groupRotation.current.scale, {
        x: window.innerWidth > 992 ? 0.5 : 0.25,
        y: window.innerWidth > 992 ? 0.5 : 0.25,
        z: window.innerWidth > 992 ? 0.5 : 0.25,
      }, {
        x: window.innerWidth > 992 ? 1 : 0.5,
        y: window.innerWidth > 992 ? 1 : 0.5,
        z: window.innerWidth > 992 ? 1 : 0.5,
        duration: 10 * debug,
        delay: 2,
        ease: 'power3.inOut',
      })
      gsap.to(groupRotation.current.rotation, {
        y: Math.PI * 10,
        x: Math.PI * 2,
        modifiers: {
          y: (y) => y % (Math.PI * 2),
          x: (x) => x % (Math.PI * 2),
        },
        duration: 11 * debug,
        delay: 2,
        ease: 'power3.inOut',
      })
      setTimeout(() => {
        setFirstTimeAnimation(false)
      }, 10500 * debug)
    }
    setTimeout(() => {
      const newScales = uniforms.map((u) => (
        // beforeAfterFlag ? Math.random() * 2 : 0
        beforeAfterFlag ? u.prevScale * 2 : u.postScale * 2
      ))
      setScales(newScales)
    }, firstTimeAnimation ? 7500 * debug : 0)
  }, [beforeAfterFlag, firstTimeAnimation])

  /*------------------------------
  Use Frame
  ------------------------------*/
  useFrame(({ clock }) => {
    material.current.uniforms.data.value.forEach((u, ind) => {
      // Se siamo nello zoom, sposto tutti i colori all'index
      // E metto tutti gli spikes a 0, tranne all'attivo
      if (active !== ind && active !== -1) {
        u.color = new Color(settings[active].color)
      } else {
        u.color = new Color(settings[ind].color)
      }
    })

    // Time wireframe
    material.current.uniforms.uTime.value = clock.getElapsedTime()

    if (firstTimeAnimation) return

    if (isDragging.current) {
      // Dragging
      gsap.killTweensOf(groupRotation.current.rotation)
      gsap.to(groupDrag.current.rotation, {
        y: `+=${(dragSpeed.current.x * 0.005)}`,
        x: `+=${(dragSpeed.current.y * 0.005)}`,
        modifiers: {
          x: (x) => x % (Math.PI * 2),
          y: (y) => y % (Math.PI * 2),
        },
        duration: 2,
        ease: 'power3.out',
      })
    } else if (active !== -1) {
      // Attivo
      gsap.killTweensOf(groupRotation.current.rotation)
      gsap.to(groupDrag.current.rotation, {
        y: settings[active].rot.y,
        x: settings[active].rot.x,
        duration: 4,
        ease: 'power3.out',
      })
      gsap.to(groupRotation.current.rotation, {
        y: 0,
      })
    } else {
      // Rotazione automatica
      gsap.to(groupRotation.current.rotation, {
        y: '+=.02',
        modifiers: {
          y: (y) => y % (Math.PI * 2),
        },
        delay: 1.3,
      })
    }
  })

  /*------------------------------
  Gsap Uniforms
  ------------------------------*/
  useEffect(() => {
    material.current.uniforms.data.value.forEach((u, ind) => {
      gsap.to(u, {
        scale: active === -1 || active === ind ? scales[ind] : 0, // <= Se active !== -1
        duration: 3,
        ease: 'power3.out',
        delay: active === -1 ? ind * 0.2 : 0,
      })
    })
  }, [scales, active])

  /*------------------------------
  Camera Zoom
  ------------------------------*/
  useEffect(() => {
    gsap.killTweensOf(camera.position)
    gsap.to(camera.position, {
      z: active !== -1 ? 5 : 8.5,
      // x: active !== -1 ? 1 : 0.5,
      y: active !== -1 ? 0.25 : 0,
      duration: 1.2,
      ease: 'power3.out',
    })
  }, [active])

  /*------------------------------
  Binding Drag
  ------------------------------*/
  const handleMouseDown = (e) => {
    if (e.target.closest('.dg') !== null || e.target.closest('.controls') !== null || e.target.closest('.button') !== null) return
    isDragging.current = true
    setCursorDown(true)
    const x = e.clientX || e.touches[0].clientX
    const y = e.clientY || e.touches[0].clientY
    mouse.current.x = x
    mouse.current.y = y
    dragSpeed.current.x = 0
    dragSpeed.current.y = 0
  }
  const handleMouseUp = () => {
    isDragging.current = false
    setCursorDown(false)
  }
  const handleMouseMove = (e) => {
    if (!isDragging.current) return
    const x = e.clientX || e.touches[0].clientX
    const y = e.clientY || e.touches[0].clientY
    dragSpeed.current.x = x - mouse.current.x
    dragSpeed.current.y = y - mouse.current.y
  }
  useEffect(() => {
    const canvas = gl.domElement.parentElement
    canvas.addEventListener('mousedown', handleMouseDown)
    canvas.addEventListener('touchstart', handleMouseDown)
    canvas.addEventListener('mouseup', handleMouseUp)
    canvas.addEventListener('touchend', handleMouseUp)
    canvas.addEventListener('mouseleave', handleMouseUp)
    canvas.addEventListener('touchcancel', handleMouseUp)
    canvas.addEventListener('mousemove', handleMouseMove)
    canvas.addEventListener('touchmove', handleMouseMove)
    canvas.addEventListener('contextmenu', handleMouseUp)

    return () => {
      canvas.removeEventListener('mousedown', handleMouseDown)
      canvas.removeEventListener('touchstart', handleMouseDown)
      canvas.removeEventListener('mouseup', handleMouseUp)
      canvas.removeEventListener('touchend', handleMouseUp)
      canvas.removeEventListener('mouseleave', handleMouseUp)
      canvas.removeEventListener('touchcancel', handleMouseUp)
      canvas.removeEventListener('mousemove', handleMouseMove)
      canvas.removeEventListener('touchmove', handleMouseMove)
      canvas.removeEventListener('contextmenu', handleMouseUp)
    }
  }, [])

  return (
    <group ref={groupRotation} position-y={0.2}>
      <group ref={groupDrag}>
        <mesh ref={sphere}>
          <icosahedronBufferGeometry
            attach="geometry"
            args={[1, 20]}
          />
          <shaderMaterial
            ref={material}
            attach="material"
            args={[shaderArgs]}
          />
        </mesh>

        <mesh ref={wireframe}>
          <icosahedronBufferGeometry
            attach="geometry"
            args={[1.5, 8]}
          />
          <shaderMaterial
            ref={material}
            attach="material"
            args={[shaderArgsWireframe]}
            wireframe={true}
          />
        </mesh>
        {mediaQueryKey > 2 && <Cursors emotionPercentages={emotionPercentages} settings={settings} />}
      </group>
    </group>
  )
}
