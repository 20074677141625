import { useRef, useState, useEffect, useContext, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import queryString from 'query-string'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { Context as GeneralContext } from '@/context'
import { Context as AudioContext } from '@/context/audio'
import Meta from '@/components/Meta'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import RevealText from '@/components/RevealText'
import RevealFadeIn from '@/components/RevealFadeIn'
import usePagePrecache from '@/hooks/usePagePrecache'
import * as routerActions from '@/actions/fakerouter'
import * as experienceActions from '@/actions/experience'
import * as contentActions from '@/actions/content'
import useStore from '@/base/zustand'
import { convertTime } from '@/utils/date'
import { setMinMaxValue, setZeroValue } from '@/utils/math'
import { convertRichText } from '@/utils/strings'
import style from './style'

const useStyles = createUseStyles(style)

const ConfirmTimer = () => {
  //const { finalTime, setFinalTime } = useStore()
  const { setPageAnimationReady, pageAnimationReady, headerHeight, setHeaderExtraDark } = useContext(GeneralContext)
  const { isAudioControlsVisible, setAudioActive, setAudioControlsVisible, setFirstTimeAudio, isFirstTimeAudio } = useContext(AudioContext)
  const classes = useStyles({ headerHeight })
  const location = useLocation()
  const [isDataFetched, setDataFetched] = useState(false)
  const [isReturningVisitor, setReturningVistor] = useState(false)
  const $root = useRef()
  const [h, setH] = useState('00')
  const [m, setM] = useState('00')
  const [s, setS] = useState('00')
  
  /*------------------------------
  Redux Store
  ------------------------------*/
  const { page, fakeLocation, strings, endTime, startTime, experience,currentLanguage } = useSelector((state) => ({
    page: state.content['welcome-back-page'] || {},
    fakeLocation: state.fakerouter.location,
    strings: state.options.strings,
    endTime: state.experience.endTime,
    startTime: state.experience.startTime,
    experience: state.experience,
    currentLanguage:state.locale.currentLanguage
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const moveForward = useCallback(() => dispatch(routerActions.moveForward()), [dispatch])
  const setStep = useCallback((num) => dispatch(experienceActions.setStep(num)), [dispatch])
  //const setTime = useCallback((value) => dispatch(experienceActions.setTime(value)), [dispatch])
  const fetchContent = useCallback((slug) => dispatch(contentActions.fetchStrapiContent(slug,'welcome-back-pages')), [dispatch])
  const setEndTime = useCallback((time) => dispatch(experienceActions.setEndTime(time)), [dispatch])
  const setStartTime = useCallback((time) => dispatch(experienceActions.setStartTime(time)), [dispatch])

  useEffect(() => {
    localStorage.removeItem('isTimerVisited')
    if(startTime===0){
      const sTime = experience.hasOwnProperty('created_at') ? experience.created_at : '';
      if(sTime){
        setStartTime(new Date(experience.created_at).getTime())
      }else{
        setStartTime(new Date().getTime())
      }
    }
    setEndTime(new Date().getTime());
  }, [])

  useEffect(() => {
    if(startTime > 0 && endTime > 0){
      localStorage.setItem('expState', JSON.stringify({ experience: experience}));   // to set start time to exp
      //setExperience(JSON.parse(localStorage.getItem('expState')))
    }

    const { hours, minutes, seconds } = convertTime((endTime-startTime), true)
    setH(setZeroValue(hours))
    setM(setZeroValue(minutes))
    setS(setZeroValue(seconds))
  }, [startTime,endTime])
  
  /*------------------------------
  Fetch Content
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length === 0) fetchContent('welcome-back-page')
    if (!isAudioControlsVisible) setAudioControlsVisible(true)
    const q = queryString.parse(location.search)
    if (q.complete) setReturningVistor(true)
  }, [])
  useEffect(() => {
    if (Object.keys(page).length > 0 )
      fetchContent('welcome-back-page');
  }, [currentLanguage])
  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0) setDataFetched(true)
  }, [page])

  /*------------------------------
  Preload
  ------------------------------*/
  const [load] = usePagePrecache({
    init: isDataFetched,
    sources: [],
    callback: () => setPageAnimationReady(true),
  })

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && <Meta />
  }

  /*------------------------------
  Header Inverse
  ------------------------------*/
  useEffect(() => {
    setHeaderExtraDark(true)
    return () => setHeaderExtraDark(false)
  }, [])

  /*------------------------------
  Handle Click Next
  ------------------------------*/
  const handleClickNext = () => {
    setStep(2)
    //setFinalTime({ h, m, s })
    const time = parseInt((h * 60 * 60), 10) + parseInt((m * 60), 10) + parseInt(s, 10)
    //setTime(time < 1200 ? 1200 : time)
    //setTime(parseInt((endTime-startTime)/1000))
    setEndTime(startTime+(time*1000))
    moveForward()
    if (isFirstTimeAudio && isReturningVisitor) {
      setFirstTimeAudio(false)
      setAudioActive(true)
    }
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return load && (
      <div className={classes.page}>
        <div className={classes.hero}>
          <div className={classes.wrapper}>
            <RevealText
              tag="h1"
              type="lines"
              value={page.typographyHeading}
              visible={pageAnimationReady}
              lineHeight={1}
              delay={0.5}
              className={classes.title}
            />
            <RevealText
              tag="div"
              type="lines"
              value={convertRichText(page.bodyCopy)}
              visible={pageAnimationReady}
              lineHeight={1.3}
              delay={0}
              className={classes.text}
            />
            <RevealFadeIn
              delay={1}
              inview={pageAnimationReady}
            >
              <div className={classes.timer}>
                <div>
                  <input
                    type="number"
                    min="0"
                    max="99"
                    value={h}
                    onChange={(e) => { setH(setMinMaxValue(e)) }}
                    onBlur={(e) => { setH(setMinMaxValue(e)) }}
                  />
                  <span>{page.hours}</span>
                </div>
                <strong>:</strong>
                <div>
                  <input
                    type="number"
                    min="0"
                    max="59"
                    value={m}
                    onChange={(e) => { setM(setMinMaxValue(e)) }}
                    onBlur={(e) => { setM(setMinMaxValue(e)) }}
                  />
                  <span>{page.minutes}</span>
                </div>
                <strong>:</strong>
                <div>
                  <input
                    type="number"
                    min="0"
                    max="59"
                    value={s}
                    onChange={(e) => { setS(setMinMaxValue(e)) }}
                    onBlur={(e) => { setS(setMinMaxValue(e)) }}
                  />
                  <span>{page.seconds}</span>
                </div>
              </div>
            </RevealFadeIn>

            <div className={classNames({
              [classes.buttons]: true,
              //disabled: parseInt((h * 60 * 60), 10) + parseInt((m * 60), 10) + parseInt(s, 10) < 1200,
            })}
            >
              <RevealFadeIn
                delay={2}
                inview={pageAnimationReady}
              >
                <button
                  onClick={handleClickNext}
                  className={classNames({
                    [classes.btn]: true,
                    noStroke: true,
                  })}
                >
                  <span>{page.continue || strings['general.continue']}</span>
                </button>
                <div className={classes.disabledHint}>{strings['hint.timer']}</div>
              </RevealFadeIn>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <LocomotiveScroll
        init={load}
        className={`page pageConfirmTimer ${classes.root}`}
        ref={$root}
      >
        {renderHelmet()}
        {renderContent()}
      </LocomotiveScroll>
    </>
  )
}

export default ConfirmTimer
