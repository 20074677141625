import { useEffect, useCallback, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import FakeRouter from "@/components/FakeRouter";
import LiteBegin from "@/pages/LiteExperience/LiteBegin";
import LiteChooseEvent from "@/pages/LiteExperience/LiteChooseEvent";
import LiteQuestions from "@/pages/LiteExperience/LiteQuestions";
import LiteEventStateOfMind from "@/pages/LiteExperience/LiteEventStateOfMind";
//import Steps from '@/pages/Experience/Steps'
import { history } from "@/base/store";
import CountdownFaceScan from "@/pages/Experience/CountdownFaceScan";
import FaceScanCamera from "@/pages/Experience/FaceScanCamera";
import CountdownQuestions from "@/pages/Experience/CountdownQuestions";
import Questions from "@/pages/Experience/Questions";
import ChooseAgeGender from "@/pages/Experience/ChooseAgeGender";
import ChooseSport from "@/pages/Experience/ChooseSport";
import Timer from "@/pages/Experience/Timer";
import ConfirmTimer from "@/pages/Experience/ConfirmTimer";
import Results from "@/pages/Experience/Results";
import ResultsSaveThumb from "@/pages/Experience/ResultsSaveThumb";
import SharePage from "@/pages/Experience/SharePage";
import FacialScanDenied from "@/pages/Experience/FacialScanDenied";
import Debug from "@/pages/Experience/Debug";
import Blank from "@/pages/Experience/Blank";
import EmailModal from "@/components/EmailModal";
import RestartModal from "@/components/RestartModal";
import GenericRestartModal from "@/components/GenericRestartModal";
import usePagePrecache from "@/hooks/usePagePrecache";
import useStore from "@/base/zustand";
import * as routerActions from "@/actions/fakerouter";
import * as eventsActions from "@/actions/lite-events";
import * as experienceActions from "@/actions/experience";
import { getYYYYMMDD, daysBetween } from "@/utils/date";
import LiteResults from "./LiteResults";
import LiteEnd from "./LiteEnd";

const LiteExperience = () => {
  const { setFirstTimeAnimation, setScales, setActive } = useStore();
  const location = useLocation();

  /*------------------------------
  Redux Store
  ------------------------------*/
  const {
    lastNotCompletedUUID,
    fakeLocation,
    isStartingExperience,
    currentLanguage,
    currentTag,
  } = useSelector(
    (state) => ({
      fakeLocation: state.liteFakeRouter.location,
      isStartingExperience: state.experience.isStartingExperience,
      currentLanguage: state.locale.currentLanguage,
      currentTag: state.events.currentTag,
      lastNotCompletedUUID: state.user.lastNotCompletedUUID,
    }),
    shallowEqual
  );

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch();
  const changeLocation = useCallback(
    (slug) => dispatch(routerActions.changeLocation(slug)),
    [dispatch]
  );
  const fetchEvents = useCallback(
    (date, tag) => dispatch(eventsActions.fetchLiteEvents(date, tag)),
    [dispatch]
  );
  const fetchExperience = useCallback(
    (uuid) => dispatch(experienceActions.fetchExperience(uuid)),
    [dispatch]
  );

  /*------------------------------
  Manage Query String
  ------------------------------*/
  const manageQS = (qs) => {
    if (qs.slug) changeLocation(qs.slug); // Debug Purpose [http://URL?slug=something]
    if (qs.share) {
      // [http://URL?share=8f9b92ac-9d33-11eb-a8b3-0242ac130003]
      fetchExperience(qs.share);
      changeLocation("results");
    }
  };

  /*------------------------------
  Preload
  ------------------------------*/
  const [realLoad, setRealLoad] = useState(false);
  const [load] = usePagePrecache({
    // eslint-disable-line
    init: true,
    sources: [],
    callback: () => setRealLoad(true),
  });

  useEffect(() => {
    if (realLoad) {
      // Fetch all Daily Events
      const today = getYYYYMMDD(new Date());
      fetchEvents(today, `country_all,${currentTag}`);
    }
  }, [realLoad, currentTag]);

  /*------------------------------
  Fetch Daily Events, Sports & Manage QS
  ------------------------------*/
  useEffect(() => {
    if (realLoad) {
      // Set current experience location from Query String
      const q = queryString.parse(location.search);
      manageQS(q);
    }
  }, [realLoad]);

  /*------------------------------
  Reset Zustand Results if Starting Experience
  ------------------------------*/
  useEffect(() => {
    if (isStartingExperience) {
      setFirstTimeAnimation(true);
      setScales([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
      setActive(-1);
    }
  }, [isStartingExperience]);

  return (
    currentLanguage && (
      <>
        <FakeRouter location={fakeLocation}>
          <LiteBegin key="lite-begin" />
          <LiteChooseEvent key="lite-choose-event" />
          <LiteQuestions key="lite-questions" />
          <LiteResults key="lite-result" />
          <LiteEnd key="lite-end" />
          <LiteEventStateOfMind key="lite-event-state-of-mind" />
          {/*<Steps key="steps" />*/}
          {/* <LiteQuestions key="lite-questions" />
        <LiteResults key="lite-results" />
         */}
        </FakeRouter>
      </>
    )
  );
};

export default LiteExperience;
