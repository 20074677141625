import {
  LITE_FAKEROUTER_CHANGE_LOCATION,
  LITE_FAKEROUTER_MOVE_FORWARD,
  LITE_FAKEROUTER_MOVE_BACKWARD,
} from '@/actions/types'

const changeLocation = (slug) => (dispatch, getState) => {
  dispatch({
    type: LITE_FAKEROUTER_CHANGE_LOCATION,
    payload: slug,
  })
}

const moveForward = () => (dispatch, getState) => {
  dispatch({ type: LITE_FAKEROUTER_MOVE_FORWARD })
} 

const moveBackward = () => (dispatch, getState) => {
  dispatch({ type: LITE_FAKEROUTER_MOVE_BACKWARD })
}

export {
  changeLocation,
  moveForward,
  moveBackward,
}
