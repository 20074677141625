import axios from 'axios'
import { REQUEST_LOCALES, RECEIVE_LOCALES, SET_LANGUAGE } from '@/actions/types'
import { API, CONTENTFUL_SPACE, CONTENTFUL_TOKEN } from '@/constants'
import {getLang} from '@/utils/locale'
import {axiosInstance} from '@/base/axios'

const requestLocales = () => ({ type: REQUEST_LOCALES })

const receiveLocales = (data) => ({
  type: RECEIVE_LOCALES,
  payload: data,
})


const fetchStrapiLocales = () => {
  return async (dispatch) => {
    dispatch(requestLocales())
    const response = await axiosInstance.get(
      `languages`
    );
    
    const locales = response.data.data.map((it) => it.attributes).filter((it)=>it.isActive);
    let schema = {}
    locales.forEach((el)=>{
      schema[el.Code] = { title: el.Name, code: el.Code, mapCode: el.Code }
    });
    dispatch(receiveLocales(schema))
  }
}

const fetchExistentLanguage = () => {
  const currentLanguage = localStorage.getItem('language') || getLang(navigator.language) || null
  return ({
    type: SET_LANGUAGE,
    payload: currentLanguage,
  })
}

const setLanguage = (code) => {
  localStorage.setItem('language', code)
  return ({
    type: SET_LANGUAGE,
    payload: code,
  })
}

export {
  fetchExistentLanguage,
  setLanguage,
  fetchStrapiLocales
}
