import {
  RECEIVE_PROFILE,
  RECEIVE_LOGIN,
  RECEIVE_REGISTER,
  RECEIVE_IS_NOT_LOGGED_IN,
  RECEIVE_LOGOUT,
  RECEIVE_EMAIL_RESET,
  RECEIVE_PASSWORD_RESET,
  RECEIVE_PREFERENCES,
  SET_POSITION,
} from '@/actions/types'
import { API } from '@/constants'
import { resetExperience } from '@/actions/experience'
import { getPromiseFromFetch } from '@/utils/form'

const receiveProfile = (data) => ({
  type: RECEIVE_PROFILE,
  payload: data,
})

// const fetchProfile = () => async (dispatch, getState) => {

//   let token = getUserToken(getState())

//   const request = await getPromiseFromFetch({
//     method: 'get',
//     params: { headers: { Authorization: `Bearer ${token}` } },
//     url: API.PROFILE,
//   })
//   // const request = {
//   //   status: 200,
//   //   data: {
//   //     experiences: [],
//   //     profile: {
//   //       city_id: 1,
//   //       country_id: 180,
//   //       created_at: '2021-04-22T10:11:28.000000Z',
//   //       email: 'fede@studiogusto.com',
//   //       email_verified_at: '2021-04-22T10:12:02.000000Z',
//   //       experiences: [],
//   //       id: 2,
//   //       marketing: true,
//   //       nickname: 'federico',
//   //       region_id: 1,
//   //       updated_at: '2021-04-22T10:14:47.000000Z',
//   //       wmm_consent: true,
//   //     },
//   //     token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMmEyYzI3NjRmYTAyYzQ2ODNlN2RiM2ZiYWNmNjNmOWVmMWQ3YTlkNWYwOTA1ZjAyMWUxNWU4YWI5ZjFkMzA5NTFkZDA5MjQzMjllYWE1ZGEiLCJpYXQiOjE2MTg5MTY3NDkuNjM2NTM1LCJuYmYiOjE2MTg5MTY3NDkuNjM2NTM2LCJleHAiOjE2NTA0NTI3NDkuNjMxODc4LCJzdWIiOiI1Iiwic2NvcGVzIjpbXX0.fN_rBcOsgfNm-k9_A1gWkBQHB2VqdC-xYAA3djYeIlzN6UAnqFTu9oDFb6XI_42Bw17EWEf7BaMbwiN5KepltL4_s_3UUvjFzRAgIG1N6V8BF-1DHV4If3dNSaCNYXFIiBdmgTbkjw5lTGtaIMjBQwArgSdnHMJz961UCBo5Bk62pHSSElBo_4NxfIdd0W3eiiZv6962OS4QsBiyBCxir7NE1qdVAlKoX41lBPQMP0llTdPr86XJ9yF5cw4vYdUPmkXLx7ThR17BVNYek7WqzkygdYZmXajp0VKV6TcHhGaCdy8QrdLRl4ezNW24wYlFebFirvd8zsthgpYlvmOw3S4lb0_HAp4KtzQEiB-4Akl6s_fwy8ud2RMGx9DpOeYS8_A5SEkrpixi_7GzetKbO1H2pM0blC3UIThSQ9pstM73rJKqwRZ_r_GWhLwPQKeq2noRTQcP8qzWOkfqfgs1H9HreFEMHNEg6KZZeGsHLx6lfCWaGOuszXVCvc8oUH6DAGORrQ1tmTqtkMwqdcwtp0ev88h1GqAedpvH9VXSBIWX55Tlmf7fMUQBLI2DxszEY6_KZBI4WF2n7z59bkOrkQPH_ErWJOWljgoQZ3tBemscrk7PUDr-RmRZ-99pzN4KHK25gyvSZsJ1ll2Fq6AOuDQ9BUO6KZVDhLR_GY3FLw4',
//   //   },
//   // }

//   if (request.status === 200) {
//     await dispatch(receiveLogin(request.data))
//     //await dispatch(receiveProfile(request.data))
//   }
//   return request
// }

const fetchProfile = () => async (dispatch, getState) => {

  let token = getUserToken(getState())

  const request = await getPromiseFromFetch({
    method: 'get',
    params: { headers: { Authorization: `Bearer ${token}` } },
    url: API.PROFILE,
  })

  if (request.status === 200) {
    await dispatch(receiveProfile(request.data))
  }
}

const receiveLogin = (data) => ({
  type: RECEIVE_LOGIN,
  payload: data,
})

const fetchLogin = (data) => async (dispatch, getState) => {
  const request = await getPromiseFromFetch({
    method: 'post',
    params: {
      //...data,
      code: data,
      uuid: getState().experience.uuid,
      ...(getState().experience.image && { uuid: getState().experience.uuid }),
    },
    url: API.OAUTH_LOGIN,
  })

  // const request = {
  //   status: 200,
  //   data: {
  //     experiences: [],
  //     profile: {
  //       city_id: 1,
  //       country_id: 180,
  //       created_at: '2021-04-22T10:11:28.000000Z',
  //       email: 'fede@studiogusto.com',
  //       email_verified_at: '2021-04-22T10:12:02.000000Z',
  //       experiences: [],
  //       id: 2,
  //       marketing: true,
  //       nickname: 'federico',
  //       region_id: 1,
  //       updated_at: '2021-04-22T10:14:47.000000Z',
  //       wmm_consent: true,
  //     },
  //     token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMmEyYzI3NjRmYTAyYzQ2ODNlN2RiM2ZiYWNmNjNmOWVmMWQ3YTlkNWYwOTA1ZjAyMWUxNWU4YWI5ZjFkMzA5NTFkZDA5MjQzMjllYWE1ZGEiLCJpYXQiOjE2MTg5MTY3NDkuNjM2NTM1LCJuYmYiOjE2MTg5MTY3NDkuNjM2NTM2LCJleHAiOjE2NTA0NTI3NDkuNjMxODc4LCJzdWIiOiI1Iiwic2NvcGVzIjpbXX0.fN_rBcOsgfNm-k9_A1gWkBQHB2VqdC-xYAA3djYeIlzN6UAnqFTu9oDFb6XI_42Bw17EWEf7BaMbwiN5KepltL4_s_3UUvjFzRAgIG1N6V8BF-1DHV4If3dNSaCNYXFIiBdmgTbkjw5lTGtaIMjBQwArgSdnHMJz961UCBo5Bk62pHSSElBo_4NxfIdd0W3eiiZv6962OS4QsBiyBCxir7NE1qdVAlKoX41lBPQMP0llTdPr86XJ9yF5cw4vYdUPmkXLx7ThR17BVNYek7WqzkygdYZmXajp0VKV6TcHhGaCdy8QrdLRl4ezNW24wYlFebFirvd8zsthgpYlvmOw3S4lb0_HAp4KtzQEiB-4Akl6s_fwy8ud2RMGx9DpOeYS8_A5SEkrpixi_7GzetKbO1H2pM0blC3UIThSQ9pstM73rJKqwRZ_r_GWhLwPQKeq2noRTQcP8qzWOkfqfgs1H9HreFEMHNEg6KZZeGsHLx6lfCWaGOuszXVCvc8oUH6DAGORrQ1tmTqtkMwqdcwtp0ev88h1GqAedpvH9VXSBIWX55Tlmf7fMUQBLI2DxszEY6_KZBI4WF2n7z59bkOrkQPH_ErWJOWljgoQZ3tBemscrk7PUDr-RmRZ-99pzN4KHK25gyvSZsJ1ll2Fq6AOuDQ9BUO6KZVDhLR_GY3FLw4',
  //   },
  // }
  if (request.status === 200) { 
    await dispatch(receiveLogin(request.data))
    //await dispatch(resetExperience())

    const state = JSON.stringify( {isLoggedIn: true, token: request.data.token } );
    localStorage.setItem('loginState', state);
  }

  return request
}

const receiveRegister = (data) => ({
  type: RECEIVE_REGISTER,
  payload: data,
})

const fetchRegister = (data) => async (dispatch, getState) => {
  const request = await getPromiseFromFetch({
    method: 'post',
    params: {
      ...data,
      password_confirmation: data.password,
      wmm_consent: true,
      ...(getState().experience.image && { uuid: getState().experience.uuid }),
    },
    url: API.REGISTER,
  })
  // const request = {
  //   status: 200,
  //   data: {
  //     city_id: 1,
  //     country_id: 180,
  //     created_at: '2021-04-22T10:43:51.000000Z',
  //     email: 'fsdvd@sduvf.com',
  //     id: 4,
  //     marketing: false,
  //     nickname: 'njknvjdfknvdf',
  //     region_id: 1,
  //     updated_at: '2021-04-22T10:43:51.000000Z',
  //     wmm_consent: false,
  //   },
  // }
  if (request.status === 200) {
    await dispatch(receiveRegister(request.data))
    await dispatch(resetExperience())
  }
  return request
}

const receiveIsNotLoggedIn = () => ({ type: RECEIVE_IS_NOT_LOGGED_IN })

const receiveLogout = () => ({ type: RECEIVE_LOGOUT })

const fetchLogout = () => async (dispatch, getState) => {

  let token = getUserToken(getState())

  const request = await getPromiseFromFetch({
    method: 'post',
    config: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
    url: API.LOGOUT,
  })
  // const request = {
  //   status: 200,
  //   data: {
  //     message: 'You have been successfully logged out!',
  //   },
  // }
  if (request.status === 200) {
    localStorage.removeItem('loginState')
    localStorage.removeItem('isTimerVisited')

    await dispatch(receiveIsNotLoggedIn())
    await setTimeout(() => {
      dispatch(receiveLogout(request.data))
    }, 500)

    //await dispatch(resetExperience())
  }
  return request
}

const receiveEmailReset = (data) => ({
  type: RECEIVE_EMAIL_RESET,
  payload: data,
})

const fetchEmailReset = (data) => async (dispatch) => {
  const request = await getPromiseFromFetch({
    method: 'post',
    params: {
      ...data,
    },
    url: API.EMAIL_RESET,
  })
  // const request = {
  //   status: 200,
  //   data: {
  //     message: 'Check you email for reset link!',
  //   },
  // }
  if (request.status === 200) await dispatch(receiveEmailReset(request.data))
  return request
}

const receivePasswordReset = (data) => ({
  type: RECEIVE_PASSWORD_RESET,
  payload: data,
})

const fetchPasswordReset = (data) => async (dispatch) => {
  const request = await getPromiseFromFetch({
    method: 'post',
    params: {
      ...data,
      password_confirmation: data.password,
    },
    url: API.PASSWORD_RESET,
  })
  // const request = {
  //   status: 200,
  //   data: {
  //     message: 'You have been successfully reset your password!',
  //   },
  // }
  if (request.status === 200) await dispatch(receivePasswordReset(request.data))
  return request
}

const receivePreferences = (data) => ({
  type: RECEIVE_PREFERENCES,
  payload: data,
})

const fetchPreferences = (data) => async (dispatch, getState) => {

  let token = getUserToken(getState())

  const request = await getPromiseFromFetch({
    method: 'post',
    config: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
    params: {
      ...data,
      wmm_consent: true,
    },
    url: API.SAVE_USER_PREFERENCES,
  })
  // const request = {
  //   status: 200,
  //   data: {
  //     message: 'Data did saved',
  //   },
  // }
  if (request.status === 200) await dispatch(receivePreferences(data))
  return request
}

const setPosition = (data) => ({
  type: SET_POSITION,
  payload: data,
})

const getUserToken = (state) => { 
  let token = ''
  if(state.user.token){
    token = state.user.token
  } else {
    const state = JSON.parse(localStorage.getItem('loginState'))
    if(state && state.token) {
      token = state.token
    }
  }

  return token;
}

export {
  fetchProfile,
  fetchLogin,
  fetchLogout,
  fetchRegister,
  fetchEmailReset,
  fetchPreferences,
  fetchPasswordReset,
  setPosition,
}