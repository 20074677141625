const style = (theme) => {
  const output = {
    "@global": {
      body: {
        background: theme.colors[3],
      },
    },
    root: {
      color: theme.colors[1],
    },
    page: {
      minHeight: (props) => `calc(var(--vh) - ${props.headerHeight}px)`,
      overflow: "hidden",
      background: "#3D50BC!important",
    },
    wrapper: {
      extend: theme.wrapper,
      position: "relative",
      zIndex: 1,
      display : 'flex',
      justifyContent : 'start',
      paddingTop : 'calc(var(--height)*0.067px)',
    },
    btn: {
      extend: theme.button,
    },
    downArrow: {
      position: "absolute",
      bottom: 40,
      width: "100%",
      textAlign: "center",
    },

    roundBtn: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "& $circle": {
        width: 50,
        height: 50,
        margin: [10, 10],
        borderRadius: "50%",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        background: theme.colors[1],
        border: `1px solid ${theme.colors[1]}`,
        cursor: "pointer",
        transition: `all .3s ${theme.easings["power3.out"]}`,
      },
      "& svg": {
        fill: theme.colors[3],
        width: 25,
        height: 25,
        transition: `all .3s ${theme.easings["power3.out"]}`,
      },
      "& $label": {
        fontSize: 14,
        color: theme.colors[1],
        position: "relative",
        zIndex: 1,
        display: "inline-block",
        "&::before": {
          content: '""',
          position: "absolute",
          zIndex: 1,
          left: 0,
          bottom: 1,
          width: "100%",
          height: 1,
          backgroundColor: "currentColor",
          transition: `transform .6s ${theme.easings["power3.out"]}`,
          transform: "scaleX(0)",
          transformOrigin: "100% 0",
        },
      },
      "@media (hover: hover)": {
        "&:hover": {
          "& $circle": {
            background: "transparent",
          },
          "& svg": {
            fill: theme.colors[1],
          },
          "& $label": {
            "&::before": {
              transform: "scaleX(1)",
              transformOrigin: "0 0",
            },
          },
        },
      },
    },
    circle: {},
    label: {},
    circleSm: {
      "& svg": {
        width: 16,
        height: 16,
      },
    },

    roundIco: {
      width: 50,
      height: 50,
      borderRadius: "50%",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      border: `1px solid ${theme.colors[1]}`,
      cursor: "pointer",
      "& svg": {
        fill: theme.colors[1],
      },
    },

    // Hero
    hero: {
      minHeight: "var(--vh)",
      paddingTop: (props) => `${props.headerHeight}px`,
      paddingBottom: 80,
      paddingLeft: 30,
      paddingRight: 30,
      textAlign: "center",
      position: "relative",
      zIndex: 1,
      fontSize: 18,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "& $wrapper": {
        display: "flex",
      },
    },
    title: {
      fontSize : 'calc(var(--width)*0.031px)!important',
      fontWeight : '500',
      fontFamily: theme.fonts[1],
      marginBottom: 30,
      textAlign : 'start'
    },
    text: {
      fontSize: 18,
      marginBottom: 20,
    },
    content : {
      maxWidth :   'calc(var(--width)*0.44px)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      '& .copy' : {
        lineHeight : '100%',
        maxWidth : 'calc(var(--width)*0.29px)',
        fontSize : 'calc(var(--width)*0.010px)',
        textAlign : 'start',
      },
      "& button" : {
        marginTop : '50px'
      }
    },
    progress : {
      marginTop : 'calc(var(--height)*0.043px)',
      marginLeft : 'calc(var(--width)*0.045px)',
      position : 'relative',
      "& .averageEventScore" : {
        position : 'absolute',
        bottom : '-15%',
        left : '50%',
        transform : 'translateX(-50%)',
        minWidth : '390px'
      }
    }
    




  };


  /*------------------------------
    MD
    ------------------------------*/
  output[theme.mediaInverse.md] = {
    hero : {
      justifyContent : 'start'
    },
    wrapper: {
      extend: theme.wrapper,
      position: "relative",
      zIndex: 1,
      display : 'flex',
      flexDirection : "column",
      justifyContent : 'start',
      paddingTop : 'calc(var(--height)*0.05px)',
    },
    title: {
      fontSize : 'calc(var(--width)*0.072px)!important',
      fontWeight : '500',
      fontFamily: theme.fonts[1],
      marginBottom: 30,
      textAlign : 'start'
    },
    content : {
      maxWidth :   '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      '& .copy' : {
        lineHeight : '100%',
        maxWidth : 'calc(var(--width)*0.67px)',
        fontSize : 'calc(var(--width)*0.025px)',
        textAlign : 'start',
      },
      "& button" : {
        marginTop : '50px'
      }
    },
    progress : {
      margin : ['calc(var(--height)*0.051px)',0]
    }
  };



  return output;
};

export default style;
