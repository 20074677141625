import {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
  useCallback,
} from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import CountUp from "react-countup";
import { Context as GeneralContext } from "@/context";
import { Context as AudioContext } from "@/context/audio";
import Meta from "@/components/Meta";
import RevealText from "@/components/RevealText";
import RevealFadeIn from "@/components/RevealFadeIn";
import LocomotiveScroll from "@/components/LocomotiveScroll";
import usePagePrecache from "@/hooks/usePagePrecache";
import useWindowSize from "@/hooks/useWindowSize";
import { convertRichText } from "@/utils/strings";
import * as contentActions from "@/actions/content";
import * as liteExperienceActions from "@/actions/lite-experience";
import * as routerActions from "@/actions/lite-fakerouter";
import style from "./style";
import LiteProgress from "../../../components/LiteProgress";
import classNames from "classnames";
import LiteProgresContainer from "../../../components/LiteProgressContainer";

const useStyles = createUseStyles(style);
const LiteResults = () => {
  const { setPageAnimationReady, pageAnimationReady, headerHeight } =
    useContext(GeneralContext);
  const [isDataFetched, setDataFetched] = useState(false);
  const [activeEmotion, setActiveEmotion] = useState();
  const { width, height } = useWindowSize();
  const $root = useRef();
  const slug = "lite-results";
  /*------------------------------
  Redux Store
  ------------------------------*/
  const {
    currentLanguage,
    page,
    strings,
    emotions,
    cognitiveScore,
    emotionalScore,
    cognitiveLength,
    emotionalLength,
    expAvg,
    emotionData,
    event_id,
  } = useSelector(
    (state) => ({
      currentLanguage: state.locale.currentLanguage,
      page: state.content[slug] || {},
      strings: state.options.strings,
      emotions: state.emotions.items,
      emotionData: state.liteExperience.emotions,
      cognitiveScore : state.liteExperience.cognitiveScore,
      emotionalScore : state.liteExperience.emotionalScore,
      cognitiveLength : state.liteExperience.cognitiveLength,
      emotionalLength : state.liteExperience.emotionalLength,
      expAvg: state.liteExperience.expStateOfMind,
      event_id: state.liteExperience.event_id,
    }),
    shallowEqual
  );

  const classes = useStyles({ headerHeight });
  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch();
  const fetchContent = useCallback(
    (slug) => dispatch(contentActions.fetchContent(slug, "liteResult")),
    [dispatch]
  );
  const saveExperience = useCallback(
    async () => dispatch(liteExperienceActions.saveLiteExperience()),
    [dispatch]
  );
  const fetchEventStateOfMind = useCallback(
    async (event_id) =>
      dispatch(liteExperienceActions.getEventStateOfMind(event_id)),
    [dispatch]
  );
  const moveForward = useCallback(
    () => dispatch(routerActions.moveForward()),
    [dispatch]
  );

  useEffect(() => {
    (async () => {
      if (event_id) {
        await saveExperience();
        await fetchEventStateOfMind(event_id);
      }
    })();
  }, [event_id]);

  useEffect(() => {
    if (Object.keys(page).length === 0) fetchContent(slug);
  }, [currentLanguage]);
  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0) setDataFetched(true);
  }, [page]);
  /*------------------------------
  Preload
  ------------------------------*/
  const [load] = usePagePrecache({
    init: isDataFetched,
    sources: [],
    callback: () => setPageAnimationReady(true),
  });
  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && <Meta />;
  };
  /*------------------------------
  Render Hero
  ------------------------------*/
  const renderHero = () => {
    return (
      <div className={classes.hero}>
        <div
          style={{
            display: "flex",
            alignItems: width >= 992 ? "center" : "start",
            justifyContent: "center",
            ...(width >= 992 && { minHeight: "100vh" }),
            ...(width < 992 && { height: "100vh" }),
            "--sWidth": width,
            "--sHeight": height,
          }}
        >
          <div className={classes.wrapper}>
            <div className={classes.wrapperInside}>
              <RevealText
                tag="h1"
                type="lines"
                value={
                  !!activeEmotion
                    ? activeEmotion.group == 'emotional' ? strings['eventresults.traitgroup1'] : strings['eventresults.traitgroup2']
                    : page.heading
                }
                visible={pageAnimationReady}
                lineHeight={1.2}
                delay={0}
                className={classes.resultHeading}
                style={
                  !!activeEmotion
                    ? { "--color": activeEmotion.color }
                    : { "--color": "#485bc7" }
                }
              />
              <RevealText
                tag="h2"
                type="lines"
                value={
                  !!activeEmotion
                    ? activeEmotion.name.charAt(0).toUpperCase() +
                      activeEmotion.name.slice(1)
                    : page.subHeading
                }
                visible={pageAnimationReady}
                lineHeight={1.2}
                delay={0}
                className={classes.resultSubheading}
                style={
                  !!activeEmotion
                    ? { "--color": activeEmotion.color }
                    : { "--color": "#485bc7" }
                }
              />
              <RevealText
                tag="div"
                type="lines"
                value={
                  !!activeEmotion
                    ? activeEmotion.description
                    : convertRichText(page.bodyCopy)
                }
                visible={pageAnimationReady}
                lineHeight={1.4}
                delay={1.3}
                className={classes.beginText}
                style={
                  !!activeEmotion
                    ? { "--color": activeEmotion.color }
                    : { "--color": "#485bc7" }
                }
              />
              {pageAnimationReady && !!activeEmotion == false && (
                <div
                  className={classNames({ [classes.resultBarsWrapper]: true })}
                >
                  <div className={"activeBox_bar"}>
                    <div className="label">{strings['eventresults.traitgroup1']}</div>
                    <div className="barWrapper">
                      <div
                        className="bar"
                        style={{ "--color": `#485bc7${80}` }}
                      >
                        <div
                          style={{
                            "--color": "#485bc7",
                            "--width": `${emotionalScore * 100 / emotionalLength*10}%`,
                          }}
                          className="progress"
                        />
                      </div>
                      <div className="score">{`${emotionalScore}/${emotionalLength*10}`}</div>
                    </div>
                  </div>
                  <div className={"activeBox_bar"}>
                    <div className="label">{strings['eventresults.traitgroup2']}</div>
                    <div className="barWrapper">
                      <div
                        className="bar"
                        style={{ "--color": `#485bc7${80}` }}
                      >
                        <div
                          style={{
                            "--color": "#485bc7",
                            "--width": `${cognitiveScore * 100 / cognitiveLength*10}%`,
                          }}
                          className="progress"
                        />
                      </div>
                      <div className="score">{`${cognitiveScore}/${cognitiveLength*10}`}</div>
                    </div>
                  </div>
                </div>
              )}
              {pageAnimationReady && (
                <div className={classes.metricCircles}>
                  <div className="label">
                    <h4>{page.cta}</h4>
                  </div>
                  <div className="bigCircles circles">
                    {emotions.map((e) => (
                      <svg
                        className={classNames({
                          ["emotion"]: true,
                          ["active"]: activeEmotion == e,
                        })}
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={(event) => {
                          setActiveEmotion(e);
                        }}
                      >
                        <circle
                          fill={e.color}
                          cx="17"
                          cy="17"
                          r="10"
                          className="circle"
                        />
                        <circle
                          style={{ "--color": e.color }}
                          cx="17"
                          cy="17"
                          r="15"
                          className="circleBorder"
                        />
                      </svg>
                    ))}
                  </div>
                  <div className="circles smallCircles">
                    {emotions.map((e) => (
                      <svg
                        className={classNames({
                          ["emotion"]: true,
                          ["active"]: activeEmotion == e,
                        })}
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={(event) => {
                          setActiveEmotion(e);
                        }}
                      >
                        <circle
                          fill={e.color}
                          cx="12"
                          cy="12"
                          r="6"
                          className="circle"
                        />
                        <circle
                          style={{ "--color": e.color }}
                          cx="12"
                          cy="12"
                          r="8"
                          className="circleBorder"
                        />
                      </svg>
                    ))}
                  </div>
                </div>
              )}
              {!!activeEmotion && (
                <button
                  className={classNames({
                    [classes.btn]: true,
                  })}
                  onClick={() => {
                    setActiveEmotion(undefined);
                  }}
                >
                  <span>{page.backToOverviewButton}</span>
                </button>
              )}
            </div>
            {pageAnimationReady && (
              <RevealFadeIn delay={1} inview={pageAnimationReady}>
                <LiteProgresContainer
                  activeEmotion={activeEmotion}
                  emotionData={emotionData}
                  score={expAvg}
                />
              </RevealFadeIn>
            )}
          </div>
          <div
            className={classes.mobileWrapper}
            style={{ "--width": width, "--height": height }}
          >
            <div className={classes.wrapperInside}>
              <RevealText
                tag="h1"
                type="lines"
                value={
                  !!activeEmotion
                    ? activeEmotion.group == 'emotional' ? strings['eventresults.traitgroup1'] : strings['eventresults.traitgroup2']
                    : page.heading
                }
                visible={pageAnimationReady}
                lineHeight={1.2}
                delay={0}
                className={classes.resultHeading}
                style={{
                  "--color": !!activeEmotion ? activeEmotion.color : "#485bc7",
                  "--fontSize": `${(width - 50) * 0.08}px`,
                }}
              />
              <RevealText
                tag="h2"
                type="lines"
                value={
                  !!activeEmotion
                    ? activeEmotion.name.charAt(0).toUpperCase() +
                      activeEmotion.name.slice(1)
                    : page.subHeading
                }
                visible={pageAnimationReady}
                lineHeight={1.2}
                delay={0}
                className={classes.resultSubheading}
                style={{
                  "--color": !!activeEmotion ? activeEmotion.color : "#485bc7",
                  "--fontSize": `${(width - 50) * 0.055}px`,
                }}
              />
              <RevealText
                tag="div"
                type="lines"
                value={
                  !!activeEmotion
                    ? activeEmotion.description
                    : convertRichText(page.bodyCopy)
                }
                visible={pageAnimationReady}
                lineHeight={1.4}
                delay={1.3}
                className={classes.beginText}
                style={{
                  "--fontSize": `${(width - 50) * 0.033}px`,
                  "--color": !!activeEmotion ? activeEmotion.color : "#485bc7",
                  marginBottom: !!activeEmotion
                    ? `${(width - 50) * 0.029}px`
                    : 0,
                }}
              />
              {pageAnimationReady && !!activeEmotion == false && (
                <div
                  className={classNames({ [classes.resultBarsWrapper]: true })}
                >
                  <div className={"activeBox_bar"}>
                    <div
                      className="label"
                      style={{ "--fontSize": `${(width - 50) * 0.033}px` }}
                    >
                      {strings['eventresults.traitgroup1']}
                    </div>
                    <div className="barWrapper">
                      <div
                        className="bar"
                        style={{ "--color": `#485bc7${80}` }}
                      >
                        <div
                          style={{
                            "--color": "#485bc7",
                            "--width": `${emotionalScore * 100 / emotionalLength*10}%`,
                          }}
                          className="progress"
                        />
                      </div>
                      <div
                        className="score"
                        style={{ "--fontSize": `${(width - 50) * 0.033}px` }}
                      >{`${emotionalScore}/${emotionalLength*10}`}</div>
                    </div>
                  </div>
                  <div className={"activeBox_bar"}>
                    <div
                      className="label"
                      style={{ "--fontSize": `${(width - 50) * 0.033}px` }}
                    >
                      {strings['eventresults.traitgroup2']}
                    </div>
                    <div className="barWrapper">
                      <div
                        className="bar"
                        style={{ "--color": `#485bc7${80}` }}
                      >
                        <div
                          style={{
                            "--color": "#485bc7",
                            "--width":`${cognitiveScore * 100 / cognitiveLength*10}%`,
                          }}
                          className="progress"
                        />
                      </div>
                      <div
                        className="score"
                        style={{ "--fontSize": `${(width - 50) * 0.033}px` }}
                      >{`${cognitiveScore}/${cognitiveLength*10}`}</div>
                    </div>
                  </div>
                </div>
              )}
              {pageAnimationReady && (
                <div
                  className={classes.metricCircles}
                  style={
                    !!activeEmotion
                      ? { marginBottom: `${(width - 50) * 0.033}px` }
                      : { marginBottom: 0 }
                  }
                >
                  <div
                    className="label"
                    style={{ "--fontSize": `${(width - 50) * 0.033}px` }}
                  >
                    <h4>Click on a metric to explore</h4>
                  </div>
                  <div className="circles bigCircles">
                    {emotions.map((e) => (
                      <svg
                        viewBox="0 0 100 100"
                        width="100%"
                        height="100%"
                        className={classNames({
                          ["emotion"]: true,
                          ["active"]: activeEmotion == e,
                        })}
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={(event) => {
                          setActiveEmotion(e);
                        }}
                      >
                        <circle
                          fill={e.color}
                          cx="50"
                          cy="50"
                          r="50"
                          className="circle"
                        />
                        <circle
                          style={{ "--color": e.color }}
                          cx="50"
                          cy="50"
                          r="50"
                          className="circleBorder"
                        />
                      </svg>
                    ))}
                  </div>
                </div>
              )}
              {!!activeEmotion && (
                <button
                  className={classNames({
                    [classes.btn]: true,
                  })}
                  onClick={() => {
                    setActiveEmotion(undefined);
                  }}
                >
                  <span>{page.backToOverviewButton}</span>
                </button>
              )}
            </div>
            {pageAnimationReady && (
              <RevealFadeIn delay={1} inview={pageAnimationReady}>
                <LiteProgresContainer
                  activeEmotion={activeEmotion}
                  emotionData={emotionData}
                  score={expAvg}
                />
              </RevealFadeIn>
            )}
            <div className={classes.buttonWrapper}>
              <button
                onClick={() => {
                  moveForward();
                }}
                className={classNames({
                  [classes.btn]: true,
                  ["continueBtn"]: true,
                })}
              >
                <span>{page.button}</span>
              </button>
            </div>
          </div>
          <button
            onClick={() => {
              moveForward();
            }}
            className={classNames({
              [classes.btn]: true,
              ["continueBtn"]: true,
              ["desktopBtn"]: true,
            })}
          >
            <span>{page.button}</span>
          </button>
        </div>
        {/* <RevealText
          tag="div"
          type="lines"
          value={convertRichText(page.disclaimer)}
          visible={pageAnimationReady}
          lineHeight={1.2}
          delay={2.2}
          className={classes.beginDisclaimer}
        /> */}
      </div>
    );
  };
  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return (
      load && (
        <>
          <div className={classes.page}>{renderHero()}</div>
        </>
      )
    );
  };

  return (
    <LocomotiveScroll
      init={load}
      className={`page ${classes.root}`}
      ref={$root}
    >
      {renderHelmet()}
      {renderContent()}
    </LocomotiveScroll>
  );
};
export default LiteResults;
