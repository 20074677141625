import injectSheet from 'react-jss'
import { useSelector, shallowEqual } from 'react-redux'
import classNames from 'classnames'

import style from '../style'

const TextField = ({
  classes,
  disabled,
  label,
  name,
  onBlur,
  onChange,
  required,
  type,
  value,
  error,
  touched,
  withLabel,
  submitCount,
}) => {
  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { strings } = useSelector((state) => ({
    strings: state.options.strings,
  }), shallowEqual)

  return (
    <div
      className={classNames({
        [classes.formControl]: true,
        [classes.formControlDisabled]: disabled,
        [classes.formError]: (error && touched) || (error && submitCount >= 1),
      })}
    >
      <label>
        {withLabel && (
          <span className={classes.label}>
            {strings[`form.fields.${name}`]}
            {' '}
            {required ? '*' : ''}
          </span>
        )}
        <input
          className={classes.input}
          type={type}
          name={name}
          placeholder={strings[`form.fields.${name}`]}
          onChange={onChange}
          onBlur={onBlur}
          value={value || ''}
          disabled={disabled}
        />
      </label>
      <div
        className={classNames({
          [classes.error]: true,
          [classes.visible]: (error && touched) || (error && submitCount >= 1),
        })}
      >
        {error}
      </div>
    </div>
  )
}

TextField.defaultProps = {
  withLabel: false,
}

export default injectSheet(style)(TextField)
