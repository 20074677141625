import { useEffect, useState, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import gsap from 'gsap'
import ConditionalWrapper from '@/components/ConditionalWrapper'
import ParseText from '@/components/ParseText'
import DelayLink from '@/components/DelayLink'
import { useResize } from '@/components/Handlers'
import SplitText from '@/vendors/bonus-files-for-npm-users/umd/SplitText'
import stylo from './style'

const useStyles = createUseStyles(stylo)

const RevealText = ({
  className,
  value,
  lineHeight,
  delay,
  stagger,
  tag,
  type,
  duration,
  ease,
  link,
  visible,
  inview,
  style,
  key
}) => {
  // Il line-height minimo per la visualizzazione corretta di un overflow:hidden è 1.25
  // Passo ad ogni linea il margine negativo in em per correggere il line-height passato dalle props
  const linesMargin = -1.25 + lineHeight
  const classes = useStyles({ linesMargin })
  const $root = useRef()
  const $words = useRef()
  const $split = useRef()
  const winWidth = useRef()
  const Tag = tag
  const [splitted, setSplitted] = useState(false)
  const [readyText, setReadyText] = useState(false)

  /*------------------------------
  Wrap Line
  ------------------------------*/
  const wrapLine = (el, wrapper) => {
    el.parentNode.insertBefore(wrapper, el)
    wrapper.classList.add('line')
    wrapper.appendChild(el)
  }

  /*------------------------------
  Split
  ------------------------------*/
  const split = () => {
    if (!$words.current) return
    $split.current = new SplitText($words.current, { type: `lines ${type}` })
    gsap.set($split.current[type], {
      y: '155%',
      transformOrigin: 'center bottom',
    })
    setSplitted(true)

    $split.current.lines.forEach((line) => {
      wrapLine(line, document.createElement('div'))
    })
  }

  /*------------------------------
  Resize
  ------------------------------*/
  useResize(() => {
    if (winWidth.current !== window.innerWidth && $split.current) {
      $split.current.revert()
      setSplitted(false)
      setTimeout(() => {
        split()
        winWidth.current = window.innerWidth
      }, 200)
    }
  }, [])

  /*------------------------------
  Init
  ------------------------------*/
  useEffect(() => {
    if (readyText) {
      winWidth.current = window.innerWidth
      split()
    }
  }, [readyText])
  const splitCallback = () =>{
    if($split && $split.current){
      // $split.current.revert()
      setSplitted(false)
    }
    setTimeout(() => {
      split()
      winWidth.current = window.innerWidth
    }, 200)
  }

  /*------------------------------
  Visible
  ------------------------------*/
  useEffect(() => {
    if (splitted) {
      gsap.to($split.current[type], {
        y: (visible || inview) ? '0%' : '155%',
        duration,
        stagger,
        ease,
        delay,
      })
    }
  }, [visible, inview, splitted])

  return (
    <Tag
      key={key}
      ref={$root}
      className={classNames({
        [className]: className,
        [classes.root]: true,
        [classes.hidden]: !splitted,
        [classes.visible]: visible,
        visible: visible || inview,
      })}
      style={style}
    >
      <ConditionalWrapper
        condition={link}
        wrapper={(child) => <DelayLink className={classes.link} to={link}>{child}</DelayLink>}
      >
        <ParseText
          className={classes.wrap}
          text={value}
          ref={$words}
          onReadyCallback={() => splitCallback()}
        />
      </ConditionalWrapper>
    </Tag>
  )
}

/*------------------------------
Default Props
------------------------------*/
RevealText.defaultProps = {
  className: '',
  value: '',
  type: 'chars', // chars, words, or lines
  delay: 0,
  lineHeight: 1.25,
  tag: 'div',
  stagger: 0.12,
  duration: 2,
  ease: 'power4.out',
  link: false,
  visible: false,
  inview: false,
}

export default RevealText
