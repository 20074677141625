import axios from "axios";
import { axiosInstance } from "@/base/axios";
import {
  REQUEST_EVENTS,
  RECEIVE_DAILY_EVENTS,
  RECEIVE_TOTAL_EVENTS,
  REQUEST_EVENT_IMAGE,
  RECEIVE_EVENT_IMAGE,
  REQUEST_TAGS,
  RECEIVE_TAGS,
  SET_COUNTRY_CODE,
  SET_CURRENT_TAG,
} from "@/actions/types";
import { API, CONTENTFUL_SPACE, CONTENTFUL_TOKEN } from "@/constants";
import { isDateBetween, dateToTimestamp } from "../utils/date";

const requestEvents = () => ({ type: REQUEST_EVENTS });
const requestTags = () => ({ type: REQUEST_TAGS });

const receiveDailyEvents = (data) => ({
  type: RECEIVE_DAILY_EVENTS,
  payload: data,
});

const receiveTotalEvents = (data) => ({
  type: RECEIVE_TOTAL_EVENTS,
  payload: data,
});

const receiveTags = (data) => ({
  type: RECEIVE_TAGS,
  payload: data,
});
const fetchStrapiEvents = (today, tag) => async (dispatch, getState) => {
  dispatch(requestEvents());
  if (today) today = dateToTimestamp(today);
  const localeCode =
    getState().locale.currentLanguage ?? document.documentElement.lang;
  const query = today
    ? `events?filters[startDate][$lte]=${today}&filters[endDate][$gte]=${today}&locale=${localeCode}&populate=*`
    : "events?pagination[start]=0&pagination[limit]=999&populate=*";
  const response = await axiosInstance.get(query);
  const todayDate = new Date();
  let filteredResponse = response.data.data
    .map((it) => {
      const splitWhere = it.attributes.where?.split(";");
      if (splitWhere?.length !== 2) {
        return {
          ...it.attributes,
          id: it.id,
        };
      }
      return {
        ...it.attributes,
        where: {
          lat: splitWhere[0],
          lon: splitWhere[1],
        },
        id: it.id,
      };
    })
    .filter((it) => {
      if (today) {
        return isDateBetween(todayDate, it.startDate, it.endDate);
      } else {
        return true;
      }
    });
  filteredResponse = filteredResponse.map((event) => {
    Object.keys(event).forEach((key) => {
      if (typeof event[key] === "object" && event[key] && event[key]["data"]) {
        if (event[key]["data"]["attributes"]) {
          event[
            key
          ] = `${API.STRAPI_CMS}${event[key]["data"]["attributes"]["url"]}`;
        }
      }
    });
    return event;
  });

  if (today) {
    dispatch(receiveDailyEvents(filteredResponse));
  } else {
    dispatch(receiveTotalEvents(filteredResponse));
  }
};

const fetchTags = () => async (dispatch) => {
  dispatch(requestTags());
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
    },
  };
  const response = await axios.get(
    `${API.CMS}/${CONTENTFUL_SPACE}/tags`,
    params
  );
  dispatch(receiveTags(response.data.items));
};

const requestImage = () => ({ type: REQUEST_EVENT_IMAGE });

const receiveEventImage = (data, key) => ({
  type: RECEIVE_EVENT_IMAGE,
  payload: data,
  key,
});

const fetchEventImage = (id) => async (dispatch, getState) => {
  dispatch(requestImage());
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      locale: getState().locale.currentLanguage,
    },
  };
  const response = await axios.get(
    `${API.CMS}/${CONTENTFUL_SPACE}/assets/${id}`,
    params
  );
  dispatch(receiveEventImage(response.data.fields.file, "eventImage"));
};

const fetchSelectedEventImage = (id) => async (dispatch, getState) => {
  dispatch(requestImage());
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      locale: getState().locale.currentLanguage,
    },
  };
  const response = await axios.get(
    `${API.CMS}/${CONTENTFUL_SPACE}/assets/${id}`,
    params
  );
  const { file } = response.data.fields;
  dispatch(receiveEventImage(file, "selectedEventImage"));
  return file;
};

const fetchEventLogo = (id) => async (dispatch, getState) => {
  dispatch(requestImage());
  const params = {
    params: {
      access_token: CONTENTFUL_TOKEN,
      locale: getState().locale.currentLanguage,
    },
  };
  const response = await axios.get(
    `${API.CMS}/${CONTENTFUL_SPACE}/assets/${id}`,
    params
  );
  dispatch(receiveEventImage(response.data.fields.file, "eventLogo"));
  const { file } = response.data.fields;
  return file;
};

const fetchExistentCountryCode = () => {
  const currentCountryCode = localStorage.getItem("country_code") || null;
  return {
    type: SET_COUNTRY_CODE,
    payload: currentCountryCode,
  };
};

const setCountryCode = (code) => {
  localStorage.setItem("country_code", code);
  return {
    type: SET_COUNTRY_CODE,
    payload: code,
  };
};

const setCurrentTag = (tag) => {
  return {
    type: SET_CURRENT_TAG,
    payload: tag,
  };
};

export {
  fetchStrapiEvents,
  fetchTags,
  fetchEventImage,
  fetchSelectedEventImage,
  fetchEventLogo,
  fetchExistentCountryCode,
  setCountryCode,
  setCurrentTag,
};
