import { useRef, useState, useEffect, useContext, useCallback } from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Context as GeneralContext } from "@/context";
import Meta from "@/components/Meta";
import Card from "@/components/Card";
import LocomotiveScroll from "@/components/LocomotiveScroll";
import useMediaQuery from "@/hooks/useMediaQuery";
import usePagePrecache from "@/hooks/usePagePrecache";
import * as routerActions from "@/actions/lite-fakerouter";
import * as liteExperienceActions from "@/actions/lite-experience";
import * as questionsActions from "@/actions/questions";
import { dividedByTen } from "@/utils/math";
import { EVENTS, EMITTER } from "@/constants";

import stylePre from "./style";
import stylePost from "./stylePost";

const useStylesPre = createUseStyles(stylePre);
const useStylesPost = createUseStyles(stylePost);

const LiteQuestions = () => {
  const { setPageAnimationReady, headerHeight } = useContext(GeneralContext);
  const [mediaQueryKey] = useMediaQuery();
  const $root = useRef();
  const [active, setActive] = useState(0);
  const activeRef = useRef(active);
  const [goOn, setGoOn] = useState(false);
  const [isDataFetched, setDataFetched] = useState(false);
  const cardWidth = useRef(mediaQueryKey > 2 ? 442 : 330);
  const timerNext = useRef();
  const questionsNum = useRef();
  const results = useRef();

  /*------------------------------
  Redux Store
  ------------------------------*/
  const { emotions, step, strings, questions } = useSelector(
    (state) => ({
      emotions: state.emotions.items.filter((e) => e.step === "questions"),
      strings: state.options.strings,
      step: state.experience.step,
      questions: state.liteQuestions.items,
    }),
    shallowEqual
  );

  const classesPre = useStylesPre({ headerHeight });
  const classesPost = useStylesPost({ headerHeight });

  const classes = step === 1 ? classesPre : classesPost;

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch();
  const moveNext = useCallback(
    (name) => dispatch(routerActions.changeLocation(name)),
    [dispatch]
  );
  const setEmotion = useCallback(
    (value, key, status) =>
      dispatch(liteExperienceActions.setLiteSingleEmotion(value, key, status)),
    [dispatch]
  );
  const setAverages = useCallback(
    () => dispatch(liteExperienceActions.setLiteExperinceAverages()),
    [dispatch]
  );

  /*------------------------------
  Check data Fetched
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(questions).length > 0) {
      questionsNum.current = Object.keys(questions).filter(
        (qKey) => qKey.includes("question") || qKey.includes("Question")
      ).length;
      results.current = Array(questionsNum.current).fill(0);
      setDataFetched(true);
    }
  }, [questions]);

  /*------------------------------
  Preload
  ------------------------------*/
  const [load] = usePagePrecache({
    init: isDataFetched,
    sources: [],
    callback: () => {
      cardWidth.current = mediaQueryKey > 1 ? 442 : 330;
      setPageAnimationReady(true);
    },
  });

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && <Meta />;
  };

  /*------------------------------
  Active Ref
  ------------------------------*/
  useEffect(() => {
    activeRef.current = active;
  }, [active]);

  /*------------------------------
  Resize
  ------------------------------*/
  useEffect(() => {
    cardWidth.current = mediaQueryKey > 1 ? 442 : 330;
  }, [mediaQueryKey]);

  /*------------------------------
  SetResult
  ------------------------------*/
  const setResult = (answer, vote) => {
    results.current[answer] = vote;
    if (!results.current.some((a) => a === 0)) {
      setGoOn(true); // Se nessuna delle risposte ha valore zero, allora abilito il pulsante continua
    } else {
      setActive(Math.min(activeRef.current + 1, questionsNum.current - 1));
    }
  };

  /*------------------------------
  Render Counter
  ------------------------------*/
  const renderCounter = () => {
    return (
      <div className={classes.counter}>
        <strong>{`${
          active + 1 < 10 ? "0" + (active + 1).toString() : active + 1
        }`}</strong>
        <span>{`${questionsNum.current}`}</span>
      </div>
    );
  };

  /*------------------------------
  Render Questions
  ------------------------------*/
  const renderQuestions = () => {
    return (
      <div className={classes.questionsWrapper}>
        <div
          className={classes.questions}
          style={{
            marginLeft: `${
              -(cardWidth.current * 0.5) - active * cardWidth.current
            }px`,
          }}
        >
          {Object.keys(questions).length > 0 &&
            Object.keys(questions)
              .filter(
                (qKey) => qKey.includes("question") || qKey.includes("Question")
              )
              .map((q, ind) => {
                const myPos = ind - active;
                return (
                  <Card
                    key={(ind + 1000).toString()}
                    index={ind}
                    className={classes.card}
                    title={step === 1 ? questions[q] : questions[q]}
                    isActive={ind === active}
                    onClick={setResult}
                    step={step}
                    hint={
                      step === 1
                        ? questions.key
                        : ind === 0
                        ? questions.scale
                        : questions.key
                    }
                    style={{
                      transform: `rotate(${myPos * 4}deg) translateY(${Math.abs(
                        myPos * 15 * myPos
                      )}px)`,
                    }}
                  />
                );
              })}
        </div>
      </div>
    );
  };
  /*------------------------------
  Handle Click Continue
  ------------------------------*/
  const handleClickContinue = () => {
    results.current.map((result, index) => {
      let emotion = questions["emotion" + (index + 1).toString()];
      setEmotion(
        results.current[index],
        questions["emotion" + (index + 1).toString()].key,
        "post"
      );
    });
    setAverages();
    moveNext("lite-result");
  };

  /*------------------------------
  Render Next
  ------------------------------*/
  const renderNext = () => {
    return (
      <div
        className={classNames({
          [classes.buttonNext]: true,
          disabled: !goOn,
        })}
      >
        <button className={classes.btn} onClick={handleClickContinue}>
          <span>
            {step === 1
              ? strings["questions.pre.next"]
              : strings["questions.post.next"]}
          </span>
        </button>
        <div className={classes.hint}>{strings["questions.hint"]}</div>
      </div>
    );
  };
  /*------------------------------
  Render Arrows
  ------------------------------*/
  const renderControls = () => {
    return (
      <div className={classes.controls}>
        <button
          onClick={() => {
            clearTimeout(timerNext.current);
            setActive(Math.max(0, active - 1));
          }}
          className={classNames({
            [classes.arrow]: true,
            carousel__prev: true,
          })}
        >
          <svg viewBox="0 0 11 21">
            <path
              fillRule="evenodd"
              d="M11.000,0.000 L11.000,21.000 L-0.000,10.000 L11.000,0.000 Z"
            />
          </svg>
        </button>
        <button
          onClick={() => {
            clearTimeout(timerNext.current);
            setActive(Math.min(active + 1, questionsNum.current - 1));
          }}
          className={classNames({
            [classes.arrow]: true,
            carousel__next: true,
          })}
        >
          <svg viewBox="0 0 11 21">
            <path
              fillRule="evenodd"
              d="M-0.000,0.000 L-0.000,21.000 L11.000,10.000 L-0.000,0.000 Z"
            />
          </svg>
        </button>
        {renderNext()}
      </div>
    );
  };

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return (
      load && (
        <>
          <div className={classes.page}>
            {renderCounter()}
            {renderQuestions()}
            {renderControls()}
          </div>
        </>
      )
    );
  };

  return (
    <>
      <LocomotiveScroll
        init={load}
        className={`page pageQuestions ${classes.root}`}
        ref={$root}
      >
        {renderHelmet()}
        {renderContent()}
      </LocomotiveScroll>
    </>
  );
};

export default LiteQuestions;
